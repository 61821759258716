<div class="layout-footer">
    <div>
        Copyright &copy; {{copyright}}
        <br />All Rights Reserved
        <br />Justin Loadholtz
    </div>
    <div class="flex gap-2 right">
        {{lastUpdated | date:'short'}}
        <br />v{{version}}
    </div>
</div>