<div class="p-fluid">
    <div class="grid form-group">
        <div class="field col-12 lg:col-3">
            <span class="p-float-label">
                <p-dropdown name="charts" [(ngModel)]="args.chart" [options]="charts" required (onChange)="onChartChanged()"></p-dropdown>
                <label>Chart</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1" *ngIf="shouldShowGolfRoundTypes">
            <span class="p-float-label">
                <p-dropdown name="golfRoundTypes" [(ngModel)]="args.golfRoundType" [options]="golfRoundTypes" required (onChange)="onGolfRoundTypeChanged()"></p-dropdown>
                <label>Golf Round Type</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1" *ngIf="shouldShowComparisons">
            <span class="p-float-label">
                <p-dropdown name="compare" [(ngModel)]="args.comparison" [options]="comparisons" required (onChange)="onComparisonChanged()"></p-dropdown>
                <label>Comparison</label>
            </span>
        </div>
        <div class="field col-12 lg:col-2" *ngIf="shouldShowGolfers">
            <span class="p-float-label">
                <p-dropdown [options]="golfers" [(ngModel)]="golferId" (onChange)="onGolferChanged()"></p-dropdown>
                <label>Golfer</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1" *ngIf="shouldShowYears">
            <span class="p-float-label">
                <p-dropdown [options]="yearsPlayed" [(ngModel)]="args.year" (onChange)="onYearChanged();"></p-dropdown>
                <label>Year</label>
            </span>
        </div>
    </div>

    <div>
        <my-miss-tendency-radar-chart [data]="chartData" *ngIf="showMissTendencyChart"></my-miss-tendency-radar-chart>
        <my-putting-by-starting-distance-line-chart [data]="chartData" [puttCountLabel]="getPuttCountLabel()" [isPercentage]="isPercentagePuttingChart" *ngIf="showPuttingByStartingDistanceChart"></my-putting-by-starting-distance-line-chart>
        <my-short-game-proximity-line-chart [data]="chartData" [shotTypeLabel]="getShortGameProximityShotTypeLabel()" *ngIf="showShortGameProximityChart"></my-short-game-proximity-line-chart>
        <my-proximity-by-shot-line-chart [data]="chartData" *ngIf="showProximityByShotChart"></my-proximity-by-shot-line-chart>
        <my-courses-played-pie-chart [data]="chartData" *ngIf="showCoursesPlayedChart"></my-courses-played-pie-chart>
        <my-score-by-hole-line-chart [data]="chartData" *ngIf="showScoreByHoleChart"></my-score-by-hole-line-chart>
        <my-scoring-average-per-par-bar-chart [data]="chartData" *ngIf="showGolfRoundScoringAverageChart"></my-scoring-average-per-par-bar-chart>
        <my-score-name-bar-chart [data]="chartData" *ngIf="showGolfRoundScoresChart"></my-score-name-bar-chart>
        <my-fairways-per-par-bar-chart [data]="chartData" *ngIf="showGolfRoundFairwaysChart"></my-fairways-per-par-bar-chart>
        <my-girs-per-par-bar-chart [data]="chartData" *ngIf="showGolfRoundGIRsChart"></my-girs-per-par-bar-chart>
        <my-putt-count-bar-chart [data]="chartData" *ngIf="showGolfRoundPuttsChart"></my-putt-count-bar-chart>
        <my-penalties-per-par-bar-chart [data]="chartData" *ngIf="showGolfRoundPenaltiesChart"></my-penalties-per-par-bar-chart>
        <my-running-strokes-line-chart [data]="chartData" *ngIf="showGolfRoundRunningStrokesChart"></my-running-strokes-line-chart>
        <my-running-score-line-chart [data]="chartData" *ngIf="showGolfRoundRunningScoreChart"></my-running-score-line-chart>
        <my-score-by-hole-line-chart [data]="chartData" *ngIf="showGolfRoundScoreByHoleChart"></my-score-by-hole-line-chart>
    </div>
</div>
