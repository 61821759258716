<div class="p-fluid">
    <div class="grid form-group">
        <div class="field col-12 lg:col-2">
            <span class="p-float-label">
                <p-dropdown [options]="golfers" [(ngModel)]="golferId" (onChange)="onGolferChanged()"></p-dropdown>
                <label>Golfer</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1">
            <span class="p-float-label">
                <p-dropdown [options]="goalYears" [(ngModel)]="args.year" (onChange)="onYearChanged();"></p-dropdown>
                <label>Year</label>
            </span>
        </div>
    </div>

    <div>
        <p-table [value]="goals" [loading]="isLoading" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md">
            <ng-template pTemplate="caption">
                Goals
                <i class="fas fa-plus-square new-goal clickable" [routerLink]="['/statistics/goals/new']" pTooltip="Create New Goal"></i>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="width5">Time Period</th>
                    <th class="left">Statistic</th>
                    <th class="width5">Rounds Included</th>
                    <th class="width4">Target</th>
                    <th class="width4">Actual</th>
                    <th class="width4">Difference</th>
                    <th class="width3">18 Hole<br />Rounds</th>
                    <th class="width3">9 Hole<br />Rounds</th>
                    <th class="width3">Days<br />Remaining</th>
                    <th class="width3" *ngIf="canEditGoals()"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-goal let-rowIndex="rowIndex">
                <tr class="rowgroup-header" *ngIf="shouldShowGoalRowGroupHeader(rowIndex)">
                    <td [attr.colspan]="canEditGoals() ? 10 : 9">
                        {{getGoalStatusDescription(goal)}}
                    </td>
                </tr>
                <tr>
                    <td class="center">
                        <span class="p-column-title">Time Period</span>
                        {{goal.periodDisplayText}}
                    </td>
                    <td class="left">
                        <i class="far fa-eye goal-visibility" *ngIf="goal.isVisibleToFriends" pTooltip="Visible to Friends"></i>
                        <span class="p-column-title">Statistic</span>
                        {{goal.statistic.displayName}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Rounds Included</span>
                        {{getRoundsIncludedDescription(goal)}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Target</span>
                        {{goal.formattedTarget}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Actual</span>
                        {{goal.formattedActual}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Difference</span>
                        <span [ngClass]="getAdornmentClass(goal)">{{goal.formattedDifference}}</span>
                    </td>
                    <td class="center">
                        <span class="p-column-title">18 Hole Rounds</span>
                        {{goal.total18HoleRounds | number}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">9 Hole Rounds</span>
                        {{goal.total9HoleRounds | number}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Days Remaining</span>
                        <span *ngIf="shouldShowDaysRemaining(goal)">{{goal.daysRemaining | number}}</span>
                        <span *ngIf="!shouldShowDaysRemaining(goal)">*</span>
                    </td>
                    <td class="center" *ngIf="canEditGoals()">
                        <span class="status-icons">
                            <i class="fas fa-clone clickable copy-icon" pTooltip="Copy Goal" (click)="copyGoal(goal)"></i>
                            <i class="fas fa-edit clickable edit-icon" pTooltip="Edit" (click)="editGoal(goal)"></i>
                            <i class="fas fa-trash-alt clickable delete-icon" pTooltip="Delete" (click)="deleteGoal(goal, rowIndex)"></i>
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="canEditGoals() ? 10 : 9">
                        {{getEmptyMessage('No goals.')}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p-confirmDialog key="goals-list-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
    </div>
</div>
