<p-table [value]="groups" [rows]="25" [loading]="isLoading" sortField="strokesGainedVsScratch" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md partial-width">
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="ordinal" rowspan="2" class="left width8">
                {{nameHeader}}
                <p-sortIcon field="ordinal"></p-sortIcon>
            </th>
            <th pSortableColumn="totalShots" rowspan="2" class="width4">
                {{totalShotsHeader}}
                <p-sortIcon field="totalShots"></p-sortIcon>
            </th>
            <th pSortableColumn="avgDistance" rowspan="2" class="width4">
                Avg Distance
                <p-sortIcon field="avgDistance"></p-sortIcon>
            </th>
            <th *ngIf="showPercentOfShots" colspan="2" class="width4">% of</th>
            <th *ngIf="showStrokesGained" colspan="2" class="width3">Strokes Gained vs Pro</th>
            <th *ngIf="showStrokesGained" colspan="2" class="width3">Strokes Gained vs Scratch</th>
        </tr>
        <tr>
            <th pSortableColumn="percentOfSubset" *ngIf="showPercentOfShots" class="border-left width5">
                {{isPuttAnalysis ? 'All Putts' : 'Tee to Green Shots'}}
                <p-sortIcon field="percentOfSubset"></p-sortIcon>
            </th>
            <th pSortableColumn="percentOfAll" *ngIf="showPercentOfShots" class="width5">
                All Shots
                <p-sortIcon field="percentOfAll"></p-sortIcon>
            </th>
            <th pSortableColumn="strokesGainedVsProPerShot" *ngIf="showStrokesGained" class="border-left width4">
                Per {{isPuttAnalysis ? 'Putt' : 'Shot'}}
                <p-sortIcon field="strokesGainedVsProPerShot"></p-sortIcon>
            </th>
            <th pSortableColumn="strokesGainedVsPro" *ngIf="showStrokesGained" class="width4">
                Total
                <p-sortIcon field="strokesGainedVsPro"></p-sortIcon>
            </th>
            <th pSortableColumn="strokesGainedVsScratchPerShot" *ngIf="showStrokesGained" class="border-left width4">
                Per {{isPuttAnalysis ? 'Putt' : 'Shot'}}
                <p-sortIcon field="strokesGainedVsScratchPerShot"></p-sortIcon>
            </th>
            <th pSortableColumn="strokesGainedVsScratch" *ngIf="showStrokesGained" class="width4">
                Total
                <p-sortIcon field="strokesGainedVsScratch"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-group>
        <tr>
            <td>
                <span class="p-column-title">{{nameHeader}}</span>
                {{group.displayName}}
            </td>
            <td class="center">
                <span class="p-column-title">{{isPuttAnalysis ? 'Putts' : 'Shots'}}</span>
                {{group.totalShots | number}}
            </td>
            <td class="center">
                <span class="p-column-title">Avg Distance</span>
                {{group.avgDistance | number:'1.1-1'}}
            </td>
            <td *ngIf="showPercentOfShots" class="center">
                <span class="p-column-title">% of {{isPuttAnalysis ? 'All Putts' : 'Tee to Green Shots'}}</span>
                {{group.percentOfSubset | percent:'1.2-2'}}
            </td>
            <td *ngIf="showPercentOfShots" class="center">
                <span class="p-column-title">% of All Shots</span>
                {{group.percentOfAll | percent:'1.2-2'}}
            </td>
            <td *ngIf="showStrokesGained" class="center">
                <span class="p-column-title">Strokes Gained vs Pro/{{isPuttAnalysis ? 'Putt' : 'Shot'}}</span>
                {{group.strokesGainedVsProPerShot | number:'1.3-3'}}
            </td>
            <td *ngIf="showStrokesGained" class="center">
                <span class="p-column-title">Total Strokes Gained vs Pro</span>
                {{group.strokesGainedVsPro | number:'1.3-3'}}
            </td>
            <td *ngIf="showStrokesGained" class="center">
                <span class="p-column-title">Strokes Gained vs Scratch/{{isPuttAnalysis ? 'Putt' : 'Shot'}}</span>
                {{group.strokesGainedVsScratchPerShot | number:'1.3-3'}}
            </td>
            <td *ngIf="showStrokesGained" class="center">
                <span class="p-column-title">Total Strokes Gained vs Scratch</span>
                {{group.strokesGainedVsScratch | number:'1.3-3'}}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="columnCount">
                No stroke level data.
            </td>
        </tr>
    </ng-template>
</p-table>