<div class="p-fluid">
    <div class="grid form-group">
        <div class="field col-12 lg:col-3">
            <span class="p-float-label">
                <p-dropdown [options]="golfers" [(ngModel)]="golferId" (onChange)="onGolferChanged()"></p-dropdown>
                <label>Golfer</label>
            </span>
        </div>
        <div class="field col-12 lg:col-2">
            <span class="p-float-label">
                <p-dropdown name="golfRoundTypes" [(ngModel)]="golfRoundTypeId" [options]="golfRoundTypes" required (onChange)="onGolfRoundTypeChanged()"></p-dropdown>
                <label>Golf Round Type</label>
            </span>
        </div>
    </div>

    <div>
        <my-statistic-snapshot-chart [data]="chartData" [isPercentage]="isPercentage"></my-statistic-snapshot-chart>
    </div>
</div>
