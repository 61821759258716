import { Component, Input, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { SelectItem } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { MessagesComponent } from '../../../shared/ui/messages/messages.component';
import { CurrentGolferService } from '../../../shared/golfers/current-golfer.service';
import { GolfersControllerProxy, RoundsControllerProxy } from '../../../shared/server-proxies';
import { StorageService } from '../../../shared/core/storage.service';
import { ScorecardBaseDirective } from '../scorecard-base.directive';
import { ScorecardCalculatorService } from './scorecard-calculator.service';
import { SignalRHubConnectionFactory } from '../../core/signalr-hub-connection-factory.service';

import {
    ModelsCoreRoundsGolfRound,
    ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
    ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole
} from '../../../shared/swagger-codegen/models';

enum ScorecardViewMode {
    fullScorecard,
    holeStrokes
}

@Component({
    selector: 'my-scorecard',
    templateUrl: './scorecard.component.html',
    styleUrls: [
        './scorecard.component.scss'
    ]
})
export class ScorecardComponent extends ScorecardBaseDirective implements OnInit {
    constructor(
        roundsProxy: RoundsControllerProxy,
        golfersProxy: GolfersControllerProxy,
        currentGolfer: CurrentGolferService,
        hubConnectionFactory: SignalRHubConnectionFactory,
        private calculator: ScorecardCalculatorService,
        private decimalPipe: DecimalPipe,
        private storage: StorageService) {
        super(roundsProxy, golfersProxy, currentGolfer, hubConnectionFactory);
    }

    @Input() round: ModelsCoreRoundsGolfRound;
    @Input() messages: MessagesComponent;
    @Input() readOnly = false;
    @Input() showLegend = true;
    showColors = true;
    anyBackNines: boolean;
    loading: boolean;
    private viewMode = ScorecardViewMode.fullScorecard;
    private showColorsStorageKey = 'GolfRoundScorecard.ShowColors';

    get viewingFullScorecard() {
        return this.viewMode === ScorecardViewMode.fullScorecard;
    }

    get viewingHoleStrokes() {
        return this.viewMode === ScorecardViewMode.holeStrokes;
    }

    ngOnInit() {
        this.showColors = this.getShowColorsPreference();

        if(this.scorecard) {
            this.initializeScorecard({ includeEmptyDefaultGolfClubSelectItems: true });
            this.checkForBack9();
            this.initializeSelectLists();
        }
        else if(this.round && this.round.golfRoundId) {
            this.loading = true;
            this.loadScorecard(this.round.golfRoundId, { includeEmptyDefaultGolfClubSelectItems: true })
                .pipe(finalize(() => this.loading = false))
                .subscribe(
                    () => {
                        this.checkForBack9();
                        this.initializeSelectLists();
                    });
        }
    }

    saveScorecard() {
        const models = this.getModifiedScorecardData();

        if(models.length > 0) {
            this.saveScorecardModels(models)
                .pipe(
                    finalize(
                        () => {
                            this.messages.clearInMilliseconds(5000);
                        }))
                .subscribe(
                    () => {
                        this.messages.showSuccess('Changes have been saved.');
                    },
                    error => {
                        this.messages.showError(`An error occurred. (${error.status})`);
                    });
        }
        else {
            this.messages.showInfo('Data has not changed so saving is not necessary.');
            this.messages.clearInMilliseconds(5000);
        }
    }

    toggleViewMode() {
        this.viewMode = this.viewMode === ScorecardViewMode.holeStrokes
            ? ScorecardViewMode.fullScorecard
            : ScorecardViewMode.holeStrokes;
    }

    isPlaceholderHole(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        return (hole as any).isPlaceholder === true;
    }

    getHandicapForMenLabel() {
        return this.scorecard.hasFemaleGolfers ? 'Men\'s HCP' : 'Handicap';
    }

    getHandicapForWomenLabel() {
        return this.scorecard.hasMaleGolfers ? 'Women\'s HCP' : 'Handicap';
    }

    getParForMenLabel() {
        return this.scorecard.hasFemaleGolfers ? 'Men\'s Par' : 'Par';
    }

    getParForWomenLabel() {
        return this.scorecard.hasMaleGolfers ? 'Women\'s Par' : 'Par';
    }

    getOutLabel() {
        return this.scorecard.has18HoleRounds ? 'Out' : 'Total';
    }

    getStrokesCssClass(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        const relativeScore = hole.strokes - hole.par;
        const isPlaceholder = this.isPlaceholderHole(hole);
        /* eslint-disable @typescript-eslint/naming-convention */
        const css: { [key: string]: boolean } = {
            ace: hole.strokes === 1,
            eagle: relativeScore === -2,
            birdie: relativeScore === -1,
            par: relativeScore === 0,
            bogey: relativeScore === 1,
            'double-bogey': relativeScore === 2,
            'triple-bogey': relativeScore === 3,
            'quad-bogey': relativeScore >= 4,
            unscored: !isPlaceholder && (hole.strokes == null || hole.strokes < 1)
        };
        /* eslint-enable @typescript-eslint/naming-convention */

        this.applyEnableColorsOption(css);

        return css;
    }

    getStrokesGainedVsProTooltip(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        return this.getStrokesGainedTooltip(hole, true);
    }

    getStrokesGainedVsScratchTooltip(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        return this.getStrokesGainedTooltip(hole, false);
    }

    private getStrokesGainedTooltip(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole, vsPro: boolean) {
        const sgOffTheTee = vsPro ? hole.strokesGainedOffTeeVsPro : hole.strokesGainedOffTeeVsScratch;
        const sgApproach = vsPro ? hole.strokesGainedApproachVsPro : hole.strokesGainedApproachVsScratch;
        const sgAroundGreen = vsPro ? hole.strokesGainedAroundGreenVsPro : hole.strokesGainedAroundGreenVsScratch;
        const sgPutting = vsPro ? hole.strokesGainedPuttingVsPro : hole.strokesGainedPuttingVsScratch;
        const sgTeeToGreen = vsPro ? hole.strokesGainedTeeToGreenVsPro : hole.strokesGainedTeeToGreenVsScratch;
        const sgTotal = vsPro ? hole.strokesGainedTotalVsPro : hole.strokesGainedTotalVsScratch;

        if(sgTotal === undefined) {
            return undefined;
        }

        const table = `
            <table>
                <tr>
                    <td>SG: Off the Tee</td>
                    <td>${this.decimalPipe.transform(sgOffTheTee, '1.3-3')}</td>
                </tr>
                <tr>
                    <td>SG: Approach</td>
                    <td>${this.decimalPipe.transform(sgApproach, '1.3-3')}</td>
                </tr>
                <tr>
                    <td>SG: Around the Green</td>
                    <td>${this.decimalPipe.transform(sgAroundGreen, '1.3-3')}</td>
                </tr>
                <tr>
                    <td>SG: Putting</td>
                    <td>${this.decimalPipe.transform(sgPutting, '1.3-3')}</td>
                </tr>
                <tr>
                    <td>SG: Tee to Green</td>
                    <td>${this.decimalPipe.transform(sgTeeToGreen, '1.3-3')}</td>
                </tr>
                <tr>
                    <td>SG: Total</td>
                    <td>${this.decimalPipe.transform(sgTotal, '1.3-3')}</td>
                </tr>
            </table>
        `;

        return table;
    }
    
    shouldShowUnfinishedHoleAsterisk(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        return !hole.completed && hole.golfRoundGolferHoleId > 0;
    }

    getNetStrokesCssClass(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        /* eslint-disable @typescript-eslint/naming-convention */
        const css: { [key: string]: boolean } = {
            'adjusted-strokes': hole.strokes !== hole.netStrokes
        };
        /* eslint-enable @typescript-eslint/naming-convention */

        return css;
    }

    getAdjustedStrokesCssClass(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        /* eslint-disable @typescript-eslint/naming-convention */
        const css: { [key: string]: boolean } = {
            'adjusted-strokes': hole.strokes !== hole.adjustedStrokes
        };
        /* eslint-enable @typescript-eslint/naming-convention */

        return css;
    }

    getMetricCssClass(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole, metricName: string, propertyName?: string) {
        /* eslint-disable @typescript-eslint/naming-convention */
        const css: { [key: string]: boolean } = {
            'great-range': false,
            'good-range': false,
            'bad-range': false
        };
        /* eslint-enable @typescript-eslint/naming-convention */

        if(!propertyName) {
            propertyName = metricName;
        }

        if(hole.hasOwnProperty(propertyName) && this.scorecard.metricRanges.hasOwnProperty(metricName)) {
            const metric = hole[propertyName];
            const ranges = this.scorecard.metricRanges[metricName];

            if(ranges.great) {
                css['great-range'] = metric >= ranges.great.from && metric <= ranges.great.to;
            }

            if(ranges.good) {
                css['good-range'] = metric >= ranges.good.from && metric <= ranges.good.to;
            }

            if(ranges.bad) {
                css['bad-range'] = metric >= ranges.bad.from && metric <= ranges.bad.to;
            }
        }

        this.applyEnableColorsOption(css);

        return css;
    }

    onShowColorsChanged() {
        this.storage.setItem(this.showColorsStorageKey, this.showColors);
    }

    getFairwayResultLabel(fairwayResultId: number) {
        return this.getSelectItemLabel(this.fairwayResults, fairwayResultId);
    }

    getFairwayMissLabel(fairwayMissId: number) {
        return this.getSelectItemLabel(this.fairwayMisses, fairwayMissId);
    }

    getApproachShotResultLabel(approachShotResultId: number) {
        return this.getSelectItemLabel(this.approachShotResults, approachShotResultId);
    }

    getApproachShotMissLabel(approachShotMissId: number) {
        return this.getSelectItemLabel(this.approachShotMisses, approachShotMissId);
    }

    getShortGameResultLabel(shortGameResultId: number) {
        return this.getSelectItemLabel(this.shortGameResults, shortGameResultId);
    }

    getUpAndDownResultLabel(upAndDownResultId: number) {
        return this.getSelectItemLabel(this.upAndDownResults, upAndDownResultId);
    }

    getGolfClubLabel(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer, teeShotClubId: number) {
        const clubs = this.getGolfClubSelectItems(golfer);
        const club = clubs ? clubs.find(c => c.value === teeShotClubId) : undefined;
        return club ? club.label : undefined;
    }

    shouldAllowStatsCalculation(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return this.currentGolfer.isAdmin && golfer.isComplete;
    }

    calculateStats(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        this.roundsProxy.calculateStats({ golfRoundId: this.round.golfRoundId, golferId: golfer.golferId })
            .subscribe();
    }

    shouldShowProjectedFront9ScoreTooltip(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.holesCompleted > 0 && golfer.holesPlayed < 9;
    }

    shouldShowProjectedBack9ScoreTooltip(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.holesCompleted > 0 && golfer.holesPlanned > golfer.holesPlayed;
    }

    shouldShowProjectedScoreTooltip(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.holesCompleted > 0 && golfer.holesPlanned > golfer.holesPlayed;
    }

    getProjectedFront9ScoreTooltip(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return this.getProjectedScoreTooltipByHoles(golfer.front9);
    }

    getProjectedBack9ScoreTooltip(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return this.getProjectedScoreTooltipByHoles(golfer.back9);
    }

    getProjectedScoreTooltip(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return this.getProjectedScoreTooltipByHoles(golfer.front9, golfer.back9);
    }

    private getProjectedScoreTooltipByHoles(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        const projectedScore = this.calculator.getProjectedScore(...holeLists);
        const projectedParOutScore = this.calculator.getProjectedParOutScore(...holeLists);
        const projectedBogeyOutScore = this.calculator.getProjectedBogeyOutScore(...holeLists);
        const projectedBirdieOutScore = this.calculator.getProjectedBirdieOutScore(...holeLists);
        let rows = '';
        
        rows += `<tr><td>Projected Score:</td><td>${projectedScore}</td></tr>`;
        rows += `<tr><td>Par Out:</td><td>${projectedParOutScore}</td></tr>`;
        rows += `<tr><td>Bogey Out:</td><td>${projectedBogeyOutScore}</td></tr>`;
        rows += `<tr><td>Birdie Out:</td><td>${projectedBirdieOutScore}</td></tr>`;

        return `<table>${rows}</table>`;
    }

    private getSelectItemLabel(selectItems: SelectItem[], value: any) {
        const item = selectItems.find(i => i.value === value);
        return item ? item.label : undefined;
    }

    private checkForBack9() {
        this.anyBackNines = this.scorecard.golfers.some(
            golfer => {
                return golfer.back9.length === 9;
            });

        if(this.anyBackNines) {
            this.ensureAllGolfersHaveBackNinePlaceholderHoles();
        }
    }

    private ensureAllGolfersHaveBackNinePlaceholderHoles() {
        this.scorecard.golfers.forEach(golfer => {
            while(golfer.back9.length < 9) {
                const placeholder: any = { isPlaceholder: true };
                golfer.back9.push(placeholder as ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole);
            }
        });
    }

    private initializeSelectLists() {
        this.loadScorecardLookups({ includeEmptyDefaultSelectItems: true })
            .subscribe();
    }

    private applyEnableColorsOption(css: { [key: string]: boolean }) {
        if(!this.showColors) {
            Object.keys(css)
                .forEach(
                    key => {
                        css[key] = false;
                    });
        }
    }

    private getShowColorsPreference() {
        let preference = this.storage.getItem(this.showColorsStorageKey);

        if(preference === null) {
            preference = true;
        }

        return preference;
    }
}
