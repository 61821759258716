import { Input, ViewChild, Directive } from '@angular/core';
import { UIChart } from 'primeng/chart';
import { ChartType } from 'chart.js';
import { ChartService } from './chart.service';
import { BaseComponentDirective } from '../shared/ui/base-component.directive';

import {
    ModelsCoreSharedChartingChartData
} from '../shared/swagger-codegen/models';

@Directive()
export abstract class ChartBaseDirective<TChartType extends ChartType = ChartType> extends BaseComponentDirective {
    constructor(
        protected chartService: ChartService,
        chartType: TChartType) {
        super();
        this.chartType = chartType;
    }

    @Input() data: ModelsCoreSharedChartingChartData;
    options: any;

    @ViewChild(UIChart, { static: true })
    chart: UIChart;
    private myChartType: TChartType;

    set chartType(value: TChartType) {
        if(this.myChartType !== value) {
            this.myChartType = value;
            this.options = this.chartService.createDefaultChartOptions(value);

            if(this.chart) {
                this.chart.type = value;
                this.chart.reinit();
            }
        }
    }

    get chartType() {
        return this.myChartType;
    }
}
