import { Injectable } from '@angular/core';
import { HttpHandler, HttpEvent, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigurationService, VersionUpdate } from '../core/configuration.service';

@Injectable()
export class VersionHeaderInterceptor implements HttpInterceptor {
    constructor(private configuration: ConfigurationService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const version = this.configuration.version;
        let reqClone;

        if(version) {
            const headers = req.headers
                .set('X-Client', `Web: ${version}`);

            reqClone = req.clone(
                {
                    headers: headers
                });
        }
        else {
            reqClone = req;
        }

        return next.handle(reqClone)
            .pipe(
                tap((evt: HttpEvent<any>) => this.checkForNewClientVersion(evt))
            );
    }

    private checkForNewClientVersion(evt: HttpEvent<any>) {
        if(evt instanceof HttpResponse) {
            const response = evt as HttpResponse<any>;
            const newVersion = response.headers.get('X-Client-Upgraded');

            if(newVersion) {
                const versionUpdate: VersionUpdate = {
                    currentVersion: this.configuration.version,
                    newVersion: newVersion
                };

                this.configuration.versionUpdated(versionUpdate);
            }
        }
    }
}
