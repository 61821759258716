<form #courseForm="ngForm" autocomplete="off" novalidate="novalidate">
    <div class="grid form-group">
        <div class="field col-12">
            <span class="p-float-label">
                <input #name="ngModel" name="name" [(ngModel)]="course.name" maxlength="50" required pInputText class="width10" autofocus (change)="onNameChanged()">
                <my-validation-message [model]="name" type="required"></my-validation-message>
                <label>Name</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <input #shortName="ngModel" name="shortName" [(ngModel)]="course.shortName" maxlength="20" required pInputText>
                <i class="fas fa-question-circle help-icon" style="padding-left: 5px" pTooltip="A name of 20 characters or less that can be used in chart legends and other limited space areas."></i>
                <my-validation-message [model]="shortName" type="required"></my-validation-message>
                <label>Short Name</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <input #street1="ngModel" name="street1" [(ngModel)]="course.street1" maxlength="75" required pInputText class="width10">
                <my-validation-message [model]="street1" type="required"></my-validation-message>
                <label>Street 1</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <input #street2="ngModel" name="street2" [(ngModel)]="course.street2" maxlength="50" pInputText class="width10">
                <label>Street 2</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <input #city="ngModel" name="city" [(ngModel)]="course.city" maxlength="50" required pInputText class="width10">
                <my-validation-message [model]="city" type="required"></my-validation-message>
                <label>City</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <p-dropdown #stateCode="ngModel" name="stateCode" [options]="states" [(ngModel)]="course.stateCode" styleClass="state-selector" [autoDisplayFirst]="false" required (onChange)="onStateChanged()"></p-dropdown>
                <my-validation-message [model]="stateCode" type="required"></my-validation-message>
                <label>State</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <input #zip="ngModel" name="zip" maxlength="5" pattern="^\d{5}$" [(ngModel)]="course.zipCode" required pInputText class="width2">
                <my-validation-message [model]="zip" type="required"></my-validation-message>
                <label>Zip Code</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <p-dropdown #timeZone="ngModel" name="timeZone" [options]="timeZones" [(ngModel)]="course.timeZoneId" styleClass="width15" [autoDisplayFirst]="false" required></p-dropdown>
                <my-validation-message [model]="timeZone" type="required"></my-validation-message>
                <label>Time Zone</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <p-inputMask #phone="ngModel" name="phone" class="width4" mask="(999) 999-9999? x99999" [(ngModel)]="course.phoneNumber"></p-inputMask>
                <label>Phone Number</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <input #website="ngModel" type="url" name="website" maxlength="100" [(ngModel)]="course.websiteUrl" pInputText class="width15">
                <label>Website</label>
            </span>
        </div>
        <div class="col-12">
            <button type="submit" pButton label="Save Course" (click)="saveCourse()" [disabled]="isLoading || !courseForm.form.valid"></button>
        </div>
    </div>
</form>
