/// <reference types="google.maps" />
import {
    ModelsCoreCoursesMapMarkers,
    ModelsWebApiCoursesSaveCourseMapAreaCourseMapMarkerModel
} from '../../swagger-codegen/models';

import { DeleteMenu } from './delete-menu';
import { Disposable } from './disposable';
import { disposeMapComponents } from './map-utilities';

import LatLng = google.maps.LatLng;
import Marker = google.maps.Marker;
import MarkerOptions = google.maps.MarkerOptions;

export class MapMarkerWrapper implements Disposable {
    constructor(type?: ModelsCoreCoursesMapMarkers, options?: MarkerOptions) {
        this.myNativeMarker = new Marker(options);

        if(type) {
            this.nativeMarker.set('markerType', type);
        }
    }

    get nativeMarker() {
        return this.myNativeMarker;
    }
    
    get type() {
        return this.nativeMarker.get('markerType') as ModelsCoreCoursesMapMarkers;
    }
    
    private myNativeMarker: Marker;

    getPosition() {
        return this.nativeMarker.getPosition();
    }

    setPosition(position: LatLng) {
        this.nativeMarker.setPosition(position);
    }

    dispose() {
        if(this.nativeMarker) {
            disposeMapComponents(this.myNativeMarker);
            this.myNativeMarker = undefined;
        }
    }

    createUpdateModel() {
        const position = this.getPosition();
        const model: ModelsWebApiCoursesSaveCourseMapAreaCourseMapMarkerModel = {
            latitude: position.lat(),
            longitude: position.lng(),
            mapMarker: this.type
        };

        return model;
    }

    addDeleteListener(deleteMenu: DeleteMenu) {
        this.myNativeMarker.addListener('rightclick', (args) => {
            deleteMenu.open(args.latLng, this, `Delete ${this.nativeMarker.getTitle()}`);
        });
    }

    static getType(marker: Marker) {
        return marker.get('markerType') as ModelsCoreCoursesMapMarkers;
    }
}
