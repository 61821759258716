/* eslint-disable */
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { ConfigurationService } from '../shared/core/configuration.service';

@Component({
    selector: 'app-footer',
    styles: [
        '.right { text-align: right; }'
    ],
    templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {
    constructor(public layoutService: LayoutService, configuration: ConfigurationService) {
        const yearCreated = 2007;
        const currentYear = new Date().getFullYear();
        this.copyright = currentYear > yearCreated ? `${yearCreated} - ${currentYear}` : yearCreated.toString();
        this.lastUpdated = new Date(configuration.buildDate);
        this.version = configuration.version;
    }

    copyright: string;
    lastUpdated: Date;
    version: string;
}
