<div class="card">
    <h1>{{pageTitle}}</h1>
    <form #goalForm="ngForm" autocomplete="off" novalidate="novalidate">
        <div class="grid form-group">
            <div class="col-12 lg:col-6">
                <p-fieldset legend="Time Period" styleClass="with-bottom-margin">
                    <div class="grid form-group">
                        <div class="field col-12">
                            <span class="p-float-label">
                                <p-dropdown #goalType="ngModel" name="goalType" [styleClass]="'width4'" [(ngModel)]="selectedGoalType" [options]="goalTypes" required></p-dropdown>
                                <my-validation-message [model]="goalType" type="required"></my-validation-message>
                                <label>Goal Type</label>
                            </span>
                        </div>
                        <div class="field col-12 lg:col-2" *ngIf="shouldShowYear">
                            <span class="p-float-label">
                                <p-dropdown #year="ngModel" name="year" [styleClass]="'width2'" [(ngModel)]="goal.year" [options]="years" (onChange)="onYearChanged();" required></p-dropdown>
                                <my-validation-message [model]="year" type="required"></my-validation-message>
                                <label>Year</label>
                            </span>
                        </div>
                        <div class="field col-12 lg:col-2" *ngIf="shouldShowQuarter">
                            <span class="p-float-label">
                                <p-dropdown #quarter="ngModel" name="quarter" [styleClass]="'width2'" [(ngModel)]="goal.quarter" [options]="quarters" required></p-dropdown>
                                <my-validation-message [model]="quarter" type="required"></my-validation-message>
                                <label>Quarter</label>
                            </span>
                        </div>
                        <div class="field col-12 lg:col-2" *ngIf="shouldShowMonth">
                            <span class="p-float-label">
                                <p-dropdown #month="ngModel" name="month" [styleClass]="'width4'" [(ngModel)]="goal.month" [options]="months" required></p-dropdown>
                                <my-validation-message [model]="month" type="required"></my-validation-message>
                                <label>Month</label>
                            </span>
                        </div>
                    </div>
                </p-fieldset>
                <p-fieldset legend="Goal Details" styleClass="with-bottom-margin">
                    <div class="grid form-group">
                        <div class="field col-12">
                            <span class="p-float-label">
                                <p-dropdown #statistic="ngModel" name="statistic" [styleClass]="'width15'" [(ngModel)]="selectedStatistic" [options]="statistics" required></p-dropdown>
                                <my-validation-message [model]="statistic" type="required"></my-validation-message>
                                <label>Statistic</label>
                            </span>
                        </div>
                        <div class="field col-12">
                            <span class="p-float-label">
                                <input #target="ngModel" type="number" name="target" pInputText class="width3 center" [(ngModel)]="goal.target" required>
                                <my-validation-message [model]="target" type="required"></my-validation-message>
                                <label>Target</label>
                            </span>
                        </div>
                        <div class="field col-12">
                            <span class="p-float-label">
                                <p-dropdown #golfRoundType="ngModel" name="golfRoundType" [styleClass]="'width10'" [(ngModel)]="goal.golfRoundType" [options]="golfRoundTypes" required></p-dropdown>
                                <my-validation-message [model]="golfRoundType" type="required"></my-validation-message>
                                <label>Which Rounds Count Toward this Goal?</label>
                            </span>
                        </div>
                        <div class="col-12">
                            <p-checkbox name="isVisibleToFriends" label="Allow Friends to See this Goal" binary="true" [(ngModel)]="goal.isVisibleToFriends"></p-checkbox>
                        </div>
                    </div>
                </p-fieldset>
                <p-fieldset legend="Action Plan" styleClass="with-bottom-margin">
                    <div class="grid form-group">
                        <div class="col-12">
                            <p-message severity="info" text="Your action plan will not be visible to other golfers."></p-message>
                        </div>
                        <div class="col-12">
                            <textarea name="notes" [(ngModel)]="goal.notes" rows="10" maxlength="4000"></textarea>
                        </div>
                    </div>
                </p-fieldset>
                <div class="col-12">
                    <button type="submit" pButton label="{{getSaveButtonLabel()}}" (click)="saveGoal()" [disabled]="isLoading || !goalForm.form.valid"></button>
                </div>
                <div class="col-12">
                    <my-messages></my-messages>
                </div>
            </div>
        </div>
    </form>
</div>
