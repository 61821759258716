import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Observable, of as observableOf, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { GolfersControllerProxy } from '../server-proxies/golfers-controller-proxy.service';
import { CurrentGolferService } from './current-golfer.service';

import {
    ModelsCoreCoursesCourse,
    ModelsCoreGolfersGolfer
} from '../../shared/swagger-codegen/models';

export interface IGolferSelectItem extends SelectItem {
    golfer: ModelsCoreGolfersGolfer;
}

export interface ICourseSelectItem extends SelectItem {
    course: ModelsCoreCoursesCourse;
}

export interface IGolferLookupSelectItemLists {
    friends: IGolferSelectItem[];
    yearsPlayed: SelectItem[];
}

@Injectable({ providedIn: 'root' })
export class GolferLookupService {
    constructor(private golfersProxy: GolfersControllerProxy, private currentGolfer: CurrentGolferService) {

    }

    private friends: ModelsCoreGolfersGolfer[];
    private friendSelectItems: IGolferSelectItem[];
    private myDefaultFriendId: number;
    private yearsPlayed: number[];
    private yearsPlayedSelectItems: SelectItem[];
    private myDefaultYearPlayed: number;
    private yearsPracticed: number[];
    private yearsPracticedSelectItems: SelectItem[];
    private myDefaultYearPracticed: number;
    private favoriteCourses: ModelsCoreCoursesCourse[];
    private favoriteCourseSelectItems: ICourseSelectItem[];
    private myDefaultFavoriteCourseId: number;

    get defaultFriendId() {
        return this.myDefaultFriendId;
    }

    get defaultYearPlayed() {
        return this.myDefaultYearPlayed;
    }

    get defaultYearPracticed() {
        return this.myDefaultYearPracticed;
    }

    get defaultFavoriteCourseId() {
        return this.myDefaultFavoriteCourseId;
    }

    private get golferId() {
        return this.currentGolfer.golferId;
    }

    resetFriends() {
        this.friends = undefined;
        this.friendSelectItems = undefined;
        this.myDefaultFriendId = undefined;
    }

    resetYearsPlayed() {
        this.yearsPlayed = undefined;
        this.yearsPlayedSelectItems = undefined;
        this.myDefaultYearPlayed = undefined;
    }

    resetYearsPracticed() {
        this.yearsPracticed = undefined;
        this.yearsPracticedSelectItems = undefined;
        this.myDefaultYearPracticed = undefined;
    }

    resetFavoriteCourses() {
        this.favoriteCourses = undefined;
        this.favoriteCourseSelectItems = undefined;
        this.myDefaultFavoriteCourseId = undefined;
    }

    getFriendAndYearsPlayedSelectItems(includeAllYearsItem = true) {
        const friendsObservable = this.getFriendSelectItems();
        const yearsPlayedObservable = this.getYearsPlayedSelectItems(includeAllYearsItem);

        return forkJoin([friendsObservable, yearsPlayedObservable])
            .pipe(
                map(
                    results => {
                        return {
                            friends: results[0],
                            yearsPlayed: results[1]
                        } as IGolferLookupSelectItemLists;
                    }));
    }

    getFriendSelectItems() {
        let observable: Observable<IGolferSelectItem[]>;

        if(this.friendSelectItems) {
            observable = observableOf(this.friendSelectItems);
        }
        else {
            observable = this.getFriends()
                .pipe(
                    map(
                        golfers => {
                            return this.mapGolfersToSelectItems(golfers);
                        })
                );
        }

        return observable;
    }

    getYearsPlayedSelectItems(includeAllYearsItem = true) {
        let observable: Observable<SelectItem[]>;

        if(this.yearsPlayedSelectItems) {
            observable = observableOf(this.yearsPlayedSelectItems);
        }
        else {
            observable = this.getYearsPlayed()
                .pipe(
                    map(
                        yearsPlayed => {
                            return this.mapYearsPlayedToSelectItems(yearsPlayed);
                        })
                );
        }

        return observable
            .pipe(
                map(
                    items => {
                        return includeAllYearsItem ? [{ value: 0, label: 'ALL' } as SelectItem].concat(items) : items;
                    }));
    }

    getYearsPracticedSelectItems(includeAllYearsItem = true) {
        let observable: Observable<SelectItem[]>;

        if(this.yearsPracticedSelectItems) {
            observable = observableOf(this.yearsPracticedSelectItems);
        }
        else {
            observable = this.getYearsPracticed()
                .pipe(
                    map(
                        yearsPracticed => {
                            return this.mapYearsPracticedToSelectItems(yearsPracticed);
                        })
                );
        }

        return observable
            .pipe(
                map(
                    items => {
                        return includeAllYearsItem ? [{ value: 0, label: 'ALL' } as SelectItem].concat(items) : items;
                    }));
    }

    getFavoriteCourseSelectItems() {
        let observable: Observable<ICourseSelectItem[]>;

        if(this.favoriteCourseSelectItems) {
            observable = observableOf(this.favoriteCourseSelectItems);
        }
        else {
            observable = this.getFavoriteCourses()
                .pipe(
                    map(
                        courses => {
                            return this.mapCoursesToSelectItems(courses);
                        })
                );
        }

        return observable;
    }

    private getFriends() {
        let observable: Observable<ModelsCoreGolfersGolfer[]>;

        if(this.friends) {
            observable = observableOf(this.friends);
        }
        else {
            observable = this.golfersProxy.getFriends(this.golferId)
                .pipe(
                    map(
                        response => {
                            this.friends = response.body;
                            return this.friends;
                        }));
        }

        return observable;
    }

    private mapGolfersToSelectItems(golfers: ModelsCoreGolfersGolfer[]) {
        const selectItems: IGolferSelectItem[] = golfers
            .map(
                golfer => {
                    return {
                        value: golfer.golferId,
                        label: `${golfer.firstName} ${golfer.lastName}`,
                        golfer: golfer
                    };
                });

        selectItems.unshift(
            {
                value: this.currentGolfer.golferId,
                label: `${this.currentGolfer.firstName} ${this.currentGolfer.lastName}`,
                golfer: this.currentGolfer
            });

        this.friendSelectItems = selectItems;
        this.myDefaultFriendId = this.getDefaultFriendId();

        return selectItems;
    }

    private getDefaultFriendId() {
        const defaultGolfer = this.friendSelectItems.find(
            item => {
                return item.value === this.golferId;
            });
        let defaultFriendId = 0;

        if(defaultGolfer) {
            defaultFriendId = defaultGolfer.value;
        } else if(this.friendSelectItems.length > 0) {
            defaultFriendId = this.friendSelectItems[0].value;
        }

        return defaultFriendId;
    }

    private getYearsPlayed() {
        let observable: Observable<number[]>;

        if(this.yearsPlayed) {
            observable = observableOf(this.yearsPlayed);
        }
        else {
            observable = this.golfersProxy.getYearsPlayed(this.golferId)
                .pipe(
                    map(
                        response => {
                            return response.body;
                        }));
        }

        return observable;
    }

    private mapYearsPlayedToSelectItems(years: number[]) {
        const selectItems: SelectItem[] = years
            .map(
                year => {
                    return {
                        value: year,
                        label: year.toString()
                    };
                });

        const defaultYear = selectItems.length > 0 ? selectItems[0].value : 0;

        this.yearsPlayedSelectItems = selectItems;
        this.myDefaultYearPlayed = defaultYear;

        return selectItems;
    }

    private getYearsPracticed() {
        let observable: Observable<number[]>;

        if(this.yearsPracticed) {
            observable = observableOf(this.yearsPracticed);
        }
        else {
            observable = this.golfersProxy.getYearsPracticed(this.golferId)
                .pipe(
                    map(
                        response => {
                            return response.body;
                        }));
        }

        return observable;
    }

    private mapYearsPracticedToSelectItems(years: number[]) {
        const selectItems: SelectItem[] = years
            .map(
                year => {
                    return {
                        value: year,
                        label: year.toString()
                    };
                });

        const defaultYear = selectItems.length > 0 ? selectItems[0].value : 0;

        this.yearsPracticedSelectItems = selectItems;
        this.myDefaultYearPracticed = defaultYear;

        return selectItems;
    }

    private getFavoriteCourses() {
        let observable: Observable<ModelsCoreCoursesCourse[]>;

        if(this.favoriteCourses) {
            observable = observableOf(this.favoriteCourses);
        }
        else {
            observable = this.golfersProxy.getFavoriteCourses(this.golferId)
                .pipe(
                    map(
                        response => {
                            this.favoriteCourses = response.body;
                            return this.favoriteCourses;
                        }));
        }

        return observable;
    }

    private mapCoursesToSelectItems(courses: ModelsCoreCoursesCourse[]) {
        const selectItems: ICourseSelectItem[] = courses
            .map(
                course => {
                    return {
                        value: course.courseId,
                        label: course.name,
                        course: course
                    };
                });

        this.favoriteCourseSelectItems = selectItems;
        this.myDefaultFavoriteCourseId = selectItems.length > 0 ? selectItems[0].value : 0;

        return selectItems;
    }
}
