import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { golfersRoutes } from './golfers.routes';
import { SharedModule } from '../shared/shared.module';
import { ChartsModule } from '../charts/charts.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DualStatisticComponent } from './dashboard/dual-statistic.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { GolferDefaultStatsComponent } from './profile/default-stats/golfer-default-stats.component';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';
import { GolferDetailComponent } from './profile/detail/golfer-detail.component';
import { GolferProfileTabViewComponent } from './profile/golfer-profile-tab-view.component';
import { GolferListComponent } from './list/golfer-list.component';
import { GolfClubsListComponent } from './profile/golf-clubs/list/golf-clubs-list.component';
import { GolfClubDetailsComponent } from './profile/golf-clubs/details/golf-club-details.component';
import { GolfClubYardageAnalysisDialogComponent } from './profile/golf-clubs/yardage-analysis-dialog/yardage-analysis-dialog.component';
import { NotificationPreferencesComponent } from './profile/notification-preferences/notification-preferences.component';
import { SwingThoughtsComponent } from './profile/swing-thoughts/swing-thoughts.component';
import { HandicapsTabViewComponent } from './handicaps/handicaps-tab-view.component';
import { HandicapDifferentialsComponent } from './handicaps/differentials/handicap-differentials.component';
import { HistoricalHandicapsComponent } from './handicaps/historical/historical-handicaps.component';
import { ProjectedHandicapComponent } from './handicaps/projected/projected-handicap.component';
import { RegisterComponent } from './register/register.component';

@NgModule({
    imports: [
        golfersRoutes,
        CommonModule,
        FormsModule,
        SharedModule,
        ChartsModule
    ],
    declarations: [
        DashboardComponent,
        DualStatisticComponent,
        GolferDefaultStatsComponent,
        ChangePasswordComponent,
        GolferDetailComponent,
        GolferProfileTabViewComponent,
        GolferListComponent,
        GolfClubsListComponent,
        GolfClubDetailsComponent,
        GolfClubYardageAnalysisDialogComponent,
        SwingThoughtsComponent,
        HandicapsTabViewComponent,
        HandicapDifferentialsComponent,
        HistoricalHandicapsComponent,
        NotificationPreferencesComponent,
        ProjectedHandicapComponent,
        LoginComponent,
        LogoutComponent,
        RegisterComponent
    ],
    providers: [
    ]
})
export class GolfersModule {

}
