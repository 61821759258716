/// <reference types="google.maps" />
import { Disposable } from './disposable';
import { GreenMapMarker } from './green-map-marker';
import { LabelMapMarker } from './label-map-marker';
import { MapMarkerWrapper } from './map-marker-wrapper';
import { TeeMapMarker } from './tee-map-marker';
import { YardageMapMarker } from './yardage-map-marker';
import { disposeMapComponents, yardsBetween } from './map-utilities';

import Map = google.maps.Map;
import LatLng = google.maps.LatLng;
import Polyline = google.maps.Polyline;
import interpolate = google.maps.geometry.spherical.interpolate;

export interface TeeToGreenLineUpdates {
    teeMarker?: TeeMapMarker;
    yardageMarkers?: YardageMapMarker[];
    greenMarker?: GreenMapMarker;
}

export class TeeToGreenLine implements Disposable {
    constructor(private map: Map, private isHoleStrokeMode: boolean = false) {
        this.line = new Polyline({
            map: map,
            path: [],
            strokeColor: '#1e88e5'
        });

        this.totalYardageLabel = new LabelMapMarker(this.map, this.map.getCenter());
    }

    requireTeeMarker = true;
    requireGreenMarker = true;
    showTotalYardageLabel = true;
    showYardageLabels = true;
    private line: Polyline;
    private totalYardageLabel: LabelMapMarker;
    private yardageLabels: LabelMapMarker[] = [];
    private teeMarker: TeeMapMarker;
    private greenMarker: GreenMapMarker;
    private yardageMarkers: YardageMapMarker[] = [];
    private isVisible = true;

    dispose() {
        disposeMapComponents(this.line, this.yardageLabels, this.totalYardageLabel);
    }

    update(updates?: TeeToGreenLineUpdates) {
        this.applyUpdates(updates);

        if((!this.requireTeeMarker || this.teeMarker) && (!this.requireGreenMarker || this.greenMarker)) {
            this.setPath();
            this.ensureYardageLabels();
            this.updateYardageLabels();

            this.setVisibleForChildComponents(true);
        }
        else {
            this.setVisibleForChildComponents(false);
        }
    }

    setVisible(visible: boolean) {
        this.isVisible = visible;
        this.setVisibleForChildComponents(visible);
    }

    private setVisibleForChildComponents(visible: boolean) {
        this.line.setVisible(visible && this.isVisible);

        const pathLength = this.line.getPath().getLength();

        this.yardageLabels.forEach((label, i) => {
            label.nativeMarker.setVisible(this.showYardageLabels && visible && this.isVisible && i < pathLength);
        });
    }
    
    private applyUpdates(updates?: TeeToGreenLineUpdates) {
        if(updates) {
            if(updates.teeMarker) {
                this.teeMarker = updates.teeMarker;
                this.totalYardageLabel.setPosition(this.teeMarker.getPosition());
            }

            if(updates.yardageMarkers) {
                this.yardageMarkers = updates.yardageMarkers;
            }

            if(updates.greenMarker) {
                this.greenMarker = updates.greenMarker;
            }
        }
    }
    
    private setPath() {
        const markers: MapMarkerWrapper[] = [];

        if(this.teeMarker) {
            markers.push(this.teeMarker);
        }

        if(this.yardageMarkers) {
            markers.push(...this.yardageMarkers);
        }

        let includeGreenMarkerInPath = true;

        if(this.isHoleStrokeMode && this.yardageMarkers && this.yardageMarkers.length > 0) {
            const lastYardageMarker = this.yardageMarkers[this.yardageMarkers.length - 1];
            includeGreenMarkerInPath = lastYardageMarker.nativeMarker.get('finishedInHole') === true;
        }

        if(includeGreenMarkerInPath && this.greenMarker) {
            markers.push(this.greenMarker);
        }

        const newPathPositions = markers
            .filter(m => m)
            .map(m => m.getPosition());
        this.setPathPositions(newPathPositions);
    }

    setPathPositions(positions: LatLng[]) {
        const currentPath = this.line.getPath();

        if(currentPath.getLength() !== positions.length) {
            this.line.setPath(positions);
        }
        else {
            positions.forEach((position, i) => currentPath.setAt(i, position));
        }
    }

    private ensureYardageLabels() {
        const pathLength = this.line.getPath().getLength();
        const numberOfLabelsNeeded = pathLength - 1;

        while(this.yardageLabels.length < numberOfLabelsNeeded) {
            const label = new LabelMapMarker(this.map, this.map.getCenter());
            label.nativeMarker.setVisible(false);

            this.yardageLabels.push(label);
        }
    }
    
    private updateYardageLabels() {
        const path = this.line.getPath();
        const pathLength = path.getLength();
        let totalYards = 0;

        for(let i = 1; i < pathLength; i++) {
            const fromPosition = path.getAt(i - 1);
            const toPosition = path.getAt(i);
            const midPointPosition = interpolate(fromPosition, toPosition, 0.5);
            const yards = yardsBetween(fromPosition, toPosition);
            totalYards += yards;

            const yardageLabel = this.yardageLabels[i - 1];
            yardageLabel.text = Math.round(yards).toString();
            yardageLabel.setPosition(midPointPosition);
        }

        this.updateTotalYardageLabel(totalYards);
    }

    private updateTotalYardageLabel(totalYards: number) {
        const path = this.line.getPath();
        const length = path.getLength();

        this.totalYardageLabel.nativeMarker.setVisible(this.showTotalYardageLabel && length > 2);

        if(length > 2) {
            const start = path.getAt(length - 2);
            const next = path.getAt(length - 1);
            const position = interpolate(start, next, 0.98);
            this.totalYardageLabel.setPosition(position);
            this.totalYardageLabel.text = Math.round(totalYards).toString();
        }
    }
}
