<div class="card">
    <h1><my-course-name></my-course-name></h1>
    <div class="grid">
        <div class="col-12">
            <div class="field col-12 lg:col-1">
                <span class="p-float-label">
                    <input #avgDrive="ngModel" type="number" min="0" step="5" name="avgDrive" [(ngModel)]="drivingDistance" required pInputText class="width2 center" autofocus>
                    <my-validation-message [model]="avgDrive" type="required"></my-validation-message>
                    <label>Average Drive</label>
                </span>
            </div>
        </div>
        <div class="col-12 lg:col-7">
            <table class="p-datatable p-datatable-gridlines scorecard" *ngIf="tees && drivingDistance">
                <thead class="p-datatable-thead">
                    <tr>
                        <th scope="col" rowspan="3" class="left">Tee</th>
                        <th scope="col" colspan="3" class="center">Par 3</th>
                        <th scope="col" colspan="6" class="center">Par 4</th>
                        <th scope="col" colspan="6" class="center">Par 5</th>
                    </tr>
                    <tr>
                        <th scope="col" rowspan=2>Min<br />Yards</th>
                        <th scope="col" rowspan=2>Avg<br />Yards</th>
                        <th scope="col" rowspan=2>Max<br />Yards</th>
                        <th scope="col" colspan="2" class="center">Min</th>
                        <th scope="col" colspan="2" class="center">Avg</th>
                        <th scope="col" colspan="2" class="center">Max</th>
                        <th scope="col" colspan="2" class="center">Min</th>
                        <th scope="col" colspan="2" class="center">Avg</th>
                        <th scope="col" colspan="2" class="center">Max</th>
                    </tr>
                    <tr>
                        <th scope="col">Yards</th>
                        <th scope="col">Shot 2</th>
                        <th scope="col">Yards</th>
                        <th scope="col">Shot 2</th>
                        <th scope="col">Yards</th>
                        <th scope="col">Shot 2</th>
                        <th scope="col">Yards</th>
                        <th scope="col">Shot 2</th>
                        <th scope="col">Yards</th>
                        <th scope="col">Shot 2</th>
                        <th scope="col">Yards</th>
                        <th scope="col">Shot 2</th>
                    </tr>
                </thead>
                <tbody class="p-datatable-tbody">
                    <tr class="tee-row" [ngClass]="tee.cssClassName" *ngFor="let tee of tees">
                        <th scope="row" class="left">
                            {{tee.name}}<br />
                            {{tee.yards | number}}<br />
                            {{tee.rating | number:'1.1-1'}} / {{tee.slope | number}}
                        </th>
                        <td>{{tee.minimumPar3Yards}}</td>
                        <td>{{tee.averagePar3Yards}}</td>
                        <td>{{tee.maximumPar3Yards}}</td>
                        <td>{{tee.minimumPar4Yards}}</td>
                        <td>{{shot2Yards(tee.minimumPar4Yards)}}</td>
                        <td>{{tee.averagePar4Yards}}</td>
                        <td>{{shot2Yards(tee.averagePar4Yards)}}</td>
                        <td>{{tee.maximumPar4Yards}}</td>
                        <td>{{shot2Yards(tee.maximumPar4Yards)}}</td>
                        <td>{{tee.minimumPar5Yards}}</td>
                        <td>{{shot2Yards(tee.minimumPar5Yards)}}</td>
                        <td>{{tee.averagePar5Yards}}</td>
                        <td>{{shot2Yards(tee.averagePar5Yards)}}</td>
                        <td>{{tee.maximumPar5Yards}}</td>
                        <td>{{shot2Yards(tee.maximumPar5Yards)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-12 lg:col-5">
            <table class="p-datatable p-datatable-gridlines p-datatable-striped scorecard" *ngIf="tees">
                <thead class="p-datatable-thead">
                    <tr>
                        <th scope="col" colspan="2" class="center">PGA &amp; USGA Recommendations</th>
                    </tr>
                    <tr>
                        <th scope="col">Avg. Drive</th>
                        <th scope="col">Recommended Tee Yardage</th>
                    </tr>
                </thead>
                <tbody class="p-datatable-tbody">
                    <tr [ngClass]="getCssClassForRecommendedTeeByAvgDrive(300, 99999)">
                        <td>300+ Yards</td>
                        <td>7,150-7,400 Yards</td>
                    </tr>
                    <tr [ngClass]="getCssClassForRecommendedTeeByAvgDrive(275, 299)">
                        <td>275-299 Yards</td>
                        <td>6,700-6,900 Yards</td>
                    </tr>
                    <tr [ngClass]="getCssClassForRecommendedTeeByAvgDrive(250, 274)">
                        <td>250-274 Yards</td>
                        <td>6,200-6,400 Yards</td>
                    </tr>
                    <tr [ngClass]="getCssClassForRecommendedTeeByAvgDrive(225, 249)">
                        <td>225-249 Yards</td>
                        <td>5,800-6,000 Yards</td>
                    </tr>
                    <tr [ngClass]="getCssClassForRecommendedTeeByAvgDrive(200, 225)">
                        <td>200-225 Yards</td>
                        <td>5,200-5,400 Yards</td>
                    </tr>
                    <tr [ngClass]="getCssClassForRecommendedTeeByAvgDrive(175, 199)">
                        <td>175-199 Yards</td>
                        <td>4,400-4,600 Yards</td>
                    </tr>
                    <tr [ngClass]="getCssClassForRecommendedTeeByAvgDrive(150, 174)">
                        <td>150-174 Yards</td>
                        <td>3,500-3,700 Yards</td>
                    </tr>
                    <tr [ngClass]="getCssClassForRecommendedTeeByAvgDrive(125, 149)">
                        <td>125-149 Yards</td>
                        <td>2,800-3,000 Yards</td>
                    </tr>
                    <tr [ngClass]="getCssClassForRecommendedTeeByAvgDrive(0, 124)">
                        <td>< 125 Yards</td>
                        <td>2,100-2,300 Yards</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
