<div class="card">
    <h1>Practice Session</h1>
    <div class="grid">
        <p-accordion *ngIf="practiceSession">
            <p-accordionTab *ngFor="let sessionActivity of practiceSession.practiceSessionActivities; let index = index;" [header]="getActivityHeader(sessionActivity)" [selected]="isActiveActivity(sessionActivity)" [disabled]="isActivityDisabled(sessionActivity)" (selectedChange)="onActivitySelected(sessionActivity)">
                <form #practiceSessionForm="ngForm" autocomplete="off" novalidate="novalidate">
                    <div class="grid form-group">
                        <div *ngIf="showVariation(sessionActivity)" class="col-12">
                            <label>Variation</label>
                            {{sessionActivity.practiceActivityVariation.name}}
                            <span *ngIf="showVariationDescription(sessionActivity)">- {{sessionActivity.practiceActivityVariation.description}}</span>
                        </div>
                        <div class="col-12">
                            <span [innerHtml]="sessionActivity.practiceActivity.description | textToHtml"></span>
                        </div>
                        <div *ngIf="showPlannedMinutes(sessionActivity)" class="col-12">
                            <label>Planned Minutes</label>
                            {{sessionActivity.plannedMinutes}}
                        </div>
                        <div *ngIf="showAverageDuration(sessionActivity)" class="col-12">
                            <label>Average Time</label>
                            {{getAverageDuration(sessionActivity)}}
                        </div>
                        <div *ngIf="showGoal(sessionActivity)" class="col-12">
                            <label>Goal</label>
                            {{sessionActivity.practiceActivityVariation.goal}}
                        </div>
                        <div *ngIf="showGoal(sessionActivity)" class="col-12">
                            <label>Goal Achieved?</label>
                            <span *ngIf="isReadOnly(sessionActivity)">{{getGoalAchievedYesOrNo(sessionActivity)}}</span>
                            <p-selectButton *ngIf="isEditable(sessionActivity)" #isGoalAchieved="ngModel" name="isGoalAchieved-{{sessionActivity.practiceSessionActivityId}}" [options]="yesOrNo" [(ngModel)]="sessionActivity.isGoalAchieved" required></p-selectButton>
                        </div>
                        <div *ngIf="showScore(sessionActivity)" class="col-12">
                            <label>Scoring Rules</label>
                            <span>{{sessionActivity.practiceActivity.scoringRules}}</span>
                        </div>
                        <div *ngIf="showBestScore(sessionActivity)" class="col-12">
                            <label>Best Score</label>
                            {{getBestScore(sessionActivity)}}
                        </div>
                        <div *ngIf="showBestScoreThisYear(sessionActivity)" class="col-12">
                            <label>Best Score this Year</label>
                            {{getBestScoreThisYear(sessionActivity)}}
                        </div>
                        <div *ngIf="showCounter(sessionActivity)" class="col-12">
                            <label>Counter</label>
                            <p-spinner class="width1 center" name="counter-{{sessionActivity.practiceSessionActivityId}}" [(ngModel)]="counter" [min]="0" [max]="1500"></p-spinner>
                        </div>
                        <div *ngIf="showScore(sessionActivity)" class="col-12">
                            <label>Score</label>
                            <span *ngIf="isReadOnly(sessionActivity)">{{sessionActivity.score}}</span>
                            <p-spinner *ngIf="isEditable(sessionActivity)" #score="ngModel" class="width1 center" name="score-{{sessionActivity.practiceSessionActivityId}}" [(ngModel)]="sessionActivity.score" [min]="-500" [max]="500" required></p-spinner>
                        </div>
                        <div *ngIf="timerElapsedTime" class="col-12">
                            <label>Elapsed Time</label>
                            {{timerElapsedTime}}
                        </div>
                        <div *ngIf="showElapsedMinutes(sessionActivity)" class="col-12">
                            {{getElapsedTime(sessionActivity)}}
                        </div>
                        <div class="grid">
                            <div class="col-6">
                                <button *ngIf="canStartActivity(sessionActivity)" type="submit" pButton label="Start" (click)="startPracticeActivity(sessionActivity)" [disabled]="isLoading  || !practiceSessionForm.form.valid"></button>
                                <button *ngIf="canCompleteActivity(sessionActivity)" type="submit" pButton label="Complete" (click)="completePracticeActivity(sessionActivity, index)" [disabled]="isLoading  || !practiceSessionForm.form.valid"></button>
                            </div>
                            <div class="col-6 right">
                                <button *ngIf="canStartActivity(sessionActivity)" type="submit" pButton label="Skip" (click)="skipPracticeActivity(sessionActivity, index)" [disabled]="isLoading"></button>
                            </div>
                        </div>
                    </div>
                </form>
            </p-accordionTab>
            <p-accordionTab *ngIf="practiceSession.completed" header="Session Notes" [selected]="sessionJustEnded">
                <form #practiceSessionForm="ngForm" autocomplete="off" novalidate="novalidate">
                    <div class="grid form-group">
                        <div class="field col-12">
                            <span class="p-float-label">
                                <textarea #notes="ngModel" class="width20" rows="10" name="notes" pInputTextarea [(ngModel)]="practiceSession.notes"></textarea>
                                <label>Notes</label>
                            </span>
                        </div>
                        <div class="col-12">
                            <button type="submit" pButton label="Save" (click)="completeSession()" [disabled]="isLoading"></button>
                        </div>
                    </div>
                </form>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
<p-confirmDialog key="practice-session-entry-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
