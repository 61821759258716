import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponentDirective } from '../../shared/ui';
import { CoursesControllerProxy } from '../../shared/server-proxies';
import { ScorecardHoleComponent } from './scorecard-hole/scorecard-hole.component';

import {
    ModelsCoreCoursesCourse,
    ModelsCoreCoursesCourseMapArea,
    ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-single-hole-scorecard-tab-view',
    templateUrl: './single-hole-scorecard-tab-view.component.html'
})
export class SingleHoleScorecardTabViewComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private coursesProxy: CoursesControllerProxy,
        private currentRoute: ActivatedRoute) {

        super();
    }

    @ViewChild(ScorecardHoleComponent, { static: true }) scorecardComponent: ScorecardHoleComponent;
    course: ModelsCoreCoursesCourse;
    courseMapArea: ModelsCoreCoursesCourseMapArea;
    courseId: number;
    private golfRoundId: number;

    ngOnInit() {
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    const golfRoundId = parseInt(params['golfRoundId'], 10);
                    const navigationIndex = parseInt(params['index'], 10) || 0;
                    const continueRound = params['continue'] === 'true';

                    if(this.golfRoundId !== golfRoundId) {
                        this.golfRoundId = golfRoundId;
                        this.scorecardComponent.loadSingleHoleScorecard(golfRoundId)
                            .pipe(this.takeUntilUnsubscribed())
                            .subscribe(
                                (response) => {
                                    this.courseId = response.body.course.courseId;
                                    this.loadCourseMap(this.courseId);
                                    
                                    if(continueRound) {
                                        this.scorecardComponent.navigateToFirstUnscoredHole();
                                    }
                                    else {
                                        this.scorecardComponent.setNavigationIndex(navigationIndex);
                                    }
                                });
                    }
                    else {
                        this.scorecardComponent.setNavigationIndex(navigationIndex);
                    }
                });
    }

    get scorecard() {
        return this.scorecardComponent.scorecard;
    }

    get selectedHole() {
        return this.scorecardComponent.selectedHole;
    }

    get scorecardIsLoaded() {
        return this.scorecard !== undefined;
    }

    get showNotesTab() {
        return this.scorecardIsLoaded && this.selectedHole.golferHoleInfo && this.selectedHole.golferHoleInfo.notes;
    }

    get showMapTab() {
        return this.scorecardIsLoaded && this.courseMapArea && this.courseMapArea.latitude !== undefined;
    }

    get showHistoricalStatsTab() {
        return this.scorecardIsLoaded && this.selectedHole.golferHoleInfo && this.selectedHole.golferHoleInfo.allTimeHistory;
    }

    get showQuickGlanceTab() {
        return this.scorecardIsLoaded;
    }

    get showFullScorecardTab() {
        return this.scorecardIsLoaded;
    }

    onScorecardHoleInitialized(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        this.setHoleMap(hole);
    }

    private loadCourseMap(courseId: number) {
        this.coursesProxy.getCourseMap(courseId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.course = response.body;
                    this.setHoleMap(this.selectedHole);
                });
    }

    private setHoleMap(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        if(this.course && this.course.courseMapAreas) {
            this.courseMapArea = this.course.courseMapAreas.find(ma => ma.hole && ma.hole.holeId === hole.holeId);
        }
    }
}
