<div class="login-body h-screen flex flex-column bg-cover">
    <div class="login-header shadow-2 z-5 p-3 flex justify-content-between flex-row align-items-center">
        <div class="ml-3 flex" routerLink="/">
            <div class="logo">
                Par Pros
            </div>
        </div>
        <div class="mr-3 flex">

        </div>
    </div>

    <div class="login-panel align-self-center mt-auto mb-auto">
        <div class="text-center z-5 flex flex-column border-1 border-round-md surface-border surface-card px-3">
            <div class="login-panel-header -mt-5 text-white border-round-md mx-auto px-3 py-1 border-1 surface-border">
                <h2 class="m-0">LOGIN</h2>
            </div>

            <h4>Welcome to Par Pros</h4>
            <img class="login-image" src="/assets/images/ParPros.png" />
            <div *ngIf="isLoggingInWithToken" class="text-color-secondary mb-6 px-6">
                Logging In<br />
                <i class="fas fa-spinner fa-pulse"></i>
            </div>

            <form #loginForm="ngForm" autocomplete="off" novalidate="novalidate">
                <div *ngIf="!isLoggingInWithToken">
                    <div class="w-full flex flex-column gap-3 px-3 pb-6">
                        <span class="p-input-icon-left p-float-label">
                            <i class="pi pi-envelope"></i>

                            <input #email="ngModel" type="email" name="email" autocomplete="username" maxlength="255" pInputText [(ngModel)]="credentials.email" required [attr.autofocus]="getEmailAutoFocus()">
                            <my-validation-message [model]="email" type="required"></my-validation-message>
                            <label for="inputgroup">Email</label>
                        </span>

                        <span class="p-input-icon-left p-float-label">
                            <i class="pi pi-lock"></i>

                            <input #password="ngModel" type="password" name="password" autocomplete="current-password" maxlength="50" pInputText [(ngModel)]="credentials.password" required [attr.autofocus]="getPasswordAutoFocus()">
                            <label for="inputgroup">Password</label>
                        </span>

                        <div class="p-inputgroup mt-4 mb-3">
                            <p-checkbox name="rememberMe" label="Remember Me" [(ngModel)]="credentials.rememberMe" binary="true"></p-checkbox>
                        </div>
                    </div>
                </div>
            </form>

            <p-button *ngIf="!isLoggingInWithToken" class="w-full my-3 px-3" label="LOGIN" icon="fas fa-user" (click)="login()" [disabled]="isLoading  || !loginForm.form.valid"></p-button>
            <my-messages *ngIf="!isLoggingInWithToken"></my-messages>
            <div *ngIf="!isLoggingInWithToken" class="mt-3 mb-3 px-3">
                New to Par Pros? <strong><a [routerLink]="['/register']">Register Here!</a></strong>
            </div>
        </div>
    </div>
</div>
