import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';

import { ScorecardDialogComponent } from './scorecard-dialog.component';

@Injectable()
export class ScorecardDialogService {
    constructor(
        private dialogService: DialogService,
        private datePipe: DatePipe) {

    }

    open(golfRoundId: number, teeTime: Date, courseName: string) {
        const teeTimeDate = this.datePipe.transform(teeTime, 'fullDate');
        const teeTimeTime = this.datePipe.transform(teeTime, 'shortTime');

        return this.dialogService.open(
            ScorecardDialogComponent,
            {
                data: {
                    golfRoundId: golfRoundId
                },
                header: `${teeTimeDate} ${teeTimeTime} at ${courseName}`,
                width: '95%',
                height: '95%',
                contentStyle: {
                    overflow: 'auto',
                    width: '100%',
                    height: '90%'
                }
            });
    }
}
