// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreRoundsLieTypes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export const ModelsCoreRoundsLieTypes = {
    Tee: 1 as ModelsCoreRoundsLieTypes,
    Fairway: 2 as ModelsCoreRoundsLieTypes,
    Rough: 3 as ModelsCoreRoundsLieTypes,
    Sand: 4 as ModelsCoreRoundsLieTypes,
    Fringe: 5 as ModelsCoreRoundsLieTypes,
    Green: 6 as ModelsCoreRoundsLieTypes,
    Recovery: 7 as ModelsCoreRoundsLieTypes,
    DeepRough: 8 as ModelsCoreRoundsLieTypes,
    Penalty1Stroke: 9 as ModelsCoreRoundsLieTypes,
    Penalty2Stroke: 10 as ModelsCoreRoundsLieTypes,
    Hole: 11 as ModelsCoreRoundsLieTypes
};
