<div *ngIf="currentGolfer" class="layout-menu-profile">
    <button class="p-link" (click)="toggleMenu()" [pTooltip]="currentGolfer.fullName" [tooltipDisabled]="isTooltipDisabled">
        <i class="fas fa-user-circle clickable profile-icon"></i>
        <span>
            <strong>{{currentGolfer.fullName}}</strong>
            <small>Handicap: {{currentGolfer.handicap ? currentGolfer.handicap.formattedHandicapIndex : 'N/A'}}</small>
        </span>
        <i class="layout-menu-profile-toggler pi pi-fw" [ngClass]="{'pi-angle-down': menuProfilePosition === 'start' || isHorizontal, 'pi-angle-up': menuProfilePosition === 'end' && !isHorizontal}"></i>
    </button>

    <ul *ngIf="menuProfileActive" [@menu]="isHorizontal ? 'overlay' : 'inline'">
        <li pTooltip="Profile" [tooltipDisabled]="isTooltipDisabled" [routerLink]="['/profile/create']">
            <button class="p-link" [routerLink]="['/golfers', currentGolfer.golferId]">
                <i class="pi pi-id-card"></i>
                <span>Profile</span>
            </button>
        </li>
        <li pTooltip="Theme" [tooltipDisabled]="isTooltipDisabled">
            <button class="p-link" (click)="toggleDarkMode()">
                <i class="pi" [ngClass]="{'pi-sun': isDarkMode, 'pi-moon': !isDarkMode }"></i>
                <span>{{darkModeItemLabel}}</span>
            </button>
        </li>
        <li pTooltip="Privacy Policy" [tooltipDisabled]="isTooltipDisabled">
            <button class="p-link" [routerLink]="['/public/privacy-policy']">
                <i class="pi pi-lock"></i>
                <span>Privacy Policy</span>
            </button>
        </li>
        <li pTooltip="Logout" [tooltipDisabled]="isTooltipDisabled" [routerLink]="['/logout']">
            <button class="p-link">
                <i class="pi pi-power-off pi-fw"></i>
                <span>Logout</span>
            </button>
        </li>
    </ul>
</div>
