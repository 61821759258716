import { Component, Input } from '@angular/core';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

@Component({
    selector: 'my-dual-statistic',
    template: `
        <span *ngIf="has9HoleStatistic()">{{statistic9Holes}}</span>
        <span *ngIf="showSeparator()"> &bull; </span>        
        <span *ngIf="has18HoleStatistic()">{{statistic18Holes}}</span>
        <span *ngIf="showNotApplicable()">N/A</span>
    `
})
export class DualStatisticComponent extends BaseComponentDirective {
    constructor() {
        super();
        this.enableNotApplicable = true;
    }

    @Input()
    statistic9Holes: any;

    @Input()
    statistic18Holes: any;

    @Input()
    enableNotApplicable: boolean;

    showSeparator() {
        return this.has9HoleStatistic() && this.has18HoleStatistic();
    }

    has9HoleStatistic() {
        return this.statistic9Holes != null;
    }

    has18HoleStatistic() {
        return this.statistic18Holes != null;
    }

    showNotApplicable() {
        return this.enableNotApplicable && !(this.has9HoleStatistic() || this.has18HoleStatistic());
    }
}
