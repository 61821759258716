// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreGolfersFriendStatus = 0 | 1 | 2 | 3;

export const ModelsCoreGolfersFriendStatus = {
    NotAvailable: 0 as ModelsCoreGolfersFriendStatus,
    None: 1 as ModelsCoreGolfersFriendStatus,
    PendingFriend: 2 as ModelsCoreGolfersFriendStatus,
    Friend: 3 as ModelsCoreGolfersFriendStatus
};
