import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';

import { GolfersControllerProxy, ReferenceControllerProxy } from '../../shared/server-proxies';
import { MessagesComponent } from '../../shared/ui/messages/messages.component';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import {
    ModelsWebApiGolfersRegisterModel
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-register',
    templateUrl: './register.component.html'
})
export class RegisterComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersControllerProxy: GolfersControllerProxy,
        private referenceControllerProxy: ReferenceControllerProxy,
        private router: Router) {
        super();
    }

    @ViewChild(MessagesComponent, { static: true }) messages: MessagesComponent;
    states: SelectItem[] = [];
    genders: SelectItem[] = [];
    registerModel: ModelsWebApiGolfersRegisterModel = {
        email: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        gender: '',
        city: '',
        stateCode: '',
    };

    ngOnInit() {
        this.referenceControllerProxy.getStateSelectItems()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.states = response;
                });
        this.referenceControllerProxy.getGenderSelectItems()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.genders = response;
                });
    }

    register() {
        this.taskStarted();
        this.messages.clear();

        this.golfersControllerProxy.register(this.registerModel)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    this.router.navigateByUrl('/login');
                },
                response => this.messages.showApiError(response));
    }
}
