<div *ngIf="viewingFullScorecard" class="p-datatable">
    <div class="p-datatable-loading" *ngIf="loading"></div>
    <div class="p-datatable-loading-content" style="height: 300px" *ngIf="loading">
        <i [class]="'p-datatable-loading-icon pi-spin pi pi-spinner'"></i>
    </div>
    <form class="grid" #scorecardForm="ngForm" autocomplete="off" novalidate="novalidate">
        <div class="col-12" style="overflow: auto">
            <table class="p-datatable scorecard" *ngIf="scorecard">
                <thead class="p-datatable-thead">
                    <tr class="course-nine-row">
                        <th class="transparent"></th>
                        <th scope="col" colspan="10">{{scorecard.course.front9.name}}</th>
                        <th *ngIf="anyBackNines" scope="col" colspan="10">{{scorecard.course.back9.name}}</th>
                        <th *ngIf="anyBackNines" class="transparent"></th>
                    </tr>
                </thead>
                <tbody class="p-datatable-tbody">
                    <tr class="hole-row">
                        <th scope="row">Hole</th>
                        <td *ngFor="let hole of scorecard.course.front9.holes">{{hole.displayText}}</td>
                        <td class="in-out">{{getOutLabel()}}</td>
                        <td *ngFor="let hole of scorecard.course.back9.holes">{{hole.displayText}}</td>
                        <td *ngIf="anyBackNines" class="in-out">In</td>
                        <td *ngIf="anyBackNines" class="total">Total</td>
                    </tr>
                    <tr *ngIf="scorecard.hasMaleGolfers" class="handicap-row">
                        <th scope="row">{{getHandicapForMenLabel()}}</th>
                        <td *ngFor="let hole of scorecard.course.front9.holes">{{hole.handicapForMen}}</td>
                        <td></td>
                        <td *ngFor="let hole of scorecard.course.back9.holes">{{hole.handicapForMen}}</td>
                        <td *ngIf="anyBackNines"></td>
                        <td *ngIf="anyBackNines"></td>
                    </tr>
                    <tr *ngIf="scorecard.hasFemaleGolfers" class="handicap-row">
                        <th scope="row">{{getHandicapForWomenLabel()}}</th>
                        <td *ngFor="let hole of scorecard.course.front9.holes">{{hole.handicapForWomen}}</td>
                        <td></td>
                        <td *ngFor="let hole of scorecard.course.back9.holes">{{hole.handicapForWomen}}</td>
                        <td *ngIf="anyBackNines"></td>
                        <td *ngIf="anyBackNines"></td>
                    </tr>
                    <tr class="tee-row" [ngClass]="tee.cssClassName" *ngFor="let tee of scorecard.course.tees">
                        <th scope="row">{{tee.name}}</th>
                        <td *ngFor="let hole of tee.front9.holes">{{hole.yards}}</td>
                        <td class="in-out">{{tee.front9.yards}}</td>
                        <td *ngFor="let hole of tee.back9.holes">{{hole.yards}}</td>
                        <td *ngIf="anyBackNines" class="in-out">{{tee.back9.yards}}</td>
                        <td *ngIf="anyBackNines" class="total">{{tee.yards}}</td>
                    </tr>
                    <tr *ngIf="scorecard.hasMaleGolfers" class="par-row">
                        <th scope="row">{{getParForMenLabel()}}</th>
                        <td *ngFor="let hole of scorecard.course.front9.holes">{{hole.parForMen}}</td>
                        <td class="in-out">{{scorecard.course.front9.parForMen}}</td>
                        <td *ngFor="let hole of scorecard.course.back9.holes">{{hole.parForMen}}</td>
                        <td *ngIf="anyBackNines" class="in-out">{{scorecard.course.back9.parForMen}}</td>
                        <td *ngIf="anyBackNines" class="total">{{scorecard.course.parForMen}}</td>
                    </tr>
                    <tr *ngIf="scorecard.hasFemaleGolfers" class="par-row">
                        <th scope="row">{{getParForWomenLabel()}}</th>
                        <td *ngFor="let hole of scorecard.course.front9.holes">{{hole.parForWomen}}</td>
                        <td class="in-out">{{scorecard.course.front9.parForWomen}}</td>
                        <td *ngFor="let hole of scorecard.course.back9.holes">{{hole.parForWomen}}</td>
                        <td *ngIf="anyBackNines" class="in-out">{{scorecard.course.back9.parForWomen}}</td>
                        <td *ngIf="anyBackNines" class="total">{{scorecard.course.parForWomen}}</td>
                    </tr>
                    <ng-template let-golfer let-odd="odd" let-even="even" ngFor [ngForOf]="scorecard.golfers">
                        <tr class="golfer-name-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">
                                {{golfer.displayName}}
                                <i *ngIf="shouldAllowStatsCalculation(golfer)" class="fas fa-cogs clickable recalc-stats-icon" (click)="calculateStats(golfer)" pTooltip="Calculate Statistics"></i>
                            </th>
                            <td *ngFor="let hole of golfer.front9" [ngClass]="getStrokesCssClass(hole)">
                                <span *ngIf="readOnly">{{hole.strokes}}</span>
                                <input *ngIf="!readOnly" name="strokes-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="1" pInputText required #strokes="ngModel" [(ngModel)]="hole.strokes">
                                <i *ngIf="shouldShowUnfinishedHoleAsterisk(hole)" class="fas fa-asterisk incomplete-holes" pTooltip="Unfinished Hole"></i>
                            </td>
                            <td class="in-out">
                                {{sumStrokes(golfer.front9)}}
                                <i *ngIf="shouldShowProjectedFront9ScoreTooltip(golfer)" class="fas fa-sign-in-alt projected-score-icon" [pTooltip]="getProjectedFront9ScoreTooltip(golfer)" [escape]="false" tooltipPosition="left"></i>
                            </td>
                            <td *ngFor="let hole of golfer.back9" [ngClass]="getStrokesCssClass(hole)">
                                <span *ngIf="readOnly">{{hole.strokes}}</span>
                                <input *ngIf="!readOnly && !isPlaceholderHole(hole)" name="strokes-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="1" pInputText required #strokes="ngModel" [(ngModel)]="hole.strokes">
                                <i *ngIf="shouldShowUnfinishedHoleAsterisk(hole)" class="fas fa-asterisk incomplete-holes" pTooltip="Unfinished Hole"></i>
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">
                                {{sumStrokes(golfer.back9)}}
                                <i *ngIf="shouldShowProjectedBack9ScoreTooltip(golfer)" class="fas fa-sign-in-alt projected-score-icon" [pTooltip]="getProjectedBack9ScoreTooltip(golfer)" [escape]="false" tooltipPosition="left"></i>
                            </td>
                            <td *ngIf="anyBackNines" class="total">
                                {{sumStrokes(golfer.front9, golfer.back9)}}
                                <i *ngIf="shouldShowProjectedScoreTooltip(golfer)" class="fas fa-sign-in-alt projected-score-icon" [pTooltip]="getProjectedScoreTooltip(golfer)" [escape]="false" tooltipPosition="left"></i>
                            </td>
                        </tr>
                        <tr *ngIf="golfer.isComplete" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Net Strokes</th>
                            <td *ngFor="let hole of golfer.front9" [ngClass]="getNetStrokesCssClass(hole)">
                                {{hole.netStrokes}}
                            </td>
                            <td class="in-out">{{sumNetStrokes(golfer.front9)}}</td>
                            <td *ngFor="let hole of golfer.back9" [ngClass]="getNetStrokesCssClass(hole)">
                                {{hole.netStrokes}}
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">{{sumNetStrokes(golfer.back9)}}</td>
                            <td *ngIf="anyBackNines" class="total">{{sumNetStrokes(golfer.front9, golfer.back9)}}</td>
                        </tr>
                        <tr *ngIf="golfer.isComplete" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">
                                Adjusted Strokes
                                <i class="fas fa-info-circle adjusted-strokes-description" pTooltip="Per the World Handicap System rules, a score for handicap purposes should not be overly influenced by one or two bad hole scores that are not reflective of a player's demonstrated ability. To prevent this, the maximum individual hole score is Net Double Bogey after the player has a handicap index established or Par + 5 before the player has a handicap index established."></i>
                            </th>
                            <td *ngFor="let hole of golfer.front9" [ngClass]="getAdjustedStrokesCssClass(hole)">
                                {{hole.adjustedStrokes}}
                            </td>
                            <td class="in-out">{{sumAdjustedStrokes(golfer.front9)}}</td>
                            <td *ngFor="let hole of golfer.back9" [ngClass]="getAdjustedStrokesCssClass(hole)">
                                {{hole.adjustedStrokes}}
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">{{sumAdjustedStrokes(golfer.back9)}}</td>
                            <td *ngIf="anyBackNines" class="total">{{sumAdjustedStrokes(golfer.front9, golfer.back9)}}</td>
                        </tr>
                        <tr *ngIf="trackingStrokesGained(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">
                                <a class="clickable" pTooltip="Click for Stroke-level View" (click)="toggleViewMode()">SG: Total (Pro)</a>
                            </th>
                            <td *ngFor="let hole of golfer.front9" [pTooltip]="getStrokesGainedVsProTooltip(hole)" [escape]="false">
                                {{hole.strokesGainedTotalVsPro | number:'1.3-3'}}
                            </td>
                            <td class="in-out">{{sumStrokesGainedTotalVsPro(golfer.front9) | number:'1.3-3'}}</td>
                            <td *ngFor="let hole of golfer.back9" [pTooltip]="getStrokesGainedVsProTooltip(hole)" [escape]="false">
                                {{hole.strokesGainedTotalVsPro | number:'1.3-3'}}
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">{{sumStrokesGainedTotalVsPro(golfer.back9) | number:'1.3-3'}}</td>
                            <td *ngIf="anyBackNines" class="total">{{sumStrokesGainedTotalVsPro(golfer.front9, golfer.back9) | number:'1.3-3'}}</td>
                        </tr>
                        <tr *ngIf="trackingStrokesGained(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">
                                <a class="clickable" pTooltip="Click for Stroke-level View" (click)="toggleViewMode()">SG: Total (Scratch)</a>
</th>
                            <td *ngFor="let hole of golfer.front9" [pTooltip]="getStrokesGainedVsScratchTooltip(hole)" [escape]="false">
                                {{hole.strokesGainedTotalVsScratch | number:'1.3-3'}}
                            </td>
                            <td class="in-out">{{sumStrokesGainedTotalVsScratch(golfer.front9) | number:'1.3-3'}}</td>
                            <td *ngFor="let hole of golfer.back9" [pTooltip]="getStrokesGainedVsScratchTooltip(hole)" [escape]="false">
                                {{hole.strokesGainedTotalVsScratch | number:'1.3-3'}}
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">{{sumStrokesGainedTotalVsScratch(golfer.back9) | number:'1.3-3'}}</td>
                            <td *ngIf="anyBackNines" class="total">{{sumStrokesGainedTotalVsScratch(golfer.front9, golfer.back9) | number:'1.3-3'}}</td>
                        </tr>
                        <tr *ngIf="trackingBasicFairways(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Fairway</th>
                            <td *ngFor="let hole of golfer.front9">
                                <span *ngIf="readOnly">{{hole.fairway === 1 ? 'Y' : ''}}</span>
                                <p-checkbox *ngIf="!readOnly && hasFairway(hole)" name="fairways-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.fairway" binary="true" (onChange)="onFairwayCheckChanged(golfer, hole, $event)"></p-checkbox>
                            </td>
                            <td class="in-out">
                                {{sumFairways(golfer.front9)}}<br />
                                {{calculateFairwayPercent(golfer.front9) | percent}}
                            </td>
                            <td *ngFor="let hole of golfer.back9">
                                <span *ngIf="readOnly">{{hole.fairway === 1 ? 'Y' : ''}}</span>
                                <p-checkbox *ngIf="!readOnly && !isPlaceholderHole(hole) && hasFairway(hole)" name="fairways-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.fairway" binary="true" (onChange)="onFairwayCheckChanged(golfer, hole, $event)"></p-checkbox>
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">
                                {{sumFairways(golfer.back9)}}<br />
                                {{calculateFairwayPercent(golfer.back9) | percent}}
                            </td>
                            <td *ngIf="anyBackNines" class="total">
                                {{sumFairways(golfer.front9, golfer.back9)}}<br />
                                {{calculateFairwayPercent(golfer.front9, golfer.back9) | percent}}
                            </td>
                        </tr>
                        <tr *ngIf="trackingAdvancedFairways(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Fairway</th>
                            <td *ngFor="let hole of golfer.front9" [ngClass]="getMetricCssClass(hole, 'fairwayResultId')">
                                <span *ngIf="readOnly && !isPlaceholderHole(hole) && hasFairway(hole)">{{getFairwayResultLabel(hole.fairwayResultId)}}</span>
                                <p-dropdown *ngIf="!readOnly && hasFairway(hole)" name="fairway-result-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.fairwayResultId" [options]="fairwayResults" styleClass="width-full" required (onChange)="onFairwayResultChanged(golfer, hole)"></p-dropdown>
                            </td>
                            <td class="in-out">
                                {{sumFairways(golfer.front9)}}<br />
                                {{calculateFairwayPercent(golfer.front9) | percent}}
                            </td>
                            <td *ngFor="let hole of golfer.back9" [ngClass]="getMetricCssClass(hole, 'fairwayResultId')">
                                <span *ngIf="readOnly && !isPlaceholderHole(hole) && hasFairway(hole)">{{getFairwayResultLabel(hole.fairwayResultId)}}</span>
                                <p-dropdown *ngIf="!readOnly && !isPlaceholderHole(hole) && hasFairway(hole)" name="fairway-result-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.fairwayResultId" [options]="fairwayResults" styleClass="width-full" required (onChange)="onFairwayResultChanged(golfer, hole)"></p-dropdown>
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">
                                {{sumFairways(golfer.back9)}}<br />
                                {{calculateFairwayPercent(golfer.back9) | percent}}
                            </td>
                            <td *ngIf="anyBackNines" class="total">
                                {{sumFairways(golfer.front9, golfer.back9)}}<br />
                                {{calculateFairwayPercent(golfer.front9, golfer.back9) | percent}}
                            </td>
                        </tr>
                        <tr *ngIf="trackingAdvancedFairways(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Fairway Miss</th>
                            <td *ngFor="let hole of golfer.front9">
                                <span *ngIf="readOnly && isMissedFairway(hole)">{{getFairwayMissLabel(hole.fairwayMissId)}}</span>
                                <p-dropdown *ngIf="!readOnly && hasFairway(hole) && isMissedFairway(hole)" name="fairway-miss-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.fairwayMissId" [options]="fairwayMisses" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td class="in-out">&nbsp;</td>
                            <td *ngFor="let hole of golfer.back9">
                                <span *ngIf="readOnly && isMissedFairway(hole)">{{getFairwayMissLabel(hole.fairwayMissId)}}</span>
                                <p-dropdown *ngIf="!readOnly && !isPlaceholderHole(hole) && hasFairway(hole) && isMissedFairway(hole)" name="fairway-miss-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.fairwayMissId" [options]="fairwayMisses" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">&nbsp;</td>
                            <td *ngIf="anyBackNines" class="total">&nbsp;</td>
                        </tr>
                        <tr *ngIf="trackingTeeShotClub(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Club Used<br />on Tee</th>
                            <td *ngFor="let hole of golfer.front9">
                                <span *ngIf="readOnly">{{getGolfClubLabel(golfer, hole.teeShotClubId)}}</span>
                                <p-dropdown *ngIf="!readOnly" name="tee-shot-club-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.teeShotClubId" [options]="getGolfClubSelectItems(golfer)" styleClass="width-full" required (onChange)="onTeeShotClubChanged(golfer, hole)"></p-dropdown>
                            </td>
                            <td class="in-out"></td>
                            <td *ngFor="let hole of golfer.back9">
                                <span *ngIf="readOnly">{{getGolfClubLabel(golfer, hole.teeShotClubId)}}</span>
                                <p-dropdown *ngIf="!readOnly && !isPlaceholderHole(hole)" name="tee-shot-club-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.teeShotClubId" [options]="getGolfClubSelectItems(golfer)" styleClass="width-full" required (onChange)="onTeeShotClubChanged(golfer, hole)"></p-dropdown>
                            </td>
                            <td *ngIf="anyBackNines" class="in-out"></td>
                            <td *ngIf="anyBackNines" class="total"></td>
                        </tr>
                        <tr *ngIf="trackingDrivingDistance(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Driving<br />Distance</th>
                            <td *ngFor="let hole of golfer.front9">
                                <span *ngIf="readOnly">{{hole.drivingDistance}}</span>
                                <input *ngIf="!readOnly && hasFairway(hole)" name="driving-distance-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="0" pInputText #drivingDistance="ngModel" [(ngModel)]="hole.drivingDistance">
                            </td>
                            <td class="in-out">{{avgDrivingDistance(golfer.front9)}}</td>
                            <td *ngFor="let hole of golfer.back9">
                                <span *ngIf="readOnly">{{hole.drivingDistance}}</span>
                                <input *ngIf="!readOnly && hasFairway(hole)" name="driving-distance-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="0" pInputText #drivingDistance="ngModel" [(ngModel)]="hole.drivingDistance">
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">{{avgDrivingDistance(golfer.back9)}}</td>
                            <td *ngIf="anyBackNines" class="total">{{avgDrivingDistance(golfer.front9, golfer.back9)}}</td>
                        </tr>
                        <tr *ngIf="trackingBasicGIRs(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">GIR</th>
                            <td *ngFor="let hole of golfer.front9">
                                <span *ngIf="readOnly">{{hole.gir === 1 ? 'Y' : ''}}</span>
                                <p-checkbox *ngIf="!readOnly" name="gir-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.gir" binary="true" (onChange)="onGIRCheckChanged(golfer, hole, $event)"></p-checkbox>
                            </td>
                            <td class="in-out">
                                {{sumGIRs(golfer.front9)}}<br />
                                {{calculateGIRPercent(golfer.front9) | percent}}
                            </td>
                            <td *ngFor="let hole of golfer.back9">
                                <span *ngIf="readOnly">{{hole.gir === 1 ? 'Y' : ''}}</span>
                                <p-checkbox *ngIf="!readOnly && !isPlaceholderHole(hole)" name="gir-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.gir" binary="true" (onChange)="onGIRCheckChanged(golfer, hole, $event)"></p-checkbox>
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">
                                {{sumGIRs(golfer.back9)}}<br />
                                {{calculateGIRPercent(golfer.back9) | percent}}
                            </td>
                            <td *ngIf="anyBackNines" class="total">
                                {{sumGIRs(golfer.front9, golfer.back9)}}<br />
                                {{calculateGIRPercent(golfer.front9, golfer.back9) | percent}}
                            </td>
                        </tr>
                        <tr *ngIf="trackingAdvancedGIRs(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Approach<br />Shot</th>
                            <td *ngFor="let hole of golfer.front9" [ngClass]="getMetricCssClass(hole, 'approachShotResultId')">
                                <span *ngIf="readOnly">{{getApproachShotResultLabel(hole.approachShotResultId)}}</span>
                                <p-dropdown *ngIf="!readOnly" name="advanced-girs-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.approachShotResultId" [options]="approachShotResults" styleClass="width-full" required (onChange)="onApproachShotResultChanged(golfer, hole)"></p-dropdown>
                            </td>
                            <td class="in-out">
                                {{sumGIRs(golfer.front9)}}<br />
                                {{calculateGIRPercent(golfer.front9) | percent}}
                            </td>
                            <td *ngFor="let hole of golfer.back9" [ngClass]="getMetricCssClass(hole, 'approachShotResultId')">
                                <span *ngIf="readOnly">{{getApproachShotResultLabel(hole.approachShotResultId)}}</span>
                                <p-dropdown *ngIf="!readOnly && !isPlaceholderHole(hole)" name="advanced-girs-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.approachShotResultId" [options]="approachShotResults" styleClass="width-full" required (onChange)="onApproachShotResultChanged(golfer, hole)"></p-dropdown>
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">
                                {{sumGIRs(golfer.back9)}}<br />
                                {{calculateGIRPercent(golfer.back9) | percent}}
                            </td>
                            <td *ngIf="anyBackNines" class="total">
                                {{sumGIRs(golfer.front9, golfer.back9)}}<br />
                                {{calculateGIRPercent(golfer.front9, golfer.back9) | percent}}
                            </td>
                        </tr>
                        <tr *ngIf="trackingAdvancedGIRs(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Approach<br />Shot Miss</th>
                            <td *ngFor="let hole of golfer.front9">
                                <span *ngIf="readOnly && isMissedApproachShot(hole)">{{getApproachShotMissLabel(hole.approachShotMissId)}}</span>
                                <p-dropdown *ngIf="!readOnly && isMissedApproachShot(hole)" name="approach-shot-miss-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.approachShotMissId" [options]="approachShotMisses" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td class="in-out">&nbsp;</td>
                            <td *ngFor="let hole of golfer.back9">
                                <span *ngIf="readOnly && isMissedApproachShot(hole)">{{getApproachShotMissLabel(hole.approachShotMissId)}}</span>
                                <p-dropdown *ngIf="!readOnly && isMissedApproachShot(hole) && !isPlaceholderHole(hole)" name="approach-shot-miss-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.approachShotMissId" [options]="approachShotMisses" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">&nbsp;</td>
                            <td *ngIf="anyBackNines" class="total">&nbsp;</td>
                        </tr>
                        <tr *ngIf="trackingApproachShotClub(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Club Used<br />on Approach</th>
                            <td *ngFor="let hole of golfer.front9">
                                <span *ngIf="readOnly">{{getGolfClubLabel(golfer, hole.approachShotClubId)}}</span>
                                <p-dropdown *ngIf="!readOnly && hasFairway(hole) && approachShotWasAttempted(hole)" name="approach-shot-club-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.approachShotClubId" [options]="getGolfClubSelectItems(golfer)" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td class="in-out"></td>
                            <td *ngFor="let hole of golfer.back9">
                                <span *ngIf="readOnly">{{getGolfClubLabel(golfer, hole.approachShotClubId)}}</span>
                                <p-dropdown *ngIf="!readOnly && hasFairway(hole) && approachShotWasAttempted(hole) && !isPlaceholderHole(hole)" name="approach-shot-club-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.approachShotClubId" [options]="getGolfClubSelectItems(golfer)" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td *ngIf="anyBackNines" class="in-out"></td>
                            <td *ngIf="anyBackNines" class="total"></td>
                        </tr>
                        <tr *ngIf="trackingApproachShotDistance(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Approach<br />Shot Distance</th>
                            <td *ngFor="let hole of golfer.front9">
                                <span *ngIf="readOnly">{{hole.approachShotDistance}}</span>
                                <input *ngIf="!readOnly && approachShotWasAttempted(hole)" name="approach-shot-distance-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="1" pInputText #approachShotDistance="ngModel" [(ngModel)]="hole.approachShotDistance" (change)="onApproachShotDistanceChanged(golfer, hole)">
                            </td>
                            <td class="in-out">{{avgApproachShotDistance(golfer.front9)}}</td>
                            <td *ngFor="let hole of golfer.back9">
                                <span *ngIf="readOnly">{{hole.approachShotDistance}}</span>
                                <input *ngIf="!readOnly && approachShotWasAttempted(hole) && !isPlaceholderHole(hole)" name="approach-shot-distance-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="1" pInputText #approachShotDistance="ngModel" [(ngModel)]="hole.approachShotDistance" (change)="onApproachShotDistanceChanged(golfer, hole)">
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">{{avgApproachShotDistance(golfer.back9)}}</td>
                            <td *ngIf="anyBackNines" class="total">{{avgApproachShotDistance(golfer.front9, golfer.back9)}}</td>
                        </tr>
                        <tr *ngIf="trackingGreenHitFromDistance(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Green Hit<br />from Distance</th>
                            <td *ngFor="let hole of golfer.front9">
                                <span *ngIf="readOnly">{{hole.greenHitFromDistance}}</span>
                                <input *ngIf="!readOnly" name="green-hit-from-distance-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="1" pInputText #greenHitFromDistance="ngModel" [(ngModel)]="hole.greenHitFromDistance">
                            </td>
                            <td class="in-out">
                                {{sumGreenHitFromDistance(golfer.front9)}}<br />
                                {{avgGreenHitFromDistance(golfer.front9)}}
                            </td>
                            <td *ngFor="let hole of golfer.back9">
                                <span *ngIf="readOnly">{{hole.greenHitFromDistance}}</span>
                                <input *ngIf="!readOnly && !isPlaceholderHole(hole)" name="green-hit-from-distance-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="1" pInputText #greenHitFromDistance="ngModel" [(ngModel)]="hole.greenHitFromDistance">
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">
                                {{sumGreenHitFromDistance(golfer.back9)}}<br />
                                {{avgGreenHitFromDistance(golfer.back9)}}
                            </td>
                            <td *ngIf="anyBackNines" class="total">
                                {{sumGreenHitFromDistance(golfer.front9, golfer.back9)}}<br />
                                {{avgGreenHitFromDistance(golfer.front9, golfer.back9)}}
                            </td>
                        </tr>
                        <tr *ngIf="trackingChips(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Chip</th>
                            <td *ngFor="let hole of golfer.front9" [ngClass]="getMetricCssClass(hole, 'chipResultId')">
                                <span *ngIf="readOnly">{{getShortGameResultLabel(hole.chipResultId)}}</span>
                                <p-dropdown *ngIf="!readOnly" name="chips-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.chipResultId" [options]="shortGameResults" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td class="in-out">
                                {{sumChips(golfer.front9)}}<br />
                                {{calculateChipPercent(golfer.front9) | percent}}
                            </td>
                            <td *ngFor="let hole of golfer.back9" [ngClass]="getMetricCssClass(hole, 'chipResultId')">
                                <span *ngIf="readOnly">{{getShortGameResultLabel(hole.chipResultId)}}</span>
                                <p-dropdown *ngIf="!readOnly && !isPlaceholderHole(hole)" name="chips-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.chipResultId" [options]="shortGameResults" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">
                                {{sumChips(golfer.back9)}}<br />
                                {{calculateChipPercent(golfer.back9) | percent}}
                            </td>
                            <td *ngIf="anyBackNines" class="total">
                                {{sumChips(golfer.front9, golfer.back9)}}<br />
                                {{calculateChipPercent(golfer.front9, golfer.back9) | percent}}
                            </td>
                        </tr>
                        <tr *ngIf="trackingPitches(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Pitch</th>
                            <td *ngFor="let hole of golfer.front9" [ngClass]="getMetricCssClass(hole, 'pitchResultId')">
                                <span *ngIf="readOnly">{{getShortGameResultLabel(hole.pitchResultId)}}</span>
                                <p-dropdown *ngIf="!readOnly" name="pitches-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.pitchResultId" [options]="shortGameResults" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td class="in-out">
                                {{sumPitches(golfer.front9)}}<br />
                                {{calculatePitchPercent(golfer.front9) | percent}}
                            </td>
                            <td *ngFor="let hole of golfer.back9" [ngClass]="getMetricCssClass(hole, 'pitchResultId')">
                                <span *ngIf="readOnly">{{getShortGameResultLabel(hole.pitchResultId)}}</span>
                                <p-dropdown *ngIf="!readOnly && !isPlaceholderHole(hole)" name="pitches-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.pitchResultId" [options]="shortGameResults" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">
                                {{sumPitches(golfer.back9)}}<br />
                                {{calculatePitchPercent(golfer.back9) | percent}}
                            </td>
                            <td *ngIf="anyBackNines" class="total">
                                {{sumPitches(golfer.front9, golfer.back9)}}<br />
                                {{calculatePitchPercent(golfer.front9, golfer.back9) | percent}}
                            </td>
                        </tr>
                        <tr *ngIf="trackingSandShots(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Sand</th>
                            <td *ngFor="let hole of golfer.front9" [ngClass]="getMetricCssClass(hole, 'sandResultId')">
                                <span *ngIf="readOnly">{{getShortGameResultLabel(hole.sandResultId)}}</span>
                                <p-dropdown *ngIf="!readOnly" name="sand-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.sandResultId" [options]="shortGameResults" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td class="in-out">
                                {{sumSandShots(golfer.front9)}}<br />
                                {{calculateSandShotPercent(golfer.front9) | percent}}
                            </td>
                            <td *ngFor="let hole of golfer.back9" [ngClass]="getMetricCssClass(hole, 'sandResultId')">
                                <span *ngIf="readOnly">{{getShortGameResultLabel(hole.sandResultId)}}</span>
                                <p-dropdown *ngIf="!readOnly && !isPlaceholderHole(hole)" name="sand-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.sandResultId" [options]="shortGameResults" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">
                                {{sumSandShots(golfer.back9)}}<br />
                                {{calculateSandShotPercent(golfer.back9) | percent}}
                            </td>
                            <td *ngIf="anyBackNines" class="total">
                                {{sumSandShots(golfer.front9, golfer.back9)}}<br />
                                {{calculateSandShotPercent(golfer.front9, golfer.back9) | percent}}
                            </td>
                        </tr>
                        <tr *ngIf="trackingUpAndDowns(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Up &amp; Down<br />from 1-10</th>
                            <td *ngFor="let hole of golfer.front9" [ngClass]="getMetricCssClass(hole, 'upAndDowns', 'upAndDownResultFrom1To10Id')">
                                <span *ngIf="readOnly">{{getUpAndDownResultLabel(hole.upAndDownResultFrom1To10Id)}}</span>
                                <p-dropdown *ngIf="!readOnly" name="upAndDownFrom1To10-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.upAndDownResultFrom1To10Id" [options]="upAndDownResults" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td class="in-out">
                                {{sumUpAndDownsFrom1To10(golfer.front9)}}<br />
                                {{calculateUpAndDownFrom1To10Percent(golfer.front9) | percent}}
                            </td>
                            <td *ngFor="let hole of golfer.back9" [ngClass]="getMetricCssClass(hole, 'upAndDowns', 'upAndDownResultFrom1To10Id')">
                                <span *ngIf="readOnly">{{getUpAndDownResultLabel(hole.upAndDownResultFrom1To10Id)}}</span>
                                <p-dropdown *ngIf="!readOnly && !isPlaceholderHole(hole)" name="upAndDownFrom1To10-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.upAndDownResultFrom1To10Id" [options]="upAndDownResults" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">
                                {{sumUpAndDownsFrom1To10(golfer.back9)}}<br />
                                {{calculateUpAndDownFrom1To10Percent(golfer.back9) | percent}}
                            </td>
                            <td *ngIf="anyBackNines" class="total">
                                {{sumUpAndDownsFrom1To10(golfer.front9, golfer.back9)}}<br />
                                {{calculateUpAndDownFrom1To10Percent(golfer.front9, golfer.back9) | percent}}
                            </td>
                        </tr>
                        <tr *ngIf="trackingUpAndDowns(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Up &amp; Down<br />from 11-30</th>
                            <td *ngFor="let hole of golfer.front9" [ngClass]="getMetricCssClass(hole, 'upAndDowns', 'upAndDownResultFrom11To30Id')">
                                <span *ngIf="readOnly">{{getUpAndDownResultLabel(hole.upAndDownResultFrom11To30Id)}}</span>
                                <p-dropdown *ngIf="!readOnly" name="upAndDownFrom11To30-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.upAndDownResultFrom11To30Id" [options]="upAndDownResults" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td class="in-out">
                                {{sumUpAndDownsFrom11To30(golfer.front9)}}<br />
                                {{calculateUpAndDownFrom11To30Percent(golfer.front9) | percent}}
                            </td>
                            <td *ngFor="let hole of golfer.back9" [ngClass]="getMetricCssClass(hole, 'upAndDowns', 'upAndDownResultFrom11To30Id')">
                                <span *ngIf="readOnly">{{getUpAndDownResultLabel(hole.upAndDownResultFrom11To30Id)}}</span>
                                <p-dropdown *ngIf="!readOnly && !isPlaceholderHole(hole)" name="upAndDownFrom11To30-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.upAndDownResultFrom11To30Id" [options]="upAndDownResults" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">
                                {{sumUpAndDownsFrom11To30(golfer.back9)}}<br />
                                {{calculateUpAndDownFrom11To30Percent(golfer.back9) | percent}}
                            </td>
                            <td *ngIf="anyBackNines" class="total">
                                {{sumUpAndDownsFrom11To30(golfer.front9, golfer.back9)}}<br />
                                {{calculateUpAndDownFrom11To30Percent(golfer.front9, golfer.back9) | percent}}
                            </td>
                        </tr>
                        <tr *ngIf="trackingUpAndDowns(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Up &amp; Down<br />from 31-50</th>
                            <td *ngFor="let hole of golfer.front9" [ngClass]="getMetricCssClass(hole, 'upAndDowns', 'upAndDownResultFrom31To50Id')">
                                <span *ngIf="readOnly">{{getUpAndDownResultLabel(hole.upAndDownResultFrom31To50Id)}}</span>
                                <p-dropdown *ngIf="!readOnly" name="upAndDownFrom31To50-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.upAndDownResultFrom31To50Id" [options]="upAndDownResults" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td class="in-out">
                                {{sumUpAndDownsFrom31To50(golfer.front9)}}<br />
                                {{calculateUpAndDownFrom31To50Percent(golfer.front9) | percent}}
                            </td>
                            <td *ngFor="let hole of golfer.back9" [ngClass]="getMetricCssClass(hole, 'upAndDowns', 'upAndDownResultFrom31To50Id')">
                                <span *ngIf="readOnly">{{getUpAndDownResultLabel(hole.upAndDownResultFrom31To50Id)}}</span>
                                <p-dropdown *ngIf="!readOnly && !isPlaceholderHole(hole)" name="upAndDownFrom31To50-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" [(ngModel)]="hole.upAndDownResultFrom31To50Id" [options]="upAndDownResults" styleClass="width-full" required></p-dropdown>
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">
                                {{sumUpAndDownsFrom31To50(golfer.back9)}}<br />
                                {{calculateUpAndDownFrom31To50Percent(golfer.back9) | percent}}
                            </td>
                            <td *ngIf="anyBackNines" class="total">
                                {{sumUpAndDownsFrom31To50(golfer.front9, golfer.back9)}}<br />
                                {{calculateUpAndDownFrom31To50Percent(golfer.front9, golfer.back9) | percent}}
                            </td>
                        </tr>
                        <tr *ngIf="trackingPutts(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Putts</th>
                            <td *ngFor="let hole of golfer.front9" [ngClass]="getMetricCssClass(hole, 'putts')">
                                <span *ngIf="readOnly">{{hole.putts}}</span>
                                <input *ngIf="!readOnly" name="putts-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="0" pInputText required #putts="ngModel" [(ngModel)]="hole.putts" (blur)="onPuttsChanged(golfer, hole)">
                            </td>
                            <td class="in-out">{{sumPutts(golfer.front9)}}</td>
                            <td *ngFor="let hole of golfer.back9" [ngClass]="getMetricCssClass(hole, 'putts')">
                                <span *ngIf="readOnly">{{hole.putts}}</span>
                                <input *ngIf="!readOnly && !isPlaceholderHole(hole)" name="putts-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="0" pInputText required #putts="ngModel" [(ngModel)]="hole.putts" (blur)="onPuttsChanged(golfer, hole)">
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">{{sumPutts(golfer.back9)}}</td>
                            <td *ngIf="anyBackNines" class="total">{{sumPutts(golfer.front9, golfer.back9)}}</td>
                        </tr>
                        <tr *ngIf="trackingFirstPuttDistance(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">First Putt<br />Distance</th>
                            <td *ngFor="let hole of golfer.front9">
                                <span *ngIf="readOnly">{{hole.firstPuttDistance}}</span>
                                <input *ngIf="!readOnly" name="first-putt-distance-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="0" pInputText required #firstPuttDistance="ngModel" [(ngModel)]="hole.firstPuttDistance" (blur)="onFirstPuttDistanceChanged(golfer, hole)">
                            </td>
                            <td class="in-out">{{avgFirstPuttDistance(golfer.front9)}}</td>
                            <td *ngFor="let hole of golfer.back9">
                                <span *ngIf="readOnly">{{hole.firstPuttDistance}}</span>
                                <input *ngIf="!readOnly && !isPlaceholderHole(hole)" name="first-putt-distance-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="0" pInputText required #firstPuttDistance="ngModel" [(ngModel)]="hole.firstPuttDistance" (blur)="onFirstPuttDistanceChanged(golfer, hole)">
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">{{avgFirstPuttDistance(golfer.back9)}}</td>
                            <td *ngIf="anyBackNines" class="total">{{avgFirstPuttDistance (golfer.front9, golfer.back9)}}</td>
                        </tr>
                        <tr *ngIf="trackingLastPuttDistance(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Last Putt<br />Distance</th>
                            <td *ngFor="let hole of golfer.front9">
                                <span *ngIf="readOnly">{{hole.lastPuttDistance}}</span>
                                <input *ngIf="!readOnly" name="last-putt-distance-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="0" pInputText required #lastPuttDistance="ngModel" [(ngModel)]="hole.lastPuttDistance" (blur)="onLastPuttDistanceChanged(golfer, hole)">
                            </td>
                            <td class="in-out">
                                {{sumLastPuttDistance(golfer.front9)}}<br />
                                {{avgLastPuttDistance(golfer.front9)}}
                            </td>
                            <td *ngFor="let hole of golfer.back9">
                                <span *ngIf="readOnly">{{hole.lastPuttDistance}}</span>
                                <input *ngIf="!readOnly && !isPlaceholderHole(hole)" name="last-putt-distance-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="0" pInputText required #lastPuttDistance="ngModel" [(ngModel)]="hole.lastPuttDistance" (blur)="onLastPuttDistanceChanged(golfer, hole)">
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">
                                {{sumLastPuttDistance(golfer.back9)}}<br />
                                {{avgLastPuttDistance(golfer.back9)}}
                            </td>
                            <td *ngIf="anyBackNines" class="total">
                                {{sumLastPuttDistance (golfer.front9, golfer.back9)}}<br />
                                {{avgLastPuttDistance (golfer.front9, golfer.back9)}}
                            </td>
                        </tr>
                        <tr *ngIf="trackingPenaltyStrokes(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Penalties</th>
                            <td *ngFor="let hole of golfer.front9" [ngClass]="getMetricCssClass(hole, 'penaltyStrokes')">
                                <span *ngIf="readOnly">{{hole.penaltyStrokes}}</span>
                                <input *ngIf="!readOnly" name="penaltyStrokes-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="0" pInputText required #penaltyStrokes="ngModel" [(ngModel)]="hole.penaltyStrokes">
                            </td>
                            <td class="in-out">{{sumPenaltyStrokes(golfer.front9)}}</td>
                            <td *ngFor="let hole of golfer.back9" [ngClass]="getMetricCssClass(hole, 'penaltyStrokes')">
                                <span *ngIf="readOnly">{{hole.penaltyStrokes}}</span>
                                <input *ngIf="!readOnly && !isPlaceholderHole(hole)" name="penaltyStrokes-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="0" pInputText required #penaltyStrokes="ngModel" [(ngModel)]="hole.penaltyStrokes">
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">{{sumPenaltyStrokes(golfer.back9)}}</td>
                            <td *ngIf="anyBackNines" class="total">{{sumPenaltyStrokes(golfer.front9, golfer.back9)}}</td>
                        </tr>
                        <tr *ngIf="trackingMulligans(golfer)" class="stat-row" [ngClass]="{ 'p-datatable-even': even, 'p-datatable-odd': odd }">
                            <th scope="row">Mulligans</th>
                            <td *ngFor="let hole of golfer.front9" [ngClass]="getMetricCssClass(hole, 'mulligans')">
                                <span *ngIf="readOnly">{{hole.mulligans}}</span>
                                <input *ngIf="!readOnly" name="mulligans-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="0" pInputText required #mulligans="ngModel" [(ngModel)]="hole.mulligans">
                            </td>
                            <td class="in-out">{{sumMulligans(golfer.front9)}}</td>
                            <td *ngFor="let hole of golfer.back9" [ngClass]="getMetricCssClass(hole, 'mulligans')">
                                <span *ngIf="readOnly">{{hole.mulligans}}</span>
                                <input *ngIf="!readOnly && !isPlaceholderHole(hole)" name="mulligans-{{golfer.golfRoundGolferId}}-{{hole.ordinal}}" type="number" min="0" pInputText required #mulligans="ngModel" [(ngModel)]="hole.mulligans">
                            </td>
                            <td *ngIf="anyBackNines" class="in-out">{{sumMulligans(golfer.back9)}}</td>
                            <td *ngIf="anyBackNines" class="total">{{sumMulligans(golfer.front9, golfer.back9)}}</td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </div>
        <div *ngIf="showLegend" class="legend col-12">
            <div class="legend-cell">
                <p-checkbox name="showColors" label="Show Colors" [(ngModel)]="showColors" binary="true" (onChange)="onShowColorsChanged()"></p-checkbox>
            </div>
            <div class="legend-cell" *ngIf="showColors">
                <div class="legend-box unscored"></div>Unscored
            </div>
            <div class="legend-cell" *ngIf="showColors">
                <div class="legend-box ace"></div>Aces / Double-Eagles
            </div>
            <div class="legend-cell" *ngIf="showColors">
                <div class="legend-box eagle"></div>Eagles
            </div>
            <div class="legend-cell" *ngIf="showColors">
                <div class="legend-box birdie"></div>Birdies
            </div>
            <div class="legend-cell" *ngIf="showColors">
                <div class="legend-box par"></div>Par
            </div>
            <div class="legend-cell" *ngIf="showColors">
                <div class="legend-box bogey"></div>Bogeys
            </div>
            <div class="legend-cell" *ngIf="showColors">
                <div class="legend-box double-bogey"></div>Double Bogeys
            </div>
            <div class="legend-cell" *ngIf="showColors">
                <div class="legend-box triple-bogey"></div>Triple Bogeys
            </div>
            <div class="legend-cell" *ngIf="showColors">
                <div class="legend-box quad-bogey"></div>Quadruple Bogeys or worse
            </div>
            <div class="legend-cell" *ngIf="showColors">
                <div class="legend-box great-range"></div>Great Stat
            </div>
            <div class="legend-cell" *ngIf="showColors">
                <div class="legend-box good-range"></div>Good Stat
            </div>
            <div class="legend-cell" *ngIf="showColors">
                <div class="legend-box bad-range"></div>Bad Stat
            </div>
        </div>
        <div *ngIf="!readOnly" class="col-12">
            <button type="submit" pButton label="Save Scorecard" (click)="saveScorecard()" [disabled]="isLoading || !scorecardForm.form.valid"></button>
        </div>
    </form>
</div>
<div *ngIf="viewingHoleStrokes">
    <a class="clickable" (click)="toggleViewMode()">
        <i class="fas fa-arrow-left"></i>
        Return to Scorecard
    </a>
    <my-scorecard-hole-strokes [scorecard]="scorecard"
                               [courseId]="round?.course?.courseId"
                               [round]="round"
                               [scorecardLookups]="scorecardLookups"
                               [realTimeHoleUpdates]="realTimeHoleUpdates"></my-scorecard-hole-strokes>

</div>
