// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreRoundsWindSpeeds = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export const ModelsCoreRoundsWindSpeeds = {
    MilesPerHour0To5: 1 as ModelsCoreRoundsWindSpeeds,
    MilesPerHour6To10: 2 as ModelsCoreRoundsWindSpeeds,
    MilesPerHour11To15: 3 as ModelsCoreRoundsWindSpeeds,
    MilesPerHour16To20: 4 as ModelsCoreRoundsWindSpeeds,
    MilesPerHour21To25: 5 as ModelsCoreRoundsWindSpeeds,
    MilesPerHour26To30: 6 as ModelsCoreRoundsWindSpeeds,
    MilesPerHour31To35: 7 as ModelsCoreRoundsWindSpeeds,
    MilesPerHour36To40: 8 as ModelsCoreRoundsWindSpeeds,
    MilesPerHour41Plus: 9 as ModelsCoreRoundsWindSpeeds
};
