import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { AuthGuard } from '../shared/auth/auth-guard.service';
import { AppLayoutComponent } from '../layout/app.layout.component';
import { NewRoundComponent } from './new/new-round.component';
import { RoundDetailTabViewComponent } from './detail/round-detail-tab-view.component';
import { RoundListComponent } from './list/round-list.component';
import { SingleHoleScorecardTabViewComponent } from './single-hole-scorecard/single-hole-scorecard-tab-view.component';
import { StrokeAnalysisComponent } from './stroke-analysis/stroke-analysis.component';

export const routes: Routes = [
    {
        path: 'rounds',
        component: AppLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: RoundListComponent,
                data: {
                    title: 'Golf Round List'
                }
            },
            {
                path: 'new',
                component: NewRoundComponent,
                data: {
                    title: 'New Golf Round'
                }
            },
            {
                path: 'stroke-analysis',
                component: StrokeAnalysisComponent,
                data: {
                    title: 'Stroke Analysis'
                }
            },
            {
                path: ':golfRoundId',
                component: RoundDetailTabViewComponent,
                data: {
                    title: 'Golf Round'
                }
            },
            {
                path: ':golfRoundId/scorecard',
                component: SingleHoleScorecardTabViewComponent,
                data: {
                    title: 'Scorecard'
                }
            }
        ]
    }
];

export const roundsRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
