// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreRoundsScoreCategories = 1 | 2 | 3 | 4;

export const ModelsCoreRoundsScoreCategories = {
    BelowAverage: 1 as ModelsCoreRoundsScoreCategories,
    Average: 2 as ModelsCoreRoundsScoreCategories,
    AboveAverage: 3 as ModelsCoreRoundsScoreCategories,
    Exceptional: 4 as ModelsCoreRoundsScoreCategories
};
