<div class="card p-fluid">
    <div class="grid">
        <div class="col-12">
            <p-tabView [activeIndex]="activeTabIndex" (onChange)="onActiveTabChanged($event);">
                <p-tabPanel header="Statistics" leftIcon="fas fa-list-ol">
                    <ng-template pTemplate="content">
                        <my-statistics-list></my-statistics-list>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="Achievements" leftIcon="fas fa-trophy">
                    <ng-template pTemplate="content">
                        <my-achievements-list></my-achievements-list>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="Goals" leftIcon="fas fa-bullseye">
                    <ng-template pTemplate="content">
                        <my-goals-list></my-goals-list>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="Charts" leftIcon="fas fa-chart-bar">
                    <ng-template pTemplate="content">
                        <my-statistic-charts></my-statistic-charts>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="Head to Head" leftIcon="fas fa-people-carry">
                    <ng-template pTemplate="content">
                        <my-head-to-head-statistics></my-head-to-head-statistics>
                    </ng-template>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>
