<div class="card">
    <form #improvementPlanForm="ngForm" autocomplete="off" novalidate="novalidate">
        <div class="grid form-group">
            <p-table [value]="improvementPlans" [loading]="isLoading" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="reorder-column"></th>
                        <th class="width2 left">Priority</th>
                        <th class="left">Statistic</th>
                        <th class="width5">Level 1</th>
                        <th class="width5">Level 2</th>
                        <th class="width5">Level 3</th>
                        <th class="width5">Level 4</th>
                        <th class="width5">Level 5</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-improvementPlan let-rowIndex="rowIndex">
                    <tr [pReorderableRow]="rowIndex">
                        <td>
                            <i class="fa fa-bars clickable" pReorderableRowHandle></i>
                        </td>
                        <td class="left">
                            <span class="p-column-title">Priority</span>
                            Priority {{rowIndex + 1}}
                        </td>
                        <td class="left">
                            <span class="p-column-title">Statistic</span>
                            <p-dropdown #statisticKey="ngModel" styleClass="width-full" name="statisticKey-{{rowIndex}}" [options]="statistics" [(ngModel)]="improvementPlan.statisticKey" required></p-dropdown>
                            <my-validation-message [model]="statisticKey" type="required"></my-validation-message>
                        </td>
                        <td class="level-1">
                            <span class="p-column-title">Level 1</span>
                            <input #level1="ngModel" class="center" name="level1-{{rowIndex}}" type="number" pInputText [(ngModel)]="improvementPlan.level1" required>
                            <my-validation-message [model]="improvementPlan.level1" type="required"></my-validation-message>
                        </td>
                        <td class="level-2">
                            <span class="p-column-title">Level 2</span>
                            <input #level2="ngModel" class="center" name="level2-{{rowIndex}}" type="number" pInputText [(ngModel)]="improvementPlan.level2" required>
                            <my-validation-message [model]="improvementPlan.level2" type="required"></my-validation-message>
                        </td>
                        <td class="level-3">
                            <span class="p-column-title">Level 3</span>
                            <input #level3="ngModel" class="center" name="level3-{{rowIndex}}" type="number" pInputText [(ngModel)]="improvementPlan.level3" required>
                            <my-validation-message [model]="improvementPlan.level3" type="required"></my-validation-message>
                        </td>
                        <td class="level-4">
                            <span class="p-column-title">Level 4</span>
                            <input #level4="ngModel" class="center" name="level4-{{rowIndex}}" type="number" pInputText [(ngModel)]="improvementPlan.level4" required>
                            <my-validation-message [model]="improvementPlan.level4" type="required"></my-validation-message>
                        </td>
                        <td class="level-5">
                            <span class="p-column-title">Level 5</span>
                            <input #level5="ngModel" class="center" name="level5-{{rowIndex}}" type="number" pInputText [(ngModel)]="improvementPlan.level5" required>
                            <my-validation-message [model]="improvementPlan.level5" type="required"></my-validation-message>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="8">
                            {{getEmptyMessage('No session activities.')}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="col-12">
                <button type="submit" pButton label="Save" (click)="save()" [disabled]="isLoading  || !improvementPlanForm.form.valid"></button>
            </div>
            <div class="col-12">
                <my-messages></my-messages>
            </div>
        </div>
    </form>
</div>
