import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import { PracticeTypeChartData } from './practice-time-chart-dialog.service';

@Component({
    selector: 'my-practice-time-chart-dialog',
    templateUrl: './practice-time-chart-dialog.component.html'
})
export class PracticeTimeChartDialogComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private dynamicDialogConfig: DynamicDialogConfig) {
        super();
    }

    data: PracticeTypeChartData;

    ngOnInit() {
        this.data = this.dynamicDialogConfig.data;
    }
}
