import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { PracticeControllerProxy } from '../../../shared/server-proxies';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { MessagesComponent } from '../../../shared/ui/messages/messages.component';
import { CurrentGolferService } from '../../../shared/golfers/current-golfer.service';
import { PracticeTabViewIndexes } from '../../practice-tab-view-indexes';
import { PracticeActivityHistoryChartDialogService }
    from '../../practice-activity-history-chart-dialog/practice-activity-history-chart-dialog.service';
import { PracticeTimeChartDialogService } from '../../practice-time-chart-dialog/practice-time-chart-dialog.service';
import { TimeSpan } from '../../../shared/util';

import {
    ModelsCorePracticePracticeActivity,
    ModelsCorePracticePracticeActivityVariation,
    ModelsCorePracticePracticeCategory,
    ModelsCorePracticePracticePlan,
    ModelsCorePracticePracticePlanPlan,
    ModelsCorePracticePracticePlanActivity,
    ModelsCorePracticePracticeScoreQualifiers,
    ModelsCorePracticePracticeType,
    ModelsWebApiPracticeSavePracticePlanActivityModel,
    ModelsWebApiPracticeSavePracticePlanModel,
    ModelsWebApiPracticeSavePracticePlanPlanModel
} from '../../../shared/swagger-codegen/models';

export interface ISelectItemWithPracticeActivityVariation extends SelectItem {
    variation: ModelsCorePracticePracticeActivityVariation;
}

export interface IPracticePlanActivityWithActivityVariationSelectItems extends ModelsCorePracticePracticePlanActivity {
    practiceActivityVariationSelectItems: ISelectItemWithPracticeActivityVariation[];
}

@Component({
    selector: 'my-practice-plan-detail',
    templateUrl: './practice-plan-detail.component.html',
    styleUrls: [
        './practice-plan-detail.component.scss'
    ]
})
export class PracticePlanDetailComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private practiceControllerProxy: PracticeControllerProxy,
        private currentGolfer: CurrentGolferService,
        private confirmationService: ConfirmationService,
        private practiceActivityHistoryChartDialogService: PracticeActivityHistoryChartDialogService,
        private practiceTimeChartDialogService: PracticeTimeChartDialogService,
        private router: Router,
        private currentRoute: ActivatedRoute) {
        super();
    }

    @ViewChild(MessagesComponent) messages: MessagesComponent;
    practicePlan: ModelsCorePracticePracticePlan;
    isAddingPracticeActivities: boolean;
    isAddingChildPracticePlans: boolean;
    excludedChildPlanIds: number[] = [];
    private practicePlanId: number;
    private golferId: number;

    get isAdding() {
        return this.isAddingPracticeActivities || this.isAddingChildPracticePlans;
    }

    ngOnInit() {
        this.golferId = this.currentGolfer.golferId;

        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    this.practicePlanId = parseInt(params['practicePlanId'], 10) || 0;

                    if(this.practicePlanId > 0) {
                        this.taskStarted();
                        this.practiceControllerProxy.getPracticePlan(this.practicePlanId)
                            .pipe(
                                finalize(() => this.taskCompleted()),
                                this.takeUntilUnsubscribed())
                            .subscribe(
                                response => {
                                    this.mapActivityVariationsForPlan(response.body);
                                    this.practicePlan = response.body;
                                    this.updateExcludedChildPlanIds();
                                });
                    }
                    else {
                        this.practicePlan = {
                            notificationsEnabled: true,
                            isActive: true,
                            practicePlanActivities: [],
                            childPracticePlans: []
                        } as ModelsCorePracticePracticePlan;

                        this.updateExcludedChildPlanIds();
                        this.clearTasks();
                    }
            });
    }

    save() {
        this.savePracticePlan()
            .subscribe(
                () => this.router.navigate(['/practice', { tab: PracticeTabViewIndexes.plansTabIndex }]),
                response => this.messages.showApiError(response));
    }

    stopAddingActivities() {
        this.isAddingPracticeActivities = false;
    }

    stopAddingChildPlans() {
        this.isAddingChildPracticePlans = false;
    }

    shouldShowPracticeTimeByCategoryChartIcon() {
        return this.practicePlan.practicePlanActivities.findIndex(ppa => ppa.plannedMinutes > 0) !== -1;
    }

    showPracticeTimeByCategoryChart() {
        const header = `${this.practicePlan.name || 'Practice Plan'}`;
        const categories: ModelsCorePracticePracticeCategory[] = [];
        const types: ModelsCorePracticePracticeType[] = [];
        const categoryMap: { [practiceCategoryId: number]: ModelsCorePracticePracticeCategory } = {};
        const typeMap: { [practiceTypeId: number]: ModelsCorePracticePracticeType } = {};

        this.practicePlan.practicePlanActivities.forEach(practicePlanActivity => {
            if(!isNaN(practicePlanActivity.plannedMinutes)) {
                const practiceCategory = practicePlanActivity.practiceActivity.practiceCategory;
                const practiceType = practicePlanActivity.practiceActivity.practiceType;
                let category = categoryMap[practiceCategory.practiceCategoryId];
                let type = typeMap[practiceType.practiceTypeId];

                if(!category) {
                    category = {
                        minutes: 0,
                        name: practiceCategory.name,
                        ordinal: practiceCategory.ordinal,
                        practiceCategoryId: practiceCategory.practiceCategoryId
                    };

                    categories.push(category);
                    categoryMap[practiceCategory.practiceCategoryId] = category;
                }

                if(!type) {
                    type = {
                        minutes: 0,
                        name: practiceType.name,
                        ordinal: practiceType.ordinal,
                        practiceTypeId: practiceType.practiceTypeId
                    };

                    types.push(type);
                    typeMap[practiceType.practiceTypeId] = type;
                }

                category.minutes += practicePlanActivity.plannedMinutes;
                type.minutes += practicePlanActivity.plannedMinutes;
            }
        });

        categories.sort((a, b) => a.ordinal > b.ordinal ? 1 : -1);
        types.sort((a, b) => a.ordinal > b.ordinal ? 1 : -1);

        this.practiceTimeChartDialogService.open(header, categories, types);
    }

    shouldShowScoringHistoryChart(practicePlanActivity: ModelsCorePracticePracticePlanActivity) {
        return practicePlanActivity.practiceActivity.practiceScoreQualifierId !==
            ModelsCorePracticePracticeScoreQualifiers.None;
    }

    showScoringHistoryChart(practicePlanActivity: ModelsCorePracticePracticePlanActivity) {
        let header = practicePlanActivity.practiceActivity.name;
        let practiceActivityVariationId: number;

        if(practicePlanActivity.practiceActivityVariation) {
            header += `: ${practicePlanActivity.practiceActivityVariation.name}`;
            practiceActivityVariationId = practicePlanActivity.practiceActivityVariation.practiceActivityVariationId;
        }

        this.practiceActivityHistoryChartDialogService.open(
            header,
            practicePlanActivity.practiceActivity.practiceActivityId,
            practiceActivityVariationId);
    }

    deletePracticeActivity(practicePlanActivity: ModelsCorePracticePracticePlanActivity, index: number) {
        const message = `Are you sure you want to remove ${practicePlanActivity.practiceActivity.name} from this plan?`;

        this.confirmationService.confirm(
            {
                key: 'practice-plan-detail-component',
                header: 'Remove Practice Activity from Plan?',
                message: message,
                accept: () => {
                    if(practicePlanActivity.practicePlanActivityId > 0) {
                        this.taskStarted();
                        this.practiceControllerProxy.deletePracticePlanActivity(practicePlanActivity.practicePlanActivityId)
                            .pipe(
                                finalize(() => this.taskCompleted()),
                                this.takeUntilUnsubscribed())
                            .subscribe(
                                () => {
                                    this.practicePlan.practicePlanActivities.splice(index, 1);
                                },
                                response => this.messages.showApiError(response));
                    }
                    else {
                        this.practicePlan.practicePlanActivities.splice(index, 1);
                    }
                }
            });
    }

    deleteChildPracticePlan(practicePlan: ModelsCorePracticePracticePlanPlan, index: number) {
        const message = `Are you sure you want to remove ${practicePlan.name} from this plan?`;

        this.confirmationService.confirm(
            {
                key: 'practice-plan-detail-component',
                header: 'Remove Child Practice Plan from Plan?',
                message: message,
                accept: () => {
                    if(practicePlan.practicePlanPlanId > 0) {
                        this.taskStarted();
                        this.practiceControllerProxy.deletePracticePlanPlan(practicePlan.practicePlanPlanId)
                            .pipe(
                                finalize(() => this.taskCompleted()),
                                this.takeUntilUnsubscribed())
                            .subscribe(
                                () => {
                                    this.practicePlan.childPracticePlans.splice(index, 1);
                                    this.updateExcludedChildPlanIds();
                                },
                                response => this.messages.showApiError(response));
                    }
                    else {
                        this.practicePlan.childPracticePlans.splice(index, 1);
                        this.updateExcludedChildPlanIds();
                    }
                }
            });
    }

    addPracticeActivities() {
        this.isAddingPracticeActivities = true;
    }

    addChildPracticePlans() {
        this.isAddingChildPracticePlans = true;
    }

    getAverageDuration(practicePlanActivity: ModelsCorePracticePracticePlanActivity) {
        let duration = '';

        if(practicePlanActivity.practiceActivityVariation && practicePlanActivity.practiceActivityVariation.averageElapsedMinutes > 0) {
            duration = practicePlanActivity.practiceActivityVariation.averageElapsedDuration;
        }
        else if(practicePlanActivity.practiceActivity.averageElapsedMinutes > 0) {
            duration = practicePlanActivity.practiceActivity.averageElapsedDuration;
        }

        return duration;
    }

    getTotalPlannedMinutes() {
        let totalMinutes = 0;

        this.practicePlan.practicePlanActivities.forEach(practicePlanActivity => {
            if(practicePlanActivity.plannedMinutes > 0) {
                totalMinutes += practicePlanActivity.plannedMinutes;
            }
        });

        return totalMinutes > 0 ? TimeSpan.fromMinutes(totalMinutes).toString() : '';
    }

    getTotalAverageDuration() {
        let totalMinutes = 0;

        this.practicePlan.practicePlanActivities.forEach(practicePlanActivity => {
            if(practicePlanActivity.practiceActivityVariation
                && practicePlanActivity.practiceActivityVariation.averageElapsedMinutes > 0) {
                totalMinutes += practicePlanActivity.practiceActivityVariation.averageElapsedMinutes;
            }
            else if(practicePlanActivity.practiceActivity.averageElapsedMinutes > 0) {
                totalMinutes += practicePlanActivity.practiceActivity.averageElapsedMinutes;
            }
        });

        return totalMinutes > 0 ? TimeSpan.fromMinutes(totalMinutes).toString() : '';
    }

    onActivityAddedToPlan(practiceActivity: ModelsCorePracticePracticeActivity) {
        const plannedActivity = {} as IPracticePlanActivityWithActivityVariationSelectItems;
        const variations = practiceActivity.practiceActivityVariations;

        plannedActivity.practiceActivity = this.deepClone(practiceActivity);

        if(variations && variations.length > 0) {
            plannedActivity.practiceActivityVariation = this.deepClone(variations[0]);
            this.mapActivityVariationsToSelectItems(plannedActivity);
        }

        this.practicePlan.practicePlanActivities.push(plannedActivity);
    }

    onChildPlanAddedToPlan(plan: ModelsCorePracticePracticePlan) {
        const childPlan = this.deepClone(plan) as ModelsCorePracticePracticePlanPlan;
        this.practicePlan.childPracticePlans.push(childPlan);
        this.updateExcludedChildPlanIds();
    }

    onActivityVariationChanged(plannedActivity: IPracticePlanActivityWithActivityVariationSelectItems) {
        const variationId = plannedActivity.practiceActivityVariation.practiceActivityVariationId;
        plannedActivity.practiceActivityVariation = plannedActivity.practiceActivityVariationSelectItems
            .find(i => i.value === variationId).variation;
    }

    private mapActivityVariationsForPlan(practicePlan: ModelsCorePracticePracticePlan) {
        practicePlan.practicePlanActivities.forEach(
            a => this.mapActivityVariationsToSelectItems(a as IPracticePlanActivityWithActivityVariationSelectItems)
        );
    }

    private mapActivityVariationsToSelectItems(plannedActivity: IPracticePlanActivityWithActivityVariationSelectItems) {
        const variation = plannedActivity.practiceActivity.practiceActivityVariations;

        if(variation && variation.length > 0) {
            plannedActivity.practiceActivityVariationSelectItems = variation.map(
                v => {
                    return {
                        value: v.practiceActivityVariationId,
                        label: v.name,
                        variation: v
                    };
                });

            if(!plannedActivity.practiceActivityVariation) {
                plannedActivity.practiceActivityVariation = {} as ModelsCorePracticePracticeActivityVariation;
            }
        }
    }

    private savePracticePlan() {
        this.taskStarted();

        const updateModel = this.convertPracticePlanToUpdateModel();

        if(this.practicePlanId > 0) {
            return this.practiceControllerProxy.savePracticePlan(this.practicePlanId, updateModel)
                .pipe(
                    finalize(() => this.taskCompleted()),
                    this.takeUntilUnsubscribed());
        }
        else {
            return this.practiceControllerProxy.addPracticePlan(updateModel)
                .pipe(
                    finalize(() => this.taskCompleted()),
                    this.takeUntilUnsubscribed());
        }
    }

    private convertPracticePlanToUpdateModel() {
        const planModel = {
            practicePlanActivities: [],
            childPracticePlans: []
        } as ModelsWebApiPracticeSavePracticePlanModel;

        planModel.golferId = this.golferId;
        planModel.name = this.practicePlan.name;
        planModel.notificationsEnabled = this.practicePlan.notificationsEnabled;
        planModel.isActive = this.practicePlan.isActive;

        this.practicePlan.practicePlanActivities.forEach((activity: IPracticePlanActivityWithActivityVariationSelectItems) => {
            const activityModel = {
                practicePlanActivityId: activity.practicePlanActivityId,
                practiceActivityId: activity.practiceActivity.practiceActivityId,
                practiceActivityVariationId: undefined,
                plannedMinutes: activity.plannedMinutes
            } as ModelsWebApiPracticeSavePracticePlanActivityModel;

            if(activity.practiceActivityVariation) {
                activityModel.practiceActivityVariationId = activity.practiceActivityVariation.practiceActivityVariationId;
            }

            planModel.practicePlanActivities.push(activityModel);
        });

        this.practicePlan.childPracticePlans.forEach((childPlan: ModelsCorePracticePracticePlanPlan) => {
            const childPlanModel: ModelsWebApiPracticeSavePracticePlanPlanModel = {
                practicePlanPlanId: childPlan.practicePlanPlanId,
                practicePlanId: childPlan.practicePlanId
            };

            planModel.childPracticePlans.push(childPlanModel);
        });

        return planModel;
    }

    private updateExcludedChildPlanIds() {
        const excludedIds: number[] = [];

        if(this.practicePlan) {
            if(this.practicePlan.practicePlanId > 0) {
                excludedIds.push(this.practicePlan.practicePlanId);
            }

            this.practicePlan.childPracticePlans.forEach(p => {
                excludedIds.push(p.practicePlanId);
            });
        }

        this.excludedChildPlanIds = excludedIds;
    }

    private deepClone<T>(source: T): T {
        const json = JSON.stringify(source);
        return JSON.parse(json);
    }
}
