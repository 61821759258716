import { Component } from '@angular/core';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';

@Component({
    selector: 'my-girs-per-par-bar-chart',
    template: '<p-chart type="bar" [data]="data" [options]="options" responsive="true"></p-chart>'
})
export class GIRsPerParBarChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'bar');
    }
}
