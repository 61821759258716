<div class="card">
    <h1>Practice Activity</h1>
    <form *ngIf="model" #practiceActivityForm="ngForm" autocomplete="off" novalidate="novalidate">
        <div class="grid form-group">
            <div class="field col-12">
                <span class="p-float-label">
                    <input #name="ngModel" class="width10" name="name" maxlength="50" pInputText [(ngModel)]="model.name" required>
                    <my-validation-message [model]="name" type="required"></my-validation-message>
                    <label>Name</label>
                </span>
            </div>
            <div class="col-12 lg:col-8">
                <label>Description</label>
                <textarea #description="ngModel" name="description" [(ngModel)]="model.description" rows="15"></textarea>
                <my-validation-message [model]="description" type="required"></my-validation-message>
            </div>
            <div class="field col-12">
                <span class="p-float-label">
                    <p-dropdown #practiceCategory="ngModel" styleClass="width6" name="practiceCategory" [options]="practiceCategories" [(ngModel)]="model.practiceCategory" [autoDisplayFirst]="false" required></p-dropdown>
                    <my-validation-message [model]="practiceCategory" type="required"></my-validation-message>
                    <label>Category</label>
                </span>
            </div>
            <div class="field col-12">
                <span class="p-float-label">
                    <p-dropdown #practiceType="ngModel" styleClass="width6" name="practiceType" [options]="practiceTypes" [(ngModel)]="model.practiceType" [autoDisplayFirst]="false" (onChange)="onPracticeTypeChanged()" required></p-dropdown>
                    <i *ngIf="practiceTypeDescription" class="fas fa-info-circle help-icon" pTooltip="{{practiceTypeDescription}}" style="padding-left: 5px;"></i>
                    <my-validation-message [model]="practiceType" type="required"></my-validation-message>
                    <label>Practice Type</label>
                </span>
            </div>
            <div class="field col-12">
                <span class="p-float-label">
                    <p-dropdown #practiceScoreQualifier="ngModel" styleClass="width6" name="practiceScoreQualifier" [options]="practiceScoreQualifiers" [(ngModel)]="model.practiceScoreQualifier" [autoDisplayFirst]="false" required></p-dropdown>
                    <my-validation-message [model]="practiceScoreQualifier" type="required"></my-validation-message>
                    <label>Score Type</label>
                </span>
            </div>
            <div class="col-12 lg:col-8" *ngIf="isScorable()">
                <label>Scoring Rules</label>
                <textarea #scoringRules="ngModel" name="scoringRules" [(ngModel)]="model.scoringRules" required rows="5"></textarea>
                <my-validation-message [model]="scoringRules" type="required"></my-validation-message>
            </div>
            <div class="field col-12 lg:col-8" *ngIf="isScorable()">
                <span class="p-float-label">
                    <input type="number" #startingScore="ngModel" class="width2 center" name="startingScore" pInputText [(ngModel)]="model.startingScore" required>
                    <i *ngIf="shouldShowScoringHistoryChart()" class="fas fa-chart-line line-chart clickable" pTooltip="Scoring History" (click)="showScoringHistoryChart()" style="padding-left: 5px;"></i>
                    <my-validation-message [model]="startingScore" type="required"></my-validation-message>
                    <label>Starting Score</label>
                </span>
            </div>
            <div>
                <p-table [value]="model.practiceActivityVariations" [loading]="isLoading" styleClass="p-datatable-striped">
                    <ng-template pTemplate="caption">
                        Variations
                        <i class="fas fa-plus-square new-item-icon clickable" (click)="addActivityVariation()" pTooltip="Add a Variation to this Activity"></i>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="reorder-column"></th>
                            <th class="width15">Name</th>
                            <th>Description</th>
                            <th class="width10">Goal</th>
                            <th class="width3 center" *ngIf="isScorable()">Starting<br />Score</th>
                            <th class="width2"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-variation let-rowIndex="rowIndex">
                        <tr [pReorderableRow]="rowIndex">
                            <td>
                                <i class="fa fa-bars clickable" pReorderableRowHandle></i>
                            </td>
                            <td>
                                <span class="p-column-title">Name</span>
                                <input #variationName="ngModel" name="variationName-{{rowIndex}}" maxlength="50" pInputText [(ngModel)]="variation.name" required>
                                <my-validation-message [model]="variationName" type="required"></my-validation-message>
                            </td>
                            <td>
                                <span class="p-column-title">Description</span>
                                <input #variationDescription="ngModel" class="width10" name="variationDescription-{{rowIndex}}" maxlength="200" pInputText [(ngModel)]="variation.description">
                            </td>
                            <td>
                                <span class="p-column-title">Goal</span>
                                <input #variationGoal="ngModel" name="variationGoal-{{rowIndex}}" maxlength="50" pInputText [(ngModel)]="variation.goal">
                            </td>
                            <td *ngIf="isScorable()">
                                <input type="number" class="width2 center" name="startingScore-{{rowIndex}}" pInputText [(ngModel)]="variation.startingScore">
                            </td>
                            <td>
                                <span class="status-icons">
                                    <i class="fas fa-trash-alt clickable delete-icon" pTooltip="Delete" (click)="deleteActivityVariation(variation, rowIndex)"></i>
                                    <i *ngIf="shouldShowScoringHistoryChart(variation)" class="fas fa-chart-line line-chart clickable" pTooltip="Scoring History" (click)="showScoringHistoryChart(variation)"></i>
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td [attr.colspan]="isScorable() ? 5 : 4">
                                {{getEmptyMessage('No variations for this activity.')}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="col-12">
                <button type="submit" pButton label="Save" (click)="save()" [disabled]="isLoading  || !practiceActivityForm.form.valid" style="margin-right: 35px;"></button>
                <button *ngIf="!isEditing" type="submit" pButton label="Save Then Add Another" (click)="saveAndAddAnother()" [disabled]="isLoading  || !practiceActivityForm.form.valid"></button>
            </div>
            <div class="col-12">
                <my-messages></my-messages>
            </div>
        </div>
    </form>
</div>
<p-confirmDialog key="practice-activity-detail-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
