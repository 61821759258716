import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MessagesComponent, BaseComponentDirective } from '../../../shared/ui';
import { CoursesControllerProxy } from '../../../shared/server-proxies';
import { finalize } from 'rxjs/operators';

import {
    ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-scorecard-hole-notes',
    templateUrl: './scorecard-hole-notes.component.html'
})
export class ScorecardHoleNotesComponent extends BaseComponentDirective implements OnInit {
    constructor(private coursesProxy: CoursesControllerProxy) {
        super();
    }

    @ViewChild('notesMessages', { static: true }) notesMessages: MessagesComponent;
    @Input() courseId: number;
    @Input() selectedHole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole;
    
    ngOnInit() {

    }

    saveNotes() {
        this.taskStarted();

        const notesMessages = this.notesMessages;
        this.coursesProxy.saveHoleNotes(this.courseId, this.selectedHole.holeId, { notes: this.selectedHole.golferHoleInfo.notes })
            .pipe(
                finalize(
                    () => {
                        this.taskCompleted();
                        notesMessages.clearInMilliseconds(5000);
                    }),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    notesMessages.showSuccess('Notes have been saved.');
                },
                error => {
                    notesMessages.showError(`An error occurred. (${error.status})`);
                });
    }
}
