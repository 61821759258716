import { Component } from '@angular/core';
import { TooltipItem, ChartData, ChartDataset } from 'chart.js';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';
import { TimeSpan } from '../shared/util';

interface TimeTotals {
    practiceMinutes: number;
    onCourseMinutes: number;
}

@Component({
    selector: 'my-practice-time-by-month-stacked-bar-chart',
    template: '<p-chart type="bar" [data]="data" [options]="options" responsive="true"></p-chart>'
})
export class PracticeTimeByMonthStackedBarChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'bar');
        this.options.scales.y.stacked = true;
        this.options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);
        this.options.scales.y.ticks.callback = this.getTick.bind(this);

        this.options.scales.yRight = {
            position: 'right',
            beginAtZero: true
        };
    }

    private getTooltipLabel(tooltipItem: TooltipItem<'bar'>) {
        const dataSet = tooltipItem.dataset;
        const stack = dataSet.stack;
        const data = dataSet.data as number[];

        const month = tooltipItem.label;
        const practiceTypeOrCategory = dataSet.label;

        const totalTimeForMonth = this.sumAllDataSetsInStackAtIndex(tooltipItem.chart.data, stack, tooltipItem.dataIndex);
        const monthPracticeMinutesTimeSpan = TimeSpan.fromMinutes(totalTimeForMonth.practiceMinutes);
        const minutesForThisMonthAndTypeOrCategory = data[tooltipItem.dataIndex];
        const minutesForThisMonthAndTypeOrCategoryTimeSpan = TimeSpan.fromMinutes(minutesForThisMonthAndTypeOrCategory);

        const percentOfMonth = totalTimeForMonth.practiceMinutes > 0
            ? (minutesForThisMonthAndTypeOrCategory / totalTimeForMonth.practiceMinutes) * 100
            : 0;
        const tooltipLines = [];

        if(this.isAvgPracticeTimePerMonthDataSet(dataSet)) {
            tooltipLines.push(` Averaged ${minutesForThisMonthAndTypeOrCategoryTimeSpan.toString()}/Month thru ${month}`);
        }
        else if(this.isMonthlyHandicapDataSet(dataSet)) {
            tooltipLines.push(` ${practiceTypeOrCategory} for ${month}: ${minutesForThisMonthAndTypeOrCategory}`);
        }
        else if(this.isOnCourseTimeDataSet(dataSet)) {
            tooltipLines.push(
                ` Estimated ${minutesForThisMonthAndTypeOrCategoryTimeSpan.toString()} `
                + `Spent on the Course vs ${monthPracticeMinutesTimeSpan} Spent Practicing`
            );

            if(totalTimeForMonth.practiceMinutes > 0) {
                const playToPracticeRatio = totalTimeForMonth.onCourseMinutes > totalTimeForMonth.practiceMinutes
                    ? `${(totalTimeForMonth.onCourseMinutes / totalTimeForMonth.practiceMinutes).toFixed(1)} to 1`
                    : `1 to ${(totalTimeForMonth.practiceMinutes / totalTimeForMonth.onCourseMinutes).toFixed(1)}`;
                tooltipLines.push(` ${playToPracticeRatio} Play-Practice Ratio for ${month}`);
            }
        }
        else {
            tooltipLines.push(
                ` ${minutesForThisMonthAndTypeOrCategoryTimeSpan.toString()} Spent on ${practiceTypeOrCategory}`,
                ` ${percentOfMonth.toFixed(0)}% of ${month}'s ${monthPracticeMinutesTimeSpan.toString()} Practice Time`
            );
        }

        return tooltipLines;
    }

    private getTick(value: any) {
        const ts = TimeSpan.fromMinutes(value);
        return `${ts.toString()}`;
    }

    private sumAllDataSetsInStackAtIndex(chartData: ChartData, stack: string, index: number) {
        const totals: TimeTotals = {
            practiceMinutes: 0,
            onCourseMinutes: 0
        };

        const dataSets = chartData.datasets.filter(d => d.stack === stack);

        for(let i = 0; i < dataSets.length; i++) {
            const dataSet = dataSets[i];
            const data = dataSet.data as number[];

            if(data.length > index) {
                const value = data[index];

                if(value) {
                    if(this.isOnCourseTimeDataSet(dataSet)) {
                        totals.onCourseMinutes += value;
                    }
                    else {
                        totals.practiceMinutes += value;
                    }
                }
            }
        }

        return totals;
    }

    private isAvgPracticeTimePerMonthDataSet(dataSet: ChartDataset) {
        return dataSet.label.toLowerCase().indexOf('avg practice time') !== -1;
    }

    private isMonthlyHandicapDataSet(dataSet: ChartDataset) {
        return dataSet.label.toLowerCase().indexOf('handicap') !== -1;
    }

    private isOnCourseTimeDataSet(dataSet: ChartDataset) {
        return dataSet.label.toLowerCase().indexOf('on course') !== -1;
    }
}
