<div class="card">
    <h1>New Golf Round</h1>
    <form #roundForm="ngForm" autocomplete="off" novalidate="novalidate">
        <div *ngIf="showExistingGolfRounds">
                <p-messages severity="warn">
                    <ng-template pTemplate>
                        <span class="p-inline-message-icon pi pi-exclamation-triangle"></span>
                        <div class="ml-2">{{existingGolfRoundsMessage}}</div>
                        <div class="ml-2">
                            <button type="submit" class="p-button-info" pButton label="I Want a New Round" (click)="continueWithNewRoundCreation()"></button>
                        </div>
                    </ng-template>
                </p-messages>

                <p-table [value]="existingGolfRounds" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="width11 left">Tee Time</th>
                            <th class="left">Course</th>
                            <th class="width8 left">Created By</th>
                            <th class="width4"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-round let-rowIndex="rowIndex">
                        <tr>
                            <td class="left">
                                <span class="p-column-title">Tee Time</span>
                                {{round.teeTime | date:'EEEE, M/d/yyyy'}}
                                {{round.teeTime | date:'shortTime'}}
                            </td>
                            <td class="left">
                                <span class="p-column-title">Course</span>
                                {{round.course.name}}
                            </td>
                            <td class="left">
                                <span class="p-column-title">Created By</span>
                                {{getGolferName(round.createdByGolfer)}}
                            </td>
                            <td class="center">
                                <button type="submit" class="p-button-info" icon="fa fa-check" pButton label="Join Round" (click)="joinRound(round)" [disabled]="isLoading"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        
        <div *ngIf="showGolfRoundCreation" class="grid form-group">
            <div class="field col-12 lg:col-2">
                <span class="p-float-label">
                    <p-calendar #teeTime="ngModel" name="teeTime" [(ngModel)]="round.teeTime" showTime="true"
                                showButtonBar="true" dateFormat="m/d/yy" hourFormat="12"></p-calendar>
                    <my-validation-message [model]="teeTime" type="required"></my-validation-message>
                    <label>Tee Time</label>
                </span>
            </div>
            <div class="col-12">
                <p-selectButton name="viewOptions" [options]="viewOptions" [(ngModel)]="selectedView" (onChange)="onViewChanged()"></p-selectButton>
            </div>
            <div class="field col-12" *ngIf="isCityStateView">
                <span class="p-float-label">
                    <p-dropdown name="states" [options]="states" [(ngModel)]="stateCode" styleClass="state-selector" (onChange)="onStateChanged();"></p-dropdown>
                    <label>State</label>
                </span>
            </div>
            <div class="field col-12" *ngIf="isCityStateView">
                <span class="p-float-label">
                    <p-dropdown name="cities" [options]="cities" [(ngModel)]="city" styleClass="city-selector" (onChange)="onCityChanged();"></p-dropdown>
                    <label>City</label>
                </span>
            </div>
            <div class="field col-12 lg:col-4">
                <span class="p-float-label">
                    <p-dropdown #course="ngModel" name="course" [(ngModel)]="round.courseId" [options]="courses"
                                [autoDisplayFirst]="false" styleClass="width10" required></p-dropdown>
                    <my-validation-message [model]="course" type="required"></my-validation-message>
                    <label>Course</label>
                </span>
            </div>
            <div class="col-12">
                <p-multiSelect name="golfers" [(ngModel)]="round.golferIds" [options]="golfers" defaultLabel="Select Golfers"
                               maxSelectedLabels="4" selectedItemsLabel="{0} golfers selected" [showToggleAll]="false" [filter]="false"
                               required></p-multiSelect>
            </div>
            <div class="col-12">
                <button type="submit" pButton label="Save for Later" (click)="createRound()" [disabled]="isLoading  || !isFormValid()"
                        style="margin-right: 35px;"></button>
                <button type="submit" pButton label="Start Round Now" (click)="createRound(true)" [disabled]="isLoading  || !isFormValid()"></button>
                <p-messages [value]="messages" [closable]="false"></p-messages>
            </div>
        </div>
    </form>
</div>
