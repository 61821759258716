import { Component, OnInit, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, of as observableOf, forkJoin } from 'rxjs';
import { map, tap, finalize } from 'rxjs/operators';

import { QueryString } from '../../shared/util';
import { GolfersControllerProxy, RoundsControllerProxy } from '../../shared/server-proxies';
import { CurrentGolferService, GolferLookupService, IGolferSelectItem, IGolferLookupSelectItemLists } from '../../shared/golfers';
import { SessionService } from '../../shared/auth/session.service';
import { StatisticSnapshotChartDialogComponent } from './statistic-snapshot-chart-dialog/statistic-snapshot-chart-dialog.component';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import {
    ModelsCoreRoundsGolfRoundTypes,
    ModelsCoreStatisticsStatisticAdornments,
    ModelsCoreStatisticsStatisticCategories,
    ModelsCoreStatisticsStatisticComparisons,
    ModelsCoreStatisticsStatisticExports,
    ModelsStatisticsGetStatisticsGetStatisticsResponse,
    ModelsStatisticsGetStatisticsStatistic,
    ModelsStatisticsGetStatisticsStatisticValue,
    ModelsWebApiGolfersGetStatisticsArgs
} from '../../shared/swagger-codegen/models';

/* eslint-disable no-shadow */
export enum PseudoStatisticCategories {
    positiveChange = -1,
    negativeChange = -2
}

@Component({
    selector: 'my-statistics-list',
    templateUrl: './statistics-list.component.html',
    styleUrls: [
        './statistics-list.component.scss'
    ]
})
export class StatisticsListComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private roundsProxy: RoundsControllerProxy,
        private golferLookups: GolferLookupService,
        private currentGolfer: CurrentGolferService,
        private session: SessionService,
        private dialogService: DialogService) {
        super();
    }

    golfers: IGolferSelectItem[];
    response: ModelsStatisticsGetStatisticsGetStatisticsResponse;
    golferId: number;
    args: ModelsWebApiGolfersGetStatisticsArgs = {};
    categories: SelectItem[] = [];
    selectedCategoryId: number;
    yearsPlayed: SelectItem[];
    statistics: ModelsStatisticsGetStatisticsStatistic[];
    comparisons: SelectItem[] = [
        { label: 'Friends', value: ModelsCoreStatisticsStatisticComparisons.Friends },
        { label: 'Years', value: ModelsCoreStatisticsStatisticComparisons.MostRecentYears },
        { label: 'Quarters', value: ModelsCoreStatisticsStatisticComparisons.Quarters },
        { label: 'Months', value: ModelsCoreStatisticsStatisticComparisons.Months },
        { label: 'Recent Rounds', value: ModelsCoreStatisticsStatisticComparisons.RecentRounds }
    ];
    golfRoundTypes: SelectItem[] = [
        { label: 'ALL', value: ModelsCoreRoundsGolfRoundTypes.All },
        { label: 'Competitive', value: ModelsCoreRoundsGolfRoundTypes.Competitive },
        { label: 'Practice', value: ModelsCoreRoundsGolfRoundTypes.Practice }
    ];
    quarters: SelectItem[] = [
        { label: 'ALL', value: -1 },
        { label: 'Q1', value: 1 },
        { label: 'Q2', value: 2 },
        { label: 'Q3', value: 3 },
        { label: 'Q4', value: 4 }
    ];
    months: SelectItem[] = [
        { label: 'ALL', value: -1 },
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 }
    ];
    excelExportUrl: string;
    isLoaded = false;
    private favoriteStatistics: { [statisticId: number]: boolean } = {};

    get golfRoundId() {
        return this.args.golfRoundId;
    }

    @Input()
    set golfRoundId(value: number) {
        this.args.golfRoundId = value;
    }

    get isViewingGolfRoundStatistics() {
        return this.golfRoundId > 0;
    }

    get shouldShowGolfers() {
        return this.args.comparison === ModelsCoreStatisticsStatisticComparisons.MostRecentYears
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.MostPlayedYears
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Quarters
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Months
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.RecentRounds
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.GolfRoundImpact;
    }

    get shouldShowYears() {
        return this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Friends
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Quarters
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Months;
    }

    get shouldShowQuarters() {
        return this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Friends;
    }

    get shouldShowMonths() {
        return this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Friends
            && this.args.quarter === 0;
    }

    get shouldShowGolfRoundTypes() {
        return this.args.comparison === ModelsCoreStatisticsStatisticComparisons.MostRecentYears
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.MostPlayedYears
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Quarters
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Months
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.RecentRounds
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Friends;
    }

    get shouldShowComparisons() {
        return true;
    }

    ngOnInit(): void {
        const favoritesObservable = this.loadFavoriteStatistics();
        let selectItemListObservable: Observable<IGolferLookupSelectItemLists>;

        if(this.isViewingGolfRoundStatistics) {
            selectItemListObservable = this.initializeGolfRoundStats();
        }
        else {
            selectItemListObservable = this.initializeStats();
        }

        forkJoin([favoritesObservable, selectItemListObservable])
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                results => {
                    this.golfers = results[1].friends;
                    this.yearsPlayed = results[1].yearsPlayed;
                    this.addOrRemoveMostPlayedYearsFilter();

                    // We have to do this because you may be viewing stats of one of your friend's golf rounds that you
                    // didn't play in. In this case we can't assume the current golfer is always in the friends collection.
                    let i = this.golfers.findIndex(g => g.value === this.currentGolfer.golferId);
                    i = Math.max(0, i);

                    this.golferId = this.golfers.length > 0 ? this.golfers[i].value : -1;

                    if(!this.isViewingGolfRoundStatistics) {
                        this.args.golfRoundType = ModelsCoreRoundsGolfRoundTypes.All;
                        this.args.year = this.golferLookups.defaultYearPlayed;
                        this.args.quarter = 0;
                        this.args.month = 0;
                    }

                    this.loadStatistics();
                });
    }

    onCategoryChanged() {
        this.filterStatisticsByCategory();
    }

    onComparisonChanged() {
        this.addOrRemovePseudoCategories();
        this.loadStatistics();
    }

    onGolfRoundTypeChanged() {
        this.loadStatistics();
    }

    onGolferChanged() {
        this.loadStatistics();
    }

    onYearChanged() {
        this.loadStatistics();
    }

    onQuarterChanged() {
        this.loadStatistics();
    }

    onMonthChanged() {
        this.loadStatistics();
    }

    isFavoriteStatistic(stat: ModelsStatisticsGetStatisticsStatistic) {
        return this.favoriteStatistics[stat.statisticId] !== undefined;
    }

    addFavoriteStatistic(stat: ModelsStatisticsGetStatisticsStatistic) {
        const golferId = this.currentGolfer.golferId;
        this.golfersProxy.addFavoriteStatistic(golferId, stat.statisticId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(() => {
                this.favoriteStatistics[stat.statisticId] = true;
            });
    }

    removeFavoriteStatistic(stat: ModelsStatisticsGetStatisticsStatistic) {
        const golferId = this.currentGolfer.golferId;
        this.golfersProxy.removeFavoriteStatistic(golferId, stat.statisticId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(() => {
                delete this.favoriteStatistics[stat.statisticId];

                if(this.selectedCategoryId === ModelsCoreStatisticsStatisticCategories.Favorites) {
                    this.filterStatisticsByCategory();
                }
            });
    }

    getAdornmentClass(stat: ModelsStatisticsGetStatisticsStatisticValue) {
        let cssClass: string;

        switch(stat.adornment) {
            case ModelsCoreStatisticsStatisticAdornments.Positive:
                cssClass = 'positive-adornment';
                break;
            case ModelsCoreStatisticsStatisticAdornments.Negative:
                cssClass = 'negative-adornment';
                break;
            default:
                cssClass = '';
                break;
        }

        return cssClass;
    }

    getRankingClass(stat: ModelsStatisticsGetStatisticsStatisticValue) {
        let cssClass = '';

        switch(stat.rank) {
            case 1:
                cssClass = 'first-place';
                break;
            case 2:
                cssClass = 'second-place';
                break;
            case 3:
                cssClass = 'third-place';
                break;
        }

        return cssClass;
    }

    shouldShowRanking(stat: ModelsStatisticsGetStatisticsStatisticValue) {
        return stat.rank >= 1 && stat.rank <= 3;
    }

    shouldShowStatRowGroupHeader(rowIndex: number) {
        let show = rowIndex === 0;

        if(!show) {
            const previousRowIndex = rowIndex - 1;
            const previousCategory = previousRowIndex < this.statistics.length
                ? this.statistics[previousRowIndex].category.name
                : undefined;
            const currentCategory = rowIndex < this.statistics.length
                ? this.statistics[rowIndex].category.name
                : undefined;

            show = previousCategory !== currentCategory;
        }

        return show;
    }

    showSnapshotChart(stat: ModelsStatisticsGetStatisticsStatistic) {
        this.dialogService.open(
            StatisticSnapshotChartDialogComponent,
            {
                data: {
                    statistic: stat,
                    golferId: this.golferId,
                    year: this.args.year,
                    golfRoundTypeId: this.args.golfRoundType
                },
                header: stat.name,
                width: '70%'
            });
    }

    private initializeGolfRoundStats() {
        this.comparisons.length = 0;
        this.comparisons.push(
            { label: 'Golfers in Round', value: ModelsCoreStatisticsStatisticComparisons.GolfRound },
            { label: 'Pre-Round Stats', value: ModelsCoreStatisticsStatisticComparisons.GolfRoundImpact }
        );

        this.args.comparison = ModelsCoreStatisticsStatisticComparisons.GolfRound;

        const golfersObservable = this.roundsProxy.getGolfRoundGolfers(this.golfRoundId)
            .pipe(
                map(
                    response => {
                        return response.body.map(
                            golfer => {
                                return {
                                    value: golfer.golferId,
                                    label: `${golfer.firstName} ${golfer.lastName}`,
                                    golfer: golfer
                                } as IGolferSelectItem;
                            });
                    }));
        const yearsObservable = observableOf([] as SelectItem[]);

        return forkJoin([golfersObservable, yearsObservable])
            .pipe(
                map(
                    results => {
                        return {
                            friends: results[0],
                            yearsPlayed: results[1]
                        } as IGolferLookupSelectItemLists;
                    }));
    }

    private initializeStats() {
        this.args.comparison = ModelsCoreStatisticsStatisticComparisons.Friends;

        return this.golferLookups.getFriendAndYearsPlayedSelectItems();
    }

    private loadFavoriteStatistics() {
        const golferId = this.currentGolfer.golferId;
        return this.golfersProxy.getFavoriteStatistics(golferId)
            .pipe(
                tap(
                    response => {
                        response.body.forEach(
                            stat => {
                                this.favoriteStatistics[stat.statisticId] = true;
                            });
                    }));
    }

    private loadStatistics() {
        this.updateExportUrl();
        this.taskStarted();

        this.golfersProxy.getStatistics(this.golferId, this.args)
            .pipe(
                finalize(() => {
                    this.taskCompleted();
                    this.isLoaded = true;
                }),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.response = response.body;
                    this.createCategorySelectItems();

                    if(this.response.columnHeaders.length > 0) {
                        this.filterStatisticsByCategory();
                    }
                });
    }

    private updateExportUrl() {
        const exportArgs: ModelsWebApiGolfersGetStatisticsArgs = {};

        Object.keys(this.args).forEach(key => {
            exportArgs[key] = this.args[key];
        });

        exportArgs.exportFormat = ModelsCoreStatisticsStatisticExports.Excel;
        (exportArgs as any).access_token = this.session.getToken();

        this.excelExportUrl = `/api/golfers/${this.golferId}/statistics${QueryString.fromObject(exportArgs)}`;
    }

    private createCategorySelectItems() {
        if(this.categories.length === 0) {
            this.categories = this.response.categories.map(
                (category) => {
                    return { value: category.statisticCategoryId, label: category.name };
                });

            this.addOrRemovePseudoCategories();
        }

        if(this.selectedCategoryId === undefined) {
            this.setDefaultCategory();
        }
    }

    private addOrRemovePseudoCategories() {
        const comparingGolfRoundImpact = this.args.comparison === ModelsCoreStatisticsStatisticComparisons.GolfRoundImpact;
        const hasPseudoCategories = this.categories.length > 0
            && this.categories[0].value === PseudoStatisticCategories.positiveChange;

        if(comparingGolfRoundImpact && !hasPseudoCategories) {
            this.categories.unshift(
                { value: PseudoStatisticCategories.positiveChange, label: 'Positive Change' },
                { value: PseudoStatisticCategories.negativeChange, label: 'Negative Change' }
            );
        }
        else if(!comparingGolfRoundImpact && hasPseudoCategories) {
            this.categories.splice(0, 3);

            if(this.selectedCategoryId === PseudoStatisticCategories.positiveChange
                || this.selectedCategoryId === PseudoStatisticCategories.negativeChange) {
                this.setDefaultCategory();
            }
        }
    }

    private setDefaultCategory() {
        this.selectedCategoryId = ModelsCoreStatisticsStatisticCategories.Favorites;
    }

    private filterStatisticsByCategory() {
        const selectedCategoryId = this.selectedCategoryId || ModelsCoreStatisticsStatisticCategories.Favorites;

        if(selectedCategoryId === ModelsCoreStatisticsStatisticCategories.Favorites) {
            this.statistics = this.response.statistics.filter(
                (statistic) => {
                    return this.isFavoriteStatistic(statistic);
                });
        }
        else if(selectedCategoryId === PseudoStatisticCategories.positiveChange
             || selectedCategoryId === PseudoStatisticCategories.negativeChange) {

            const adornment = selectedCategoryId === PseudoStatisticCategories.positiveChange
                ? ModelsCoreStatisticsStatisticAdornments.Positive
                : ModelsCoreStatisticsStatisticAdornments.Negative;

            this.statistics = this.response.statistics.filter(
                (statistic) => {
                    return statistic.adornment === adornment;
                });
        }
        else {
            this.statistics = this.response.statistics.filter(
                (statistic) => {
                    return statistic.category.statisticCategoryId === selectedCategoryId;
                });
        }
    }

    private addOrRemoveMostPlayedYearsFilter() {
        const mostRecentIndex = this.comparisons.findIndex(i => i.value === ModelsCoreStatisticsStatisticComparisons.MostRecentYears);
        const mostPlayedIndex = this.comparisons.findIndex(i => i.value === ModelsCoreStatisticsStatisticComparisons.MostPlayedYears);

        if(this.yearsPlayed.length > 12) {
            this.comparisons[mostRecentIndex].label = 'Years (Most Recent)';

            if(mostPlayedIndex === -1) {
                const item = {
                    value: ModelsCoreStatisticsStatisticComparisons.MostPlayedYears,
                    label: 'Years (Most Played)'
                };

                this.comparisons.splice(mostRecentIndex, 0, item);
            }
        }
        else if(this.yearsPlayed.length > 0) {
            this.comparisons[mostRecentIndex].label = 'Years';

            if(mostPlayedIndex !== -1) {
                this.comparisons.splice(mostPlayedIndex, 1);
            }
        }
    }
}
