<div class="grid scorecard-entry">
    <ng-template ngFor let-rowData let-rowIndex="index" [ngForOf]="source">
        <div (click)="onItemClick(rowData)" [ngClass]="itemClass">
            <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: rowData, rowIndex: rowIndex}"></ng-container>
            <div class="scorecard-entry-item-label" *ngIf="itemLabelTemplate">
                <br />
                <ng-container *ngTemplateOutlet="itemLabelTemplate; context: {$implicit: rowData, rowIndex: rowIndex}"></ng-container>
            </div>
        </div>
    </ng-template>
    <span *ngIf="showEditButton" (click)="onEditClick()" class="scorecard-entry-item">?</span>
    <span *ngIf="showSecondaryItemsButton" (click)="onShowSecondaryItemsClick()" class="scorecard-entry-item">...</span>
</div>
