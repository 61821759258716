<form *ngIf="golfer" #golferForm="ngForm" autocomplete="off" novalidate="novalidate">
    <div class="grid form-group">
        <div class="field col-12">
            <span class="p-float-label">
                <input #firstName="ngModel" class="width10" name="firstName" autocomplete="given-name" maxlength="50" pInputText [(ngModel)]="golfer.firstName" required>
                <my-validation-message [model]="firstName" type="required"></my-validation-message>
                <label>First Name</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <input #lastName="ngModel" class="width10" name="lastName" autocomplete="family-name" maxlength="50" pInputText [(ngModel)]="golfer.lastName" required>
                <my-validation-message [model]="lastName" type="required"></my-validation-message>
                <label>Last Name</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <input #email="ngModel" class="width10" type="email" name="email" autocomplete="email" pInputText [(ngModel)]="golfer.email" required>
                <my-validation-message [model]="email" type="required"></my-validation-message>
                <label>Email</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <p-inputMask name="phone" class="width6" mask="(999) 999-9999" unmask="true" [(ngModel)]="golfer.phone"></p-inputMask>
                <label>Phone</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <input #city="ngModel" class="width10" name="city" autocomplete="address-level2" maxlength="50" pInputText [(ngModel)]="golfer.city" required>
                <my-validation-message [model]="city" type="required"></my-validation-message>
                <label>City</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <p-dropdown #stateCode="ngModel" styleClass="width6" name="stateCode" autocomplete="address-level1" [options]="states" [(ngModel)]="golfer.stateCode" [autoDisplayFirst]="false" required></p-dropdown>
                <my-validation-message [model]="stateCode" type="required"></my-validation-message>
                <label>State</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <p-dropdown #gender="ngModel" styleClass="width6" name="gender" [options]="genders" [(ngModel)]="golfer.gender" [autoDisplayFirst]="false" required></p-dropdown>
                <my-validation-message [model]="gender" type="required"></my-validation-message>
                <label>USGA Handicapping Rules</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <p-dropdown #defaultCourseYardageType="ngModel" styleClass="width4 default-course-yardage-type" name="defaultCourseYardageType" [options]="defaultCourseYardageTypes" [(ngModel)]="golfer.defaultCourseYardageType" required></p-dropdown>
                <input #defaultCourseYardage="ngModel" class="width1 center" name="defaultCourseYardage" type="number" min="0" max="9999" step="100" pInputText [(ngModel)]="golfer.defaultCourseYardage" required>
                Yards
                <i class="fas fa-info-circle help-icon" pTooltip="When creating new rounds, the longest tee that meets this criteria will automatically be selected for you. However, you can always change the tee selection any time before starting your round."></i>
                <my-validation-message [model]="defaultCourseYardage" type="required"></my-validation-message>
                <label>Default Tee Yardage</label>
            </span>
        </div>
        <p-fieldset legend="Golf Club Yardage Labels">
            <div class="grid form-group">
                <div class="field col-12">
                    <span class="p-float-label">
                        <input #golfClubYardageLabel="ngModel" class="width2" name="golfClubYardageLabel" maxlength="15" pInputText [(ngModel)]="golfer.golfClubYardageLabel" required>
                        <my-validation-message [model]="golfClubYardageLabel" type="required"></my-validation-message>
                        <label>Primary</label>
                    </span>
                </div>
                <div class="field col-12">
                    <span class="p-float-label">
                        <input #golfClubSecondaryYardage1Label="ngModel" class="width2" name="golfClubSecondaryYardage1Label" maxlength="15" pInputText [(ngModel)]="golfer.golfClubSecondaryYardage1Label" required>
                        <my-validation-message [model]="golfClubSecondaryYardage1Label" type="required"></my-validation-message>
                        <label>Secondary 1</label>
                    </span>
                </div>
                <div class="field col-12">
                    <span class="p-float-label">
                        <input #golfClubSecondaryYardage2Label="ngModel" class="width2" name="golfClubSecondaryYardage2Label" maxlength="15" pInputText [(ngModel)]="golfer.golfClubSecondaryYardage2Label" required>
                        <my-validation-message [model]="golfClubSecondaryYardage2Label" type="required"></my-validation-message>
                        <label>Secondary 2</label>
                    </span>
                </div>
                <div class="field col-12">
                    <span class="p-float-label">
                        <input #golfClubSecondaryYardage3Label="ngModel" class="width2" name="golfClubSecondaryYardage3Label" maxlength="15" pInputText [(ngModel)]="golfer.golfClubSecondaryYardage3Label" required>
                        <my-validation-message [model]="golfClubSecondaryYardage3Label" type="required"></my-validation-message>
                        <label>Secondary 3</label>
                    </span>
                </div>
            </div>
        </p-fieldset>
        <div class="field col-12">
            <span class="p-float-label">
                <a [routerLink]="['/golfers', golfer.golferId, 'change-password']">Change Password</a>
            </span>
        </div>
        <div class="col-12">
            <button type="submit" pButton label="Save" (click)="save()" [disabled]="isLoading  || !golferForm.form.valid"></button>
        </div>
    </div>
</form>
