import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';

import { GolfersControllerProxy, ReferenceControllerProxy } from '../../../../shared/server-proxies';
import { MessagesComponent } from '../../../../shared/ui/messages/messages.component';
import { BaseComponentDirective } from '../../../../shared/ui/base-component.directive';

import {
    ModelsCoreGolfersGolfClub,
    ModelsCoreGolfersGolfClubCategories,
    ModelsCoreGolfersGolfer,
    ModelsWebApiGolfersSaveGolfClubModel
} from '../../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-golf-club-details',
    templateUrl: './golf-club-details.component.html'
})
export class GolfClubDetailsComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersControllerProxy: GolfersControllerProxy,
        private referenceControllerProxy: ReferenceControllerProxy,
        private currentRoute: ActivatedRoute,
        private router: Router) {
        super();
    }

    @ViewChild(MessagesComponent) messages: MessagesComponent;
    golfClub: ModelsCoreGolfersGolfClub;
    golfClubCategories: SelectItem[] = [];
    golferId: number;
    golfClubId: number;
    golfer: ModelsCoreGolfersGolfer;
    selectedGolfClub: ModelsCoreGolfersGolfClub;
    isNewClub: boolean;

    ngOnInit() {
        this.referenceControllerProxy.getGolfClubCategorySelectItems()
        .pipe(this.takeUntilUnsubscribed())
        .subscribe(
            response => {
                this.golfClubCategories = response;
            });

        this.currentRoute.params
            .pipe(
                this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    const golferIdParam = params['golferId'];
                    this.golferId = parseInt(golferIdParam, 10);

                    const golfClubIdParam = params['golfClubId'];
                    this.golfClubId = parseInt(golfClubIdParam, 10) || 0;
                    this.isNewClub = this.golfClubId === 0;

                    this.golfersControllerProxy.getGolfer(this.golferId)
                        .pipe(this.takeUntilUnsubscribed())
                        .subscribe(
                            response => {
                                this.golfer = response.body;
                            },
                            response => this.messages.showApiError(response));

                    if(this.isNewClub) {
                        this.golfClub = {
                            abbreviation: '',
                            golfClubCategory: {
                                golfClubCategoryId: ModelsCoreGolfersGolfClubCategories.Driver,
                                name: ''
                            },
                            golfClubId: 0,
                            gripBrand: '',
                            gripInstructions: '',
                            gripModel: '',
                            headBrand: '',
                            headModel: '',
                            name: '',
                            shaftBrand: '',
                            shaftFlex: '',
                            shaftModel: '',
                            swingWeight: '',
                            yardage: 0,
                            isActive: true,
                            purchaseDate: null
                        };
                    }
                    else {
                        this.taskStarted();
                        this.golfersControllerProxy.getGolfClubs(this.golferId, { includeInactive: true })
                            .pipe(
                                finalize(() => this.taskCompleted()),
                                this.takeUntilUnsubscribed())
                            .subscribe(
                                response => {
                                    const golfClubs = response.body;
                                    const club = golfClubs.find(gc => gc.golfClubId === this.golfClubId);

                                    //The PrimeNG Calendar component is picky about its date model.
                                    if(club.purchaseDate) {
                                        club.purchaseDate = new Date(club.purchaseDate);
                                    }
                                    else {
                                        club.purchaseDate = null;
                                    }

                                    this.golfClub = club;
                                });
                    }
                });
    }

    getPageTitle() {
        return this.isNewClub ? 'Add New Club' : 'Edit Club';
    }

    getButtonLabel() {
        return this.isNewClub ? 'Add' : 'Save';
    }

    save() {
        const model = this.createSaveModelFromClub(this.golfClub);

        if(this.isNewClub) {
            this.addClub(model);
        }
        else {
            this.saveClub(model);
        }
    }

    saveClub(model: ModelsWebApiGolfersSaveGolfClubModel) {
        this.taskStarted();
        this.golfersControllerProxy.saveGolfClub(this.golferId, this.golfClubId, model)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    this.router.navigate(['golfers', this.golferId, { tab: 1 }]);
                },
                response => this.messages.showApiError(response)
            );
    }

    addClub(model: ModelsWebApiGolfersSaveGolfClubModel) {
        this.taskStarted();
        this.golfersControllerProxy.addGolfClub(this.golferId, model)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    this.router.navigate(['golfers', this.golferId, { tab: 1 }]);
                },
                response => this.messages.showApiError(response));
    }

    shouldShowYardage(club: ModelsCoreGolfersGolfClub) {
        return this.golfer && club?.golfClubCategory?.golfClubCategoryId !== ModelsCoreGolfersGolfClubCategories.Putter;
    }

    hasOnCourseYardages(club: ModelsCoreGolfersGolfClub) {
        return club.yardageOnCoursePercentile50 > 0 || club.yardageOnCoursePercentile75 || club.yardageOnCoursePercentile90;
    }

    private createSaveModelFromClub(club: ModelsCoreGolfersGolfClub) {
        return {
            abbreviation: club.abbreviation,
            golfClubCategory: club.golfClubCategory.golfClubCategoryId as any,
            gripBrand: club.gripBrand,
            gripInstructions: club.gripInstructions,
            gripModel: club.gripModel,
            headBrand: club.headBrand,
            headModel: club.headModel,
            isPured: club.isPured,
            isActive: club.isActive,
            length: club.length,
            lie: club.lie,
            loft: club.loft,
            name: club.name,
            purchaseDate: club.purchaseDate,
            shaftBrand: club.shaftBrand,
            shaftFlex: club.shaftFlex,
            shaftModel: club.shaftModel,
            swingWeight: club.swingWeight,
            yardage: club.yardage,
            secondaryYardage1: club.secondaryYardage1,
            secondaryYardage2: club.secondaryYardage2,
            secondaryYardage3: club.secondaryYardage3,
            lateralDispersion: club.lateralDispersion,
            distanceDispersion: club.distanceDispersion
        } as ModelsWebApiGolfersSaveGolfClubModel;
    }
}
