import { Component } from '@angular/core';
import { TooltipItem } from 'chart.js';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';
import { formatHandicap } from '../shared/ui';

@Component({
    selector: 'my-projected-handicap-line-chart',
    template: '<p-chart type="line" [data]="data" [options]="options" responsive="true"></p-chart>'
})
export class ProjectedHandicapLineChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'line');

        this.options.plugins.title.display = true;
        this.options.plugins.title.text = 'Handicaps Projected by Next Round\'s Score';
        this.options.scales.y.beginAtZero = false;
        this.options.scales.y.ticks.callback = this.getTick.bind(this);
        this.options.plugins.tooltip.callbacks.title = this.getTooltipTitle.bind(this);
        this.options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);
    }

    private getTick(value: any) {
        return formatHandicap(value);
    }

    private getTooltipTitle() {
        return null;
    }

    private getTooltipLabel(tooltipItem: TooltipItem<'line'>) {
        const isProjectedHandicap = tooltipItem.datasetIndex === 1;
        const dataset = tooltipItem.dataset;
        const data = dataset.data as number[];

        const score = tooltipItem.label;
        const handicapIndex = data[tooltipItem.dataIndex];
        let tooltip: string;

        if(isProjectedHandicap) {
            const currentHandicapIndex = (tooltipItem.chart.data.datasets[0].data as number[])[tooltipItem.dataIndex];

            if(handicapIndex === currentHandicapIndex) {
                tooltip = ` Handicap Index will remain unchanged at ${this.parseHandicapIndex(handicapIndex)} with a score of ${score}`;
            }
            else {
                const current = this.parseHandicapIndex(currentHandicapIndex);
                const projected = this.parseHandicapIndex(handicapIndex);
                const change = projected > current ? 'increase' : 'decrease';
                const difference = Math.abs(current - projected).toFixed(1);
                tooltip = ` Handicap Index will ${change} by ${difference} from ${this.parseHandicapIndex(currentHandicapIndex)} `
                    + `to ${this.parseHandicapIndex(handicapIndex) } with a score of ${ score }`;
            }
        }
        else {
            tooltip = ` Current Handicap Index is ${this.parseHandicapIndex(handicapIndex)}`;
        }

        return tooltip;
    }

    private parseHandicapIndex(index: number) {
        return parseFloat(index.toString().replace('+', '-'));
    }
}
