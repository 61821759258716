/// <reference types="@types/google.maps" />
import LatLng = google.maps.LatLng;

export function metersBetween(from: LatLng, to: LatLng) {
    return google.maps.geometry.spherical.computeDistanceBetween(from, to);
}

export function yardsBetween(from: LatLng, to: LatLng) {
    const meters = metersBetween(from, to);
    return metersToYards(meters);
}

export function metersToYards(meters: number) {
    return meters * 1.09361;
}

export function yardsToMeters(yards: number) {
    return yards / 1.09361;
}

export function radiansToDegrees(radians: number) {
    return radians * 180 / Math.PI;
}

export function degreesToRadians(degrees: number) {
    return degrees * Math.PI / 180;
}

export function disposeMapComponents(...components: any[]) {
    components
        .filter(component => component)
        .forEach(component => {
            try {
                if(Array.isArray(component)) {
                    disposeMapComponents(...component);
                    component.length = 0;
                }
                else if(typeof component.dispose === 'function') {
                    component.dispose();
                }
                else {
                    if(typeof component.unbindAll === 'function') {
                        component.unbindAll();
                    }

                    if(typeof component.setMap === 'function') {
                        component.setMap(null);
                    }
                }
            }
            catch(e) {
                console.error(e);
            }
        });
}
