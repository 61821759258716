<div class="grid">
    <div class="col-12 lg:col-6" *ngIf="notificationPreferences">
        <p-message *ngIf="shouldWarnOfMissingPhone" severity="warn" [text]="missingPhoneWarningMessage"></p-message>
        <p-table [value]="notificationPreferences" [loading]="isLoading" styleClass="p-datatable-striped">
            <ng-template pTemplate="caption">
                Notification Preferences
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="left">Notification</th>
                    <th class="width8">Delivery Method</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-preference let-rowIndex="rowIndex">
                <tr>
                    <td class="left">
                        <span class="p-column-title">Notification</span>
                        {{preference.name}}
                    </td>
                    <td>
                        <span class="p-column-title">Delivery Method</span>
                        <p-dropdown styleClass="width7" [options]="notificationTypes" [(ngModel)]="preference.notificationTypeId" (onChange)="onNotificationPreferenceSelected()"></p-dropdown>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="2">
                        {{getEmptyMessage('No notification preferences.')}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="col-12 lg:col-6" *ngIf="reminderPreferences && reminderPreferenceEnabled">
        <p-table [value]="reminderPreferences" [loading]="isLoading" styleClass="p-datatable-striped">
            <ng-template pTemplate="caption">
                Reminder Preferences
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th rowspan="2" class="left">Trigger When It's Been</th>
                    <th colspan="7" class="width10">Send On</th>
                </tr>
                <tr>
                    <th>Sun</th>
                    <th>Mon</th>
                    <th>Tue</th>
                    <th>Wed</th>
                    <th>Thu</th>
                    <th>Fri</th>
                    <th>Sat</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-preference let-rowIndex="rowIndex">
                <tr>
                    <td class="left">
                        <span class="p-column-title">Trigger When It's Been</span>
                        <input name="days-{{preference.reminderTypeId}}" class="center" style="width: 75px;" type="number" min="1" max="255" pInputText required [(ngModel)]="preference.days">
                        <span> {{preference.name}}</span>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Sunday</span>
                        <p-checkbox name="sunday" [(ngModel)]="preference.sunday" [binary]="true"></p-checkbox>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Monday</span>
                        <p-checkbox name="monday" [(ngModel)]="preference.monday" [binary]="true"></p-checkbox>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Tuesday</span>
                        <p-checkbox name="tuesday" [(ngModel)]="preference.tuesday" [binary]="true"></p-checkbox>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Wednesday</span>
                        <p-checkbox name="wednesday" [(ngModel)]="preference.wednesday" [binary]="true"></p-checkbox>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Thursday</span>
                        <p-checkbox name="thursday" [(ngModel)]="preference.thursday" [binary]="true"></p-checkbox>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Friday</span>
                        <p-checkbox name="friday" [(ngModel)]="preference.friday" [binary]="true"></p-checkbox>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Saturday</span>
                        <p-checkbox name="saturday" [(ngModel)]="preference.saturday" [binary]="true"></p-checkbox>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">
                        {{getEmptyMessage('No reminder preferences.')}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="col-12">
        <button type="submit" pButton label="Save" (click)="savePreferences()" [disabled]="isLoading"></button>
    </div>
</div>
