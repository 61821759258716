import { OnDestroy, Directive } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { Subject, MonoTypeOperatorFunction } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class BaseComponentDirective implements OnDestroy  {
    private unsubscribeSubject: Subject<void> = new Subject<void>();
    private taskCount = NaN;

    get isLoading() {
        return this.taskCount > 0;
    }

    ngOnDestroy() {
        this.onDestroy();
        this.unsubscribe();
    }

    getEmptyMessage(emptyMessage: string, loadingMessage = 'Loading...') {
        return this.isLoading || isNaN(this.taskCount) ? loadingMessage : emptyMessage;
    }

    customTableSort(event: SortEvent) {
        event.data.sort((data1, data2) => {
            const values1 = this.getFieldValues(data1, event.field);
            const values2 = this.getFieldValues(data2, event.field);
            let result = 0;

            for(let i = 0; i < values1.length && result === 0; i++) {
                const value1 = values1[i];
                const value2 = values2[i];

                if(value1 == null && value2 != null) {
                    result = -1;
                }
                else if(value1 != null && value2 == null) {
                    result = 1;
                }
                else if(value1 == null && value2 == null) {
                    result = 0;
                }
                else if(typeof value1 === 'string' && typeof value2 === 'string') {
                    result = value1.localeCompare(value2);
                }
                else {
                    result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
                }
            }

            return (event.order * result);
        });
    }

    private getFieldValues(data: any, fields: string) {
        return fields
            .split(',')
            .map(f => this.getFieldValue(data, f.trim()));
    }

    private getFieldValue(data: any, propertyPath: string) {
        const properties = propertyPath.split('.');
        let current = data;
        let value = null;
        let i = 0;

        for(; i < properties.length; i++) {
            current = current[properties[i]];

            if(current === undefined || current === null) {
                break;
            }

            value = current;
        }

        return i === properties.length ? value : undefined;
    }

    protected onDestroy() {
    }

    protected unsubscribe() {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }

    protected takeUntilUnsubscribed<T>(): MonoTypeOperatorFunction<T> {
        return takeUntil(this.unsubscribeSubject);
    }

    protected taskStarted() {
        this.taskCount = isNaN(this.taskCount) ? 1 : this.taskCount + 1;
    }

    protected taskCompleted() {
        this.taskCount--;
    }

    protected clearTasks() {
        this.taskCount = 0;
    }
}
