import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { GolfersControllerProxy } from '../../../shared/server-proxies';
import { CurrentGolferService, GolferLookupService, IGolferSelectItem } from '../../../shared/golfers';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';

import {
    ModelsCoreRoundsGolfRoundTypes,
    ModelsCoreStatisticsGoal,
    ModelsCoreStatisticsGoalStatus,
    ModelsCoreStatisticsStatisticAdornments,
    ModelsWebApiGolfersGetGoalsArgs
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-goals-list',
    templateUrl: './goals-list.component.html',
    styleUrls: [
        './goals-list.component.scss'
    ]
})
export class GoalsListComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private golferLookups: GolferLookupService,
        private confirmationService: ConfirmationService,
        private currentGolfer: CurrentGolferService,
        private router: Router) {
        super();
    }

    golfers: IGolferSelectItem[];
    golferId: number;
    args = {} as ModelsWebApiGolfersGetGoalsArgs;
    goalYears: SelectItem[];
    goals: ModelsCoreStatisticsGoal[] = [];

    ngOnInit(): void {
        this.golferLookups.getFriendSelectItems()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                friends => {
                    this.golfers = friends;
                    this.golferId = this.golferLookups.defaultFriendId;

                    this.loadGoals(true);
                });
    }

    onGolferChanged() {
        this.loadGoals(true);
    }

    onYearChanged() {
        this.loadGoals();
    }

    getAdornmentClass(goal: ModelsCoreStatisticsGoal) {
        let cssClass: string;

        switch(goal.adornment) {
            case ModelsCoreStatisticsStatisticAdornments.Positive:
                cssClass = 'positive-adornment';
                break;
            case ModelsCoreStatisticsStatisticAdornments.Negative:
                cssClass = 'negative-adornment';
                break;
            default:
                cssClass = '';
                break;
        }

        return cssClass;
    }

    shouldShowGoalRowGroupHeader(rowIndex: number) {
        let show = rowIndex === 0;

        if(!show) {
            const previousRowIndex = rowIndex - 1;
            const previousStatus = previousRowIndex < this.goals.length
                ? this.goals[previousRowIndex].status
                : undefined;
            const currentStatus = rowIndex < this.goals.length
                ? this.goals[rowIndex].status
                : undefined;

            show = previousStatus !== currentStatus;
        }

        return show;
    }

    getGoalStatusDescription(goal: ModelsCoreStatisticsGoal) {
        let description = '';

        switch(goal.status) {
            case ModelsCoreStatisticsGoalStatus.CompleteAccomplished:
                description = 'Complete - Accomplished';
                break;
            case ModelsCoreStatisticsGoalStatus.CompleteNotAccomplished:
                description = 'Complete - Not Accomplished';
                break;
            case ModelsCoreStatisticsGoalStatus.InProgressAccomplishing:
                description = 'In Progress - On Track to Accomplish';
                break;
            case ModelsCoreStatisticsGoalStatus.InProgressNotAccomplishing:
                description = 'In Progress - Not On Track to Accomplish';
                break;
            case ModelsCoreStatisticsGoalStatus.NotStarted:
                description = 'Not Started Yet';
                break;
        }

        return description;
    }

    getRoundsIncludedDescription(goal: ModelsCoreStatisticsGoal) {
        let description = '';

        switch(goal.golfRoundType) {
        case ModelsCoreRoundsGolfRoundTypes.Competitive:
            description = 'Only Competitive';
            break;
        case ModelsCoreRoundsGolfRoundTypes.Practice:
            description = 'Only Practice';
            break;
        case ModelsCoreRoundsGolfRoundTypes.All:
            description = 'All';
            break;
        }

        return description;
    }

    canEditGoals() {
        return this.golferId === this.currentGolfer.golferId;
    }

    editGoal(goal: ModelsCoreStatisticsGoal) {
        this.router.navigate(['/statistics/goals', goal.goalId]);
    }

    shouldShowDaysRemaining(goal: ModelsCoreStatisticsGoal) {
        return goal.year > 0;
    }

    deleteGoal(goal: ModelsCoreStatisticsGoal, index: number) {
        const message = `Are you sure you want to delete the ${goal.statistic.displayName} `
            + `goal of ${goal.formattedTarget} for ${ goal.periodDisplayText }?`;

        this.confirmationService.confirm(
            {
                key: 'goals-list-component',
                header: 'Delete Goal?',
                message: message,
                accept: () => {
                    this.taskStarted();
                    this.golfersProxy.deleteGoal(this.currentGolfer.golferId, goal.goalId)
                        .pipe(
                            finalize(() => this.taskCompleted()),
                            this.takeUntilUnsubscribed())
                        .subscribe(
                            () => {
                                this.goals.splice(index, 1);
                            });
                }
            });
    }

    copyGoal(goal: ModelsCoreStatisticsGoal) {
        const params = {
            copy: true
        };

        this.router.navigate(['/statistics/goals', goal.goalId, params]);
    }

    private loadGoals(includeGoalYears = false) {
        this.taskStarted();

        this.args.includeGoalYears = includeGoalYears ? includeGoalYears : undefined;

        this.golfersProxy.getGoals(this.golferId, this.args)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.goals = response.body.goals;

                    if(includeGoalYears) {
                        this.goalYears = response.body.goalYears.map(
                            y => {
                                return {
                                    value: y,
                                    label: y.toString()
                                } as SelectItem;
                            });
                        this.args.year = response.body.year;
                    }
                });
    }
}
