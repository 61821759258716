import {
    EventEmitter,
    QueryList,
    TemplateRef,
    AfterContentInit,
    Component,
    ContentChildren,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { PrimeTemplate, SelectItem } from 'primeng/api';

import { BindableDirective } from '../../../shared/ui/bindable.directive';

/* eslint-disable no-shadow */
export enum ScorecardEntryMode {
    edit
}

/* eslint-disable id-blacklist, no-shadow */
enum ScorecardEntryState {
    undefined,
    viewingPrimaryItems,
    viewingSecondaryItems,
    viewingSelectedItem
}

@Component({
    selector: 'my-scorecard-entry',
    templateUrl: './scorecard-entry.component.html',
    styleUrls: [
        './scorecard-entry.component.scss'
    ],
    providers: [
        BindableDirective.providerFor(ScorecardEntryComponent)
    ]
})
export class ScorecardEntryComponent extends BindableDirective<any> implements AfterContentInit, OnInit {
    @Input() items: SelectItem[];
    @Input() secondaryItems: SelectItem[];
    @Input() startInEditMode = false;
    @Input() width = 1;
    @Output() selected: EventEmitter<SelectItem> = new EventEmitter<SelectItem>();
    @ContentChildren(PrimeTemplate) templates: QueryList<any>;

    source: SelectItem[];
    defaultMode: ScorecardEntryMode;
    itemTemplate: TemplateRef<any>;
    itemLabelTemplate: TemplateRef<any>;
    private myState: ScorecardEntryState;

    get showEditButton() {
        return this.isState(ScorecardEntryState.undefined);
    }

    get showSecondaryItemsButton() {
        return this.isState(ScorecardEntryState.viewingPrimaryItems) && this.hasSecondaryItems;
    }

    get stateName() {
        return ScorecardEntryState[this.myState];
    }

    get itemClass() {
        /* eslint-disable @typescript-eslint/naming-convention */
        const classes: { [key: string]: boolean } = {
            'scorecard-entry-item': true
        };
        /* eslint-enable @typescript-eslint/naming-convention */

        switch(this.width) {
            case 2:
                classes['x2'] = true;
                break;
            case 4:
                classes['x4'] = true;
                break;
        }

        return classes;
    }

    private get state() {
        return this.myState;
    }

    private set state(value: ScorecardEntryState) {
        this.myState = value;

        switch(value) {
            case ScorecardEntryState.viewingSelectedItem:
                this.setSelectedItem();
                break;
            case ScorecardEntryState.viewingPrimaryItems:
                this.source = this.items;
                break;
            case ScorecardEntryState.viewingSecondaryItems:
                this.source = this.secondaryItems;
                break;
            case ScorecardEntryState.undefined:
                this.source = [];
                break;
        }
    }

    private get hasSecondaryItems() {
        return this.secondaryItems && this.secondaryItems.length > 0;
    }

    ngOnInit() {
        this.initialize();
    }

    ngAfterContentInit() {
        this.templates.forEach((item) => {
            switch(item.getType()) {
                case 'item':
                    this.itemTemplate = item.template;
                    break;
                case 'itemLabel':
                    this.itemLabelTemplate = item.template;
                    break;
            }
        });
    }

    onItemClick(item: SelectItem) {
        if(this.state === ScorecardEntryState.viewingSelectedItem) {
            this.onEditClick();
        }
        else {
            this.value = item.value;
            this.selected.emit(item);
        }
    }

    onEditClick() {
        this.state = ScorecardEntryState.viewingPrimaryItems;
    }

    onShowSecondaryItemsClick() {
        this.state = ScorecardEntryState.viewingSecondaryItems;
    }

    onChangeInternal(): void {
        if(this.valueIsDefined()) {
            this.state = ScorecardEntryState.viewingSelectedItem;
        }
        else {
            this.initialize();
        }
    }

    private setSelectedItem() {
        let selectedItem = this.items.find(item => item.value === this.value);

        if(!selectedItem && this.hasSecondaryItems) {
            selectedItem = this.secondaryItems.find(item => item.value === this.value);
        }

        if(selectedItem) {
            this.source = [selectedItem];
        }
        else {
            this.initialize();
        }
    }

    private initialize() {
        if(this.startInEditMode) {
            this.state = ScorecardEntryState.viewingPrimaryItems;
        }
        else {
            this.state = ScorecardEntryState.undefined;
        }
    }

    private isState(state: ScorecardEntryState) {
        return this.state === state;
    }

}
