<div class="p-fluid">
    <div class="grid form-group" *ngIf="year">
        <div class="field col-12 lg:col-2" *ngIf="shouldShowGolferSelector">
            <span class="p-float-label">
                <p-dropdown [options]="golfers" [(ngModel)]="golferId" (onChange)="onGolferChanged()"></p-dropdown>
                <label>Golfer</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1">
            <span class="p-float-label">
                <p-dropdown [options]="yearsPracticed" [(ngModel)]="year" (onChange)="onYearChanged();"></p-dropdown>
                <label>Year</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1">
            <span class="p-float-label">
                <p-dropdown [options]="months" [(ngModel)]="month" (onChange)="onMonthChanged();"></p-dropdown>
                <label>Month</label>
            </span>
        </div>
    </div>
    <div class="grid">
        <div class="col-12 lg:col-8" *ngIf="practiceSessions">
            <p-table [value]="practiceSessions" [paginator]="practiceSessions.length > 25" [pageLinks]="10" [rows]="25" [loading]="isLoading" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md">
                <ng-template pTemplate="caption">
                    Practice Sessions
                    <i class="fas fa-plus-square new-item-icon clickable" [routerLink]="['/practice/sessions/new']" pTooltip="Create Practice Session"></i>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="width6 left">Date &amp; Time</th>
                        <th class="left">Name</th>
                        <th class="width3">Practice<br />Time</th>
                        <th class="width3">Total<br />Time</th>
                        <th class="width3">Time<br />Efficiency</th>
                        <th class="width1"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-session let-rowIndex="rowIndex">
                    <tr>
                        <td class="left">
                            <span class="p-column-title">Date &amp; Time</span>
                            <a [routerLink]="['/practice/sessions', session.practiceSessionId]">
                                {{session.started || session.created | date:'EEE, M/d/yy'}}
                                {{session.started || session.created | date:'shortTime'}}
                            </a>
                        </td>
                        <td class="left">
                            <span class="p-column-title">Name</span>
                            <i *ngIf="session.notes" class="fas fa-sticky-note notes-icon" pTooltip="{{session.notes}}"></i>
                            {{session.name}}
                            <p *ngIf="session.categoryDescription || session.typeDescription" class="caption">
                                {{session.categoryDescription}}
                                <span *ngIf="session.categoryDescription && session.typeDescription">&#10070;</span>
                                {{session.typeDescription}}
                            </p>
                            <button *ngIf="!session.completed" [routerLink]="['/practice/sessions', session.practiceSessionId, 'enter']" pButton type="button" icon="fas fa-play" iconPos="right" [label]="getContinueButtonLabel(session)" class="p-button-info"></button>
                        </td>
                        <td class="center">
                            <span class="p-column-title">Practice Time</span>
                            <span *ngIf="session.completed">{{session.practiceDuration}}</span>
                            <i *ngIf="session.completed && session.practiceMinutes > 0" class="fas fa-chart-pie clickable pie-chart-icon" pTooltip="Click to View Practice Time Per Category" (click)="showPracticeTimeByCategoryChart(session)"></i>
                        </td>
                        <td class="center">
                            <span *ngIf="session.completed" class="p-column-title">Total Time</span>
                            <span *ngIf="session.completed">{{session.elapsedDuration}}</span>
                        </td>
                        <td class="center">
                            <span class="p-column-title">Practice Time Efficiency</span>
                            {{session.practiceTimeEfficiency | percent}}
                        </td>
                        <td class="center">
                            <span class="status-icons">
                                <i *ngIf="!session.completed || session.practiceMinutes === 0" class="fas fa-trash-alt clickable delete-icon" pTooltip="Delete" (click)="deletePracticeSession(session, rowIndex)"></i>
                            </span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">
                            {{getEmptyMessage('No practice sessions.')}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="grid">
        <div class="col-12">
            <my-messages></my-messages>
        </div>
    </div>
</div>
<p-confirmDialog key="practice-session-list-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
