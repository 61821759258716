import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ChartData, ChartDataset } from 'chart.js';
import { BaseComponentDirective } from '../../../../shared/ui/base-component.directive';
import { chartColors } from '../../../../charts';

import {
    ModelsCoreGolfersGolfClub,
    ModelsCoreGolfersGolfer
} from '../../../../shared/swagger-codegen/models';

interface GolfClubYardage {
    description: string;
    clubAbbreviation: string;
    clubCategory: string;
    yardageLabel: string;
    yardage: number;
}

interface GolfClubMatrixYardages {
    abbreviation: string;
    yardages: number[];
}

interface GolfClubGap {
    fromDescription: string;
    toDescription: string;
    fromYardage: number;
    toYardage: number;
    gap: number;
}

export interface GolfClubYardageAnalysisDialogData {
    golfer: ModelsCoreGolfersGolfer;
    golfClubs: ModelsCoreGolfersGolfClub[];
}

@Component({
    selector: 'my-golf-club-analysis-dialog',
    templateUrl: './yardage-analysis-dialog.component.html'
})
export class GolfClubYardageAnalysisDialogComponent extends BaseComponentDirective implements OnInit {
    constructor(private dynamicDialogConfig: DynamicDialogConfig) {
        super();
    }

    chartData: ChartData;
    matrixHeaders: string[] = [];
    matrixClubs: GolfClubMatrixYardages[] = [];
    gaps: GolfClubGap[] = [];
    private clubIdToYardagesMap: { [clubId: number]: GolfClubYardage[]};
    private clubIdToPositiveYardagesMap: { [clubId: number]: GolfClubYardage[]};
    private positiveYardages: GolfClubYardage[] = [];

    ngOnInit() {
        const data: GolfClubYardageAnalysisDialogData = this.dynamicDialogConfig.data;
        const golfer: ModelsCoreGolfersGolfer = data.golfer;
        const golfClubs: ModelsCoreGolfersGolfClub[] = data.golfClubs;

        this.collectYardages(golfer, golfClubs);
        this.loadChart();
        this.loadMatrix(golfClubs);
        this.loadGaps();
    }

    private collectYardages(golfer: ModelsCoreGolfersGolfer, golfClubs: ModelsCoreGolfersGolfClub[]) {
        this.clubIdToYardagesMap = {};
        this.clubIdToPositiveYardagesMap = {};
        this.positiveYardages.length = 0;

        golfClubs.forEach(club => {
            const clubYardages = this.getGolfClubYardages(golfer, club);
            this.clubIdToYardagesMap[club.golfClubId] = clubYardages;

            const positiveYardages = clubYardages.filter(y => y.yardage > 0);

            if(positiveYardages.length > 0) {
                this.clubIdToPositiveYardagesMap[club.golfClubId] = positiveYardages;
                Array.prototype.push.apply(this.positiveYardages, positiveYardages);
            }
        });
    }

    private getGolfClubYardages(golfer: ModelsCoreGolfersGolfer, club: ModelsCoreGolfersGolfClub) {
        const yardages: GolfClubYardage[] = [];
        const other = [
            { labelName: 'golfClubSecondaryYardage3Label', yardageName: 'secondaryYardage3' },
            { labelName: 'golfClubSecondaryYardage2Label', yardageName: 'secondaryYardage2' },
            { labelName: 'golfClubSecondaryYardage1Label', yardageName: 'secondaryYardage1' }

        ];

        let clubCategory = 'Hybrid & Wood';

        if(club.golfClubCategory.name.indexOf(' Iron') !== -1) {
            clubCategory = 'Iron';
        }
        else if(club.golfClubCategory.name.indexOf('Wedge') !== -1) {
            clubCategory = 'Wedge';
        }

        other.forEach(o => {
            const yardage = club[o.yardageName];
            const yardageLabel = golfer[o.labelName];

            yardages.push({
                description: `${club.abbreviation}: ${yardageLabel}`,
                clubAbbreviation: club.abbreviation,
                clubCategory: clubCategory,
                yardageLabel: yardageLabel,
                yardage: yardage
            });  
        });

        let description = club.abbreviation;

        if(yardages.filter(y => y.yardage > 0).length > 0) {
            description += `: ${golfer.golfClubYardageLabel}`;
        }

        yardages.push({
            description: description,
            clubAbbreviation: club.abbreviation,
            clubCategory: clubCategory,
            yardageLabel: golfer.golfClubYardageLabel,
            yardage: club.yardage
        });

        return yardages;
    }

    private loadChart() {
        this.chartData = this.createChartData(this.positiveYardages);
    }

    private createChartData(yardages: GolfClubYardage[]) {
        yardages.sort((y1, y2) => {
            if(y1.yardage === y2.yardage) {
                return y1.description < y2.description ? -1 : 1;
            }
            else {
                return y1.yardage < y2.yardage ? -1 : 1;
            }
        });
        
        const data = this.initializeChartData();
        let previousCategory = '';
        let dataset: ChartDataset;

        yardages.forEach(y => {
            if(y.clubCategory !== previousCategory) {
                const color = chartColors[data.datasets.length % chartColors.length];
                dataset = {
                    label: `${y.clubCategory} Distance`,
                    backgroundColor: color,
                    borderColor: color,
                    data: [],
                    fill: false
                };

                data.datasets.push(dataset);
            }

            dataset.data.push(y.yardage);
            data.labels.push(y.description);

            previousCategory = y.clubCategory;
        });

        for(let i = 1; i < data.datasets.length; i++) {
            const previousDataset = data.datasets[i - 1];
            const currentDataset = data.datasets[i];

            previousDataset.data.forEach(() => {
                currentDataset.data.splice(0, 0, null);
            });

            if(previousDataset.data.length > 0) {
                const lastIndex = previousDataset.data.length - 1;
                const lastValue = previousDataset.data[lastIndex];
                currentDataset.data[lastIndex] = lastValue;
            }
        }

        return data;
    }

    private initializeChartData() {
        return {
            datasets: [],
            labels: []
        };
    }

    private loadMatrix(golfClubs: ModelsCoreGolfersGolfClub[]) {
        const yardageLabels: { [label: string]: boolean }[] = [{}, {}, {}, {}];
        this.matrixHeaders.length = 0;
        this.matrixClubs.length = 0;
        
        golfClubs.forEach(club => {
            const clubYardages = this.clubIdToYardagesMap[club.golfClubId];
            const positiveClubYardages = this.clubIdToPositiveYardagesMap[club.golfClubId];
            
            if(positiveClubYardages && positiveClubYardages.length > 0) {
                const matrixClub: GolfClubMatrixYardages = {
                    abbreviation: club.abbreviation,
                    yardages: []
                };

                this.matrixClubs.push(matrixClub);

                clubYardages.forEach((y, i) => {
                    if(y.yardage > 0) {
                        yardageLabels[i][y.yardageLabel] = true;
                        matrixClub.yardages.push(y.yardage);
                    }
                    else {
                        matrixClub.yardages.push(null);
                    }
                });
            }
        });

        yardageLabels.forEach(labels => {
            const names = Object.keys(labels);
            names.sort((a, b) => a > b ? 1 : -1);
            this.matrixHeaders.push(names.join(', '));
        });
    }

    private loadGaps() {
        this.gaps.length = 0;

        if(this.positiveYardages.length > 0) {
            const first = this.positiveYardages[0];

            this.gaps.push({
                fromDescription: '0',
                toDescription: first.description,
                fromYardage: 0,
                toYardage: first.yardage,
                gap: first.yardage
            });
        }

        for(let i = 1; i < this.positiveYardages.length; i++) {
            const previous = this.positiveYardages[i - 1];
            const current = this.positiveYardages[i];
            const gap = current.yardage - previous.yardage;

            this.gaps.splice(0, 0, {
                fromDescription: previous.description,
                toDescription: current.description,
                fromYardage: previous.yardage,
                toYardage: current.yardage,
                gap: gap
            });
        }
    }
}
