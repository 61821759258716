<form #holeNotesForm="ngForm" autocomplete="off" novalidate="novalidate">
    <div class="grid">
        <div class="col-12">
            <textarea name="notes" [(ngModel)]="selectedHole.golferHoleInfo.notes" rows="8" maxlength="1500"></textarea>
        </div>
        <div class="col-12">
            <button type="submit" pButton label="Save Notes" (click)="saveNotes()" [disabled]="isLoading"></button>
            <my-messages #notesMessages></my-messages>
        </div>
    </div>
</form>
