import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import {browserTracingIntegration} from '@sentry/angular';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {ConfigurationService} from './app/shared/core/configuration.service';

const config = new ConfigurationService();
Sentry.init({
    dsn: config.traceDsn,
    release: `parpros-ng@${config.version}`,
    environment: config.environmentName.toLowerCase(),
    debug: config.isDebug,
    integrations: [browserTracingIntegration()],

    tracesSampleRate: config.traceSampleRate
});

if(environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));

