<div class="card p-fluid">
    <div class="grid form-group">
        <div class="col-12 lg:col-2">
            <p-selectButton [options]="viewOptions" [(ngModel)]="selectedView" (onChange)="onViewChanged()"></p-selectButton>
        </div>
        <div class="field col-12 lg:col-2" *ngIf="isCityStateView">
            <span class="p-float-label">
                <p-dropdown [options]="states" [(ngModel)]="stateCode" styleClass="state-selector" (onChange)="onStateChanged();"></p-dropdown>
                <label>State</label>
            </span>
        </div>
        <div class="field col-12 lg:col-2" *ngIf="isCityStateView">
            <span class="p-float-label">
                <p-dropdown [options]="cities" [(ngModel)]="city" styleClass="city-selector" (onChange)="onCityChanged();"></p-dropdown>
                <label>City</label>
            </span>
        </div>
    </div>
    <div>
        <p-table [value]="courses" [loading]="isLoading" [paginator]="courses.length > 30" [pageLinks]="10" [rows]="30" (sortFunction)="customTableSort($event)" [customSort]="true" sortField="name" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md">
            <ng-template pTemplate="caption">
                Courses
                <i class="fas fa-plus-square new-item-icon clickable" [routerLink]="['new']" pTooltip="Create New Course"></i>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th rowspan="2" class="width8 left" pSortableColumn="name">
                        Name
                        <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th rowspan="2" class="left" pSortableColumn="street1,street2,city,stateCode,zipCode">
                        Address
                        <p-sortIcon field="street1,street2,city,stateCode,zipCode"></p-sortIcon>
                    </th>
                    <th rowspan="2" class="width2" pSortableColumn="phoneNumber">
                        Phone Number
                        <p-sortIcon field="phoneNumber"></p-sortIcon>
                    </th>
                    <th rowspan="2" class="width2" pSortableColumn="longestTee.rating">
                        Rating
                        <p-sortIcon field="longestTee.rating"></p-sortIcon>
                    </th>
                    <th rowspan="2" class="width2" pSortableColumn="longestTee.slope">
                        Slope
                        <p-sortIcon field="longestTee.slope"></p-sortIcon>
                    </th>
                    <th rowspan="2" class="width2" pSortableColumn="longestTee.yards">
                        Yards
                        <p-sortIcon field="longestTee.yards"></p-sortIcon>
                    </th>
                    <th class="width14" colspan="5">My History</th>

                </tr>
                <tr>
                    <th class="width2" pSortableColumn="history.handicap">
                        Handicap
                        <p-sortIcon field="history.handicap"></p-sortIcon>
                    </th>
                    <th class="width3" pSortableColumn="history.historyFor18Holes.averageScore">
                        18 Hole Scoring
                        <p-sortIcon field="history.historyFor18Holes.averageScore"></p-sortIcon>
                    </th>
                    <th class="width3" pSortableColumn="history.historyFor9Holes.averageScore">
                        9 Hole Scoring
                        <p-sortIcon field="history.historyFor9Holes.averageScore"></p-sortIcon>
                    </th>
                    <th class="width2" pSortableColumn="history.timesPlayed">
                        Rounds Played
                        <p-sortIcon field="history.timesPlayed"></p-sortIcon>
                    </th>
                    <th class="width2" pSortableColumn="history.lastPlayed">
                        Last Played
                        <p-sortIcon field="history.lastPlayed"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-course>
                <tr>
                    <td class="left">
                        <a [routerLink]="[course.courseId]"> {{course.name}} </a>
                        <span *ngIf="course.milesAway !== undefined"><br />({{course.milesAway.toFixed(1)}} miles away)</span>
                        <br />
                        <span class="status-icons">
                            <i *ngIf="isFavoriteCourse(course)" (click)="removeFavoriteCourse(course)" class="fas fa-heart favorites-icon" pTooltip="Remove from Favorites"></i>
                            <i *ngIf="!isFavoriteCourse(course)" (click)="addFavoriteCourse(course)" class="far fa-heart favorites-icon" pTooltip="Add to Favorites"></i>
                            <a *ngIf="course.websiteUrl" href="{{course.websiteUrl}}" target="course" pTooltip="View Website">
                                <i class="fas fa-external-link-alt course-website"></i>
                            </a>
                            <a href="https://weather.com/weather/tenday/l/{{course.zipCode}}" target="weather" pTooltip="View Weather Forecast">
                                <i class="fas fa-sun weather-forecast"></i>
                            </a>
                            <i class="fas fa-golf-ball clickable which-tee" pTooltip="Decide Which Tee to Play" [routerLink]="[course.courseId, 'which-tee']"></i>
                            <i class="fa-solid fa-microscope clickable stroke-analysis" [routerLink]="['/rounds/stroke-analysis', { courseId: course.courseId }]" pTooltip="Analyze Stroke Level Data"></i>
                            <i class="fas fa-map-marker-alt clickable course-strategy" pTooltip="Course Strategy" [routerLink]="[course.courseId, 'strategy']"></i>
                            <i class="fas fa-history clickable historical-stats" (click)="viewHistory(course);" pTooltip="View Historical Stats"></i>
                        </span>
                    </td>
                    <td class="left">
                        <a href="https://www.google.com/maps/dir//{{course.street1}} {{course.street2}} {{course.city}}, {{course.stateCode}} {{course.zipCode}}" target="map">
                            {{course.street1}} {{course.street2}}
                            <br />
                            {{course.city}}, {{course.stateCode}} {{course.zipCode}}
                        </a>
                    </td>
                    <td class="width4 center">
                        <span class="p-column-title">Phone</span>
                        <a href="tel:{{course.phoneNumber}}">{{course.phoneNumber}}</a>
                    </td>
                    <td class="width4 center">
                        <span class="p-column-title">Rating</span>
                        {{course?.longestTee?.rating | number:'1.1-1'}}
                    </td>
                    <td class="width4 center">
                        <span class="p-column-title">Slope</span>
                        {{course?.longestTee?.slope | number}}
                    </td>
                    <td class="width4 center">
                        <span class="p-column-title">Yards</span>
                        {{course?.longestTee?.yards | number}}
                    </td>
                    <td class="width4 center">
                        <span class="p-column-title">Handicap</span>
                        {{course?.history?.formattedHandicap}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Scoring (18)</span>
                        <span *ngIf="course.history && course.history.historyFor18Holes">
                            {{course.history.historyFor18Holes.averageScore | number:'1.2-2'}}<br />
                            ({{course.history.historyFor18Holes.bestScore}} to {{course.history.historyFor18Holes.worstScore}})
                        </span>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Scoring (9)</span>
                        <span *ngIf="course.history && course.history.historyFor9Holes">
                            {{course.history.historyFor9Holes.averageScore | number:'1.2-2'}}<br />
                            ({{course.history.historyFor9Holes.bestScore}} to {{course.history.historyFor9Holes.worstScore}})
                        </span>
                    </td>
                    <td class="width4 center">
                        <span class="p-column-title">Times Played</span>
                        {{course?.history?.timesPlayed | number}}
                    </td>
                    <td class="width4 center">
                        <span class="p-column-title">Last Played</span>
                        {{course?.history?.lastPlayed | date:'shortDate'}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="11">
                        {{getNoCoursesMessage()}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
