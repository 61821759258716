import { Injectable } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';

import { QueryString } from '../util';

import {
    ModelsCorePracticePracticeActivity,
    ModelsCorePracticePracticeCategory,
    ModelsCorePracticePracticePlan,
    ModelsCorePracticePracticeScoreQualifier,
    ModelsCorePracticePracticeSession,
    ModelsCorePracticePracticeSessionActivity,
    ModelsCorePracticePracticeType,
    ModelsPracticeCompletePracticeSessionActivityCompletePracticeSessionActivityResponse,
    ModelsPracticeGetPracticeChartGetPracticeChartResponse,
    ModelsPracticeSavePracticeActivitySavePracticeActivityResponse,
    ModelsPracticeSavePracticePlanSavePracticePlanResponse,
    ModelsPracticeStartPracticeSessionActivityStartPracticeSessionActivityResponse,
    ModelsWebApiPracticeCompletePracticeSessionActivityModel,
    ModelsWebApiPracticeCompletePracticeSessionModel,
    ModelsWebApiPracticeGetPracticeActivityHistoryArgs,
    ModelsWebApiPracticeGetPracticeChartArgs,
    ModelsWebApiPracticeSavePracticeActivityModel,
    ModelsWebApiPracticeSavePracticePlanModel,
    ModelsWebApiPracticeSavePracticeSessionModel,
    ModelsWebApiPracticeStartPracticeSessionActivityModel
} from '../../shared/swagger-codegen/models';

@Injectable()
export class PracticeControllerProxy {
    private baseUrl = '/api/practice';

    constructor(private httpClient: HttpClientWrapperService) {
    }

    getPracticeChart(args: ModelsWebApiPracticeGetPracticeChartArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsPracticeGetPracticeChartGetPracticeChartResponse>(`${this.baseUrl}/charts${queryString}`);
    }

    getPracticePlan(practicePlanId: number) {
        return this.httpClient.get<ModelsCorePracticePracticePlan>(`${this.baseUrl}/plans/${practicePlanId}`);
    }

    addPracticePlan(model: ModelsWebApiPracticeSavePracticePlanModel) {
        return this.httpClient.post<ModelsPracticeSavePracticePlanSavePracticePlanResponse>(`${this.baseUrl}/plans`, model);
    }

    savePracticePlan(practicePlanId: number, model: ModelsWebApiPracticeSavePracticePlanModel) {
        return this.httpClient.put<any>(`${this.baseUrl}/plans/${practicePlanId}`, model);
    }

    copyPracticePlan(practicePlanId: number) {
        return this.httpClient.post<ModelsCorePracticePracticePlan>(`${this.baseUrl}/plans/${practicePlanId}/copy`);
    }

    deletePracticePlan(practicePlanId: number) {
        return this.httpClient.delete<any>(`${this.baseUrl}/plans/${practicePlanId}`);
    }

    deletePracticePlanActivity(practicePlanActivityId: number) {
        return this.httpClient.delete<any>(`${this.baseUrl}/plans/activities/${practicePlanActivityId}`);
    }

    deletePracticePlanPlan(practicePlanPlanId: number) {
        return this.httpClient.delete<any>(`${this.baseUrl}/plans/plans/${practicePlanPlanId}`);
    }

    getPracticeActivity(practiceActivityId: number) {
        return this.httpClient.get<ModelsCorePracticePracticeActivity>(`${this.baseUrl}/activities/${practiceActivityId}`);
    }

    addPracticeActivity(model: ModelsWebApiPracticeSavePracticeActivityModel) {
        return this.httpClient.post<ModelsPracticeSavePracticeActivitySavePracticeActivityResponse>(`${this.baseUrl}/activities`, model);
    }

    savePracticeActivity(practiceActivityId: number, model: ModelsWebApiPracticeSavePracticeActivityModel) {
        return this.httpClient.put<any>(`${this.baseUrl}/activities/${practiceActivityId}`, model);
    }

    deletePracticeActivity(practiceActivityId: number) {
        return this.httpClient.delete<any>(`${this.baseUrl}/activities/${practiceActivityId}`);
    }

    deletePracticeActivityVariation(practiceActivityVariationId: number) {
        return this.httpClient.delete<any>(`${this.baseUrl}/activities/variations/${practiceActivityVariationId}`);
    }

    deletePracticeSession(practiceSessionId: number) {
        return this.httpClient.delete<any>(`${this.baseUrl}/sessions/${practiceSessionId}`);
    }

    deletePracticeSessionActivity(practiceSessionActivityId: number) {
        return this.httpClient.delete<any>(`${this.baseUrl}/sessions/activities/${practiceSessionActivityId}`);
    }

    startPracticeSession(practicePlanIds: number[]) {
        return this.httpClient.post<ModelsCorePracticePracticeSession>(`${this.baseUrl}/sessions`, practicePlanIds);
    }

    getPracticeSession(practiceSessionId: number) {
        return this.httpClient.get<ModelsCorePracticePracticeSession>(`${this.baseUrl}/sessions/${practiceSessionId}`);
    }

    savePracticeSession(practiceSessionId: number, model: ModelsWebApiPracticeSavePracticeSessionModel) {
        return this.httpClient.put<any>(`${this.baseUrl}/sessions/${practiceSessionId}`, model);
    }

    completePracticeSession(practiceSessionId: number, model: ModelsWebApiPracticeCompletePracticeSessionModel) {
        return this.httpClient.put<any>(`${this.baseUrl}/sessions/${practiceSessionId}/complete`, model);
    }

    startPracticeSessionActivity(practiceSessionActivityId: number, model: ModelsWebApiPracticeStartPracticeSessionActivityModel = null) {
        model = model || {};
        return this.httpClient.post<ModelsPracticeStartPracticeSessionActivityStartPracticeSessionActivityResponse>(
            `${this.baseUrl}/sessions/activities/${practiceSessionActivityId}`,
            model);
    }

    completePracticeSessionActivity(practiceSessionActivityId: number, model: ModelsWebApiPracticeCompletePracticeSessionActivityModel) {
        return this.httpClient.put<ModelsPracticeCompletePracticeSessionActivityCompletePracticeSessionActivityResponse>(
            `${this.baseUrl}/sessions/activities/${practiceSessionActivityId}`, model);
    }

    getPracticeTypes() {
        return this.httpClient.get<ModelsCorePracticePracticeType[]>(`${this.baseUrl}/types`);
    }

    getPracticeCategories() {
        return this.httpClient.get<ModelsCorePracticePracticeCategory[]>(`${this.baseUrl}/categories`);
    }

    getPracticeScoreQualifiers() {
        return this.httpClient.get<ModelsCorePracticePracticeScoreQualifier[]>(`${this.baseUrl}/score-qualifiers`);
    }

    getPracticeActivityHistory(practiceActivityId: number, args?: ModelsWebApiPracticeGetPracticeActivityHistoryArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsCorePracticePracticeSessionActivity[]>(
            `${this.baseUrl}/activities/${practiceActivityId}/history${queryString}`);
    }
}
