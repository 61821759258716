import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SelectItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { GolfersControllerProxy, PracticeControllerProxy } from '../../../shared/server-proxies';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { MessagesComponent } from '../../../shared/ui/messages/messages.component';
import { CurrentGolferService } from '../../../shared/golfers/current-golfer.service';
import { GolferLookupService, IGolferSelectItem } from '../../../shared/golfers/golfer-lookup.service';
import { PracticeTimeChartDialogService } from '../../practice-time-chart-dialog/practice-time-chart-dialog.service';

import {
    ModelsCorePracticePracticeSession
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-practice-session-list',
    templateUrl: './practice-session-list.component.html',
    styleUrls: [
        './practice-session-list.component.scss'
    ]
})
export class PracticeSessionListComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersControllerProxy: GolfersControllerProxy,
        private practiceControllerProxy: PracticeControllerProxy,
        private golferLookups: GolferLookupService,
        private currentGolfer: CurrentGolferService,
        private confirmationService: ConfirmationService,
        private practiceTimeChartDialogService: PracticeTimeChartDialogService,
        private datePipe: DatePipe) {
        super();
    }

    @ViewChild(MessagesComponent, { static: true }) messages: MessagesComponent;
    practiceSessions: ModelsCorePracticePracticeSession[];
    yearsPracticed: SelectItem[];
    months: SelectItem[] = [];
    golfers: IGolferSelectItem[];
    year: number;
    month: number;
    golferId: number;
    private allMonths: SelectItem[] = [
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 }
    ];

    get shouldShowGolferSelector() {
        return this.currentGolfer.isAdmin;
    }

    ngOnInit() {
        this.golferId = this.currentGolfer.golferId;

        this.loadFriends();
        this.loadYearsPracticed();
    }

    getContinueButtonLabel(session: ModelsCorePracticePracticeSession) {
        return session.started ? 'Continue' : 'Start';
    }

    onYearChanged() {
        this.loadMonths();
        this.loadSessions();
    }

    onMonthChanged() {
        this.loadSessions();
    }

    showPracticeTimeByCategoryChart(session: ModelsCorePracticePracticeSession) {
        const date = this.datePipe.transform(session.started, 'fullDate');
        const time = this.datePipe.transform(session.started, 'shortTime');
        const header = `${session.name} on ${date} at ${time}`;
        this.practiceTimeChartDialogService.open(header, session.practiceCategories, session.practiceTypes);
    }

    deletePracticeSession(practiceSession: ModelsCorePracticePracticeSession, index: number) {
        const message = `Are you sure you want to delete ${practiceSession.name}?`;

        this.confirmationService.confirm(
            {
                key: 'practice-session-list-component',
                header: 'Delete Practice Session?',
                message: message,
                accept: () => {
                    this.taskStarted();
                    this.practiceControllerProxy.deletePracticeSession(practiceSession.practiceSessionId)
                        .pipe(
                            finalize(() => this.taskCompleted()),
                            this.takeUntilUnsubscribed()
                        )
                        .subscribe(
                            () => {
                                this.practiceSessions.splice(index, 1);
                            },
                            response => this.messages.showApiError(response));
                }
            });
    }

    onGolferChanged() {
        this.loadSessions();
    }

    private loadYearsPracticed() {
        this.taskStarted();
        this.golferLookups.getYearsPracticedSelectItems(false)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed()
            )
            .subscribe(
                years => {
                    this.yearsPracticed = years;
                    this.year = this.golferLookups.defaultYearPracticed;

                    this.loadMonths();
                    this.loadSessions();
                });
    }

    private loadFriends() {
        this.taskStarted();
        this.golferLookups.getFriendSelectItems()
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed()
            )
            .subscribe(
                friends => {
                    this.golfers = friends;
                    this.golferId = this.currentGolfer.golferId;
                });
    }

    private loadMonths() {
        const now = new Date();
        const currentYear = now.getFullYear();
        const year = this.year;
        const maxMonth = year < currentYear ? 12 : now.getMonth() + 1;

        this.months = this.allMonths.filter(m => m.value <= maxMonth);

        if(this.month > maxMonth) {
            this.month = maxMonth;
        }
        else if(!this.month) {
            this.month = now.getMonth() + 1;
        }
    }

    private loadSessions() {
        const args = {
            year: this.year,
            month: this.month
        };

        this.taskStarted();
        this.golfersControllerProxy.getPracticeSessions(this.golferId, args)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed()
            )
            .subscribe(
                response => {
                    this.practiceSessions = response.body;
                });
    }
}
