import { NgModule } from '@angular/core';

import { publicRoutes } from './public.routes';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

@NgModule({
    imports: [
        publicRoutes
    ],
    declarations: [
        PrivacyPolicyComponent
    ],
    providers: [
    ]
})
export class PublicModule {

}
