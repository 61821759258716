import { Component, Input } from '@angular/core';
import { TooltipItem } from 'chart.js';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';

import {
    ModelsStatisticsGetChartChartsStatisticSnapshot
} from '../shared/swagger-codegen/models';

@Component({
    selector: 'my-statistic-snapshot-chart',
    template: '<p-chart [type]="chartType" [data]="data" [options]="options" responsive="true"></p-chart>'
})
export class StatisticSnapshotChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'line');

        this.options.animation = {
            duration: 0
        };
        this.options.scales.x = {
            type: 'timeseries',
            time: {
                unit: 'quarter',
                displayFormats: {
                    quarter: '[Q]Q'
                }
            },
            gridLines: {
                display: false
            },
            offset: false,
            ticks: {
                display: false,
                major: {
                    enabled: false,
                    fontStyle: 'bold'
                },
                source: 'data',
                autoSkip: true,
                autoSkipPadding: 75,
                maxRotation: 0,
                sampleSize: 100
            }
        };

        this.options.scales.y = {
            gridLines: {
                drawBorder: false
            },
            scaleLabel: {
                display: true
            },
            ticks: {
                callback: this.getYAxisTick.bind(this)
            }
        };

        this.options.plugins.tooltip.callbacks.title = this.getTooltipTitle.bind(this);
        this.options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);

        const now = new Date();
        const today = [
            '2020',
            ('0' + (now.getMonth() + 1)).slice(-2),
            ('0' + now.getDate()).slice(-2)
        ].join('-');

        this.options.plugins.annotation.annotations.line = {
            type: 'line',
            scaleID: 'x',
            value: today,
            borderColor: '#b0bec5',
            borderWidth: 3,
            label: {
                backgroundColor: '#00bcd4',
                enabled: true
            }
        };
    }

    @Input()
    isPercentage = false;

    private getTooltipTitle() {
        return null;
    }

    private getTooltipLabel(tooltipItem: TooltipItem<'line'>) {
        const dataset = tooltipItem.dataset;
        const data = dataset.data as ModelsStatisticsGetChartChartsStatisticSnapshot[];

        return data[tooltipItem.dataIndex].tooltip;
    }

    private getYAxisTick(value: any) {
        return this.isPercentage ? `${(value * 100)}%` : value;
    }
}
