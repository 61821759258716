/// <reference types="google.maps" />
import {
    ModelsCoreCoursesMapMarkers
} from '../../swagger-codegen/models';

import { targetMarkerIcon } from './map-marker-icons';
import { MapMarkerWrapper } from './map-marker-wrapper';

import LatLng = google.maps.LatLng;
import Map = google.maps.Map;
import Point = google.maps.Point;
import Size = google.maps.Size;

export class TeeShotTargetMapMarker extends MapMarkerWrapper {
    constructor(map: Map, position: LatLng) {
        super(
            ModelsCoreCoursesMapMarkers.TeeShotTarget,
            {
                map: map,
                position: position,
                draggable: true,
                icon: {
                    anchor: new Point(16, 16),
                    scaledSize: new Size(32, 32),
                    url: targetMarkerIcon
                },
                title: 'Tee Shot Target'
            }
        );
    }
}
