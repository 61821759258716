import { Injectable } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import { SelectItem } from 'primeng/api';
import { map } from 'rxjs/operators';

import {
    ModelsCoreGolfersGolfClubCategory,
    ModelsCoreRoundsGolfRoundType,
    ModelsCoreSharedGender,
    ModelsCoreSharedState,
    ModelsCoreSharedTimeZone,
    ModelsCoreStatisticsAchievement,
    ModelsCoreStatisticsMetric,
    ModelsCoreStatisticsStatistic
} from '../../shared/swagger-codegen/models';

@Injectable()
export class ReferenceControllerProxy {
    private baseUrl = '/api/reference';

    constructor(private httpClient: HttpClientWrapperService) {
    }

    getStates() {
        return this.httpClient.get<ModelsCoreSharedState[]>(`${this.baseUrl}/states`);
    }

    getGenders() {
        return this.httpClient.get<ModelsCoreSharedGender[]>(`${this.baseUrl}/genders`);
    }

    getMetrics() {
        return this.httpClient.get<ModelsCoreStatisticsMetric[]>(`${this.baseUrl}/metrics`);
    }

    getStatistics() {
        return this.httpClient.get<ModelsCoreStatisticsStatistic[]>(`${this.baseUrl}/statistics`);
    }

    getGolfRoundTypes() {
        return this.httpClient.get<ModelsCoreRoundsGolfRoundType[]>(`${this.baseUrl}/golf-round-types`);
    }

    getAchievements() {
        return this.httpClient.get<ModelsCoreStatisticsAchievement[]>(`${this.baseUrl}/achievements`);
    }

    getGolfClubCategories() {
        return this.httpClient.get<ModelsCoreGolfersGolfClubCategory[]>(`${this.baseUrl}/golf-club-categories`);
    }

    getTimeZones() {
        return this.httpClient.get<ModelsCoreSharedTimeZone[]>(`${this.baseUrl}/time-zones`);
    }

    getStateSelectItems() {
        return this.getStates()
            .pipe(
                map(
                    response => {
                        return response.body.map(
                            s => {
                                return {
                                    value: s.code,
                                    label: s.name,
                                    model: s
                                } as SelectItem;
                            });
                    }));
    }

    getGenderSelectItems() {
        return this.getGenders()
            .pipe(
                map(
                    response => {
                        return response.body.map(
                            g => {
                                return {
                                    value: g.code,
                                    label: g.name,
                                    model: g
                                } as SelectItem;
                            });
                    }));
    }

    getMetricSelectItems() {
        return this.getMetrics()
            .pipe(
                map(
                    response => {
                        return response.body.map(
                            m => {
                                return {
                                    value: m.metricId,
                                    label: m.displayName,
                                    model: m
                                } as SelectItem;
                            });
                    }));
    }

    getStatisticSelectItems() {
        return this.getStatistics()
            .pipe(
                map(
                    response => {
                        return response.body.map(
                            s => {
                                return {
                                    value: s.statisticId,
                                    label: s.displayName,
                                    model: s
                                } as SelectItem;
                            });
                    }));
    }

    getGolfRoundTypeSelectItems() {
        return this.getGolfRoundTypes()
            .pipe(
                map(
                    response => {
                        return response.body.map(
                            t => {
                                return {
                                    value: t.golfRoundTypeId,
                                    label: t.name,
                                    model: t
                                } as SelectItem;
                            });
                    }));
    }

    getAchievementSelectItems() {
        return this.getAchievements()
            .pipe(
                map(
                    response => {
                        return response.body.map(
                            a => {
                                return {
                                    value: a.achievementId,
                                    label: a.displayName,
                                    model: a
                                } as SelectItem;
                            });
                    }));
    }

    getGolfClubCategorySelectItems() {
        return this.getGolfClubCategories()
            .pipe(
                map(
                    response => {
                        return response.body.map(
                            c => {
                                return {
                                    value: c.golfClubCategoryId,
                                    label: c.name,
                                    model: c
                                } as SelectItem;
                            });
                    }));
    }

    getTimeZoneSelectItems() {
        return this.getTimeZones()
            .pipe(
                map(
                    response => {
                        return response.body.map(
                            t => {
                                return {
                                    value: t.timeZoneId,
                                    label: t.displayName,
                                    model: t
                                } as SelectItem;
                            });
                    }));
    }
}
