<div class="p-fluid">
    <div class="grid form-group">
        <div class="field col-12 lg:col-1">
            <span class="p-float-label">
                <p-dropdown [options]="yearsPlayed" [(ngModel)]="args.year" (onChange)="onYearChanged();"></p-dropdown>
                <label>Year</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1">
            <span class="p-float-label">
                <p-dropdown [options]="quarters" [(ngModel)]="args.quarter" (onChange)="onQuarterChanged();"></p-dropdown>
                <label>Quarter</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1" *ngIf="shouldShowMonths">
            <span class="p-float-label">
                <p-dropdown [options]="months" [(ngModel)]="args.month" (onChange)="onMonthChanged();"></p-dropdown>
                <label>Month</label>
            </span>
        </div>
        <div class="col-12 lg:col-2">
            <p-selectButton [options]="viewOptions" [(ngModel)]="selectedView"></p-selectButton>
        </div>
    </div>

    <div>
        <p-table *ngFor="let golfer of headToHeadStats" [value]="golfer.vsGolfers" [loading]="isLoading" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md">
            <ng-template pTemplate="caption">
                {{golfer.fullName}}
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="width6"></th>
                    <th>
                        Wins-Losses-Ties
                    </th>
                    <th *ngIf="shouldShowStatsByRound">
                        Wire to<br />
                        Wire Wins
                    </th>
                    <th *ngIf="shouldShowStatsByRound">
                        Practice<br />
                        Concessions<br />
                        Wins-Losses-Ties
                    </th>
                    <th>
                        Avg Margin<br />
                        of Win
                    </th>
                    <th>
                        Max Margin<br />
                        of Win
                    </th>
                    <th>
                        Avg Margin<br />
                        of Loss
                    </th>
                    <th>
                        Max Margin<br />
                        of Loss
                    </th>
                    <th>
                        Wins-Losses-Ties (Net)
                    </th>
                    <th *ngIf="shouldShowStatsByRound">
                        Wire to<br />
                        Wire Wins (Net)
                    </th>
                    <th>
                        Avg Margin<br />
                        of Win (Net)
                    </th>
                    <th>
                        Max Margin<br />
                        of Win (Net)
                    </th>
                    <th>
                        Avg Margin<br />
                        of Loss (Net)
                    </th>
                    <th>
                        Max Margin<br />
                        of Loss (Net)
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-vsGolfer>
                <tr>
                    <td class="left">vs {{vsGolfer.fullName}} ({{vsGolfer.holesPlayed}})</td>
                    <td class="center">
                        <span class="p-column-title">Wins-Losses-Ties</span>
                        <span *ngIf="shouldShowStatsByRound">
                            {{vsGolfer.roundsWon | number}} - {{vsGolfer.roundsLost | number}} - {{vsGolfer.roundsTied}}<br />
                            {{vsGolfer.roundsWonPercent | percent}} - {{vsGolfer.roundsLostPercent | percent}} - {{vsGolfer.roundsTiedPercent | percent}}
                        </span>
                        <span *ngIf="shouldShowStatsByHole">
                            {{vsGolfer.holesWon | number}} - {{vsGolfer.holesLost | number}} - {{vsGolfer.holesTied}}<br />
                            {{vsGolfer.holesWonPercent | percent}} - {{vsGolfer.holesLostPercent | percent}} - {{vsGolfer.holesTiedPercent | percent}}
                        </span>
                    </td>
                    <td *ngIf="shouldShowStatsByRound" class="center">
                        <span class="p-column-title">Wire to Wire Wins</span>
                        {{vsGolfer.wireToWireWins | number}}
                    </td>
                    <td *ngIf="shouldShowStatsByRound" class="center">
                        <span class="p-column-title">Practice Concessions Wins-Losses-Ties</span>
                        {{vsGolfer.roundsWonByPracticeConcession | number}} - {{vsGolfer.roundsLostByPracticeConcession | number}} - {{vsGolfer.roundsTiedByPracticeConcession}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Avg Margin of Win</span>
                        <span *ngIf="shouldShowStatsByRound">
                            {{vsGolfer.avgMarginOfWinPerRound | number}}
                        </span>
                        <span *ngIf="shouldShowStatsByHole">
                            {{vsGolfer.avgMarginOfWinPerHole | number}}
                        </span>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Max Margin of Win</span>
                        <span *ngIf="shouldShowStatsByRound">
                            {{vsGolfer.maxMarginOfRoundsWon | number}}
                        </span>
                        <span *ngIf="shouldShowStatsByHole">
                            {{vsGolfer.maxMarginOfHolesWon | number}}
                        </span>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Avg Margin of Loss</span>
                        <span *ngIf="shouldShowStatsByRound">
                            {{vsGolfer.avgMarginOfLossPerRound | number}}
                        </span>
                        <span *ngIf="shouldShowStatsByHole">
                            {{vsGolfer.avgMarginOfLossPerHole | number}}
                        </span>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Max Margin of Loss</span>
                        <span *ngIf="shouldShowStatsByRound">
                            {{vsGolfer.maxMarginOfRoundsLost | number}}
                        </span>
                        <span *ngIf="shouldShowStatsByHole">
                            {{vsGolfer.maxMarginOfHolesLost | number}}
                        </span>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Wins-Losses-Ties (Net)</span>
                        <span *ngIf="shouldShowStatsByRound">
                            {{vsGolfer.roundsWonNet | number}} - {{vsGolfer.roundsLostNet | number}} - {{vsGolfer.roundsTiedNet}}<br />
                            {{vsGolfer.roundsWonNetPercent | percent}} - {{vsGolfer.roundsLostNetPercent | percent}} - {{vsGolfer.roundsTiedNetPercent | percent}}
                        </span>
                        <span *ngIf="shouldShowStatsByHole">
                            {{vsGolfer.holesWonNet | number}} - {{vsGolfer.holesLostNet | number}} - {{vsGolfer.holesTiedNet}}<br />
                            {{vsGolfer.holesWonNetPercent | percent}} - {{vsGolfer.holesLostNetPercent | percent}} - {{vsGolfer.holesTiedNetPercent | percent}}
                        </span>
                    </td>
                    <td *ngIf="shouldShowStatsByRound" class="center">
                        <span class="p-column-title">Wire to Wire Wins (Net)</span>
                        {{vsGolfer.wireToWireWinsNet | number}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Avg Margin of Win (Net)</span>
                        <span *ngIf="shouldShowStatsByRound">
                            {{vsGolfer.avgMarginOfNetWinPerRound | number}}
                        </span>
                        <span *ngIf="shouldShowStatsByHole">
                            {{vsGolfer.avgMarginOfNetWinPerHole | number}}
                        </span>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Max Margin of Win (Net)</span>
                        <span *ngIf="shouldShowStatsByRound">
                            {{vsGolfer.maxMarginOfRoundsWonNet | number}}
                        </span>
                        <span *ngIf="shouldShowStatsByHole">
                            {{vsGolfer.maxMarginOfHolesWonNet | number}}
                        </span>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Avg Margin of Loss (Net)</span>
                        <span *ngIf="shouldShowStatsByRound">
                            {{vsGolfer.avgMarginOfNetLossPerRound | number}}
                        </span>
                        <span *ngIf="shouldShowStatsByHole">
                            {{vsGolfer.avgMarginOfNetLossPerHole | number}}
                        </span>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Max Margin of Loss (Net)</span>
                        <span *ngIf="shouldShowStatsByRound">
                            {{vsGolfer.maxMarginOfRoundsLostNet | number}}
                        </span>
                        <span *ngIf="shouldShowStatsByHole">
                            {{vsGolfer.maxMarginOfHolesLostNet | number}}
                        </span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
