<div class="card">
    <p-tabView [activeIndex]="activeTabIndex" (onChange)="onActiveTabChanged($event)">
        <p-tabPanel header="Plans" leftIcon="fas fa-solar-panel">
            <ng-template pTemplate="content">
                <my-practice-plan-list></my-practice-plan-list>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Sessions" leftIcon="fas fa-calendar-week">
            <ng-template pTemplate="content">
                <my-practice-session-list></my-practice-session-list>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Activities" leftIcon="fas fa-bolt">
            <ng-template pTemplate="content">
                <my-practice-activity-list></my-practice-activity-list>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Summary" leftIcon="fas fa-square-root-alt">
            <ng-template pTemplate="content">
                <my-practice-activity-summary></my-practice-activity-summary>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Improvement Plan" leftIcon="fas fa-chart-line">
            <ng-template pTemplate="content">
                <my-improvement-plan></my-improvement-plan>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Charts" leftIcon="fas fa-chart-bar">
            <ng-template pTemplate="content">
                <my-practice-charts></my-practice-charts>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
</div>
