<form *ngIf="isDataLoaded()" #roundForm="ngForm" autocomplete="off" novalidate="novalidate">
    <div class="grid form-group">
        <div class="col-12" *ngIf="!isEndingOrCompletingRound">
            <p-fieldset legend="Golfers">
                <div class="field col-12">
                    <span class="p-float-label">
                        <p-calendar #teeTime="ngModel" name="teeTime" [(ngModel)]="round.teeTime" showTime="true" showButtonBar="true" dateFormat="m/d/yy" hourFormat="12" required></p-calendar>
                        <my-validation-message [model]="teeTime" type="required"></my-validation-message>
                        <label>Tee Time</label>
                    </span>
                </div>
                <div class="col-12">
                    <p-table [value]="round.golfRoundGolfers" styleClass="p-datatable-striped">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width10">Golfer</th>
                                <th class="width10">Tee</th>
                                <th class="width4">Holes</th>
                                <th class="width8">Round Type</th>
                                <th>Tracked Stats</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-golfRoundGolfer>
                            <tr>
                                <td>
                                    {{golfRoundGolfer.golfer.fullName}}
                                </td>
                                <td>
                                    <p-dropdown name="tee-{{golfRoundGolfer.golfRoundGolferId}}" [(ngModel)]="golfRoundGolfer.tee" [options]="round.course.tees" styleClass="width-full" optionLabel="displayText" required #teeNgModel="ngModel" (onChange)="onTeeChanged(golfRoundGolfer, teeNgModel.model)" appendTo="body"></p-dropdown>
                                </td>
                                <td>
                                    <p-dropdown name="holesPlanned-{{golfRoundGolfer.golfRoundGolferId}}" [(ngModel)]="golfRoundGolfer.holesPlanned" [options]="holesPlanned" styleClass="width-full" required #holesPlannedNgModel="ngModel" (onChange)="onHolesPlannedChanged(golfRoundGolfer, holesPlannedNgModel.model, $event)" appendTo="body"></p-dropdown>
                                </td>
                                <td>
                                    <p-dropdown name="roundType-{{golfRoundGolfer.golfRoundGolferId}}" [(ngModel)]="golfRoundGolfer.golfRoundType" [options]="lookups.golfRoundTypes" styleClass="width-full" optionLabel="name" required appendTo="body"></p-dropdown>
                                </td>
                                <td>
                                    <p-multiSelect name="stats-{{golfRoundGolfer.golfRoundGolferId}}" [(ngModel)]="golfRoundGolfer.trackedMetrics" [options]="getGolferMetricSelectItems(golfRoundGolfer)" [maxSelectedLabels]="3" [selectedItemsLabel]="'{0} Stats Selected'" [filter]="false" (onChange)="onTrackedMetricsChanged(golfRoundGolfer, $event)" appendTo="body"></p-multiSelect>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="5">
                                    {{getEmptyMessage('No golfers.')}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-fieldset>
        </div>
        <div class="col-12 lg:col-3" *ngIf="!isEndingOrCompletingRound">
            <p-fieldset legend="Course Details">
                <div class="grid form-group">
                    <div class="col-12" *ngIf="!isStartingRound">
                        {{round.course.name}}<br />
                        {{round.course.street1}} {{round.course.street2}}<br />
                        {{round.course.city}}, {{round.course.stateCode}} {{round.course.zipCode}}<br />
                        {{round.course.phoneNumber}}<br />
                        <a *ngIf="round.course.websiteUrl" href="{{round.course.websiteUrl}}" target="_blank">{{round.course.websiteUrl}}</a>
                    </div>
                    <div class="field col-12">
                        <span class="p-float-label">
                            <p-dropdown #frontCourseNine="ngModel" name="frontCourseNine" [styleClass]="'width4'" [(ngModel)]="round.frontCourseNine" [options]="round.course.courseNines" optionLabel="name" required (onChange)="onFrontNineChanged()"></p-dropdown>
                            <my-validation-message [model]="frontCourseNine" type="required"></my-validation-message>
                            <label>Front 9</label>
                        </span>
                    </div>
                    <div class="field col-12" *ngIf="anyGolferPlansToPlay18Holes()">
                        <span class="p-float-label">
                            <p-dropdown #backCourseNine="ngModel" name="backCourseNine" [styleClass]="'width4'" [(ngModel)]="round.backCourseNine" [options]="round.course.courseNines" optionLabel="name" required></p-dropdown>
                            <my-validation-message [model]="backCourseNine" type="required"></my-validation-message>
                            <label>Back 9</label>
                        </span>
                    </div>
                    <div class="field col-12">
                        <span class="p-float-label">
                            <p-dropdown #firstHole="ngModel" name="firstHole" [styleClass]="'width2'" [(ngModel)]="round.firstHole" [options]="round.frontCourseNine.holes" optionLabel="number" required></p-dropdown>
                            <my-validation-message [model]="firstHole" type="required"></my-validation-message>
                            <label>First Hole</label>
                        </span>
                    </div>
                </div>
            </p-fieldset>
        </div>
        <div class="col-12" *ngIf="isEndingRound">
            <p-fieldset legend="Which Golfer(s) are Ending their Round?">
                <div class="col-12">
                    <p-table [value]="round.golfRoundGolfers" styleClass="p-datatable-striped">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width2">End<br />Round?</th>
                                <th class="width10">Golfer</th>
                                <th class="width3">Holes<br />Completed</th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-golfRoundGolfer>
                            <tr>
                                <td class="center">
                                    <p-checkbox *ngIf="isRoundIncomplete(golfRoundGolfer)" name="end-round-{{golfRoundGolfer.golfRoundGolferId}}" [(ngModel)]="golfRoundGolfer.endRound" binary="true" (onChange)="onGolferEndingRoundChange()"></p-checkbox>
                                </td>
                                <td>
                                    {{golfRoundGolfer.golfer.fullName}}
                                </td>
                                <td class="center">
                                    {{golfRoundGolfer.holesPlayed}}
                                    of
                                    {{golfRoundGolfer.holesPlanned}}
                                </td>
                                <td>
                                    {{describeRoundEndingProcess(golfRoundGolfer)}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-fieldset>
        </div>
        <div class="col-12 lg:col-2">
            <p-fieldset legend="Playing Conditions">
                <div class="grid form-group">
                    <div class="field col-12">
                        <span class="p-float-label">
                            <input #temperature="ngModel" type="number" name="temperature" pInputText class="width2 center" [(ngModel)]="round.temperature">
                            <label>Temperature</label>
                        </span>
                    </div>
                    <div class="field col-12">
                        <span class="p-float-label">
                            <p-dropdown #weather="ngModel" name="weather" [styleClass]="'width4'" [(ngModel)]="round.weather.weatherId" [options]="weatherSelectItems" required></p-dropdown>
                            <my-validation-message [model]="weather" type="required"></my-validation-message>
                            <label>Weather Conditions</label>
                        </span>
                    </div>
                    <div class="field col-12">
                        <span class="p-float-label">
                            <p-dropdown #wind="ngModel" name="wind" [styleClass]="'width4'" [(ngModel)]="round.wind.windId" [options]="windSelectItems" required></p-dropdown>
                            <my-validation-message [model]="wind" type="required"></my-validation-message>
                            <label>Wind</label>
                        </span>
                    </div>
                    <div class="field col-12" *ngIf="!isStartingRound">
                        <span class="p-float-label">
                            <p-dropdown #greenCondition="ngModel" name="greenCondition" [styleClass]="'width4'" [(ngModel)]="round.greenCondition" [options]="lookups.greenCondition" optionLabel="name" required></p-dropdown>
                            <my-validation-message [model]="greenCondition" type="required"></my-validation-message>
                            <label>Condition of Greens</label>
                        </span>
                    </div>
                    <div class="field col-12" *ngIf="!isStartingRound">
                        <span class="p-float-label">
                            <p-dropdown #greenSpeed="ngModel" name="greenSpeed" [styleClass]="'width4'" [(ngModel)]="round.greenSpeed" [options]="lookups.greenSpeed" optionLabel="name" required></p-dropdown>
                            <my-validation-message [model]="greenSpeed" type="required"></my-validation-message>
                            <label>Speed of Greens</label>
                        </span>
                    </div>
                    <div class="field col-12" *ngIf="!isStartingRound">
                        <span class="p-float-label">
                            <p-dropdown #pinDifficulty="ngModel" name="pinDifficulty" [styleClass]="'width4'" [(ngModel)]="round.pinDifficulty" [options]="lookups.pinDifficulty" optionLabel="name" required></p-dropdown>
                            <my-validation-message [model]="pinDifficulty" type="required"></my-validation-message>
                            <label>Pin Difficulty</label>
                        </span>
                    </div>
                </div>
            </p-fieldset>
        </div>
        <div class="col-12 lg:col-4" *ngIf="!isStartingRound">
            <p-fieldset legend="Notes">
                <div class="col-12">
                    <p-message severity="info" text="Your notes will not be visible to other golfers."></p-message>
                </div>
                <div class="col-12">
                    <p-editor name="notes" [(ngModel)]="notes" [style]="{'height':'125px'}" maxlength="1500">
                        <p-header>
                            <span class="ql-formats">
                                <button class="ql-bold" aria-label="Bold"></button>
                                <button class="ql-italic" aria-label="Italic"></button>
                                <button class="ql-underline" aria-label="Underline"></button>
                            </span>
                            <span class="ql-formats">
                                <button class="ql-list" value="ordered" aria-label="Ordered List"></button>
                                <button class="ql-list" value="bullet" aria-label="Unordered List"></button>
                            </span>
                        </p-header>
                    </p-editor>
                </div>
            </p-fieldset>
        </div>
        <div class="col-12">
            <button type="submit" pButton label="{{getSaveButtonLabel()}}" (click)="saveRound()" [disabled]="isLoading || !roundForm.form.valid || missingGolfersEndingRound"></button>
        </div>
        <p-confirmDialog key="round-detail-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
    </div>
</form>
