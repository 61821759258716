import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ChartData } from 'chart.js';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';
import { PracticeControllerProxy } from '../../shared/server-proxies';
import { chartColors } from '../../charts';

import {
    ModelsCorePracticePracticeSessionActivity,
    ModelsWebApiPracticeGetPracticeActivityHistoryArgs
} from '../../shared/swagger-codegen/models';

import { IPracticeActivityHistoryChartConfigData } from './practice-activity-history-chart-dialog.service';

@Component({
    selector: 'my-practice-activity-history-chart-dialog',
    templateUrl: './practice-activity-history-chart-dialog.component.html'
})
export class PracticeActivityHistoryChartDialogComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private practiceProxy: PracticeControllerProxy,
        private dynamicDialogConfig: DynamicDialogConfig,
        private datePipe: DatePipe) {
        super();
    }

    chartData: ChartData;

    ngOnInit() {
        this.loadChart();
    }

    private loadChart() {
        const configData: IPracticeActivityHistoryChartConfigData = this.dynamicDialogConfig.data;

        const args: ModelsWebApiPracticeGetPracticeActivityHistoryArgs = {
            practiceActivityVariationId: configData.practiceActivityVariationId,
            recentPracticeSessionCount: 50,
            onlyScored: true
        };

        this.practiceProxy.getPracticeActivityHistory(configData.practiceActivityId, args)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(response => {
                this.chartData = this.mapModelsToChartData(response.body);
            });
    }

    private mapModelsToChartData(sessionActivities: ModelsCorePracticePracticeSessionActivity[]) {
        const dates: string[][] = [];
        const scores: number[] = [];

        sessionActivities.forEach(sessionActivity => {
            const started = new Date(sessionActivity.started);
            const date = this.datePipe.transform(started, 'shortDate');
            const time = this.datePipe.transform(started, 'shortTime');
            dates.push([date, time]);
            scores.push(sessionActivity.score);
        });

        const data = this.initializeChartData();
        data.labels = dates.reverse();
        data.datasets[0].data = scores.reverse();

        return data;
    }

    private initializeChartData() {
        return {
            datasets: [
                {
                    label: 'Score',
                    backgroundColor: chartColors[0],
                    borderColor: chartColors[0],
                    data: [],
                    fill: false
                }
            ],
            labels: []
        } as ChartData;
    }
}
