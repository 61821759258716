<div class="p-fluid">
    <div class="grid form-group">
        <div class="field col-12 lg:col-2">
            <span class="p-float-label">
                <p-dropdown name="statCategory" [(ngModel)]="selectedCategoryId" [options]="categories" required (onChange)="onCategoryChanged()"></p-dropdown>
                <label>Category</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1" *ngIf="shouldShowComparisons">
            <span class="p-float-label">
                <p-dropdown name="compare" [(ngModel)]="args.comparison" [options]="comparisons" required (onChange)="onComparisonChanged()"></p-dropdown>
                <label>Comparison</label>
            </span>
        </div>
        <div class="field col-12 lg:col-2" *ngIf="shouldShowGolfers">
            <span class="p-float-label">
                <p-dropdown [options]="golfers" [(ngModel)]="golferId" (onChange)="onGolferChanged()"></p-dropdown>
                <label>Golfer</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1" *ngIf="shouldShowYears">
            <span class="p-float-label">
                <p-dropdown [options]="yearsPlayed" [(ngModel)]="args.year" (onChange)="onYearChanged();"></p-dropdown>
                <label>Year</label>
            </span>
        </div>
    </div>

    <div>
        <p-table [value]="achievements" [loading]="isLoading" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md">
            <ng-template pTemplate="caption">
                Achievements
            </ng-template>
            <ng-template pTemplate="body" let-achievement let-rowIndex="rowIndex">
                <tr class="rowgroup-header" *ngIf="shouldShowAchievementRowGroupHeader(rowIndex)">
                    <td class="width10">
                        {{achievement.category.name}}
                    </td>
                    <td *ngFor="let columnHeader of response?.columnHeaders" class="center">
                        {{columnHeader}}
                    </td>
                </tr>
                <tr>
                    <td class="left">
                        {{achievement.name}}
                        <span class="status-icons right">
                            <i *ngIf="isFavoriteAchievement(achievement)" (click)="removeFavoriteAchievement(achievement)" class="fas fa-heart favorites-icon" pTooltip="Remove from Favorites"></i>
                            <i *ngIf="!isFavoriteAchievement(achievement)" (click)="addFavoriteAchievement(achievement)" class="far fa-heart favorites-icon" pTooltip="Add to Favorites"></i>
                        </span>
                    </td>
                    <td class="center" *ngFor="let columnHeader of response?.columnHeaders; index as columnIndex;">
                        <span class="p-column-title">{{columnHeader}}</span>
                        <a [routerLink]="getHistoryRoute(achievement, columnIndex)" *ngIf="achievement.columnValues[columnIndex].timesAchieved > 0">{{achievement.columnValues[columnIndex].timesAchieved | number}}</a>
                        <span *ngIf="achievement.columnValues[columnIndex].timesAchieved === 0">0</span>
                        <i class="fas fa-trophy" *ngIf="shouldShowRanking(achievement.columnValues[columnIndex])" [ngClass]="getRankingClass(achievement.columnValues[columnIndex])" pTooltip="{{achievement.columnValues[columnIndex].formattedRank}}"></i>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="response?.columnHeaders?.length + 1">
                        {{getEmptyMessage('No achievements.')}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
