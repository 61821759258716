// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreCoursesMapMarkers = 1 | 2 | 3 | 4 | 5;

export const ModelsCoreCoursesMapMarkers = {
    Tee: 1 as ModelsCoreCoursesMapMarkers,
    TeeShotTarget: 2 as ModelsCoreCoursesMapMarkers,
    Green: 3 as ModelsCoreCoursesMapMarkers,
    Yardage: 4 as ModelsCoreCoursesMapMarkers,
    LocationModifier: 5 as ModelsCoreCoursesMapMarkers
};
