import { NgModule } from '@angular/core';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth-guard.service';
import { SessionService } from './session.service';

export function jwtOptionsFactory(session: SessionService) {
    return {
        tokenGetter: () => {
            return session.getToken();
        }
    };
}

@NgModule(
    {
        imports: [
            JwtModule.forRoot({
                jwtOptionsProvider: {
                    provide: JWT_OPTIONS,
                    useFactory: jwtOptionsFactory,
                    deps: [SessionService]
                }
            })
        ],
        providers: [
            AuthService,
            AuthGuard,

            SessionService
        ]
    })
export class AuthModule {
}
