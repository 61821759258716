// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreStatisticsGoalTypes = 0 | 1 | 2 | 3;

export const ModelsCoreStatisticsGoalTypes = {
    Annual: 0 as ModelsCoreStatisticsGoalTypes,
    Quarterly: 1 as ModelsCoreStatisticsGoalTypes,
    Monthly: 2 as ModelsCoreStatisticsGoalTypes,
    AllTime: 3 as ModelsCoreStatisticsGoalTypes
};
