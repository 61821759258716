export const chartColors = [
    '#4CAF50',
    '#03A9F4',
    '#9C27B0',
    '#FFC107',
    '#E91E63',
    '#6534FF',
    '#62BCFA'
];

export * from './chart.service';
export * from './chart-base.directive';
export * from './charts.module';
export * from './courses-played-pie-chart.component';
export * from './fairways-per-par-bar-chart.component';
export * from './girs-per-par-bar-chart.component';
export * from './golf-club-yardage-line-chart.component';
export * from './handicap-history-line-chart.component';
export * from './improvement-plan-bar-chart.component';
export * from './miss-tendency-radar-chart.component';
export * from './penalties-per-par-bar-chart.component';
export * from './practice-activity-history-line-chart.component';
export * from './practice-activity-score-history-line-chart.component';
export * from './practice-time-by-category-pie-chart.component';
export * from './practice-time-by-category-stacked-bar-chart.component';
export * from './practice-time-by-month-stacked-bar-chart.component';
export * from './projected-handicap-line-chart.component';
export * from './proximity-by-shot-line-chart.component';
export * from './putt-count-bar-chart.component';
export * from './putting-by-starting-distance-line-chart.component';
export * from './putting-pie-chart.component';
export * from './recent-rounds-bar-chart.component';
export * from './running-score-line-chart.component';
export * from './running-strokes-line-chart.component';
export * from './score-by-hole-line-chart.component';
export * from './score-name-bar-chart.component';
export * from './scoring-average-per-par-bar-chart.component';
export * from './scoring-pie-chart.component';
export * from './short-game-proximity-line-chart.component';
export * from './statistic-snapshot-chart.component';
export * from './strokes-gained-bar-chart.component';
export * from './strokes-gained-handicap-bar-chart.component';
