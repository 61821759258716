import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';

import { GolfersControllerProxy, RoundsControllerProxy, CoursesControllerProxy } from '../../shared/server-proxies';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';
import { GolferLookupService, IGolferSelectItem } from '../../shared/golfers';

import {
    ModelsRoundsAnalyzeStrokesAnalyzeStrokesResponse,
    ModelsWebApiGolfersGetStrokeAnalysisArgs
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-stroke-analysis',
    templateUrl: './stroke-analysis.component.html'
})
export class StrokeAnalysisComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private roundsProxy: RoundsControllerProxy,
        private coursesProxy: CoursesControllerProxy,
        private golferLookups: GolferLookupService,
        private datePipe: DatePipe,
        private currentRoute: ActivatedRoute) {
        super();
    }
    
    args = {
        recentRoundCount: 10
    } as ModelsWebApiGolfersGetStrokeAnalysisArgs;
    golferId: number;
    golfers: IGolferSelectItem[];
    roundCounts: SelectItem[] = [
        { value: 1, label: 'Last Round' },
        { value: 2, label: 'Last 2 Rounds' },
        { value: 3, label: 'Last 3 Rounds' },
        { value: 5, label: 'Last 5 Rounds' },
        { value: 8, label: 'Last 8 Rounds' },
        { value: 10, label: 'Last 10 Rounds' },
        { value: 15, label: 'Last 15 Rounds' },
        { value: 20, label: 'Last 20 Rounds' }
    ];
    analysis: ModelsRoundsAnalyzeStrokesAnalyzeStrokesResponse;
    showRecentRounds = true;
    header = 'Stroke Analysis';
    private defaultHeader = 'Stroke Analysis';

    get showRoundCount() {
        return this.showRecentRounds
            && this.analysis.totalRounds > 0
            && this.analysis.totalRounds !== this.args.recentRoundCount;
    }

    ngOnInit(): void {
        this.golferLookups.getFriendSelectItems()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                friends => {
                    this.golfers = friends;
                    this.golferId = this.golferLookups.defaultFriendId;

                    this.currentRoute.params
                        .pipe(this.takeUntilUnsubscribed())
                        .subscribe(
                            params => {
                                this.args.golfRoundId = parseInt(params['golfRoundId'], 10) || 0;
                                this.args.courseId = parseInt(params['courseId'], 10) || 0;
                                this.showRecentRounds = this.args.golfRoundId <= 0;

                                if(this.args.golfRoundId > 0) {
                                    this.loadGolfRoundStrokeAnalysis();
                                }
                                else if(this.args.courseId > 0) {
                                    this.loadCourseStrokeAnalysis();
                                }
                                else {
                                    this.header = this.defaultHeader;
                                    this.loadStrokeAnalysis();
                                }
                            });
                });
    }

    onGolferChanged() {
        this.loadStrokeAnalysis();
    }

    onRoundCountChanged() {
        this.loadStrokeAnalysis();
    }

    private loadStrokeAnalysis() {
        this.taskStarted();

        this.golfersProxy.getStrokeAnalysis(this.golferId, this.args)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.analysis = response.body;
                });
    }

    private loadGolfRoundStrokeAnalysis() {
        return this.roundsProxy.getRound(this.args.golfRoundId)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    const round = response.body;
                    const teeTimeDate = this.datePipe.transform(round.teeTime, 'fullDate');
                    const teeTimeTime = this.datePipe.transform(round.teeTime, 'shortTime');
                    const courseName = round.course.name;

                    this.header =
                        `Golf Round ${this.defaultHeader} - ${teeTimeDate} ${teeTimeTime} at ${courseName}`;
                    this.golfers = round.golfRoundGolfers.map(grg => {
                        return {
                            value: grg.golfer.golferId,
                            label: `${grg.golfer.firstName} ${grg.golfer.lastName}: ${grg.strokes}`,
                            golfer: grg.golfer
                        };
                    });
                    this.golferId = this.golfers[0].value;
                    this.loadStrokeAnalysis();
                });
    }
    
    private loadCourseStrokeAnalysis() {
        this.coursesProxy.getCourse(this.args.courseId)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    const course = response.body;
                    this.header = `Course ${this.defaultHeader} - ${course.name}`;
                });
        this.loadStrokeAnalysis();
    }
}
