<div class="card">
    <h1>
        {{header}}
    </h1>
    <div class="grid form-group">
        <div class="field col-12 lg:col-2">
            <span class="p-float-label">
                <p-dropdown [options]="golfers" [(ngModel)]="golferId" (onChange)="onGolferChanged()"></p-dropdown>
                <label>Golfer</label>
            </span>
        </div>
        <div *ngIf="showRecentRounds" class="field col-12 lg:col-3">
            <span class="p-float-label">
                <p-dropdown [options]="roundCounts" [(ngModel)]="args.recentRoundCount" (onChange)="onRoundCountChanged();"></p-dropdown>
                <label>Analyze</label>
            </span>
        </div>
        <div *ngIf="showRoundCount" class="col-12">
            Analysis Based on {{analysis.totalRounds}} Round{{analysis.totalRounds === 1 ? '' : 's'}}
        </div>
    </div>
    <p-tabView *ngIf="analysis">
        <p-tabPanel header="Distance: Total">
            <my-stroke-analysis-group-table nameHeader="Distance" [analysis]="analysis" [groups]="analysis.strokesByDistanceTotal" [isPuttAnalysis]="true"></my-stroke-analysis-group-table>
        </p-tabPanel>
        <p-tabPanel header="Distance: Tee to Green">
            <my-stroke-analysis-group-table nameHeader="Distance" [analysis]="analysis" [groups]="analysis.strokesByDistanceTeeToGreen"></my-stroke-analysis-group-table>
        </p-tabPanel>
        <p-tabPanel header="Distance: Putting">
            <my-stroke-analysis-group-table nameHeader="Distance" totalShotsHeader="Total Putts" [analysis]="analysis" [groups]="analysis.strokesByDistancePutting" [isPuttAnalysis]="true"></my-stroke-analysis-group-table>
        </p-tabPanel>
        <p-tabPanel header="Golf Clubs">
            <my-stroke-analysis-group-table nameHeader="Club" [analysis]="analysis" [groups]="analysis.strokesByGolfClub"></my-stroke-analysis-group-table>
        </p-tabPanel>
        <p-tabPanel header="Golf Clubs &amp; Shot Types">
            <my-stroke-analysis-group-table nameHeader="Club &amp; Shot Type" [analysis]="analysis" [groups]="analysis.strokesByGolfClubAndShotType"></my-stroke-analysis-group-table>
        </p-tabPanel>
        <p-tabPanel header="Shot Types">
            <my-stroke-analysis-group-table nameHeader="Shot Type" [analysis]="analysis" [groups]="analysis.strokesByShotType"></my-stroke-analysis-group-table>
        </p-tabPanel>
        <p-tabPanel header="Lies">
            <my-stroke-analysis-group-table nameHeader="From Lie" [analysis]="analysis" [groups]="analysis.strokesByLieType"></my-stroke-analysis-group-table>
        </p-tabPanel>
        <p-tabPanel header="Mistakes">
            <my-stroke-analysis-group-table nameHeader="Mistake" totalShotsHeader="Count" [analysis]="analysis" [groups]="analysis.mistakes"></my-stroke-analysis-group-table>
        </p-tabPanel>
    </p-tabView>
</div>
