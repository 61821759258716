//@ts-nocheck
/* eslint @typescript-eslint/no-unused-vars: "off" */
//Disable "variable is declared but its value is never read" errors until we're ready to implement this again.
import { Injectable } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';

import {
    ModelsCoreGolfersGolfer
} from '../swagger-codegen/models';

@Injectable()
export class InstrumentationService {
    constructor(private router: Router) {
    }

    setUser(user: ModelsCoreGolfersGolfer) {
        Sentry.setUser({
            id: user.golferId,
            username: `${user.firstName} ${user.lastName}`,
            email: user.email
        });
    }

    trackPageViewStart(name: string) {
    }

    trackPageViewEnd(
        name: string,
        url: string,
        properties?: { [name: string]: string },
        measurements?: { [name: string]: number }) {

    }

    trackPageView(
        name: string,
        url: string,
        properties?: { [name: string]: string },
        measurements?: { [name: string]: number },
        pageViewDuration?: number) {

    }

    trackEvent(name: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }) {
    }

    trackException(
        exception: Error,
        handledAt: string,
        properties?: { [name: string]: string },
        measurements?: { [name: string]: number }) {

    }

    trackDependencyCall(
        method: string,
        absoluteUrl: string,
        pathName: string,
        totalTime: number,
        success: boolean,
        resultCode: number) {

    }

    startTrackingRouteChanges() {
        this.router.events.pipe(
                distinctUntilChanged(
                    (previous: any, current: any) => {
                        if(current instanceof NavigationStart) {
                            return previous.url === current.url;
                        }

                        return true;
                    }))
            .subscribe(
                (newRoute: any) => {
                    this.trackPageViewStart(newRoute.url);
                });

        this.router.events.pipe(
                distinctUntilChanged(
                    (previous: any, current: any) => {
                        if(current instanceof NavigationEnd) {
                            return previous.url === current.url;
                        }

                        return true;
                    }))
            .subscribe(
                (newRoute: any) => {
                    this.trackPageViewEnd(newRoute.url, newRoute.url);
                });
    }

    private newId() {
        const base64Chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
        let result = '';
        let random = Math.random() * 1073741824; // 5 symbols in base64, almost maxint

        while(random > 0) {
            const char = base64Chars.charAt(random % 64);
            result += char;
            random = Math.floor(random / 64);
        }

        return result;
    }
}
