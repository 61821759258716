<div class="card">
    <h1>Handicaps</h1>
    <div class="grid" *ngIf="handicaps">
        <div class="field col-12 lg:col-2">
            <span class="p-float-label">
                <p-dropdown [options]="golfers" [(ngModel)]="golferId" styleClass="golfer-selector" (onChange)="onGolferChanged()"></p-dropdown>
                <label>Golfer</label>
            </span>
        </div>
        <div class="col-12">
            <p-tabView>
                <p-tabPanel header="Current" leftIcon="fas fa-calendar-check">
                    <ng-template pTemplate="content">
                        <my-handicap-differentials></my-handicap-differentials>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="Projected" leftIcon="fas fa-chart-line">
                    <ng-template pTemplate="content">
                        <my-projected-handicap></my-projected-handicap>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="Historical" leftIcon="fas fa-history">
                    <ng-template pTemplate="content">
                        <my-historical-handicaps></my-historical-handicaps>
                    </ng-template>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>
