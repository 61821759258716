import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { SelectItem, ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { finalize, tap } from 'rxjs/operators';

import { GolfersControllerProxy, RoundsControllerProxy } from '../../shared/server-proxies';
import { GolferLookupService, IGolferSelectItem } from '../../shared/golfers';
import { ScorecardDialogService } from '../../shared/rounds/scorecard-dialog/scorecard-dialog.service';
import { SessionService } from '../../shared/auth/session.service';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';
import { QueryString } from '../../shared/util';

import {
    ModelsCoreRoundsGolfRoundExports,
    ModelsCoreRoundsGolfRoundGolfer,
    ModelsCoreRoundsScoreCategories,
    ModelsCoreRoundsStatisticsUpdateStatuses,
    ModelsCoreRoundsWeatherConditions,
    ModelsCoreRoundsWindSpeeds,
    ModelsWebApiGolfersGetGolfRoundsArgs
} from '../../shared/swagger-codegen/models';

/* eslint-disable no-shadow */
enum ViewOptions {
    stats = 0,
    scores = 1,
    handicapDetails = 2,
    strokesGainedVsPro = 3,
    strokesGainedVsScratch = 4
}

@Component({
    selector: 'my-round-list',
    templateUrl: './round-list.component.html',
    styleUrls: [
        './round-list.component.scss'
    ]
})
export class RoundListComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private roundsProxy: RoundsControllerProxy,
        private golferLookups: GolferLookupService,
        private scorecardDialog: ScorecardDialogService,
        private session: SessionService,
        private confirmationService: ConfirmationService,
        private datePipe: DatePipe,
        private router: Router) {
        super();
    }

    args = {
        page: 0,
        pageSize: 30
    } as ModelsWebApiGolfersGetGolfRoundsArgs;
    golferId: number;
    totalRounds = 0;
    golfRoundGolfers: ModelsCoreRoundsGolfRoundGolfer[] = [];
    golfers: IGolferSelectItem[];
    courses: SelectItem[];
    selectedViewOption = ViewOptions.stats;
    viewOptions: SelectItem[] = [
        { value: ViewOptions.stats, label: 'Stats' },
        { value: ViewOptions.scores, label: 'Scores' },
        { value: ViewOptions.strokesGainedVsPro, label: 'SG vs Pro' },
        { value: ViewOptions.strokesGainedVsScratch, label: 'SG vs Scratch' },
        { value: ViewOptions.handicapDetails, label: 'Handicap Details' }
    ];
    excelExportUrl: string;
    @ViewChild(Table) roundsTable: Table;

    get viewingStats() {
        return this.selectedViewOption === ViewOptions.stats;
    }

    get viewingScores() {
        return this.selectedViewOption === ViewOptions.scores;
    }

    get viewingHandicapDetails() {
        return this.selectedViewOption === ViewOptions.handicapDetails;
    }

    get viewingStrokesGained() {
        return this.selectedViewOption === ViewOptions.strokesGainedVsPro
            || this.selectedViewOption === ViewOptions.strokesGainedVsScratch;
    }

    get viewingStrokesGainedVsPro() {
        return this.selectedViewOption === ViewOptions.strokesGainedVsPro;
    }

    get columnCount() {
        if(this.viewingHandicapDetails) {
            return 9;
        }
        else if(this.viewingScores) {
            return 13;
        }
        else if(this.viewingStrokesGained) {
            return 10;
        }
        else {
            return 12;
        }
    }

    ngOnInit(): void {
        this.golferLookups.getFriendSelectItems()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                friends => {
                    this.golfers = friends;
                    this.golferId = this.golferLookups.defaultFriendId;
                    this.loadCoursesPlayed()
                        .pipe(this.takeUntilUnsubscribed())
                        .subscribe(
                            () => {
                                this.loadGolfRounds();
                            });
                });
    }

    onGolferChanged() {
        this.args.page = 0;
        this.loadCoursesPlayed()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    this.loadGolfRounds();
                });
    }

    onCourseChanged() {
        this.args.page = 0;
        this.loadGolfRounds();
    }

    getRankTooltip(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        let html = '<table>';

        for(let i = 0; i < golfRoundGolfer.golfRound.golfRoundGolferRankings.length; i++) {
            const ranking = golfRoundGolfer.golfRound.golfRoundGolferRankings[i];
            const rankDescription = ranking.isTie ? 'T-' + ranking.rank : ranking.rank.toString();

            html += ranking.golferId === this.golferId ? '<tr class="current-golfer-ranking">' : '<tr>';
            html += `<td>${rankDescription}</td>`;
            html += `<td>${ranking.displayName}</td>`;
            html += `<td>${ranking.value} </td></tr >`;
        }

        html += '</table>';

        return html;
    }

    getContinueButtonText(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer.holesPlayed > 0 ? 'Continue Round' : 'Start Round';
    }

    continueRound(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        if(golfRoundGolfer.holesPlayed > 0) {
            this.router.navigate(['rounds', golfRoundGolfer.golfRound.golfRoundId, 'scorecard', { continue: true }]);
        }
        else {
            this.router.navigate(['rounds', golfRoundGolfer.golfRound.golfRoundId, { starting: true }]);
        }
    }

    endRound(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        this.router.navigate(['rounds', golfRoundGolfer.golfRound.golfRoundId, { ending: true }]);
    }
    
    shouldShowGolfRoundRowGroupHeader(rowIndex: number) {
        let show = rowIndex === this.roundsTable.first;

        if(!show) {
            const indexWithinPage = rowIndex % this.args.pageSize;
            const previousRowIndex = indexWithinPage - 1;
            const previousYear = previousRowIndex < this.golfRoundGolfers.length
                ? this.golfRoundGolfers[previousRowIndex].golfRound.year
                : undefined;
            const currentYear = indexWithinPage < this.golfRoundGolfers.length
                ? this.golfRoundGolfers[indexWithinPage].golfRound.year
                : undefined;

            show = previousYear !== currentYear;
        }

        return show;
    }

    shouldShowStatisticsUpdateProcessingIcon(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer.statisticsUpdateStatus === ModelsCoreRoundsStatisticsUpdateStatuses.Processing;
    }

    shouldShowStatisticsUpdateFailedIcon(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer.statisticsUpdateStatus === ModelsCoreRoundsStatisticsUpdateStatuses.Failed;
    }

    shouldShowScorecardIcon(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer.holesPlayed > 0;
    }

    shouldShowStrokeAnalysisIcon(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer.isComplete
            && golfRoundGolfer.golfRoundGolferMetric
            && golfRoundGolfer.golfRoundGolferMetric.strokesGainedTotalVsPro !== undefined;
    }

    shouldShowHolesPlayed(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return !golfRoundGolfer.isComplete && golfRoundGolfer.holesPlayed > 0;
    }

    shouldShowScoreAsterisk(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer.isComplete && golfRoundGolfer.holesPlayed > golfRoundGolfer.holesCompleted;
    }

    shouldShowWindIcon(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer.isComplete
            && golfRoundGolfer.golfRound.wind
            && golfRoundGolfer.golfRound.wind.windId >= ModelsCoreRoundsWindSpeeds.MilesPerHour16To20;
    }

    getWindTooltip(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer.golfRound.wind ? `${golfRoundGolfer.golfRound.wind.name} Wind` : '';
    }

    shouldShowRainIcon(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer.isComplete
            && golfRoundGolfer.golfRound.weather
            && golfRoundGolfer.golfRound.weather.weatherId === ModelsCoreRoundsWeatherConditions.Wet;
    }

    shouldShowTempIcon(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer, percentage: number) {
        const temp = golfRoundGolfer.golfRound.temperature;

        return golfRoundGolfer.isComplete
            &&
            (
                (percentage === 1 && temp > 95) ||
                (percentage === 0.75 && temp >= 90 && temp <= 95) ||
                (percentage === 0.5 && temp >= 55 && temp <= 60) ||
                (percentage === 0.25 && temp >= 40 && temp <= 54) ||
                (percentage === 0 && temp < 40)
            );
    }

    shouldShowExceptionalScoreIcon(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer.scoreCategory === ModelsCoreRoundsScoreCategories.Exceptional;
    }

    shouldShowAboveAverageScoreIcon(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer.scoreCategory === ModelsCoreRoundsScoreCategories.AboveAverage;
    }

    shouldShowBelowAverageScoreIcon(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer.scoreCategory === ModelsCoreRoundsScoreCategories.BelowAverage;
    }

    getAboveAverageScoreTooltip(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer.scoreCategory === ModelsCoreRoundsScoreCategories.AboveAverage
            ? `Net Score of ${golfRoundGolfer.formattedNetScore} is an Above Average Round`
            : undefined;
    }

    getBelowAverageScoreTooltip(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer.scoreCategory === ModelsCoreRoundsScoreCategories.BelowAverage
            ? `Net Score of ${golfRoundGolfer.formattedNetScore} is a Below Average Round`
            : undefined;
    }
    
    getStrokesGainedOffTee(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return this.viewingStrokesGainedVsPro
            ? golfRoundGolfer.golfRoundGolferMetric?.strokesGainedOffTeeVsPro
            : golfRoundGolfer.golfRoundGolferMetric?.strokesGainedOffTeeVsScratch;
    }

    getStrokesGainedApproach(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return this.viewingStrokesGainedVsPro
            ? golfRoundGolfer.golfRoundGolferMetric?.strokesGainedApproachVsPro
            : golfRoundGolfer.golfRoundGolferMetric?.strokesGainedApproachVsScratch;
    }

    getStrokesGainedAroundGreen(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return this.viewingStrokesGainedVsPro
            ? golfRoundGolfer.golfRoundGolferMetric?.strokesGainedAroundGreenVsPro
            : golfRoundGolfer.golfRoundGolferMetric?.strokesGainedAroundGreenVsScratch;
    }

    getStrokesGainedPutting(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return this.viewingStrokesGainedVsPro
            ? golfRoundGolfer.golfRoundGolferMetric?.strokesGainedPuttingVsPro
            : golfRoundGolfer.golfRoundGolferMetric?.strokesGainedPuttingVsScratch;
    }

    getStrokesGainedTeeToGreen(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return this.viewingStrokesGainedVsPro
            ? golfRoundGolfer.golfRoundGolferMetric?.strokesGainedTeeToGreenVsPro
            : golfRoundGolfer.golfRoundGolferMetric?.strokesGainedTeeToGreenVsScratch;
    }

    getStrokesGainedTotal(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return this.viewingStrokesGainedVsPro
            ? golfRoundGolfer.golfRoundGolferMetric?.strokesGainedTotalVsPro
            : golfRoundGolfer.golfRoundGolferMetric?.strokesGainedTotalVsScratch;
    }

    getTempTooltip(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer.golfRound.temperature !== undefined ? `${golfRoundGolfer.golfRound.temperature}°` : '';
    }

    getIncompleteHoleMessage(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        const incompleteHoleCount = golfRoundGolfer.holesPlayed - golfRoundGolfer.holesCompleted;
        const holeOrHoles = incompleteHoleCount === 1 ? 'hole' : 'holes';
        return `Round includes ${incompleteHoleCount} unfinished ${holeOrHoles}.`;
    }

    showScorecardDialog(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        const golfRound = golfRoundGolfer.golfRound;
        this.scorecardDialog.open(golfRound.golfRoundId, golfRound.teeTime, golfRound.course.name);
    }

    deleteGolfRoundGolfer(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer, index: number) {
        const date = this.datePipe.transform(golfRoundGolfer.golfRound.teeTime, 'EEEE, M/d/yyyy');
        let message = `Are you sure you want to delete the ${golfRoundGolfer.golfRound.course.name} round from ${date}?`;

        const holesPlayed = golfRoundGolfer.holesPlayed;
        const holesLabel = holesPlayed === 1 ? 'hole' : 'holes';

        if(holesPlayed > 0) {
            message += ` ${golfRoundGolfer.golfer.fullName} completed ${holesPlayed} ${holesLabel} `
                + 'that will be permanently lost if you continue.';
        }

        this.confirmationService.confirm(
            {
                key: 'round-list-component',
                header: 'Delete Golf Round?',
                message: message,
                accept: () => {
                    this.taskStarted();
                    this.roundsProxy.deleteGolfRound(golfRoundGolfer.golfRound.golfRoundId, golfRoundGolfer.golfRoundGolferId)
                        .pipe(
                            finalize(() => this.taskCompleted()),
                            this.takeUntilUnsubscribed())
                        .subscribe(
                            () => {
                                this.golfRoundGolfers.splice(index, 1);
                            });
                }
            });
    }

    loadGolfRoundPage(e: LazyLoadEvent) {
        this.args.page = e.first / this.args.pageSize;

        if(this.golferId > 0) {
            this.loadGolfRounds();
        }
    }
    
    private loadCoursesPlayed() {
        return this.golfersProxy.getGolfRoundCountsPerCourse(this.golferId)
            .pipe(
                tap(response => {
                    const courses = response.body.map(
                        c => {
                            return {
                                value: c.courseId,
                                label: c.name
                            };
                        });

                    this.courses = courses;

                    if(courses.findIndex(i => i.value === this.args.courseId) === -1) {
                        this.args.courseId = 0;
                    }
                })
            );
    }

    private loadGolfRounds() {
        this.taskStarted();
        return this.golfersProxy.getGolfRounds(this.golferId, this.args)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.golfRoundGolfers = response.body.golfRoundGolfers;
                    this.totalRounds = response.body.totalRounds;
                    this.updateExportUrl();
                });
    }

    private updateExportUrl() {
        const exportArgs: ModelsWebApiGolfersGetGolfRoundsArgs = {};

        Object.keys(this.args).forEach(key => {
            exportArgs[key] = this.args[key];
        });

        exportArgs.exportFormat = ModelsCoreRoundsGolfRoundExports.Excel;
        (exportArgs as any).access_token = this.session.getToken();

        this.excelExportUrl = `/api/golfers/${this.golferId}/rounds${QueryString.fromObject(exportArgs)}`;
    }
}
