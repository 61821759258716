<div class="grid">
    <div class="col-12">
        <p-tabView>
            <p-tabPanel header="Hole">
                <my-scorecard-hole (holeInitialized)="onScorecardHoleInitialized($event)"></my-scorecard-hole>
            </p-tabPanel>
            <p-tabPanel header="Map" *ngIf="showMapTab">
                <ng-template pTemplate="content">
                    <my-course-map [course]="course" [courseMapArea]="courseMapArea"></my-course-map>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Notes" *ngIf="showNotesTab">
                <ng-template pTemplate="content">
                    <my-scorecard-hole-notes [selectedHole]="selectedHole" [courseId]="courseId"></my-scorecard-hole-notes>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="History" *ngIf="showHistoricalStatsTab">
                <ng-template pTemplate="content">
                    <my-hole-historical-stats [allTimeHistory]="selectedHole.golferHoleInfo.allTimeHistory"
                                     [mostRecentYearHistory]="selectedHole.golferHoleInfo.mostRecentYearHistory"></my-hole-historical-stats>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Quick Glance" *ngIf="showQuickGlanceTab">
                <ng-template pTemplate="content">
                    <my-scorecard-quick-glance [scorecard]="scorecard"></my-scorecard-quick-glance>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Full Scorecard" *ngIf="showFullScorecardTab">
                <ng-template pTemplate="content">
                    <my-scorecard [scorecard]="scorecard" [readOnly]="true" [showLegend]="false"></my-scorecard>
                </ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
