import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { AppLayoutComponent } from '../layout/app.layout.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

export const routes: Routes = [
    {
        path: 'public',
        component: AppLayoutComponent,
        children: [
            {
                path: 'privacy-policy',
                component: PrivacyPolicyComponent,
                data: {
                    title: 'Privacy Policy'
                }
            }
        ]
    }
];

export const publicRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
