import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { GolfersControllerProxy } from '../../../shared/server-proxies';
import { GolferLookupService, ICourseSelectItem } from '../../../shared/golfers';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';

import {
    ModelsCoreCoursesCourse,
    ModelsCoreSharedChartingChartData,
    ModelsWebApiGolfersGetProjectedHandicapArgs
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-projected-handicap',
    templateUrl: './projected-handicap.component.html'
})
export class ProjectedHandicapComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private golferLookups: GolferLookupService,
        private currentRoute: ActivatedRoute) {
        super();
    }

    golferId: number;
    chartData: ModelsCoreSharedChartingChartData;
    course: ModelsCoreCoursesCourse;
    courses: ICourseSelectItem[];
    tees: SelectItem[];
    courseNines: SelectItem[];

    args: ModelsWebApiGolfersGetProjectedHandicapArgs = {
        teeId: undefined,
        frontCourseNineId: undefined,
        backCourseNineId: undefined
    };
    courseId: number;
    frontCourseNineId: number;
    backCourseNineId: number;
    holesPlannedOptions: SelectItem[] = [
        { value: 18, label: '18 Holes' },
        { value: 9, label: '9 Holes' }
    ];
    holesPlanned = 18;

    ngOnInit(): void {
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    const golferIdParam = params['golferId'];
                    this.golferLookups.getFavoriteCourseSelectItems()
                        .pipe(this.takeUntilUnsubscribed())
                        .subscribe(
                            courses => {
                                this.golferId = parseInt(golferIdParam, 10) || this.golferLookups.defaultFriendId;
                                this.courses = courses;
                                this.courseId = this.golferLookups.defaultFavoriteCourseId;
                                this.loadCourseData();
                                this.loadHandicapProjection();
                            });
                });
    }

    get showHolesPlanned() {
        return this.courseNines && this.courseNines.length > 1;
    }

    get showFrontCourseNines() {
        return this.courseNines && (this.courseNines.length > 2 || this.holesPlanned === 9);
    }

    get showBackCourseNines() {
        return this.courseNines && this.courseNines.length > 2;
    }

    onCourseChanged() {
        this.loadCourseData();
        this.loadHandicapProjection();
    }

    onTeeChanged() {
        this.loadHandicapProjection();
    }

    onCourseNineChanged() {
        this.loadHandicapProjection();
    }

    onHolesPlannedChanged() {
        this.setDefaultBack9();
        this.loadHandicapProjection();
    }

    private loadCourseData() {
        const selectedItem = this.courses.find(c => c.value === this.courseId);
        this.course = selectedItem ? selectedItem.course : undefined;

        if(this.course) {
            this.tees = this.course.tees
                ? this.course.tees.map(
                    t => ({
                        value: t.teeId,
                        label: t.displayText
                    } as SelectItem))
                : [];

            this.courseNines = this.course.courseNines
                ? this.course.courseNines.map(
                    cn => ({
                        value: cn.courseNineId,
                        label: cn.name
                    } as SelectItem))
                : [];

            if(this.holesPlanned === 18 && this.courseNines.length < 2) {
                this.holesPlanned = 9;
            }

            this.args.teeId = this.course.tees && this.course.tees.length > 0 ? this.course.tees[0].teeId : undefined;
            this.args.frontCourseNineId = this.course.courseNines && this.course.courseNines.length > 0
                ? this.course.courseNines[0].courseNineId
                : undefined;
            this.setDefaultBack9();
        }
    }

    private loadHandicapProjection() {
        this.taskStarted();
        this.golfersProxy.getProjectedHandicap(this.golferId, this.args)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.chartData = response.body.handicapProjection;
                });
    }

    private setDefaultBack9() {
        const hasBack9 = this.course
            && this.course.courseNines
            && this.course.courseNines.length > 1
            && this.holesPlanned === 18;
        this.args.backCourseNineId = hasBack9 ? this.course.courseNines[1].courseNineId : undefined;
    }
}
