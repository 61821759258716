<div class="grid">
    <div [ngClass]="getContainerCssClasses()" *ngIf="practicePlans">
        <p-table [value]="practicePlans" [paginator]="practicePlans.length > 25" [pageLinks]="10" [rows]="25" [loading]="isLoading" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md" (onRowReorder)="onRowsReordered()">
            <ng-template pTemplate="caption">
                Practice Plans
                <i *ngIf="!enableAddingToPlan" class="fas fa-plus-square new-item-icon clickable" [routerLink]="['/practice/plans/new']" pTooltip="Create Practice Plan"></i>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="reorder-column"></th>
                    <th class="left">Name</th>
                    <th class="width4">Planned<br />Time</th>
                    <th class="width3"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-plan let-rowIndex="rowIndex">
                <tr [pReorderableRow]="rowIndex" [ngClass]="{ 'inactive-row': !plan.isActive }">
                    <td>
                        <i class="fa fa-bars clickable" pReorderableRowHandle></i>
                    </td>
                    <td class="left">
                        <span class="p-column-title">Name</span>
                        <span *ngIf="enableAddingToPlan">{{plan.name}}</span>
                        <a *ngIf="!enableAddingToPlan" [routerLink]="['/practice/plans', plan.practicePlanId]">{{plan.name}}</a>
                        <span *ngIf="!plan.isActive"> (Inactive)</span>
                        <i *ngIf="!plan.notificationsEnabled" class="fas fa-bell-slash disabled-notifications-icon" pTooltip="Notifications are disabled for this plan."></i>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Planned Time</span>
                        {{plan.plannedDuration}}
                        <i *ngIf="plan.plannedMinutes > 0" class="fas fa-chart-pie clickable pie-chart-icon" pTooltip="Click to View Practice Time Per Category" (click)="showPracticeTimeByCategoryChart(plan)"></i>
                    </td>
                    <td class="center">
                        <span class="status-icons">
                            <i *ngIf="!enableAddingToPlan" class="fas fa-clone clickable copy-icon" pTooltip="Create a Copy" (click)="copyPracticePlan(plan)"></i>
                            <i *ngIf="!enableAddingToPlan" class="fas fa-edit clickable edit-icon" pTooltip="Edit" [routerLink]="['/practice/plans', plan.practicePlanId]"></i>
                            <i *ngIf="!enableAddingToPlan" class="fas fa-trash-alt clickable delete-icon" pTooltip="Delete" (click)="deletePracticePlan(plan, rowIndex)"></i>
                            <i *ngIf="enableAddingToPlan" class="far fa-plus-square clickable add-icon" pTooltip="Add to Plan" (click)="addPracticePlanToPlan(plan)"></i>
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="4">
                        {{getEmptyMessage('No practice plans.')}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="grid">
        <div class="col-12">
            <my-messages></my-messages>
        </div>
    </div>
</div>
<p-confirmDialog key="practice-plan-list-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
