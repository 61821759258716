// This content was generated from '/api/utility/swagger-extensions'

export type ModelsPracticeGetPracticeChartPracticeCharts = 1 | 2 | 3 | 4;

export const ModelsPracticeGetPracticeChartPracticeCharts = {
    PracticeTimePerTypeByMonth: 1 as ModelsPracticeGetPracticeChartPracticeCharts,
    PracticeTimePerCategoryByMonth: 2 as ModelsPracticeGetPracticeChartPracticeCharts,
    PracticeTimePerTypeByCategory: 3 as ModelsPracticeGetPracticeChartPracticeCharts,
    ImprovementPlan: 4 as ModelsPracticeGetPracticeChartPracticeCharts
};
