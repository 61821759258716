<div class="p-fluid">
    <div class="grid form-group">
        <div class="field col-12 lg:col-3">
            <span class="p-float-label">
                <p-dropdown [options]="courses" [(ngModel)]="courseId" styleClass="width-full" (onChange)="onCourseChanged();"></p-dropdown>
                <label>Course</label>
            </span>
        </div>
        <div class="field col-12 lg:col-2">
            <span class="p-float-label">
                <p-dropdown [options]="tees" [(ngModel)]="args.teeId" styleClass="width-full" (onChange)="onTeeChanged();"></p-dropdown>
                <label>Tee</label>
            </span>
        </div>
        <div class="col-12 lg:col-2">
            <p-selectButton [options]="holesPlannedOptions" [(ngModel)]="holesPlanned" (onChange)="onHolesPlannedChanged()"></p-selectButton>
        </div>
        <div class="field col-12 lg:col-1" *ngIf="showFrontCourseNines">
            <span class="p-float-label">
                <p-dropdown [options]="courseNines" [(ngModel)]="args.frontCourseNineId" styleClass="width-full" (onChange)="onCourseNineChanged();"></p-dropdown>
                <label>Front 9</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1" *ngIf="showBackCourseNines">
            <span class="p-float-label">
                <p-dropdown [options]="courseNines" [(ngModel)]="args.backCourseNineId" styleClass="width-full" (onChange)="onCourseNineChanged();"></p-dropdown>
                <label>Back 9</label>
            </span>
        </div>
    </div>
    <div>
        <my-projected-handicap-line-chart [data]="chartData"></my-projected-handicap-line-chart>
    </div>
</div>
