import { Component, Input, ViewChild, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TabMenu } from 'primeng/tabmenu';
import { MenuItem, SelectItem, ConfirmationService } from 'primeng/api';
import { BaseComponentDirective } from '../../ui/base-component.directive';
import { CoursesControllerProxy, RoundsControllerProxy } from '../../../shared/server-proxies';
import { CourseMapComponent } from '../../../shared/courses/map';
import { finalize } from 'rxjs/operators';

import {
    ModelsCoreCoursesCourse,
    ModelsCoreCoursesCourseMapArea,
    ModelsCoreCoursesMapMarkers,
    ModelsCoreGolfersGolfClubCategories,
    ModelsCoreRoundsApproachShotResults,
    ModelsCoreRoundsFairwayResults,
    ModelsCoreRoundsGolfRound,
    ModelsCoreRoundsGolfRoundGolferHoleStroke,
    ModelsCoreRoundsGolfRoundHoleLocation,
    ModelsCoreRoundsLieTypes,
    ModelsRoundsGetGolfRoundScorecardGolfRoundScorecard,
    ModelsRoundsGetGolfRoundScorecardLookupsGetGolfRoundScorecardLookupsResponse,
    ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
    ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole,
    ModelsWebApiRoundsSaveHoleLocationArgs,
    ModelsWebApiRoundsSaveHoleLocationModel,
    ModelsWebApiRoundsSaveScorecardStrokeArgs,
    ModelsWebApiRoundsSaveScorecardStrokeHoleModel
} from '../../../shared/swagger-codegen/models';

interface GolferMenuItem extends MenuItem {
    golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer;
}

interface GolferStats {
    holesCompleted: number;
    fairways: number;
    fairwayOpportunities: number;
    fairwayPercent: number;
    girs: number;
    girPercent: number;
    putts: number;
    onePutts: number;
    twoPutts: number;
    threePutts: number;
    penalties: number;
    strokesGainedOffTeeVsScratch: number;
    strokesGainedApproachVsScratch: number;
    strokesGainedAroundGreenVsScratch: number;
    strokesGainedPuttingVsScratch: number;
    strokesGainedTeeToGreenVsScratch: number;
    strokesGainedTotalVsScratch: number;
}

@Component({
    selector: 'my-scorecard-hole-strokes',
    templateUrl: './scorecard-hole-strokes.component.html',
    styleUrls: [
        './scorecard-hole-strokes.component.scss'
    ]
})
export class ScorecardHoleStrokesComponent extends BaseComponentDirective implements OnInit, OnChanges {
    constructor(
        private coursesProxy: CoursesControllerProxy,
        private roundsProxy: RoundsControllerProxy,
        private confirmationService: ConfirmationService) {
        super();
    }

    @ViewChild(CourseMapComponent) map: CourseMapComponent;
    @ViewChild('holeTabs') holeTabs: TabMenu;
    @Input() scorecard: ModelsRoundsGetGolfRoundScorecardGolfRoundScorecard;
    @Input() courseId: number;
    @Input() round: ModelsCoreRoundsGolfRound;
    @Input() realTimeHoleUpdates: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[] = [];
    @Input() scorecardLookups: ModelsRoundsGetGolfRoundScorecardLookupsGetGolfRoundScorecardLookupsResponse;
    holeMenuItems: MenuItem[] = [];
    golferMenuItems: GolferMenuItem[] = [];
    currentHoleMenuItem: MenuItem;
    currentGolferMenuItem: MenuItem;
    loading: boolean;
    course: ModelsCoreCoursesCourse;
    currentMapArea: ModelsCoreCoursesCourseMapArea;
    currentGolferStats: GolferStats;
    golfClubSelectItems: SelectItem[] = [];
    lieTypeSelectItems: SelectItem[] = [];
    shotTypeSelectItems: SelectItem[] = [];
    holeLocation: ModelsCoreRoundsGolfRoundHoleLocation;
    approachShotId: number;
    isEditingStrokes = false;

    private tempHoleStrokeId = -1;
    private currentHoleIndex: number;
    private myCurrentGolfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer;
    private myCurrentGolferHole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole;

    get currentGolferHole() {
        return this.myCurrentGolferHole;
    }

    set currentGolferHole(golferHole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        this.myCurrentGolferHole = this.sanitizeGolferHole(golferHole);
        this.currentGolferHoleChanged();
    }

    private get currentGolfer() {
        return this.myCurrentGolfer;
    }

    private set currentGolfer(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        this.myCurrentGolfer = golfer;
        this.currentGolferChanged();
    }
    
    ngOnInit() {
        
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes['scorecard']) {
            this.ensureHoleMenuItems();
            this.ensureGolferMenuItems();

            const i = this.getInitialHoleIndex();
            this.loadHoleByIndex(i);
        }

        if(changes['courseId']) {
            this.loadCourseMap();
        }

        if(changes['scorecardLookups']) {
            this.setSelectItems();
        }

        if(changes['realTimeHoleUpdates']) {
            this.applyRealTimeHoleUpdates();
        }
    }

    editStrokes() {
        this.isEditingStrokes = true;
    }

    cancelStrokeEditing() {
        this.isEditingStrokes = false;
    }

    shouldShowIsApproach(stroke: ModelsCoreRoundsGolfRoundGolferHoleStroke) {
        return stroke.fromLieType.lieTypeId !== ModelsCoreRoundsLieTypes.Green;
    }

    shouldShowGolfClub(stroke: ModelsCoreRoundsGolfRoundGolferHoleStroke) {
        return stroke.fromLieType.lieTypeId !== ModelsCoreRoundsLieTypes.Green;
    }

    shouldShowShotType(stroke: ModelsCoreRoundsGolfRoundGolferHoleStroke) {
        return stroke.fromLieType.lieTypeId !== ModelsCoreRoundsLieTypes.Green
            && stroke.fromLieType.lieTypeId !== ModelsCoreRoundsLieTypes.Sand
            && (stroke.distanceToHole <= 50 || this.isWedge(stroke.golfClub?.golfClubId));
    }

    saveStrokes() {
        const markers = this.map.getUpdateModel().courseMapMarkers;
        const model: ModelsWebApiRoundsSaveScorecardStrokeHoleModel = {
            holeId: this.currentGolferHole.holeId,
            ordinal: this.currentGolferHole.ordinal,
            strokes: []
        };

        let ordinal = 1;

        this.currentGolferHole.holeStrokes.forEach((holeStroke, i) => {
            const marker = i < markers.length ? markers[i] : undefined;

            holeStroke.isApproachShot = holeStroke.golfRoundGolferHoleStrokeId === this.approachShotId;
            holeStroke.ordinal = ordinal++;

            model.strokes.push({
                ordinal: i + 1,
                fromLieTypeId: holeStroke.fromLieType.lieTypeId,
                toLieTypeId: holeStroke.toLieType?.lieTypeId,
                distanceToHole: holeStroke.distanceToHole,
                latitude: marker?.latitude,
                longitude: marker?.longitude,
                golfClubId: this.shouldShowGolfClub(holeStroke) ? holeStroke.golfClub?.golfClubId : undefined,
                isApproachShot: holeStroke.isApproachShot,
                shotTypeId: this.shouldShowShotType(holeStroke) ? holeStroke.shotType?.shotTypeId : undefined
            });
        });

        const args: ModelsWebApiRoundsSaveScorecardStrokeArgs = {
            overwriteLocation: true
        };

        this.roundsProxy.updateScorecardStroke(this.round.golfRoundId, this.currentGolfer.golferId, model, args)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(response => {
                this.currentGolferHole.holeStrokes = response.body.golfRoundGolferHoleStrokes;
                this.isEditingStrokes = false;
            });

        const greenMarker = markers.find(m => m.mapMarker === ModelsCoreCoursesMapMarkers.Green);

        if(greenMarker) {
            this.holeLocation.latitude = greenMarker.latitude;
            this.holeLocation.longitude = greenMarker.longitude;

            const holeLocationModel: ModelsWebApiRoundsSaveHoleLocationModel = {
                latitude: greenMarker.latitude,
                longitude: greenMarker.longitude,
                locationAccuracy: 0
            };
            const holeLocationArgs: ModelsWebApiRoundsSaveHoleLocationArgs = {
                overwriteLocation: true
            };

            this.roundsProxy.saveHoleLocation(
                    this.scorecard.golfRoundId,
                    this.currentGolferHole.holeId,
                    holeLocationModel,
                    holeLocationArgs)
                .pipe(
                    finalize(() => this.taskCompleted()),
                    this.takeUntilUnsubscribed())
                .subscribe();
        }
    }

    deleteStroke(stroke: ModelsCoreRoundsGolfRoundGolferHoleStroke, index: number) {
        const message = `Are you sure you want to remove stroke #${index + 1}?`;

        this.confirmationService.confirm(
            {
                key: 'scorecard-hole-strokes-component',
                header: `Delete Stroke #${index + 1}?`,
                message: message,
                accept: () => {
                    if(stroke.golfRoundGolferHoleStrokeId > 0) {
                        this.taskStarted();
                        this.roundsProxy.deleteScorecardStroke(
                                this.round.golfRoundId,
                                this.currentGolfer.golferId,
                                stroke.golfRoundGolferHoleStrokeId)
                            .pipe(
                                finalize(() => this.taskCompleted()),
                                this.takeUntilUnsubscribed())
                            .subscribe(response => {
                                this.currentGolferHole.holeStrokes = response.body;
                                this.currentGolferHole.holeStrokes.splice(index, 1);
                                this.isEditingStrokes = false;
                            });
                    }
                    else {
                        this.currentGolferHole.holeStrokes.splice(index, 1);
                    }
                }
            });
    }

    addStroke() {
        const golfClub = this.currentGolfer.golfClubs.length > 0 ? this.currentGolfer.golfClubs[0] : undefined;
        this.currentGolferHole.holeStrokes.push({
            golfRoundGolferHoleStrokeId: this.tempHoleStrokeId--,
            fromLieType: {
                lieTypeId: ModelsCoreRoundsLieTypes.Fairway
            },
            toLieType: {
                lieTypeId: ModelsCoreRoundsLieTypes.Fairway
            },
            golfClub: golfClub,
            distanceToHole: 100
        } as any);

        this.sanitizeGolferHole(this.currentGolferHole);
    }

    addLocation(stroke: ModelsCoreRoundsGolfRoundGolferHoleStroke, index: number) {
        const position = this.map.addYardageMarker(undefined, index);
        stroke.latitude = position.lat();
        stroke.longitude = position.lng();
    }

    addHoleLocation() {
        const position = this.map.addGreenMarker();
        this.holeLocation = {
            holeId: this.currentGolferHole.holeId,
            latitude: position.lat(),
            longitude: position.lng()
        };
    }

    private sanitizeGolferHole(golferHole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        golferHole.holeStrokes = golferHole.holeStrokes || [];

        golferHole.holeStrokes.forEach(stroke => {
            stroke.golfClub = stroke.golfClub || {} as any;
            stroke.fromLieType = stroke.fromLieType || {} as any;
            stroke.toLieType = stroke.toLieType || {} as any;
            stroke.shotType = stroke.shotType || {} as any;
        });

        return golferHole;
    }

    private ensureHoleMenuItems() {
        if(this.holeMenuItems.length > 0) {
            return;
        }

        const holes = this.scorecard.course.front9.holes.concat(this.scorecard.course.back9?.holes || []);

        this.holeMenuItems = holes.map((hole, i) => {
            return {
                label: hole.displayText,
                command: () => {
                    this.loadHoleByIndex(i);
                }
            };
        });

        this.currentHoleIndex = holes.length > 0 ? 0 : -1;
        this.currentHoleMenuItem = this.holeMenuItems.length > 0 ? this.holeMenuItems[0] : undefined;
    }

    private ensureGolferMenuItems() {
        if(this.golferMenuItems.length > 0) {
            return;
        }

        const golfers = this.scorecard.golfers
            .filter(golfer => golfer.trackedMetrics.trackStrokesGained);

        this.golferMenuItems = golfers.map(golfer => {
            return {
                label: golfer.displayName,
                golfer: golfer,
                command: () => {
                    this.currentGolfer = golfer;
                }
            };
        });

        this.currentGolfer = golfers.length > 0 ? golfers[0] : undefined;
        this.currentGolferMenuItem = this.golferMenuItems.length > 0 ? this.golferMenuItems[0] : undefined;
    }

    private setSelectItems() {
        if(this.scorecardLookups) {
            this.lieTypeSelectItems = this.scorecardLookups.lieTypes.map(item => {
                return {
                    value: item.lieTypeId,
                    label: item.name
                };
            });

            this.shotTypeSelectItems = this.scorecardLookups.shotTypes.map(item => {
                return {
                    value: item.shotTypeId,
                    label: item.name
                };
            });
        }
    }

    private getInitialHoleIndex() {
        let lastIndexWithStrokes = -1;

        if(this.currentGolfer &&
            this.currentGolfer.holesPlayed > 0 &&
            this.currentGolfer.holesPlayed < this.currentGolfer.holesPlanned) {

            const golferHoles = this.currentGolfer.front9.concat(this.currentGolfer.back9 || []);

            golferHoles.forEach((hole, i) => {
                if(hole.holeStrokes?.length > 0) {
                    lastIndexWithStrokes = i;
                }
            });
        }

        return Math.max(0, lastIndexWithStrokes);
    }

    private loadCourseMap() {
        this.coursesProxy.getCourseMap(this.courseId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(response => {
                const course = response.body;
                this.course = course;
                this.loadCurrentMapArea();
            });
    }

    private loadHoleByIndex(index: number) {
        if(index < this.holeMenuItems.length) {
            this.currentHoleMenuItem = this.holeMenuItems[index];

            if(this.holeTabs) {
                this.holeTabs.tabChanged = true;
            }
        }

        this.currentHoleIndex = index;
        this.loadCurrentGolferHole();
    }

    private currentGolferHoleChanged() {
        this.loadCurrentMapArea();

        const approachShot = this.currentGolferHole.holeStrokes.find(stroke => stroke.isApproachShot);
        this.approachShotId = approachShot?.golfRoundGolferHoleStrokeId || -1;
        this.holeLocation = this.scorecard.holeLocations.find(location =>
            location.holeId === this.currentGolferHole.holeId);
    }

    private currentGolferChanged() {
        this.updateGolferMenuItemLabels();
        this.updateCurrentGolferStats();
        this.setGolfClubItems();
    }

    private loadCurrentGolferHole() {
        if(!this.currentGolfer || this.currentHoleIndex < 0) {
            return;
        }

        this.currentGolferHole = this.getGolferHoleByIndex(this.currentHoleIndex);
    }

    private getGolferHoleByIndex(index: number) {
        const holeCount = this.currentGolfer.front9.length + (this.currentGolfer.back9 || []).length;
        const golferHoles = index > 8 ? this.currentGolfer.back9 : this.currentGolfer.front9;
        return index < holeCount ? golferHoles[index % 9] : undefined;
    }

    private loadCurrentMapArea() {
        if(!this.currentGolferHole || !this.course) {
            return;
        }

        const courseMapAreas = this.course.courseMapAreas;
        this.currentMapArea = courseMapAreas.find(cma => cma?.hole?.holeId === this.currentGolferHole.holeId);
    }

    private applyRealTimeHoleUpdates() {
        const updateForCurrentHole = this.realTimeHoleUpdates
            .find(update => update.golfRoundGolferHoleId === this.currentGolferHole?.golfRoundGolferHoleId);
        
        if(updateForCurrentHole) {
            this.currentGolferHole.holeStrokes = updateForCurrentHole.holeStrokes.concat([]);
        }
        else {
            const nextGolferHole = this.getGolferHoleByIndex(this.currentHoleIndex + 1);
            const updateForNextHole = this.realTimeHoleUpdates
                .find(update => update.golfRoundGolferHoleId === nextGolferHole?.golfRoundGolferHoleId);

            if(updateForNextHole) {
                this.loadHoleByIndex(this.currentHoleIndex + 1);
            }
        }

        this.updateGolferMenuItemLabels();
    }

    private updateGolferMenuItemLabels() {
        this.golferMenuItems.forEach(item => {
            const golfer = item.golfer;
            const completedHoles = golfer.front9.concat(golfer.back9 || [])
                .filter(h => h.strokes > 0);
            let totalStrokes = 0;
            let totalPar = 0;

            if(completedHoles.length > 0) {
                completedHoles.forEach(hole => {
                    totalStrokes += hole.strokes;
                    totalPar += hole.par;
                });

                const score = totalStrokes - totalPar;
                let formattedScore = score.toString();

                if(score === 0) {
                    formattedScore = 'EVEN';
                }
                else if(score > 0) {
                    formattedScore = '+' + formattedScore;
                }

                let thruHoles = '';

                if(completedHoles.length < this.currentGolfer.holesPlanned) {
                    thruHoles = ` thru ${completedHoles.length}`;
                }

                item.label = `${golfer.displayName}: ${totalStrokes} (${formattedScore})${thruHoles}`;
            }
            else {
                item.label = golfer.displayName;
            }
        });
    }

    private updateCurrentGolferStats() {
        if(!this.currentGolfer) {
            return;
        }

        const completedHoles = this.currentGolfer.front9.concat(this.currentGolfer.back9 || [])
            .filter(h => h.strokes > 0);

        if(completedHoles.length > 0) {
            const stats: GolferStats = {
                holesCompleted: completedHoles.length,
                fairways: 0,
                fairwayOpportunities: 0,
                fairwayPercent: 0,
                girs: 0,
                girPercent: 0,
                putts: 0,
                onePutts: 0,
                twoPutts: 0,
                threePutts: 0,
                penalties: 0,
                strokesGainedOffTeeVsScratch: 0,
                strokesGainedApproachVsScratch: 0,
                strokesGainedAroundGreenVsScratch: 0,
                strokesGainedPuttingVsScratch: 0,
                strokesGainedTeeToGreenVsScratch: 0,
                strokesGainedTotalVsScratch: 0
            };
            
            completedHoles.forEach(hole => {
                if(hole.fairwayResultId === ModelsCoreRoundsFairwayResults.Hit) {
                    stats.fairways++;
                }

                if(hole.par > 3) {
                    stats.fairwayOpportunities++;
                }

                if(hole.approachShotResultId === ModelsCoreRoundsApproachShotResults.GIR) {
                    stats.girs++;
                }

                stats.putts += hole.putts;

                if(hole.putts === 1) {
                    stats.onePutts++;
                }
                else if(hole.putts === 2) {
                    stats.twoPutts++;
                }
                else if(hole.putts === 3) {
                    stats.threePutts++;
                }

                stats.penalties += hole.penaltyStrokes;
                stats.strokesGainedOffTeeVsScratch += hole.strokesGainedOffTeeVsScratch;
                stats.strokesGainedApproachVsScratch += hole.strokesGainedApproachVsScratch;
                stats.strokesGainedAroundGreenVsScratch += hole.strokesGainedAroundGreenVsScratch;
                stats.strokesGainedPuttingVsScratch += hole.strokesGainedPuttingVsScratch;
                stats.strokesGainedTeeToGreenVsScratch += hole.strokesGainedTeeToGreenVsScratch;
                stats.strokesGainedTotalVsScratch += hole.strokesGainedTotalVsScratch;
            });

            stats.fairwayPercent = stats.fairwayOpportunities > 0 ? stats.fairways / stats.fairwayOpportunities : 0;
            stats.girPercent = stats.girs / stats.holesCompleted;

            this.currentGolferStats = stats;
        }
        else {
            this.currentGolferStats = undefined;
        }
    }

    private setGolfClubItems() {
        this.golfClubSelectItems = this.currentGolfer.golfClubs.map(club => {
            return {
                value: club.golfClubId,
                label: club.abbreviation
            };
        });
    }

    private isWedge(golfClubId: number) {
        const golfClub = this.currentGolfer.golfClubs.find(club => club.golfClubId === golfClubId);
        return golfClub?.golfClubCategory?.golfClubCategoryId === ModelsCoreGolfersGolfClubCategories.Wedge;
    }
}
