import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface IObservableMessage {
    id: string;
    content?: any;
}

@Injectable()
export class MessagePubSubService {
    private subject = new Subject<IObservableMessage>();

    publishMessage(message: IObservableMessage) {
        this.subject.next(message);
    }

    clear() {
        this.subject.next(void 0);
    }

    observeMessages(): Observable<IObservableMessage> {
        return this.subject.asObservable();
    }
}
