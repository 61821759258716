import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { GolfersControllerProxy } from '../../shared/server-proxies';
import { GolferLookupService, IGolferSelectItem } from '../../shared/golfers';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import {
    ModelsGolfersGetHandicapsGetHandicapsResponse
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-handicaps',
    templateUrl: './handicaps-tab-view.component.html'
})
export class HandicapsTabViewComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private golferLookups: GolferLookupService,
        private router: Router,
        private currentRoute: ActivatedRoute) {
        super();
    }

    handicaps: ModelsGolfersGetHandicapsGetHandicapsResponse;
    golferId: number;
    golfers: IGolferSelectItem[];

    ngOnInit(): void {
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    const golferIdParam = params['golferId'];
                    this.golferLookups.getFriendSelectItems()
                        .pipe(this.takeUntilUnsubscribed())
                        .subscribe(
                            friends => {
                                this.golferId = parseInt(golferIdParam, 10) || this.golferLookups.defaultFriendId;
                                this.golfers = friends;
                                this.loadHandicaps();
                            });
                });
    }

    onGolferChanged() {
        this.router.navigate(['/golfers', this.golferId, 'handicaps']);
    }

    private loadHandicaps() {
        this.taskStarted();
        this.golfersProxy.getHandicaps(this.golferId)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.handicaps = response.body;
                });
    }
}
