<form *ngIf="dataIsLoaded" #scorecardForm="ngForm" autocomplete="off" novalidate="novalidate">
    <div>
        <span *ngIf="!showConfigOptions && !hasMultipleGolfers">{{selectedGolfer.displayName}}</span>
        <p-dropdown *ngIf="!showConfigOptions && hasMultipleGolfers" name="golfers" [(ngModel)]="selectedGolfRoundGolferId" [options]="golfers" required (onChange)="onGolferChanged()"></p-dropdown>
        <p-dropdown *ngIf="!showConfigOptions" name="holes" [(ngModel)]="selectedHoleKey" [options]="holes" styleClass="width2" panelStyleClass="width2" [style]="{'margin-left': '15px'}" required (onChange)="onHoleChanged()"></p-dropdown>
        <i *ngIf="!showConfigOptions" class="fas fa-cog options clickable" (click)="configureRound()" pTooltip="Scorecard Options"></i>
    </div>
    <div *ngIf="showConfigOptions">
        Keep Scorecard for:
        <ul>
            <li *ngFor="let golfer of scorecard.golfers">
                <p-checkbox name="golferOptions-{{golfer.golfRoundGolferId}}" [(ngModel)]="golfer.keepScorecard" binary="true" [label]="golfer.displayName" [disabled]="isCurrentGolfer(golfer)"></p-checkbox>
            </li>
        </ul>
        <button pButton label="Save" (click)="saveRoundConfiguration()"></button>
    </div>
    <div *ngIf="!showConfigOptions">
        <table class="p-datatable p-datatable-striped">
            <thead class="p-datatable-thead">
                <tr [ngClass]="getHeaderRowCssClass()">
                    <th colspan="2" (dblclick)="fillWithMockData(selectedGolfer, selectedHole)">
                        <span>{{selectedHole.description}}</span>
                    </th>
                    <th *ngIf="!isPhone" scope="col" (dblclick)="fillWithMockData(selectedGolfer, selectedHole)">Front</th>
                    <th *ngIf="!isPhone" scope="col" (dblclick)="fillWithMockData(selectedGolfer, selectedHole)">Back</th>
                    <th scope="col" (dblclick)="fillWithMockData(selectedGolfer, selectedHole, true)">Total</th>
                </tr>
            </thead>
            <tbody class="p-datatable-tbody">
                <tr ngClass="{{getRowCss('strokes')}}">
                    <th scope="row">Strokes</th>
                    <td>
                        <my-scorecard-entry name="strokes" [(ngModel)]="selectedHole.strokes" [startInEditMode]="true" [items]="strokeItems" [secondaryItems]="secondaryStrokeItems" required>
                            <ng-template let-item pTemplate="item">
                                {{item.label}}
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone" class="center">{{sumScore(selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{sumScore(selectedGolfer.back9)}}</td>
                    <td class="center">{{sumScore(selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
                <tr ngClass="{{getRowCss('fairway')}}" *ngIf="showBasicOrAdvancedFairways(selectedGolfer, selectedHole)">
                    <th scope="row">Fairway</th>
                    <td>
                        <my-scorecard-entry name="basicFairways" [(ngModel)]="selectedHole.fairwayResultId" [startInEditMode]="true" *ngIf="trackingBasicFairways(selectedGolfer)" [items]="basicFairwayItems" required>
                            <ng-template let-item pTemplate="item">
                                <i [ngClass]="getBasicFairwayCssClass(item)"></i>
                            </ng-template>
                        </my-scorecard-entry>
                        <my-scorecard-entry name="advancedFairwayResult" [(ngModel)]="selectedHole.fairwayResultId" [startInEditMode]="true" *ngIf="trackingAdvancedFairways(selectedGolfer)" [items]="fairwayResults" (selected)="onFairwayResultChanged(selectedGolfer, selectedHole)" required>
                            <ng-template let-item pTemplate="item">
                                <i [ngClass]="getFairwayResultCssClass(item)"></i>
                            </ng-template>
                            <ng-template let-item pTemplate="itemLabel">
                                {{item.label}}
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone" class="center">{{sumFairways(selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{sumFairways(selectedGolfer.back9)}}</td>
                    <td class="center">{{sumFairways(selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
                <tr ngClass="{{getRowCss('fairway')}}" *ngIf="showAdvancedFairways(selectedGolfer, selectedHole) && isMissedFairway(selectedHole)">
                    <th scope="row">Fairway<br />Miss</th>
                    <td>
                        <my-scorecard-entry name="advancedFairwayMiss" [(ngModel)]="selectedHole.fairwayMissId" [startInEditMode]="true" [items]="fairwayMisses" required>
                            <ng-template let-item pTemplate="item">
                                <i [ngClass]="getFairwayMissCssClass(item)"></i>
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone" class="center">{{sumFairways(selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{sumFairways(selectedGolfer.back9)}}</td>
                    <td class="center">{{sumFairways(selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
                <tr ngClass="{{getRowCss('teeShotClub')}}" *ngIf="trackingTeeShotClub(selectedGolfer)">
                    <th scope="row">Club Used<br />on Tee</th>
                    <td>
                        <my-scorecard-entry name="teeShotClub" [(ngModel)]="selectedHole.teeShotClubId" [startInEditMode]="true" [items]="getGolfClubSelectItems(selectedGolfer)" required (selected)="onTeeShotClubChanged(selectedGolfer, selectedHole)">
                            <ng-template let-item pTemplate="item">
                                {{item.label}}
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone">&nbsp;</td>
                    <td *ngIf="!isPhone">&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr ngClass="{{getRowCss('drivingDistance')}}" *ngIf="trackingDrivingDistance(selectedGolfer) && hasFairway(selectedHole)">
                    <th scope="row">Driving<br />Distance</th>
                    <td>
                        <input *ngIf="hasFairway(selectedHole)" name="driving-distance" type="number" class="center" min="0" pInputText #drivingDistance="ngModel" [(ngModel)]="selectedHole.drivingDistance" required>
                    </td>
                    <td *ngIf="!isPhone" class="center">{{avgDrivingDistance(selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{avgDrivingDistance(selectedGolfer.back9)}}</td>
                    <td class="center">{{avgDrivingDistance(selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
                <tr ngClass="{{getRowCss('gir')}}" *ngIf="showBasicOrAdvancedGIRs(selectedGolfer)">
                    <th scope="row">
                        <span *ngIf="trackingBasicGIRs(selectedGolfer)">GIR</span>
                        <span *ngIf="trackingAdvancedGIRs(selectedGolfer)">Approach<br />Shot</span>
                    </th>
                    <td>
                        <my-scorecard-entry name="basicGIR" [(ngModel)]="selectedHole.approachShotResultId" [startInEditMode]="true" *ngIf="trackingBasicGIRs(selectedGolfer)" [items]="basicGIRItems" required>
                            <ng-template let-item pTemplate="item">
                                <i [ngClass]="getBasicGIRCssClass(item)"></i>
                            </ng-template>
                        </my-scorecard-entry>
                        <my-scorecard-entry name="approachShotResult" [(ngModel)]="selectedHole.approachShotResultId" [startInEditMode]="true" *ngIf="trackingAdvancedGIRs(selectedGolfer)" [items]="approachShotResults" required (selected)="onApproachShotResultChanged(selectedGolfer, selectedHole)">
                            <ng-template let-item pTemplate="item">
                                <i [ngClass]="getApproachShotResultCssClass(item)"></i>
                            </ng-template>
                            <ng-template let-item pTemplate="itemLabel">
                                {{item.label}}
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone" class="center">{{sumGIRs(selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{sumGIRs(selectedGolfer.back9)}}</td>
                    <td class="center">{{sumGIRs(selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
                <tr ngClass="{{getRowCss('gir')}}" *ngIf="trackingAdvancedGIRs(selectedGolfer) && isMissedApproachShot(selectedHole)">
                    <th scope="row">
                        Approach<br />Shot<br />Miss
                    </th>
                    <td>
                        <my-scorecard-entry name="approachShotMiss" [(ngModel)]="selectedHole.approachShotMissId" [startInEditMode]="true" [items]="approachShotMisses" required>
                            <ng-template let-item pTemplate="item">
                                <i [ngClass]="getApproachShotMissCssClass(item)"></i>
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone">&nbsp;</td>
                    <td *ngIf="!isPhone">&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr ngClass="{{getRowCss('approachShotClub')}}" *ngIf="trackingApproachShotClub(selectedGolfer) && hasFairway(selectedHole) && approachShotWasAttempted(selectedHole)">
                    <th scope="row">Club Used<br />on Approach</th>
                    <td>
                        <my-scorecard-entry name="approachShotClub" [(ngModel)]="selectedHole.approachShotClubId" [startInEditMode]="true" [items]="getGolfClubSelectItems(selectedGolfer)" required>
                            <ng-template let-item pTemplate="item">
                                {{item.label}}
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone">&nbsp;</td>
                    <td *ngIf="!isPhone">&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr ngClass="{{getRowCss('approachShotDistance')}}" *ngIf="trackingApproachShotDistance(selectedGolfer) && approachShotWasAttempted(selectedHole)">
                    <th scope="row">Approach<br />Shot<br />Distance</th>
                    <td>
                        <input name="approach-shot-distance" type="number" class="center" min="1" pInputText #approachShotDistance="ngModel" [(ngModel)]="selectedHole.approachShotDistance" required (change)="onApproachShotDistanceChanged(selectedGolfer, selectedHole)">
                    </td>
                    <td *ngIf="!isPhone" class="center">{{avgApproachShotDistance(selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{avgApproachShotDistance(selectedGolfer.back9)}}</td>
                    <td class="center">{{avgApproachShotDistance(selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
                <tr ngClass="{{getRowCss('greenHitFromDistance')}}" *ngIf="trackingGreenHitFromDistance(selectedGolfer)">
                    <th scope="row">Green<br />Hit From<br />Distance</th>
                    <td>
                        <input name="green-hit-from-distance" type="number" class="center" min="1" pInputText #greenHitFromDistance="ngModel" [(ngModel)]="selectedHole.greenHitFromDistance" required>
                    </td>
                    <td *ngIf="!isPhone" class="center">{{avgGreenHitFromDistance(selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{avgGreenHitFromDistance(selectedGolfer.back9)}}</td>
                    <td class="center">{{avgGreenHitFromDistance(selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
                <tr ngClass="{{getRowCss('chip')}}" *ngIf="trackingChips(selectedGolfer)">
                    <th scope="row">Chip</th>
                    <td>
                        <my-scorecard-entry name="chips" [(ngModel)]="selectedHole.chipResultId" [items]="shortGameResults" required>
                            <ng-template let-item pTemplate="item">
                                <i *ngIf="!isShortGameResultNoAttempt(item)" [ngClass]="getShortGameResultCssClass(item)"></i>
                                <span *ngIf="isShortGameResultNoAttempt(item)">{{item.label}}</span>
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone" class="center">{{sumChips(selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{sumChips(selectedGolfer.back9)}}</td>
                    <td class="center">{{sumChips(selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
                <tr ngClass="{{getRowCss('pitch')}}" *ngIf="trackingPitches(selectedGolfer)">
                    <th scope="row">Pitch</th>
                    <td>
                        <my-scorecard-entry name="pitches" [(ngModel)]="selectedHole.pitchResultId" [items]="shortGameResults" required>
                            <ng-template let-item pTemplate="item">
                                <i *ngIf="!isShortGameResultNoAttempt(item)" [ngClass]="getShortGameResultCssClass(item)"></i>
                                <span *ngIf="isShortGameResultNoAttempt(item)">{{item.label}}</span>
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone" class="center">{{sumPitches(selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{sumPitches(selectedGolfer.back9)}}</td>
                    <td class="center">{{sumPitches(selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
                <tr ngClass="{{getRowCss('sand')}}" *ngIf="trackingSandShots(selectedGolfer)">
                    <th scope="row">Sand</th>
                    <td>
                        <my-scorecard-entry name="sand" [(ngModel)]="selectedHole.sandResultId" [items]="shortGameResults" required>
                            <ng-template let-item pTemplate="item">
                                <i *ngIf="!isShortGameResultNoAttempt(item)" [ngClass]="getShortGameResultCssClass(item)"></i>
                                <span *ngIf="isShortGameResultNoAttempt(item)">{{item.label}}</span>
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone" class="center">{{sumSandShots(selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{sumSandShots(selectedGolfer.back9)}}</td>
                    <td class="center">{{sumSandShots(selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
                <tr ngClass="{{getRowCss('upAndDownFrom1To10')}}" *ngIf="trackingUpAndDowns(selectedGolfer)">
                    <th scope="row">Up &amp; Down<br />from 1-10</th>
                    <td>
                        <my-scorecard-entry name="upAndDownResultFrom1To10" [(ngModel)]="selectedHole.upAndDownResultFrom1To10Id" [items]="upAndDownResults" required>
                            <ng-template let-item pTemplate="item">
                                <i *ngIf="!isUpAndDownResultNoAttempt(item)" [ngClass]="getUpAndDownResultCssClass(item)"></i>
                                <span *ngIf="isUpAndDownResultNoAttempt(item)">{{item.label}}</span>
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone" class="center">{{sumUpAndDownsFrom1To10(selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{sumUpAndDownsFrom1To10(selectedGolfer.back9)}}</td>
                    <td class="center">{{sumUpAndDownsFrom1To10(selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
                <tr ngClass="{{getRowCss('upAndDownFrom11To30')}}" *ngIf="trackingUpAndDowns(selectedGolfer)">
                    <th scope="row">Up &amp; Down<br />from 11-30</th>
                    <td>
                        <my-scorecard-entry name="upAndDownResultFrom11To30" [(ngModel)]="selectedHole.upAndDownResultFrom11To30Id" [items]="upAndDownResults" required>
                            <ng-template let-item pTemplate="item">
                                <i *ngIf="!isUpAndDownResultNoAttempt(item)" [ngClass]="getUpAndDownResultCssClass(item)"></i>
                                <span *ngIf="isUpAndDownResultNoAttempt(item)">{{item.label}}</span>
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone" class="center">{{sumUpAndDownsFrom11To30(selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{sumUpAndDownsFrom11To30(selectedGolfer.back9)}}</td>
                    <td class="center">{{sumUpAndDownsFrom11To30(selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
                <tr ngClass="{{getRowCss('upAndDownFrom31To50')}}" *ngIf="trackingUpAndDowns(selectedGolfer)">
                    <th scope="row">Up &amp; Down<br />from 31-50</th>
                    <td>
                        <my-scorecard-entry name="upAndDownResultFrom31To50" [(ngModel)]="selectedHole.upAndDownResultFrom31To50Id" [items]="upAndDownResults" required>
                            <ng-template let-item pTemplate="item">
                                <i *ngIf="!isUpAndDownResultNoAttempt(item)" [ngClass]="getUpAndDownResultCssClass(item)"></i>
                                <span *ngIf="isUpAndDownResultNoAttempt(item)">{{item.label}}</span>
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone" class="center">{{sumUpAndDownsFrom31To50(selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{sumUpAndDownsFrom31To50(selectedGolfer.back9)}}</td>
                    <td class="center">{{sumUpAndDownsFrom31To50(selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
            <tr ngClass="{{getRowCss('putts')}}" *ngIf="trackingPutts(selectedGolfer)">
                    <th scope="row">Putts</th>
                    <td>
                        <my-scorecard-entry name="putts" [(ngModel)]="selectedHole.putts" [startInEditMode]="true" [items]="puttItems" [secondaryItems]="secondaryPuttItems" required (selected)="onPuttsChanged(selectedGolfer, selectedHole)">
                            <ng-template let-item pTemplate="item">
                                {{item.label}}
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone" class="center">{{sumPutts(selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{sumPutts(selectedGolfer.back9)}}</td>
                    <td class="center">{{sumPutts(selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
                <tr ngClass="{{getRowCss('firstPuttDistance')}}" *ngIf="trackingFirstPuttDistance(selectedGolfer)">
                    <th scope="row">First<br />Putt<br />Distance</th>
                    <td>
                        <my-scorecard-entry name="firstPuttDistance" [(ngModel)]="selectedHole.firstPuttDistance" [startInEditMode]="true" [items]="firstPuttDistanceItems" [secondaryItems]="secondaryPuttingDistanceItems" (selected)="onFirstPuttDistanceChanged(selectedGolfer, selectedHole)" required>
                            <ng-template let-item pTemplate="item">
                                {{item.label}}
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone" class="center">{{avgFirstPuttDistance (selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{avgFirstPuttDistance (selectedGolfer.back9)}}</td>
                    <td class="center">{{avgFirstPuttDistance (selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
                <tr ngClass="{{getRowCss('lastPuttDistance')}}" *ngIf="trackingLastPuttDistance(selectedGolfer)">
                    <th scope="row">Last<br />Putt<br />Distance</th>
                    <td>
                        <my-scorecard-entry name="lastPuttDistance" [(ngModel)]="selectedHole.lastPuttDistance" [startInEditMode]="true" [items]="lastPuttDistanceItems" [secondaryItems]="secondaryPuttingDistanceItems" (selected)="onLastPuttDistanceChanged(selectedGolfer, selectedHole)" required>
                            <ng-template let-item pTemplate="item">
                                {{item.label}}
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone" class="center">{{avgLastPuttDistance (selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{avgLastPuttDistance (selectedGolfer.back9)}}</td>
                    <td class="center">{{avgLastPuttDistance (selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
                <tr ngClass="{{getRowCss('penaltyStrokes')}}" *ngIf="trackingPenaltyStrokes(selectedGolfer)">
                    <th scope="row">Penalties</th>
                    <td>
                        <my-scorecard-entry name="penalties" [(ngModel)]="selectedHole.penaltyStrokes" [items]="penaltyItems" required>
                            <ng-template let-item pTemplate="item">
                                {{item.label}}
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone" class="center">{{sumPenaltyStrokes(selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{sumPenaltyStrokes(selectedGolfer.back9)}}</td>
                    <td class="center">{{sumPenaltyStrokes(selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
                <tr ngClass="{{getRowCss('mulligans')}}" *ngIf="trackingMulligans(selectedGolfer)">
                    <th scope="row">Mulligans</th>
                    <td>
                        <my-scorecard-entry name="mulligans" [(ngModel)]="selectedHole.mulligans" [items]="mulliganItems" required>
                            <ng-template let-item pTemplate="item">
                                {{item.label}}
                            </ng-template>
                        </my-scorecard-entry>
                    </td>
                    <td *ngIf="!isPhone" class="center">{{sumMulligans(selectedGolfer.front9)}}</td>
                    <td *ngIf="!isPhone" class="center">{{sumMulligans(selectedGolfer.back9)}}</td>
                    <td class="center">{{sumMulligans(selectedGolfer.front9, selectedGolfer.back9)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <p *ngIf="!showConfigOptions">
        <button type="submit" pButton label="Save" (click)="saveScorecard()" [disabled]="isLoading || !scorecardForm.form.valid"></button>
        <my-messages #scorecardMessages></my-messages>
    </p>
</form>
