// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreRoundsApproachShotResults = 1 | 2 | 3 | 4 | 5 | 6;

export const ModelsCoreRoundsApproachShotResults = {
    GIR: 1 as ModelsCoreRoundsApproachShotResults,
    Hit: 2 as ModelsCoreRoundsApproachShotResults,
    OkMiss: 3 as ModelsCoreRoundsApproachShotResults,
    BadMiss: 4 as ModelsCoreRoundsApproachShotResults,
    Penalty: 5 as ModelsCoreRoundsApproachShotResults,
    NoAttempt: 6 as ModelsCoreRoundsApproachShotResults
};
