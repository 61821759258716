<div class="p-fluid">
    <div class="grid form-group">
        <div class="field col-12 lg:col-2" *ngIf="shouldShowGolferSelector">
            <span class="p-float-label">
                <p-dropdown [options]="golfers" [(ngModel)]="golferId" (onChange)="onGolferChanged()"></p-dropdown>
                <label>Golfer</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1">
            <span class="p-float-label">
                <p-dropdown [options]="yearsPracticed" [(ngModel)]="year" (onChange)="onYearChanged();"></p-dropdown>
                <label>Year</label>
            </span>
        </div>
        <div class="field col-12 lg:col-2" [ngStyle]="getActivityFilterStyle()">
            <div class="p-inputgroup">
                <span class="p-float-label">
                    <input type="text" pInputText #activityFilter>
                    <label>Activity Filter</label>
                </span>
                <span class="p-inputgroup-addon clickable" (click)="clearFilter()" pTooltip="Clear Filter">
                    <i class="fas fa-times-circle"></i>
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="!summaryForScoreHistoryChart">
        <p-table [value]="filteredActivitySummaries" [paginator]="filteredActivitySummaries.length > 30" [pageLinks]="10" [rows]="30" [loading]="isLoading" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md" (sortFunction)="customTableSort($event)" [customSort]="true" sortField="totalPracticeMinutes" [sortOrder]="-1">
            <ng-template pTemplate="header">
                <tr>
                    <th class="width15 left" pSortableColumn="practiceActivity.name,practiceActivityVariation.name">
                        Activity
                        <p-sortIcon field="practiceActivity.name,practiceActivityVariation.name"></p-sortIcon>
                    </th>
                    <th class="left">Variation</th>
                    <th class="width2">First<br />Performed</th>
                    <th class="width2">Last<br />Performed</th>
                    <th class="width3" pSortableColumn="totalSessions">
                        Total<br/>Sessions
                        <p-sortIcon field="totalSessions"></p-sortIcon>
                    </th>
                    <th class="width3" pSortableColumn="totalPracticeMinutes">
                        Total<br/>Practice<br/>Time
                        <p-sortIcon field="totalPracticeMinutes"></p-sortIcon>
                    </th>
                    <th class="width2">Avg<br />Practice<br />Time</th>
                    <th class="width2">Goal<br />Achieved %</th>
                    <th class="width2">Avg<br />Score</th>
                    <th class="width2">Best<br />Score</th>
                    <th class="width2">Best<br />Score<br />Last<br />Achieved</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-summary>
                <tr>
                    <td class="left">
                        <span class="p-column-title">Activity</span>
                        {{summary.practiceActivity.name}}
                    </td>
                    <td class="left">
                        <span class="p-column-title">Variation</span>
                        {{summary.practiceActivityVariation?.name}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">First Performed</span>
                        <span>{{summary.firstPerformed | date:'shortDate'}}</span>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Last Performed</span>
                        <span>{{summary.lastPerformed | date:'shortDate'}}</span>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Total Sessions</span>
                        {{summary.totalSessions | number}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Total Practice Time</span>
                        {{summary.totalPracticeDuration}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Avg Practice Time</span>
                        {{summary.averageElapsedDuration}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">% of Goals Achieved</span>
                        <span *ngIf="summary.totalGoals > 0">{{summary.percentGoalsAchieved | percent}}</span>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Avg Score</span>
                        {{summary.averageScore | number:'1.2-2'}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Best Score</span>
                        <a *ngIf="summary.practiceActivity.practiceScoreQualifierId > 0" class="clickable" pTooltip="View Score History" (click)="showScoreHistory(summary)">{{summary.bestScore | number}}</a>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Best Score Last Achieved</span>
                        <span *ngIf="summary.practiceActivity.practiceScoreQualifierId > 0">{{summary.mostRecentDateOfBestScore | date:'shortDate'}}</span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="6">
                        {{getEmptyMessage(noActivitySummaryMessage)}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div *ngIf="summaryForScoreHistoryChart">
        <h1>{{chartTitle}}</h1>
        <div>
            <a class="clickable" (click)="hideScoreHistory()">
                <i class="fas fa-long-arrow-alt-left"></i>
                Return to Activity Summary
            </a>
        </div>
        <my-practice-activity-score-history-chart [practiceActivity]="summaryForScoreHistoryChart.practiceActivity"
                                                  [practiceActivityVariation]="summaryForScoreHistoryChart.practiceActivityVariation"
                                                  [year]="year">
        </my-practice-activity-score-history-chart>
    </div>
</div>
