import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { RoundsControllerProxy } from '../../server-proxies';
import { BaseComponentDirective } from '../../ui/base-component.directive';

import {
    ModelsCoreRoundsGolfRound
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-scorecard-dialog',
    templateUrl: './scorecard-dialog.component.html'
})
export class ScorecardDialogComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private roundsProxy: RoundsControllerProxy,
        private dynamicDialogConfig: DynamicDialogConfig) {
        super();
    }

    round: ModelsCoreRoundsGolfRound;
    readOnly: boolean;
    showLegend: boolean;

    ngOnInit() {
        this.readOnly = this.dynamicDialogConfig.data.readOnly || true;
        this.showLegend = this.dynamicDialogConfig.data.showLegend || true;

        if(this.dynamicDialogConfig.data.golfRoundId) {
            this.roundsProxy.getRound(this.dynamicDialogConfig.data.golfRoundId)
                .pipe(this.takeUntilUnsubscribed())
                .subscribe(
                    response => {
                        this.round = response.body;
                    });
        }
    }
}
