import { Injectable } from '@angular/core';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ConfigurationService } from '../core/configuration.service';
import { SessionService } from '../auth/session.service';
import { SignalRHubConnectionWrapper } from './signalr-hub-connection-wrapper';

@Injectable()
export class SignalRHubConnectionFactory {
    constructor(private configurationService: ConfigurationService, private session: SessionService) {

    }

    createHubConnection(hubUrl: string): SignalRHubConnectionWrapper {
        const logLevel = this.configurationService.isDebug ? LogLevel.Trace : LogLevel.Warning;
        const connection = new HubConnectionBuilder()
            .withUrl(
                hubUrl,
                {
                    accessTokenFactory: () => this.session.getToken()
                })
            .configureLogging(logLevel)
            .build();

        return new SignalRHubConnectionWrapper(connection);
    }
}
