import { Component } from '@angular/core';
import { TooltipItem } from 'chart.js';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';

@Component({
    selector: 'my-improvement-plan-bar-chart',
    template: '<p-chart type="bar" [data]="data" [options]="options" responsive="true"></p-chart>'
})
export class ImprovementPlanBarChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'bar');

        const yAxis = this.options.scales.y;
        yAxis.min = 0;
        yAxis.max = 5.25;
        yAxis.ticks.stepSize = 1;
        yAxis.ticks.callback = this.getTick.bind(this);
        this.options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);
    }

    private getTick(value: any) {
        return value > 5 ? '' : value.toString();
    }
    
    private getTooltipLabel(tooltipItem: TooltipItem<'bar'>) {
        const dataSet = tooltipItem.dataset;
        const data = dataSet.data as number[];
        const tooltips = (dataSet as any).tooltips;

        const lines = tooltips ? tooltips[tooltipItem.dataIndex].split('|') : [data[tooltipItem.dataIndex].toFixed(1)];
        lines.unshift(dataSet.label);

        return lines;
    }
}
