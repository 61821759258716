<div class="card">
    <h1>{{title}}</h1>
    <form *ngIf="practiceSession" #practiceSessionForm="ngForm" autocomplete="off" novalidate="novalidate">
        <div class="grid form-group">
            <div class="field col-12 lg:col-4">
                {{practiceSession.name}}
            </div>
            <div class="col-12">
                <p-table [value]="practiceSession.practiceSessionActivities" [loading]="isLoading" styleClass="p-datatable-striped">
                    <ng-template pTemplate="caption">
                        Session Activities
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="reorder-column"></th>
                            <th class="width3">Category</th>
                            <th class="width13">Activity</th>
                            <th>Variation</th>
                            <th class="width2">Planned<br />Minutes</th>
                            <th class="width2">Average<br />Time</th>
                            <th class="width2">Goal</th>
                            <th *ngIf="practiceSession.started" class="width2">Score</th>
                            <th *ngIf="practiceSession.started" class="width2">
                                Elapsed<br />Minutes
                            </th>
                            <th class="width1"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-sessionActivity let-rowIndex="rowIndex">
                        <tr [pReorderableRow]="rowIndex">
                            <td>
                                <i class="fa fa-bars clickable" pReorderableRowHandle></i>
                            </td>
                            <td class="left">
                                <span class="p-column-title">Category</span>
                                {{sessionActivity.practiceActivity.practiceCategory.name}}
                            </td>
                            <td class="left">
                                <span class="p-column-title">Activity</span>
                                {{sessionActivity.practiceActivity.name}}
                            </td>
                            <td class="left">
                                <span class="p-column-title">Variation</span>
                                <span *ngIf="sessionActivity.practiceActivityVariationSelectItems">
                                    <p-dropdown #practiceActivityVariation="ngModel" styleClass="width-full" name="practiceActivityVariation-{{rowIndex}}" [options]="sessionActivity.practiceActivityVariationSelectItems" [(ngModel)]="sessionActivity.practiceActivityVariation.practiceActivityVariationId" required (onChange)="onActivityVariationChanged(sessionActivity)"></p-dropdown>
                                    <my-validation-message [model]="practiceActivityVariation" type="required"></my-validation-message>
                                </span>
                                <span *ngIf="sessionActivity.practiceActivityVariation"><br />{{sessionActivity.practiceActivityVariation.description}}</span>
                            </td>
                            <td class="center">
                                <span class="p-column-title">Planned<br />Minutes</span>
                                <input *ngIf="!practiceSession.started" #plannedMinutes="ngModel" name="plannedMinutes-{{rowIndex}}" type="number" min="0" pInputText [(ngModel)]="sessionActivity.plannedMinutes">
                                <span *ngIf="practiceSession.started">{{sessionActivity.plannedMinutes}}</span>
                            </td>
                            <td class="center">
                                <span class="p-column-title">Average<br />Time</span>
                                {{getAverageDuration(sessionActivity)}}
                            </td>
                            <td class="center">
                                <span *ngIf="sessionActivity.practiceActivityVariation && sessionActivity.practiceActivityVariation.goal" class="p-column-title">Goal</span>
                                <span *ngIf="sessionActivity.practiceActivityVariation && sessionActivity.practiceActivityVariation.goal">
                                    <span>{{sessionActivity.practiceActivityVariation.goal}}</span><br />
                                    <p-checkbox pTooltip="Achieved Goal?" binary="true" name="isGoalAchieved-{{rowIndex}}" [(ngModel)]="sessionActivity.isGoalAchieved"></p-checkbox>
                                </span>
                            </td>
                            <td class="center" *ngIf="practiceSession.started">
                                <span *ngIf="shouldShowScore(sessionActivity)" class="p-column-title">Score</span>
                                <input *ngIf="shouldShowScore(sessionActivity)" #score="ngModel" type="number" name="score-{{rowIndex}}" class="center" pInputText [(ngModel)]="sessionActivity.score">
                            </td>
                            <td class="center" *ngIf="practiceSession.started">
                                <span class="p-column-title">Elapsed Minutes</span>
                                <input #elapsedMinutes="ngModel" type="number" name="elapsedMinutes-{{rowIndex}}" class="center" min="1" pInputText [(ngModel)]="sessionActivity.elapsedMinutes" required>
                                <my-validation-message [model]="elapsedMinutes" type="required"></my-validation-message>
                                <i *ngIf="sessionActivityIsInProgress(sessionActivity)" class="fas fa-calendar-check help-icon" [pTooltip]="getSessionActivityInProgressTooltip(sessionActivity)"></i>
                            </td>
                            <td>
                                <span class="status-icons">
                                    <i class="fas fa-trash-alt clickable delete-icon" pTooltip="Delete" (click)="deleteSessionActivity(sessionActivity, rowIndex)"></i>
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td [attr.colspan]="practiceSession.completed ? 9 : 7">
                                {{getEmptyMessage('No session activities.')}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="col-12">
                <button *ngIf="!isStarting" type="submit" pButton label="Save" (click)="save()" [disabled]="isLoading  || !practiceSessionForm.form.valid"></button>
                <button *ngIf="isStarting" type="submit" pButton label="Start Session" (click)="startSession()" [disabled]="isLoading  || !practiceSessionForm.form.valid"></button>
            </div>
            <div class="col-12">
                <my-messages></my-messages>
            </div>
        </div>
    </form>
</div>
<p-confirmDialog key="practice-session-detail-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
