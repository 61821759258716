// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreRoundsFairwayResults = 1 | 2 | 3 | 4;

export const ModelsCoreRoundsFairwayResults = {
    Hit: 1 as ModelsCoreRoundsFairwayResults,
    OkMiss: 2 as ModelsCoreRoundsFairwayResults,
    BadMiss: 3 as ModelsCoreRoundsFairwayResults,
    Penalty: 4 as ModelsCoreRoundsFairwayResults
};
