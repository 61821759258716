import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { statisticsRoutes } from './statistics.routes';
import { ChartsModule } from '../charts/charts.module';
import { SharedModule } from '../shared/shared.module';
import { AchievementHistoryComponent } from './achievements/history/achievement-history.component';
import { AchievementsListComponent } from './achievements/list/achievements-list.component';
import { GoalsListComponent } from './goals/list/goals-list.component';
import { GoalDetailComponent } from './goals/detail/goal-detail.component';
import { HeadToHeadStatisticsComponent } from './head-to-head/head-to-head-statistics.component';
import { StatisticsTabViewComponent } from './statistics-tab-view.component';
import { StatisticsListComponent } from './list/statistics-list.component';
import { StatisticChartsComponent } from './charts/statistic-charts.component';
import { StatisticSnapshotChartDialogComponent } from './list/statistic-snapshot-chart-dialog/statistic-snapshot-chart-dialog.component';

@NgModule({
    imports: [
        statisticsRoutes,
        ChartsModule,
        CommonModule,
        FormsModule,
        SharedModule
    ],
    declarations: [
        AchievementHistoryComponent,
        AchievementsListComponent,
        StatisticSnapshotChartDialogComponent,
        GoalsListComponent,
        GoalDetailComponent,
        HeadToHeadStatisticsComponent,
        StatisticsListComponent,
        StatisticsTabViewComponent,
        StatisticChartsComponent
    ],
    exports: [
        AchievementHistoryComponent,
        AchievementsListComponent,
        StatisticChartsComponent,
        StatisticsListComponent
    ],
    providers: [
    ]
})
export class StatisticsModule {

}
