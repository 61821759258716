import { Injectable } from '@angular/core';

/* eslint-disable no-var */
declare var window: Window;

@Injectable()
export class ScrollService {
    scrollToTop() {
        this.scroll({ top: 0 });
    }

    scrollTo(top: number, left: number, behavior: ScrollBehavior = 'smooth') {
        this.scroll({ top: top, left: left, behavior: behavior });
    }

    scroll(options: ScrollToOptions) {
        options.behavior = options.behavior || 'smooth';
        window.scroll(options);
    }
}
