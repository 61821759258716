import { Injectable } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import { QueryString } from '../util';
import { tap } from 'rxjs/operators';

import {
    ModelsCoreGolfersGolfer,
    ModelsCoreRoundsGolfRound,
    ModelsCoreRoundsGolfRoundGolferHoleStroke,
    ModelsRoundsCreateGolfRoundCreateGolfRoundResponse,
    ModelsRoundsGetGolfRoundChartsGolfRoundCharts,
    ModelsRoundsGetGolfRoundLookupsGetGolfRoundLookupsResponse,
    ModelsRoundsGetGolfRoundScorecardGolfRoundScorecard,
    ModelsRoundsGetGolfRoundScorecardLookupsGetGolfRoundScorecardLookupsResponse,
    ModelsRoundsGetGolfRoundSummaryModelsGolferSummary,
    ModelsRoundsSaveGolfRoundScorecardSaveGolfRoundScorecardResponse,
    ModelsRoundsSaveGolfRoundStrokeSaveGolfRoundStrokeResponse,
    ModelsStatisticsGetChartGetChartResponse,
    ModelsWebApiRoundsCalculateStatsArgs,
    ModelsWebApiRoundsCreateRoundModel,
    ModelsWebApiRoundsGetRoundArgs,
    ModelsWebApiRoundsGetScorecardArgs,
    ModelsWebApiRoundsGetStatisticsChartArgs,
    ModelsWebApiRoundsJoinRoundModel,
    ModelsWebApiRoundsSaveHoleLocationModel,
    ModelsWebApiRoundsSaveHoleLocationArgs,
    ModelsWebApiRoundsSaveRoundModel,
    ModelsWebApiRoundsSaveScorecardModel,
    ModelsWebApiRoundsSaveScorecardStrokeArgs,
    ModelsWebApiRoundsSaveScorecardStrokeHoleModel
} from '../../shared/swagger-codegen/models';

@Injectable()
export class RoundsControllerProxy {
    private baseUrl = '/api/rounds';

    constructor(private httpClient: HttpClientWrapperService) {
    }

    getRounds() {
        return this.httpClient.get<ModelsCoreRoundsGolfRound[]>(this.baseUrl)
            .pipe(
                tap(
                    response => {
                        response.body.forEach(
                            round => {
                                round.teeTime = new Date(round.teeTime);
                            });
                    }));
    }

    createRound(round: ModelsWebApiRoundsCreateRoundModel) {
        return this.httpClient.post<ModelsRoundsCreateGolfRoundCreateGolfRoundResponse>(this.baseUrl, round);
    }

    joinRound(round: ModelsWebApiRoundsJoinRoundModel) {
        return this.httpClient.post<ModelsRoundsCreateGolfRoundCreateGolfRoundResponse>(`${this.baseUrl}/existing`, round);
    }
    
    startRound(golfRoundId: number) {
        return this.httpClient.put<any>(`${this.baseUrl}/${golfRoundId}/start`);
    }

    getRound(golfRoundId: number, args?: ModelsWebApiRoundsGetRoundArgs) {
        const queryString = QueryString.fromObject(args);

        return this.httpClient.get<ModelsCoreRoundsGolfRound>(`${this.baseUrl}/${golfRoundId}${queryString}`)
            .pipe(
                tap(
                    response => {
                        if(response.body) {
                            response.body.teeTime = new Date(response.body.teeTime);
                        }
                    }));
    }

    updateRound(golfRoundId: number, model: ModelsWebApiRoundsSaveRoundModel) {
        return this.httpClient.put<any>(`${this.baseUrl}/${golfRoundId}`, model);
    }

    getScorecard(golfRoundId: number, args?: ModelsWebApiRoundsGetScorecardArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsRoundsGetGolfRoundScorecardGolfRoundScorecard>(
                `${this.baseUrl}/${golfRoundId}/scorecard${queryString}`)
            .pipe(
                tap(
                    response => {
                        response.body.golfers.forEach(golfer => {
                            if(golfer.projectedEndTime) {
                                golfer.projectedEndTime = new Date(golfer.projectedEndTime);
                            }
                        });
                    }));
    }

    getSummary(golfRoundId: number) {
        return this.httpClient.get<ModelsRoundsGetGolfRoundSummaryModelsGolferSummary[]>(`${this.baseUrl}/${golfRoundId}/summary`);
    }

    updateScorecardHole(golfRoundId: number, model: ModelsWebApiRoundsSaveScorecardModel) {
        return this.updateScorecard(golfRoundId, [model]);
    }

    updateScorecard(golfRoundId: number, models: ModelsWebApiRoundsSaveScorecardModel[]) {
        return this.httpClient.put<ModelsRoundsSaveGolfRoundScorecardSaveGolfRoundScorecardResponse>(
            `${this.baseUrl}/${golfRoundId}/scorecard`, models);
    }

    updateScorecardStroke(golfRoundId: number,
        golferId: number,
        models: ModelsWebApiRoundsSaveScorecardStrokeHoleModel,
        args?: ModelsWebApiRoundsSaveScorecardStrokeArgs) {

        const queryString = QueryString.fromObject(args);
        return this.httpClient.put<ModelsRoundsSaveGolfRoundStrokeSaveGolfRoundStrokeResponse>(
            `${this.baseUrl}/${golfRoundId}/golfers/${golferId}/hole-strokes${queryString}`,
            models);
    }

    deleteScorecardStroke(golfRoundId: number, golferId: number, golfRoundGolferHoleStrokeId: number) {
        return this.httpClient.delete<ModelsCoreRoundsGolfRoundGolferHoleStroke[]>(
            `${this.baseUrl}/${golfRoundId}/golfers/${golferId}/hole-strokes/${golfRoundGolferHoleStrokeId}`);
    }

    getLookups() {
        return this.httpClient.get<ModelsRoundsGetGolfRoundLookupsGetGolfRoundLookupsResponse>(`${this.baseUrl}/lookups`);
    }

    getScorecardLookups() {
        return this.httpClient.get<ModelsRoundsGetGolfRoundScorecardLookupsGetGolfRoundScorecardLookupsResponse>(
            `${this.baseUrl}/scorecard-lookups`);
    }

    getCharts(golfRoundId: number) {
        return this.httpClient.get<ModelsRoundsGetGolfRoundChartsGolfRoundCharts>(`${this.baseUrl}/${golfRoundId}/charts`);
    }

    getChart(golfRoundId: number, args?: ModelsWebApiRoundsGetStatisticsChartArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsStatisticsGetChartGetChartResponse>(
            `${this.baseUrl}/${golfRoundId}/statistics/chart${queryString}`);
    }

    getGolfRoundGolfers(golfRoundId: number) {
        return this.httpClient.get<ModelsCoreGolfersGolfer[]>(`${this.baseUrl}/${golfRoundId}/golfers`);
    }

    deleteGolfRound(golfRoundId: number, golfRoundGolferId: number) {
        return this.httpClient.delete<any>(`${this.baseUrl}/${golfRoundId}/golfers/${golfRoundGolferId}`);
    }

    calculateStats(args?: ModelsWebApiRoundsCalculateStatsArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.post<ModelsStatisticsGetChartGetChartResponse>(
            `${this.baseUrl}/calculate-statistics${queryString}`);
    }

    getIncompleteGolfRoundsByFriends() {
        return this.httpClient.get<ModelsCoreRoundsGolfRound[]>(
            `${this.baseUrl}/incomplete-by-friends`);
    }

    saveHoleLocation(golfRoundId: number,
        holeId: number,
        models: ModelsWebApiRoundsSaveHoleLocationModel,
        args?: ModelsWebApiRoundsSaveHoleLocationArgs) {

        const queryString = QueryString.fromObject(args);
        return this.httpClient.put<any>(
            `${this.baseUrl}/${golfRoundId}/holes/${holeId}/location${queryString}`,
            models);
    }
}
