<div class="grid">
    <div class="col-12" *ngIf="golfClubs">
        <p-table [value]="golfClubs" [loading]="isLoading" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md" selectionMode="single">
            <ng-template pTemplate="caption">
                Golf Clubs
                <i class="fas fa-plus-square new-item-icon clickable" (click)="addClub()" pTooltip="Add New Club"></i>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th rowspan="2" class="width2 left">Category</th>
                    <th rowspan="2" class="width1">Abbreviation</th>
                    <th rowspan="2" class="left">Name</th>
                    <th colspan="2">
                        On Course Strokes Gained vs Scratch
                        <i class="fas fa-info-circle help-icon" pTooltip="These stats are based on the shots captured in your 20 most recent rounds using the strokes gained scorecard."></i>
                    </th>
                    <th colspan="5">
                        On Course Distance Percentiles
                        <i class="fas fa-info-circle help-icon" pTooltip="These percentiles are based on the distance of shots captured in your 20 most recent rounds using the strokes gained scorecard.<br /><br />The distance calculation only considers stock shots from the Tee, Fairway or Rough that don't result in a penalty or recovery situation."></i>
                    </th>
                    <th colspan="4">
                        Yardage
                        <i class="fas fa-chart-line line-chart clickable" pTooltip="Chart Golf Club Yardages" (click)="showYardageChart()"></i>
                    </th>
                    <th colspan="2">Dispersion</th>
                    <th rowspan="2" class="width2"></th>
                </tr>
                <tr>
                    <th class="border-left width2">Per Shot</th>
                    <th class="width2">Total</th>
                    <th class="border-left width3">#<br />Included / Total</th>
                    <th class="width2">50th</th>
                    <th class="width2">75th</th>
                    <th class="width2">90th</th>
                    <th class="width2">100th</th>
                    <th class="border-left width2">{{golfer.golfClubSecondaryYardage3Label}}</th>
                    <th class="width2">{{golfer.golfClubSecondaryYardage2Label}}</th>
                    <th class="width2">{{golfer.golfClubSecondaryYardage1Label}}</th>
                    <th class="width2 border-right">{{golfer.golfClubYardageLabel}}</th>
                    <th class="border-left width2">Lateral</th>
                    <th class="border-right width2">Distance</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-club let-rowIndex="rowIndex">
                <tr [ngClass]="{ 'inactive-row': !club.isActive }">
                    <td class="left">
                        <span class="p-column-title">Category</span>
                        <span>{{club.golfClubCategory.name}}</span>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Abbreviation</span>
                        <span>{{club.abbreviation}}</span>
                    </td>
                    <td class="left">
                        <span class="p-column-title">Name</span>
                        <span>{{club.name}}</span>
                        <span *ngIf="!club.isActive"> (Inactive)</span>
                    </td>
                    <td class="center">
                        <ng-container *ngIf="club.strokesGainedPerShotVsScratch">
                            <span class="p-column-title">SG Per Shot vs Scratch</span>
                            <span>{{club.strokesGainedPerShotVsScratch | number:'1.3-3'}}</span>
                        </ng-container>
                    </td>
                    <td class="center">
                        <ng-container *ngIf="club.totalStrokesGainedVsScratch">
                            <span class="p-column-title">Total SG vs Scratch</span>
                            <span>{{club.totalStrokesGainedVsScratch | number:'1.3-3'}}</span>
                        </ng-container>
                    </td>
                    <td class="center">
                        <ng-container *ngIf="club.onCourseStrokes >= 0">
                            <span class="p-column-title">#</span>
                            <span>
                                {{club.onCourseStrokesForYardage | number}} / {{club.onCourseStrokes | number}}
                            </span>
                        </ng-container>
                    </td>
                    <td class="center">
                        <ng-container *ngIf="club.yardageOnCoursePercentile50 > 0">
                            <span class="p-column-title">Yardage On Course - 50th Percentile</span>
                            <span>{{club.yardageOnCoursePercentile50 | number}}</span>
                        </ng-container>
                    </td>
                    <td class="center">
                        <ng-container *ngIf="club.yardageOnCoursePercentile75 > 0">
                            <span class="p-column-title">Yardage On Course - 75th Percentile</span>
                            <span>{{club.yardageOnCoursePercentile75 | number}}</span>
                        </ng-container>
                    </td>
                    <td class="center">
                        <ng-container *ngIf="club.yardageOnCoursePercentile90 > 0">
                            <span class="p-column-title">Yardage On Course - 90th Percentile</span>
                            <span>{{club.yardageOnCoursePercentile90 | number}}</span>
                        </ng-container>
                    </td>
                    <td class="center">
                        <ng-container *ngIf="club.yardageOnCoursePercentile100 > 0">
                            <span class="p-column-title">Yardage On Course - 100th Percentile</span>
                            <span>{{club.yardageOnCoursePercentile100 | number}}</span>
                        </ng-container>
                    </td>
                    <td class="center">
                        <ng-container *ngIf="club.secondaryYardage3 > 0">
                            <span class="p-column-title">{{golfer.golfClubSecondaryYardage3Label}} Yardage</span>
                            <span>{{club.secondaryYardage3 | number}}</span>
                        </ng-container>
                    </td>
                    <td class="center">
                        <ng-container *ngIf="club.secondaryYardage2 > 0">
                            <span class="p-column-title">{{golfer.golfClubSecondaryYardage2Label}} Yardage</span>
                            <span>{{club.secondaryYardage2 | number}}</span>
                        </ng-container>
                    </td>
                    <td class="center">
                        <ng-container *ngIf="club.secondaryYardage1 > 0">
                            <span class="p-column-title">{{golfer.golfClubSecondaryYardage1Label}} Yardage</span>
                            <span>{{club.secondaryYardage1 | number}}</span>
                        </ng-container>
                    </td>
                    <td class="center">
                        <ng-container *ngIf="club.yardage > 0">
                            <span class="p-column-title">{{golfer.golfClubYardageLabel}} Yardage</span>
                            <span>{{club.yardage | number}}</span>
                        </ng-container>
                    </td>
                    <td class="center">
                        <ng-container *ngIf="club.lateralDispersion > 0">
                            <span class="p-column-title">Lateral Dispersion</span>
                            <span>{{club.lateralDispersion | number}}</span>
                        </ng-container>
                    </td>
                    <td class="center">
                        <ng-container *ngIf="club.distanceDispersion > 0">
                            <span class="p-column-title">Distance Dispersion</span>
                            <span>{{club.distanceDispersion | number}}</span>
                        </ng-container>
                    </td>
                    <td class="center">
                        <span class="status-icons">
                            <i class="fas fa-edit clickable edit-icon" pTooltip="Edit" (click)="editClub(club)"></i>
                            <i class="fas fa-trash-alt clickable delete-icon" pTooltip="Delete" (click)="deleteClub(club, rowIndex)"></i>
                            <i *ngIf="false && !isRowActive(rowIndex)" class="fas fa-plus clickable details-icon" pTooltip="View Details" (click)="showDetails(rowIndex)"></i>
                            <i *ngIf="false && isRowActive(rowIndex)" class="fas fa-minus clickable details-icon" pTooltip="Close Details" (click)="hideDetails()"></i>
                            <i *ngIf="club.details" class="fas fa-info-circle help-icon" pTooltip="{{club.details}}" [escape]="false"></i>
                        </span>
                    </td>
                </tr>
                <tr *ngIf="isRowActive(rowIndex)">
                    <td colspan="5">
                        <div class="col-12 lg:col-3">
                            <p-fieldset legend="Shaft">
                                <div class="grid form-group">
                                    <div class="field col-12">
                                        <span class="p-float-label">
                                            <input #shaftBrand="ngModel" name="shaftBrand" [(ngModel)]="club.shaftBrand"
                                                maxlength="30" disabled pInputText class="width10">
                                            <label>Brand</label>
                                        </span>
                                    </div>
                                    <div class="field col-12">
                                        <span class="p-float-label">
                                            <input #shaftModel="ngModel" name="shaftModel" [(ngModel)]="club.shaftModel"
                                                maxlength="30" disabled pInputText class="width10">
                                            <label>Model</label>
                                        </span>
                                    </div>
                                    <div class="field col-12">
                                        <span class="p-float-label">
                                            <input #shaftFlex="ngModel" name="shaftFlex" [(ngModel)]="club.shaftFlex"
                                                maxlength="20" disabled pInputText class="width10">
                                            <label>Flex</label>
                                        </span>
                                    </div>
                                </div>
                            </p-fieldset>
                        </div>
                        <div class="col-12 lg:col-3">
                            <p-fieldset legend="Head">
                                <div class="grid form-group">
                                    <div class="field col-12">
                                        <span class="p-float-label">
                                            <input #headBrand="ngModel" name="headBrand" [(ngModel)]="club.headBrand"
                                                maxlength="30" disabled pInputText class="width10">
                                            <label>Brand</label>
                                        </span>
                                    </div>
                                    <div class="field col-12">
                                        <span class="p-float-label">
                                            <input #headModel="ngModel" name="headModel" [(ngModel)]="club.headModel"
                                                maxlength="30" disabled pInputText class="width10">
                                            <label>Model</label>
                                        </span>
                                    </div>
                                </div>
                            </p-fieldset>
                        </div>
                        <div class="col-12 lg:col-3">
                            <p-fieldset legend="Grip">
                                <div class="grid form-group">
                                    <div class="field col-12">
                                        <span class="p-float-label">
                                            <input #gripBrand="ngModel" name="gripBrand" [(ngModel)]="club.gripBrand"
                                                maxlength="30" disabled pInputText class="width10">
                                            <label>Brand</label>
                                        </span>
                                    </div>
                                    <div class="field col-12">
                                        <span class="p-float-label">
                                            <input #gripModel="ngModel" name="gripModel" [(ngModel)]="club.gripModel"
                                                maxlength="30" disabled pInputText class="width10">
                                            <label>Model</label>
                                        </span>
                                    </div>
                                    <div class="field col-12">
                                        <span class="p-float-label">
                                            <textarea #gripInstructions="ngModel" rows="3" name="gripInstructions"
                                                pInputTextarea [(ngModel)]="club.gripInstructions" disabled></textarea>
                                            <label>Instructions</label>
                                        </span>
                                    </div>
                                </div>
                            </p-fieldset>
                        </div>
                        <div class="col-12 lg:col-3">
                            <p-fieldset legend="Specifications">
                                <div class="grid form-group">
                                    <div class="field col-12">
                                        <span class="p-float-label">
                                            <input #length="ngModel" name="length" [(ngModel)]="club.length" type="number"
                                                pInputText disabled class="width10">
                                            <label>Length</label>
                                        </span>
                                    </div>
                                    <div class="field col-12">
                                        <span class="p-float-label">
                                            <input #lie="ngModel" disabled name="lie" [(ngModel)]="club.lie" type="number"
                                                pInputText class="width10">
                                            <label>Lie</label>
                                        </span>
                                    </div>
                                    <div class="field col-12">
                                        <span class="p-float-label">
                                            <input #loft="ngModel" disabled name="loft" [(ngModel)]="club.loft" type="number"
                                                pInputText class="width10">
                                            <label>Loft</label>
                                        </span>
                                    </div>
                                    <div class="field col-12">
                                        <span class="p-float-label">
                                            <input #swingWeight="ngModel" name="swingWeight" [(ngModel)]="club.swingWeight"
                                                maxlength="5" disabled pInputText class="width10">
                                            <label>Swing Weight</label>
                                        </span>
                                    </div>
                                </div>
                            </p-fieldset>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="15">
                        {{getEmptyMessage('No golf clubs.')}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p-confirmDialog key="golf-club-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
    </div>
</div>
