import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedCoursesModule } from '../courses/shared-courses.module';
import { ScorecardComponent } from './scorecard/scorecard.component';
import { ScorecardCalculatorService } from './scorecard/scorecard-calculator.service';
import { ScorecardHoleStrokesComponent } from './scorecard/scorecard-hole-strokes.component';
import { ScorecardDialogComponent } from './scorecard-dialog/scorecard-dialog.component';
import { ScorecardDialogService } from './scorecard-dialog/scorecard-dialog.service';
import { UIModule } from '../ui/ui.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UIModule,
        SharedCoursesModule
    ],
    exports: [
        ScorecardComponent,
        ScorecardHoleStrokesComponent
    ],
    declarations: [
        ScorecardComponent,
        ScorecardHoleStrokesComponent,
        ScorecardDialogComponent
    ],
    providers: [
        ScorecardCalculatorService,
        ScorecardDialogService
    ]
})
export class SharedRoundsModule {

}
