import { Component, OnInit, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { CoursesControllerProxy, ReferenceControllerProxy } from '../../../shared/server-proxies';
import { MessagesComponent } from '../../../shared/ui/messages/messages.component';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';

import {
    ModelsCoreCoursesCourse,
    ModelsCoreSharedState,
    ModelsWebApiCoursesSaveCourseModel
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-course-detail',
    templateUrl: './course-detail.component.html'
})
export class CourseDetailComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private coursesProxy: CoursesControllerProxy,
        private referenceControllerProxy: ReferenceControllerProxy) {
        super();
    }

    @Input() messages: MessagesComponent;
    @Input() course: ModelsCoreCoursesCourse;
    states: SelectItem[] = [];
    timeZones: SelectItem[] = [];

    ngOnInit(): void {
        this.referenceControllerProxy.getStateSelectItems()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.states = response;
                });
        this.referenceControllerProxy.getTimeZoneSelectItems()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.timeZones = response;
                });
    }

    onNameChanged() {
        if(this.course && this.course.name.length <= 20) {
            if(!this.course.shortName || this.course.shortName.length === 0) {
                this.course.shortName = this.course.name;
            }
        }
    }

    onStateChanged() {
        const item = this.states.find(s => s.value === this.course.stateCode);

        if(item) {
            const state: ModelsCoreSharedState = (item as any).model;

            if(state.timeZoneIds && state.timeZoneIds.length > 0 && state.timeZoneIds.indexOf(this.course.timeZoneId) === -1) {
                this.course.timeZoneId = state.timeZoneIds[state.timeZoneIds.length - 1];
            }
        }
    }

    saveCourse() {
        this.taskStarted();
        return this.coursesProxy.saveCourse(this.course.courseId, this.course as ModelsWebApiCoursesSaveCourseModel)
            .pipe(
                finalize(
                    () => {
                        this.taskCompleted();
                        this.messages.clearInMilliseconds(5000);
                    }),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    this.messages.showSuccess('Changes have been saved.');
                },
                error => {
                    this.messages.showError(`An error occurred. (${error.status})`);
                });
    }
}
