import { Component, Input } from '@angular/core';
import { TooltipItem } from 'chart.js';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';

@Component({
    selector: 'my-short-game-proximity-line-chart',
    template: '<p-chart type="line" [data]="data" [options]="options" responsive="true"></p-chart>'
})
export class ShortGameProximityLineChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'line');

        this.options.plugins.tooltip.callbacks.title = this.getTooltipTitle.bind(this);
        this.options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);
        this.options.scales.y.ticks.callback = this.getTick.bind(this);
    }

    @Input()
    shotTypeLabel = 'Shots';

    private getTooltipTitle() {
        return null as string;
    }

    private getTooltipLabel(tooltipItem: TooltipItem<'line'>) {
        const dataset = tooltipItem.dataset;
        const data = dataset.data as number[];

        const proximity = tooltipItem.label;
        const golfer = dataset.label;
        const percent = data[tooltipItem.dataIndex];

        return `${golfer}: ${percent.toFixed(2)} % of ${this.shotTypeLabel} are ${proximity}`;
    }

    private getTick(value: any) {
        return `${value}%`;
    }
}
