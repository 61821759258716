import { Component } from '@angular/core';
import { TooltipItem } from 'chart.js';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';

@Component({
    selector: 'my-miss-tendency-radar-chart',
    template: '<p-chart type="radar" [data]="data" [options]="options" responsive="true"></p-chart>'
})
export class MissTendencyRadarChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'radar');

        this.options.plugins.tooltip.callbacks.title = this.getTooltipTitle.bind(this);
        this.options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);

        const radialOptions = this.options as any;
        radialOptions.scale.ticks.callback = this.getTick.bind(this);
        radialOptions.scale.ticks.stepSize = 10;
        radialOptions.scale.beginAtZero = true;
    }

    private getTooltipTitle() {
        return null;
    }

    private getTooltipLabel(tooltipItem: TooltipItem<'radar'>) {
        const dataset = tooltipItem.dataset;
        const data = dataset.data as number[];

        const miss = tooltipItem.label;
        const golfer = dataset.label;
        const percent = data[tooltipItem.dataIndex];

        return `${golfer}: ${percent.toFixed(2)} % of Misses are ${miss}`;
    }

    private getTick(value: any) {
        return `${value}%`;
    }
}
