// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreGolfersGolfClubCategories = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export const ModelsCoreGolfersGolfClubCategories = {
    Driver: 1 as ModelsCoreGolfersGolfClubCategories,
    FairwayWood: 2 as ModelsCoreGolfersGolfClubCategories,
    Hybrid: 3 as ModelsCoreGolfersGolfClubCategories,
    LongIron: 4 as ModelsCoreGolfersGolfClubCategories,
    MidIron: 5 as ModelsCoreGolfersGolfClubCategories,
    ShortIron: 6 as ModelsCoreGolfersGolfClubCategories,
    Wedge: 7 as ModelsCoreGolfersGolfClubCategories,
    Putter: 8 as ModelsCoreGolfersGolfClubCategories
};
