import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { ChartService } from './chart.service';
import { CoursesPlayedPieChartComponent } from './courses-played-pie-chart.component';
import { FairwaysPerParBarChartComponent } from './fairways-per-par-bar-chart.component';
import { GIRsPerParBarChartComponent } from './girs-per-par-bar-chart.component';
import { GolfClubYardageLineChartComponent } from './golf-club-yardage-line-chart.component';
import { HandicapHistoryLineChartComponent } from './handicap-history-line-chart.component';
import { ImprovementPlanBarChartComponent } from './improvement-plan-bar-chart.component';
import { MissTendencyRadarChartComponent } from './miss-tendency-radar-chart.component';
import { PenaltiesPerParBarChartComponent } from './penalties-per-par-bar-chart.component';
import { PracticeActivityHistoryLineChartComponent } from './practice-activity-history-line-chart.component';
import { PracticeActivityScoreHistoryLineChartComponent } from './practice-activity-score-history-line-chart.component';
import { PracticeTimeByCategoryStackedBarChartComponent } from './practice-time-by-category-stacked-bar-chart.component';
import { PracticeTimeByMonthStackedBarChartComponent } from './practice-time-by-month-stacked-bar-chart.component';
import { PracticeTimeByCategoryPieChartComponent } from './practice-time-by-category-pie-chart.component';
import { ProjectedHandicapLineChartComponent } from './projected-handicap-line-chart.component';
import { ProximityByShotLineChartComponent } from './proximity-by-shot-line-chart.component';
import { PuttCountBarChartComponent } from './putt-count-bar-chart.component';
import { PuttingByStartingDistanceLineChartComponent } from './putting-by-starting-distance-line-chart.component';
import { PuttingPieChartComponent } from './putting-pie-chart.component';
import { RecentRoundsBarChartComponent } from './recent-rounds-bar-chart.component';
import { RunningScoreLineChartComponent } from './running-score-line-chart.component';
import { RunningStrokesLineChartComponent } from './running-strokes-line-chart.component';
import { ScoreByHoleLineChartComponent } from './score-by-hole-line-chart.component';
import { ScoreNameBarChartComponent } from './score-name-bar-chart.component';
import { ScoringAveragePerParBarChartComponent } from './scoring-average-per-par-bar-chart.component';
import { ScoringPieChartComponent } from './scoring-pie-chart.component';
import { ShortGameProximityLineChartComponent } from './short-game-proximity-line-chart.component';
import { StatisticSnapshotChartComponent } from './statistic-snapshot-chart.component';
import { StrokesGainedBarChartComponent } from './strokes-gained-bar-chart.component';
import { StrokesGainedHandicapBarChartComponent } from './strokes-gained-handicap-bar-chart.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        CoursesPlayedPieChartComponent,
        FairwaysPerParBarChartComponent,
        StatisticSnapshotChartComponent,
        GIRsPerParBarChartComponent,
        GolfClubYardageLineChartComponent,
        HandicapHistoryLineChartComponent,
        ImprovementPlanBarChartComponent,
        MissTendencyRadarChartComponent,
        PenaltiesPerParBarChartComponent,
        PracticeActivityHistoryLineChartComponent,
        PracticeActivityScoreHistoryLineChartComponent,
        PracticeTimeByCategoryPieChartComponent,
        PracticeTimeByCategoryStackedBarChartComponent,
        PracticeTimeByMonthStackedBarChartComponent,
        ProjectedHandicapLineChartComponent,
        ProximityByShotLineChartComponent,
        PuttCountBarChartComponent,
        PuttingByStartingDistanceLineChartComponent,
        PuttingPieChartComponent,
        RecentRoundsBarChartComponent,
        RunningScoreLineChartComponent,
        RunningStrokesLineChartComponent,
        ScoreByHoleLineChartComponent,
        ScoreNameBarChartComponent,
        ScoringAveragePerParBarChartComponent,
        ScoringPieChartComponent,
        ShortGameProximityLineChartComponent,
        StrokesGainedBarChartComponent,
        StrokesGainedHandicapBarChartComponent
    ],
    exports: [
        CoursesPlayedPieChartComponent,
        FairwaysPerParBarChartComponent,
        StatisticSnapshotChartComponent,
        GIRsPerParBarChartComponent,
        GolfClubYardageLineChartComponent,
        HandicapHistoryLineChartComponent,
        ImprovementPlanBarChartComponent,
        MissTendencyRadarChartComponent,
        PenaltiesPerParBarChartComponent,
        PracticeActivityHistoryLineChartComponent,
        PracticeActivityScoreHistoryLineChartComponent,
        PracticeTimeByCategoryPieChartComponent,
        PracticeTimeByCategoryStackedBarChartComponent,
        PracticeTimeByMonthStackedBarChartComponent,
        ProjectedHandicapLineChartComponent,
        ProximityByShotLineChartComponent,
        PuttCountBarChartComponent,
        PuttingByStartingDistanceLineChartComponent,
        PuttingPieChartComponent,
        RecentRoundsBarChartComponent,
        RunningScoreLineChartComponent,
        RunningStrokesLineChartComponent,
        ScoreByHoleLineChartComponent,
        ScoreNameBarChartComponent,
        ScoringAveragePerParBarChartComponent,
        ScoringPieChartComponent,
        ShortGameProximityLineChartComponent,
        StrokesGainedBarChartComponent,
        StrokesGainedHandicapBarChartComponent
    ],
    providers: [
        ChartService
    ]
})
export class ChartsModule {

}
