/// <reference types="google.maps" />
import { MapMarkerWrapper } from './map-marker-wrapper';

import LatLng = google.maps.LatLng;
import Map = google.maps.Map;
import MarkerLabel = google.maps.MarkerLabel;
import SymbolPath = google.maps.SymbolPath;


export class LabelMapMarker extends MapMarkerWrapper {
    constructor(map: Map, position: LatLng, text = '', color = '#ffa726') {
        super(
            undefined,
            {
                map: map,
                icon: {
                    path: SymbolPath.CIRCLE
                },
                position: position,
                zIndex: 999,
                label: {
                    text: text,
                    color: color,
                    fontSize: '18px',
                    fontWeight: 'bold'
                }
            }
        );
    }
    
    get text() {
        return (this.nativeMarker.getLabel() as MarkerLabel).text;
    }

    set text(text: string) {
        // We have to use setLabel() because just setting the text property
        // of the label doesn't cause the map to repaint the text.
        const label = this.nativeMarker.getLabel() as MarkerLabel;
        label.text = text;
        this.nativeMarker.setLabel(label);
    }
}
