/// <reference types="google.maps" />
import {
    ModelsCoreCoursesMapMarkers
} from '../../swagger-codegen/models';

import { greenMarkerIcon } from './map-marker-icons';
import { MapMarkerWrapper } from './map-marker-wrapper';

import Icon = google.maps.Icon;
import LatLng = google.maps.LatLng;
import Map = google.maps.Map;
import Point = google.maps.Point;
import Size = google.maps.Size;

export class GreenMapMarker extends MapMarkerWrapper {
    constructor(map: Map, position: LatLng) {
        super(
            ModelsCoreCoursesMapMarkers.Green,
            {
                map: map,
                position: position,
                draggable: true,
                icon: GreenMapMarker.createIcon(),
                title: 'Green'
            }
        );
    }

    static createIcon() {
        return {
            anchor: new Point(10, 32),
            scaledSize: new Size(32, 32),
            url: greenMarkerIcon
        } as Icon;
    }
}
