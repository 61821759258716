import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesModule } from './messages/messages.module';
import { PrimeNgModule } from './primeng/primeng.module';
import { SelectAllDirective } from './directives/select-all.directive';
import { TextToHtmlPipe } from './pipes/text-to-html.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ScrollService } from './scroll.service';
import { ValidationMessageComponent } from './validation-message.component';

@NgModule(
    {
        declarations: [
            SafeHtmlPipe,
            TextToHtmlPipe,
            SelectAllDirective,
            ValidationMessageComponent
        ],
        providers: [
            ScrollService
        ],
        imports: [
            CommonModule,
            MessagesModule,
            PrimeNgModule
        ],
        exports: [
            MessagesModule,
            PrimeNgModule,
            SafeHtmlPipe,
            TextToHtmlPipe,
            SelectAllDirective,
            ValidationMessageComponent
        ]
    })
export class UIModule {
}
