<div class="p-fluid">
    <div class="grid form-group" *ngIf="isLoaded">
        <div class="field col-12 lg:col-2">
            <span class="p-float-label">
                <p-dropdown name="statCategory" [(ngModel)]="selectedCategoryId" [options]="categories" required (onChange)="onCategoryChanged()"></p-dropdown>
                <label>Category</label>
            </span>
        </div>
        <div class="field col-12 lg:col-2" *ngIf="shouldShowGolfRoundTypes">
            <span class="p-float-label">
                <p-dropdown name="golfRoundTypes" [(ngModel)]="args.golfRoundType" [options]="golfRoundTypes" required (onChange)="onGolfRoundTypeChanged()"></p-dropdown>
                <label>Golf Round Type</label>
            </span>
        </div>
        <div class="field col-12 lg:col-2" *ngIf="shouldShowComparisons">
            <span class="p-float-label">
                <p-dropdown name="compare" [(ngModel)]="args.comparison" [options]="comparisons" required (onChange)="onComparisonChanged()"></p-dropdown>
                <label>Comparison</label>
            </span>
        </div>
        <div class="field col-12 lg:col-2" *ngIf="shouldShowGolfers">
            <span class="p-float-label">
                <p-dropdown [options]="golfers" [(ngModel)]="golferId" (onChange)="onGolferChanged()"></p-dropdown>
                <label>Golfer</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1" *ngIf="shouldShowYears">
            <span class="p-float-label">
                <p-dropdown [options]="yearsPlayed" [(ngModel)]="args.year" (onChange)="onYearChanged();"></p-dropdown>
                <label>Year</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1" *ngIf="shouldShowQuarters">
            <span class="p-float-label">
                <p-dropdown [options]="quarters" [(ngModel)]="args.quarter" (onChange)="onQuarterChanged();"></p-dropdown>
                <label>Quarter</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1" *ngIf="shouldShowMonths">
            <span class="p-float-label">
                <p-dropdown [options]="months" [(ngModel)]="args.month" (onChange)="onMonthChanged();"></p-dropdown>
                <label>Month</label>
            </span>
        </div>
    </div>

    <div>
        <p-table [value]="statistics" [loading]="isLoading" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md">
            <ng-template pTemplate="caption">
                Statistics
                <a [href]="excelExportUrl">
                    <i class="fas fa-file-excel export-icon clickable" pTooltip="Export to Excel"></i>
                </a>
            </ng-template>
            <ng-template pTemplate="body" let-stat let-rowIndex="rowIndex">
                <tr class="rowgroup-header" *ngIf="shouldShowStatRowGroupHeader(rowIndex)">
                    <td class="width10 left">
                        <span>{{stat.category.name}}</span>
                    </td>
                    <td class="center" *ngFor="let columnHeader of response?.columnHeaders">
                        {{columnHeader}}
                    </td>
                </tr>
                <tr>
                    <td class="left">
                        {{stat.name}}
                        <span class="status-icons right">
                            <i *ngIf="isFavoriteStatistic(stat) && stat.statisticId" (click)="removeFavoriteStatistic(stat)" class="fas fa-heart favorites-icon" pTooltip="Remove from Favorites"></i>
                            <i *ngIf="!isFavoriteStatistic(stat) && stat.statisticId" (click)="addFavoriteStatistic(stat)" class="far fa-heart favorites-icon" pTooltip="Add to Favorites"></i>
                            <i *ngIf="stat.description" class="fas fa-info-circle help-icon" pTooltip="{{stat.description}}"></i>
                            <i class="fas fa-chart-line line-chart clickable" pTooltip="Chart this Statistic Over Time" (click)="showSnapshotChart(stat)"></i>
                        </span>
                    </td>
                    <td class="center" *ngFor="let columnHeader of response?.columnHeaders; index as columnIndex">
                        <span class="p-column-title">{{columnHeader}}</span>
                        <span [ngClass]="getAdornmentClass(stat.columnValues[columnIndex])">
                            {{stat.columnValues[columnIndex].formattedValue}}
                            <i class="fas fa-award" *ngIf="shouldShowRanking(stat.columnValues[columnIndex])" [ngClass]="getRankingClass(stat.columnValues[columnIndex])" pTooltip="{{stat.columnValues[columnIndex].formattedRank}}"></i>
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="response?.columnHeaders?.length + 1">
                        {{getEmptyMessage('No statistics.')}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
