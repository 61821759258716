<div class="grid">
    <div class="col-12" style="overflow: auto" *ngIf="hasOneOrMoreNines">
        <form #scorecardForm="ngForm" autocomplete="off" novalidate="novalidate">
            <table class="p-datatable table scorecard with-bottom-margin" *ngIf="scorecard">
                <thead class="p-datatable-thead">
                    <tr class="course-nine-row">
                        <th class="transparent" colspan="2"></th>
                        <th scope="col" *ngFor="let courseNine of scorecard.courseNines" colspan="10">
                            <input *ngIf="hasThreeOrMoreNines" name="courseNine-{{courseNine.courseNineId}}" required pInputText [(ngModel)]="courseNine.name">
                            <span *ngIf="!hasThreeOrMoreNines">{{courseNine.name}}</span>
                        </th>
                        <th *ngIf="hasTwoOrMoreNines" class="transparent"></th>
                    </tr>
                </thead>
                <tbody class="p-datatable-tbody">
                    <tr class="hole-row">
                        <th scope="col" class="width4">Tee Color</th>
                        <th class="width6" scope="col">
                            Tee Name
                            <i class="far fa-plus-square clickable" pTooltip="Add Tee" (click)="addTee()"></i>
                        </th>
                        <td *ngFor="let hole of getHoles(0)">{{hole.number}}</td>
                        <td class="in-out">{{getOutText()}}</td>
                        <td *ngFor="let hole of getHoles(1)">{{hole.number}}</td>
                        <td *ngIf="hasTwoOrMoreNines" class="in-out">{{getInText()}}</td>
                        <td *ngFor="let hole of getHoles(2)">{{hole.number}}</td>
                        <td *ngIf="hasThreeOrMoreNines" class="in-out">Out</td>
                        <td *ngIf="hasTwoOrMoreNines" class="total">Total</td>
                    </tr>
                    <tr class="tee-row" [ngClass]="tee.cssClassName" *ngFor="let tee of scorecard.tees">
                        <th scope="row">
                            <p-dropdown name="teeStyle-{{tee.teeId}}" [options]="scorecard.teeStyles" [(ngModel)]="tee.teeStyleId" [styleClass]="'width1'" (onChange)="onTeeStyleChanged(tee, $event);">
                                <ng-template let-teeStyle pTemplate="item">
                                    <div class="{{teeStyle.cssClassName}}" style="position: relative;height:25px;">
                                        {{teeStyle.name}}
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </th>
                        <td>
                            <input name="tee-{{tee.teeId}}" required pInputText [(ngModel)]="tee.name" class="width-full left">
                        </td>
                        <td *ngFor="let holeTee of getHoleTees(tee, 0)">
                            <input name="holeTee-{{holeTee.holeId}}-{{tee.teeId}}" type="number" min="0" max="999" required pInputText [(ngModel)]="holeTee.yards">
                        </td>
                        <td class="in-out">{{sumYards(tee, 0)}}</td>
                        <td *ngFor="let holeTee of getHoleTees(tee, 1)">
                            <input name="holeTee-{{holeTee.holeId}}-{{tee.teeId}}" type="number" min="0" max="999" required pInputText [(ngModel)]="holeTee.yards">
                        </td>
                        <td *ngIf="hasTwoOrMoreNines" class="in-out">{{sumYards(tee, 1)}}</td>
                        <td *ngFor="let holeTee of getHoleTees(tee, 2)">
                            <input name="holeTee-{{holeTee.holeId}}-{{tee.teeId}}" type="number" min="0" max="999" required pInputText [(ngModel)]="holeTee.yards">
                        </td>
                        <td *ngIf="hasThreeOrMoreNines" class="in-out">{{sumYards(tee, 2)}}</td>
                        <td *ngIf="hasTwoOrMoreNines" class="total">{{sumYards(tee, 0, 1, 2)}}</td>
                    </tr>
                    <tr class="par-row">
                        <th scope="row" colspan="2">Men's Par</th>
                        <td *ngFor="let rating of getHoles(0)">
                            <input name="mensPar-{{rating.holeId}}" type="number" min="3" max="5" required pInputText [(ngModel)]="rating.parForMen" (change)="onParForMenChanged(rating)">
                        </td>
                        <td>{{sumParForMen(0)}}</td>
                        <td *ngFor="let rating of getHoles(1)">
                            <input name="mensPar-{{rating.holeId}}" type="number" min="3" max="5" required pInputText [(ngModel)]="rating.parForMen" (change)="onParForMenChanged(rating)">
                        </td>
                        <td *ngIf="hasTwoOrMoreNines">{{sumParForMen(1)}}</td>
                        <td *ngFor="let rating of getHoles(2)">
                            <input name="mensPar-{{rating.holeId}}" type="number" min="3" max="5" required pInputText [(ngModel)]="rating.parForMen" (change)="onParForMenChanged(rating)">
                        </td>
                        <td *ngIf="hasThreeOrMoreNines">{{sumParForMen(2)}}</td>
                        <td *ngIf="hasTwoOrMoreNines">{{sumParForMen(0, 1, 2)}}</td>
                    </tr>
                    <tr class="par-row">
                        <th scope="row" colspan="2">Women's Par</th>
                        <td *ngFor="let rating of getHoles(0)">
                            <input name="womensPar-{{rating.holeId}}" type="number" min="3" max="5" required pInputText [(ngModel)]="rating.parForWomen">
                        </td>
                        <td>{{sumParForWomen(0)}}</td>
                        <td *ngFor="let rating of getHoles(1)">
                            <input name="womensPar-{{rating.holeId}}" type="number" min="3" max="5" required pInputText [(ngModel)]="rating.parForWomen">
                        </td>
                        <td *ngIf="hasTwoOrMoreNines">{{sumParForWomen(1)}}</td>
                        <td *ngFor="let rating of getHoles(2)">
                            <input name="womensPar-{{rating.holeId}}" type="number" min="3" max="5" required pInputText [(ngModel)]="rating.parForWomen">
                        </td>
                        <td *ngIf="hasThreeOrMoreNines">{{sumParForWomen(2)}}</td>
                        <td *ngIf="hasTwoOrMoreNines">{{sumParForWomen(0, 1, 2)}}</td>
                    </tr>
                    <tr class="handicap-row">
                        <th scope="row" colspan="2">Men's HCP</th>
                        <td *ngFor="let rating of getHoles(0)">
                            <input name="mensHandicap-{{rating.holeId}}" type="number" min="1" max="18" required pInputText [(ngModel)]="rating.handicapForMen" (change)="onHandicapForMenChanged(rating)">
                        </td>
                        <td></td>
                        <td *ngFor="let rating of getHoles(1)">
                            <input name="mensHandicap-{{rating.holeId}}" type="number" min="1" max="18" required pInputText [(ngModel)]="rating.handicapForMen" (change)="onHandicapForMenChanged(rating)">
                        </td>
                        <td *ngIf="hasTwoOrMoreNines"></td>
                        <td *ngFor="let rating of getHoles(2)">
                            <input name="mensHandicap-{{rating.holeId}}" type="number" min="1" max="18" required pInputText [(ngModel)]="rating.handicapForMen" (change)="onHandicapForMenChanged(rating)">
                        </td>
                        <td *ngIf="hasThreeOrMoreNines"></td>
                        <td *ngIf="hasTwoOrMoreNines"></td>
                    </tr>
                    <tr class="handicap-row">
                        <th scope="row" colspan="2">Women's HCP</th>
                        <td *ngFor="let rating of getHoles(0)">
                            <input name="womensHandicap-{{rating.holeId}}" type="number" min="1" max="18" required pInputText [(ngModel)]="rating.handicapForWomen">
                        </td>
                        <td></td>
                        <td *ngFor="let rating of getHoles(1)">
                            <input name="womensHandicap-{{rating.holeId}}" type="number" min="1" max="18" required pInputText [(ngModel)]="rating.handicapForWomen">
                        </td>
                        <td *ngIf="hasTwoOrMoreNines"></td>
                        <td *ngFor="let rating of getHoles(2)">
                            <input name="womensHandicap-{{rating.holeId}}" type="number" min="1" max="18" required pInputText [(ngModel)]="rating.handicapForWomen">
                        </td>
                        <td *ngIf="hasThreeOrMoreNines"></td>
                        <td *ngIf="hasTwoOrMoreNines"></td>
                    </tr>
                </tbody>
            </table>
            <table class="p-datatable with-bottom-margin col-6 scorecard" *ngIf="scorecard">
                <thead class="p-datatable-thead">
                    <tr>
                        <th class="transparent"></th>
                        <th scope="col" [attr.colspan]="scorecard.courseNines.length">
                            Men's<br />Rating / Slope
                        </th>
                        <th scope="col" [attr.colspan]="scorecard.courseNines.length">
                            Women's<br />Rating / Slope
                        </th>
                    </tr>
                    <tr>
                        <th class="transparent"></th>
                        <ng-template let-courseNine ngFor [ngForOf]="scorecard.courseNines">
                            <th scope="col">
                                {{courseNine.name}}
                            </th>
                        </ng-template>
                        <ng-template let-courseNine ngFor [ngForOf]="scorecard.courseNines">
                            <th scope="col">
                                {{courseNine.name}}
                            </th>
                        </ng-template>
                    </tr>
                </thead>
                <tbody class="p-datatable-tbody">
                    <tr class="tee-row" [ngClass]="tee.cssClassName" *ngFor="let tee of scorecard.tees">
                        <th scope="row" class="left">
                            {{tee.name}}
                        </th>
                        <ng-template let-courseNine ngFor [ngForOf]="scorecard.courseNines">
                            <td class="center">
                                <input name="mensRating-{{courseNine.courseNineId}}-{{tee.teeId}}" type="number" min="0" max="150" required pInputText [(ngModel)]="getTeeRating(courseNine, tee).ratingForMen" (change)="onRatingForMenChanged(courseNine, tee)">
                                /
                                <input name="mensSlope-{{courseNine.courseNineId}}-{{tee.teeId}}" type="number" min="55" max="155" required pInputText [(ngModel)]="getTeeRating(courseNine, tee).slopeForMen" (change)="onSlopeForMenChanged(courseNine, tee)">
                            </td>
                        </ng-template>
                        <ng-template let-courseNine ngFor [ngForOf]="scorecard.courseNines">
                            <td class="center">
                                <input name="womensRating-{{courseNine.courseNineId}}-{{tee.teeId}}" type="number" min="0" max="150" required pInputText [(ngModel)]="getTeeRating(courseNine, tee).ratingForWomen">
                                /
                                <input name="womensSlope-{{courseNine.courseNineId}}-{{tee.teeId}}" type="number" min="55" max="155" required pInputText [(ngModel)]="getTeeRating(courseNine, tee).slopeForWomen">
                            </td>
                        </ng-template>
                    </tr>
                </tbody>
            </table>
            <div class="col-6">
                <p-message severity="info" [escape]="false" text="If the course scorecard does not include slope and rating per 9 then you can search for those details <strong><a href='https://ncrdb.usga.org/NCRDB/' target='usga'>here</a></strong>."></p-message>
            </div>
            <div class="col-12">
                <button type="submit" pButton label="Save Scorecard" (click)="saveScorecard()" [disabled]="isLoading || !scorecardForm.form.valid"></button>
            </div>
        </form>
    </div>
</div>
