// This content was generated from '/api/utility/swagger-extensions'

export type ModelsStatisticsGetChartStatisticCharts = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31 | 32 | 33 | 34 | 35 | 36 | 37 | 38;

export const ModelsStatisticsGetChartStatisticCharts = {
    AvgPerformanceByHoleOrder: 1 as ModelsStatisticsGetChartStatisticCharts,
    AvgPerformanceByHoleHandicap: 2 as ModelsStatisticsGetChartStatisticCharts,
    AvgPuttsByStartingDistance: 3 as ModelsStatisticsGetChartStatisticCharts,
    PercentOf1PuttsByStartingDistance: 4 as ModelsStatisticsGetChartStatisticCharts,
    PercentOf3PuttsByStartingDistance: 5 as ModelsStatisticsGetChartStatisticCharts,
    ProximityToHoleForChips: 6 as ModelsStatisticsGetChartStatisticCharts,
    ProximityToHoleForPitches: 7 as ModelsStatisticsGetChartStatisticCharts,
    ProximityToHoleForBunkerShots: 8 as ModelsStatisticsGetChartStatisticCharts,
    AvgProximityToHoleByShotType: 9 as ModelsStatisticsGetChartStatisticCharts,
    MissTendencyOfFairways: 10 as ModelsStatisticsGetChartStatisticCharts,
    BadMissTendencyOfFairways: 11 as ModelsStatisticsGetChartStatisticCharts,
    PenaltyMissTendencyOfFairways: 12 as ModelsStatisticsGetChartStatisticCharts,
    MissTendencyOfApproachShots: 13 as ModelsStatisticsGetChartStatisticCharts,
    BadMissTendencyOfApproachShots: 14 as ModelsStatisticsGetChartStatisticCharts,
    PenaltyMissTendencyOfApproachShots: 15 as ModelsStatisticsGetChartStatisticCharts,
    CoursesPlayed: 17 as ModelsStatisticsGetChartStatisticCharts,
    FairwaysPerPar: 18 as ModelsStatisticsGetChartStatisticCharts,
    GIRsPerPar: 19 as ModelsStatisticsGetChartStatisticCharts,
    Par3Scoring: 20 as ModelsStatisticsGetChartStatisticCharts,
    Par4Scoring: 21 as ModelsStatisticsGetChartStatisticCharts,
    Par5Scoring: 22 as ModelsStatisticsGetChartStatisticCharts,
    PenaltiesPerPar: 23 as ModelsStatisticsGetChartStatisticCharts,
    PuttCounts: 24 as ModelsStatisticsGetChartStatisticCharts,
    PuttingPercentages: 25 as ModelsStatisticsGetChartStatisticCharts,
    ScoreNames: 26 as ModelsStatisticsGetChartStatisticCharts,
    ScoringPerPar: 27 as ModelsStatisticsGetChartStatisticCharts,
    ScoringPercentages: 28 as ModelsStatisticsGetChartStatisticCharts,
    GolfRoundScoringAverages: 29 as ModelsStatisticsGetChartStatisticCharts,
    GolfRoundScores: 30 as ModelsStatisticsGetChartStatisticCharts,
    GolfRoundFairways: 31 as ModelsStatisticsGetChartStatisticCharts,
    GolfRoundGIRs: 32 as ModelsStatisticsGetChartStatisticCharts,
    GolfRoundPutts: 33 as ModelsStatisticsGetChartStatisticCharts,
    GolfRoundPenalties: 34 as ModelsStatisticsGetChartStatisticCharts,
    GolfRoundRunningStrokes: 35 as ModelsStatisticsGetChartStatisticCharts,
    GolfRoundRunningScore: 36 as ModelsStatisticsGetChartStatisticCharts,
    GolfRoundScoreByHole: 37 as ModelsStatisticsGetChartStatisticCharts,
    StatisticSnapshot: 38 as ModelsStatisticsGetChartStatisticCharts
};
