// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreSharedNotificationsNotificationTypes = 1 | 2 | 3 | 4;

export const ModelsCoreSharedNotificationsNotificationTypes = {
    None: 1 as ModelsCoreSharedNotificationsNotificationTypes,
    Email: 2 as ModelsCoreSharedNotificationsNotificationTypes,
    SMS: 3 as ModelsCoreSharedNotificationsNotificationTypes,
    MobilePushNotification: 4 as ModelsCoreSharedNotificationsNotificationTypes
};
