<div class="login-body h-screen flex flex-column bg-cover">
    <div class="login-header shadow-2 z-5 p-3 flex justify-content-between flex-row align-items-center">
        <div class="ml-3 flex" routerLink="/">
            <div class="logo">
                Par Pros
            </div>
        </div>
        <div class="mr-3 flex">

        </div>
    </div>

    <div class="login-panel align-self-center mt-auto mb-auto">
        <div class="text-center z-5 flex flex-column border-1 border-round-md surface-border surface-card px-3">
            <div class="login-panel-header -mt-5 text-white border-round-md mx-auto px-3 py-1 border-1 surface-border">
                <h2 class="m-0">REGISTER</h2>
            </div>

            <h4>Welcome to Par Pros</h4>

            <form #loginForm="ngForm" autocomplete="off" novalidate="novalidate">
                <div class="w-full flex flex-column gap-3 px-3 pb-6">
                    <span class="p-input-icon-left p-float-label">
                        <i class="pi pi-envelope"></i>

                        <input #email="ngModel" type="email" name="email" autocomplete="username" maxlength="255" pInputText [(ngModel)]="registerModel.email" required>
                        <my-validation-message [model]="email" type="required"></my-validation-message>
                        <label>Email</label>
                    </span>

                    <span class="p-input-icon-left p-float-label">
                        <i class="pi pi-lock"></i>

                        <input #password="ngModel" type="password" name="password" autocomplete="new-password" maxlength="50" pPassword [(ngModel)]="registerModel.password" required>
                        <label>Password</label>
                    </span>

                    <span class="p-input-icon-left p-float-label">
                        <i class="pi pi-lock"></i>

                        <input #confirmPassword="ngModel" type="password" name="confirmPassword" autocomplete="new-password" maxlength="50" pInputText [(ngModel)]="registerModel.confirmPassword" required>
                        <my-validation-message [model]="confirmPassword" type="required"></my-validation-message>
                        <label>Confirm Password</label>
                    </span>

                    <span class="p-input-icon-left p-float-label">
                        <i class="fas fa-id-card"></i>

                        <input #firstName="ngModel" name="firstName" autocomplete="given-name" maxlength="50" pInputText [(ngModel)]="registerModel.firstName" required>
                        <my-validation-message [model]="firstName" type="required"></my-validation-message>
                        <label>First Name</label>
                    </span>

                    <span class="p-input-icon-left p-float-label">
                        <i class="fas fa-id-card"></i>

                        <input #lastName="ngModel" name="lastName" autocomplete="family-name" maxlength="50" pInputText [(ngModel)]="registerModel.lastName" required>
                        <my-validation-message [model]="lastName" type="required"></my-validation-message>
                        <label>Last Name</label>
                    </span>

                    <span class="p-input-icon-left p-float-label">
                        <i class="fas fa-city"></i>

                        <input #city="ngModel" name="city" autocomplete="address-level2" maxlength="50" pInputText [(ngModel)]="registerModel.city" required>
                        <my-validation-message [model]="city" type="required"></my-validation-message>
                        <label>City</label>
                    </span>

                    <span class="p-input-icon-left p-float-label">
                        <i class="fas fa-flag-usa"></i>

                        <p-dropdown inputId="state" #stateCode="ngModel" name="stateCode" autocomplete="address-level1" [options]="states" [(ngModel)]="registerModel.stateCode" [autoDisplayFirst]="false" required></p-dropdown>
                        <my-validation-message [model]="stateCode" type="required"></my-validation-message>
                        <label for="state">State</label>
                    </span>

                    <span class="p-input-icon-left p-float-label">
                        <i class="fas fa-restroom"></i>

                        <p-dropdown inputId="gender" #gender="ngModel" name="gender" [options]="genders" [(ngModel)]="registerModel.gender" [autoDisplayFirst]="false" required></p-dropdown>
                        <my-validation-message [model]="gender" type="required"></my-validation-message>
                        <label for="gender">USGA Handicapping Rules</label>
                    </span>
                </div>

                <div class="mt-4 mb-4 px-3">
                    <p-button class="login-button" label="REGISTER" icon="fas fa-user-plus" (click)="register()" [disabled]="isLoading  || !loginForm.form.valid"></p-button>
                    <my-messages></my-messages>
                </div>
            </form>
        </div>
    </div>
</div>
