import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'textToHtml' })
export class TextToHtmlPipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {
    }

    transform(value: string): SafeHtml {
        const html = this.replaceNewlinesWithHtmlBreaks(value);
        return this.domSanitizer.bypassSecurityTrustHtml(html);
    }

    private replaceNewlinesWithHtmlBreaks(text: string) {
        return text.replace(/\r?\n/g, '<br />');
    }
}
