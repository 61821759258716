<div class="card p-fluid">
    <div class="grid form-group">
        <div class="field col-12 lg:col-2">
            <span class="p-float-label">
                <p-dropdown [options]="golfers" [(ngModel)]="golferId" (onChange)="onGolferChanged()"></p-dropdown>
                <label>Golfer</label>
            </span>
        </div>
        <div class="field col-12 lg:col-3">
            <span class="p-float-label">
                <p-dropdown [options]="courses" [(ngModel)]="args.courseId" (onChange)="onCourseChanged();" [filter]="courses && courses.length > 5" [resetFilterOnHide]="true"></p-dropdown>
                <label>Course</label>
            </span>
        </div>
        <div class="col-12 lg:col-4 right">
            <p-selectButton [options]="viewOptions" [(ngModel)]="selectedViewOption" styleClass="view-options"></p-selectButton>
        </div>
    </div>
    <div *ngIf="golfRoundGolfers">
        <p-table [value]="golfRoundGolfers" [paginator]="totalRounds > args.pageSize" [pageLinks]="10" [rows]="args.pageSize" [lazy]="true" (onLazyLoad)="loadGolfRoundPage($event)" [totalRecords]="totalRounds" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md">
            <ng-template pTemplate="caption">
                Golf Rounds
                <i class="fas fa-plus-square new-item-icon clickable" [routerLink]="['new']" pTooltip="Create New Round"></i>
                <a [href]="excelExportUrl">
                    <i class="fas fa-file-excel export-icon clickable" pTooltip="Export to Excel"></i>
                </a>
                <i class="fa-solid fa-microscope clickable stroke-analysis-icon" [routerLink]="['/rounds/stroke-analysis']" pTooltip="Analyze Stroke Level Data"></i>
            </ng-template>
            <ng-template pTemplate="body" let-round let-rowIndex="rowIndex">
                <tr class="rowgroup-header" *ngIf="shouldShowGolfRoundRowGroupHeader(rowIndex)">
                    <td class="widtd11 left">{{round.golfRound.year}}</td>
                    <td class="left">Course</td>
                    <td class="width8 left">Tee</td>
                    <td class="width2 center">Score</td>
                    <td class="width2 center" *ngIf="viewingScores">Front 9</td>
                    <td class="width2 center" *ngIf="viewingScores">Back 9</td>
                    <td class="width2 center" *ngIf="viewingStats">Net Score</td>
                    <td class="width3 center" *ngIf="viewingStats">Rank</td>
                    <td class="width1 center" *ngIf="viewingStats">Fairways</td>
                    <td class="width1 center" *ngIf="viewingStats">GIR</td>
                    <td class="width1 center" *ngIf="viewingStats">Putts</td>
                    <td class="width1 center" *ngIf="viewingStats">1 Putts</td>
                    <td class="width1 center" *ngIf="viewingStats">3 Putts</td>
                    <td class="width2 center" *ngIf="viewingStats">Penalties</td>
                    <td class="width1 center" *ngIf="viewingScores">Eagles</td>
                    <td class="width1 center" *ngIf="viewingScores">Birdies</td>
                    <td class="width1 center" *ngIf="viewingScores">Pars</td>
                    <td class="width1 center" *ngIf="viewingScores">Bogeys</td>
                    <td class="width1 center" *ngIf="viewingScores">Doubles</td>
                    <td class="width1 center" *ngIf="viewingScores">Triples</td>
                    <td class="width1 center" *ngIf="viewingScores">Quads+</td>
                    <td class="width3 center" *ngIf="viewingStrokesGained">SG: Off the Tee</td>
                    <td class="width3 center" *ngIf="viewingStrokesGained">SG: Approach the Green</td>
                    <td class="width3 center" *ngIf="viewingStrokesGained">SG: Around the Green</td>
                    <td class="width3 center" *ngIf="viewingStrokesGained">SG: Putting</td>
                    <td class="width3 center" *ngIf="viewingStrokesGained">SG: Tee to Green</td>
                    <td class="width3 center" *ngIf="viewingStrokesGained">SG: Total</td>
                    <td class="width2 center" *ngIf="viewingHandicapDetails">Adjusted<br />Score</td>
                    <td class="width3 center" *ngIf="viewingHandicapDetails">Front 9<br />Rating/Slope</td>
                    <td class="width3 center" *ngIf="viewingHandicapDetails">Back 9<br />Rating/Slope</td>
                    <td class="width2 center" *ngIf="viewingHandicapDetails">Course<br />Handicap</td>
                    <td class="width2 center" *ngIf="viewingHandicapDetails">Differential</td>
                </tr>
                <tr>
                    <td class="left">
                        <a routerLink="/rounds/{{round.golfRound.golfRoundId}}">
                            {{round.golfRound.teeTime | date:'EEEE, M/d/yyyy'}}
                            {{round.golfRound.teeTime | date:'shortTime'}}
                        </a>
                        <span class="status-icons right">
                            <i *ngIf="!round.isComplete" class="fas fa-trash-alt clickable delete-icon" pTooltip="Delete" (click)="deleteGolfRoundGolfer(round, rowIndex)"></i>
                            <i *ngIf="shouldShowStatisticsUpdateProcessingIcon(round)" class="fas fa-spinner fa-spin statistics-processing" pTooltip="Statistics are Being Calculated for this Round"></i>
                            <i *ngIf="shouldShowStatisticsUpdateFailedIcon(round)" class="fas fa-exclamation-triangle statistics-failed" pTooltip="An Error Occurred While Calculating Statistics for this Round"></i>
                            <i *ngIf="shouldShowWindIcon(round)" class="fas fa-wind windy" pTooltip="{{getWindTooltip(round)}}"></i>
                            <i *ngIf="shouldShowRainIcon(round)" class="fas fa-cloud-rain wet" pTooltip="Wet Conditions"></i>
                            <i *ngIf="shouldShowTempIcon(round, 1)" class="fas fa-thermometer-full very-hot" pTooltip="{{getTempTooltip(round)}}"></i>
                            <i *ngIf="shouldShowTempIcon(round, 0.75)" class="fas fa-thermometer-three-quarters hot" pTooltip="{{getTempTooltip(round)}}"></i>
                            <i *ngIf="shouldShowTempIcon(round, 0.50)" class="fas fa-thermometer-half cool" pTooltip="{{getTempTooltip(round)}}"></i>
                            <i *ngIf="shouldShowTempIcon(round, 0.25)" class="fas fa-thermometer-quarter cold" pTooltip="{{getTempTooltip(round)}}"></i>
                            <i *ngIf="shouldShowTempIcon(round, 0)" class="fas fa-thermometer-empty very-cold" pTooltip="{{getTempTooltip(round)}}"></i>
                            <i *ngIf="shouldShowExceptionalScoreIcon(round)" class="fas fa-fire exceptional-score" [pTooltip]="round.exceptionalScoreDescription"></i>
                            <i *ngIf="shouldShowAboveAverageScoreIcon(round)" class="fa-solid fa-circle-check positive-adornment" [pTooltip]="getAboveAverageScoreTooltip(round)"></i>
                            <i *ngIf="shouldShowBelowAverageScoreIcon(round)" class="fa-solid fa-toilet negative-adornment" [pTooltip]="getBelowAverageScoreTooltip(round)"></i>
                            <i *ngIf="round.golfRoundType.isPracticeRound" class="fas fa-parking practice-round" pTooltip="Practice Round"></i>
                            <i *ngIf="round.holesPlanned === 9" class="nine-hole-round" pTooltip="9 Hole Round">9</i>
                            <i *ngIf="round.includedInHandicap" class="fas fa-h-square clickable included-in-handicap" pTooltip="Included in Handicap Index" [routerLink]="['../golfers', round.golfer.golferId, 'handicaps']"></i>
                            <i *ngIf="shouldShowStrokeAnalysisIcon(round)" class="fa-solid fa-microscope clickable stroke-analysis" [routerLink]="['/rounds/stroke-analysis', { golfRoundId: round.golfRound.golfRoundId }]" pTooltip="Analyze Stroke Level Data"></i>
                            <i *ngIf="shouldShowScorecardIcon(round)" class="fas fa-th clickable scorecard-icon" pTooltip="View Scorecard" (click)="showScorecardDialog(round)"></i>
                        </span>
                        <p *ngIf="!round.isComplete">
                            <button pButton type="button" icon="fas fa-play" iconPos="right" label="{{getContinueButtonText(round)}}" class="p-button-info continue-round" (click)="continueRound(round)"></button>
                        </p>
                        <p *ngIf="!round.isComplete && round.holesPlayed > 0">
                            <button pButton type="button" icon="fas fa-stop" iconPos="right" label="End Round" class="p-button-danger end-round" (click)="endRound(round)"></button>
                        </p>
                    </td>
                    <td class="left">
                        <span class="p-column-title">Course</span>
                        {{round.golfRound.course.name}}
                    </td>
                    <td class="left">
                        <span class="p-column-title">Tee</span>
                        {{round.tee.name}}
                        ({{round.tee.rating | number:'1.1-1'}}/{{round.tee.slope | number}})
                        - {{round.yards | number}} Yards
                    </td>
                    <td class="center">
                        <span class="p-column-title">Score</span>
                        {{round.formattedScore}}
                        <span *ngIf="shouldShowHolesPlayed(round)"><br />Thru {{round.holesPlayed}}</span>
                        <i *ngIf="shouldShowScoreAsterisk(round)" class="fas fa-asterisk incomplete-holes" [pTooltip]="getIncompleteHoleMessage(round)"></i>
                    </td>
                    <td class="center" *ngIf="viewingScores">
                        <span class="p-column-title">Front 9</span>
                        {{round.formattedFront9Score}}
                    </td>
                    <td class="center" *ngIf="viewingScores">
                        <span class="p-column-title">Back 9</span>
                        {{round.formattedBack9Score}}
                    </td>
                    <td class="center" *ngIf="viewingStats">
                        <span class="p-column-title">Net Score</span>
                        {{round.formattedNetScore}}
                        <span *ngIf="shouldShowHolesPlayed(round)"><br />Thru {{round.holesPlayed}}</span>
                    </td>
                    <td class="center" *ngIf="viewingStats">
                        <span class="p-column-title">Rank</span>
                        <span *ngIf="round.golfRoundType.isCompetitiveRound && round.golfRoundGolferRanking">
                            {{round.golfRoundGolferRanking.formattedRank}}
                            of
                            {{round.golfRound.golfRoundGolferRankings.length}}
                            <span class="status-icons">
                                <i class="fas fa-info-circle ranking-info" pTooltip="{{getRankTooltip(round)}}" [escape]="false"></i>
                            </span>
                        </span>
                    </td>
                    <td *ngIf="viewingStats" class="center">
                        <span class="p-column-title">Fairways</span>
                        {{round.golfRoundGolferMetric?.fairways}}
                    </td>
                    <td *ngIf="viewingStats" class="center">
                        <span class="p-column-title">GIR</span>
                        {{round.golfRoundGolferMetric?.girs}}
                    </td>
                    <td *ngIf="viewingStats" class="center">
                        <span class="p-column-title">Putts</span>
                        {{round.golfRoundGolferMetric?.putts}}
                    </td>
                    <td *ngIf="viewingStats" class="center">
                        <span class="p-column-title">1 Putts</span>
                        {{round.golfRoundGolferMetric?.onePutts}}
                    </td>
                    <td *ngIf="viewingStats" class="center">
                        <span class="p-column-title">3 Putts</span>
                        {{round.golfRoundGolferMetric?.threePutts}}
                    </td>
                    <td *ngIf="viewingStats" class="center">
                        <span class="p-column-title">Penalties</span>
                        {{round.golfRoundGolferMetric?.penaltyStrokes}}
                    </td>
                    <td *ngIf="viewingScores" class="center">
                        <span class="p-column-title">Eagles</span>
                        {{round.golfRoundGolferMetric?.eagles}}
                    </td>
                    <td *ngIf="viewingScores" class="center">
                        <span class="p-column-title">Birdies</span>
                        {{round.golfRoundGolferMetric?.birdies}}
                    </td>
                    <td *ngIf="viewingScores" class="center">
                        <span class="p-column-title">Pars</span>
                        {{round.golfRoundGolferMetric?.pars}}
                    </td>
                    <td *ngIf="viewingScores" class="center">
                        <span class="p-column-title">Bogeys</span>
                        {{round.golfRoundGolferMetric?.bogeys}}
                    </td>
                    <td *ngIf="viewingScores" class="center">
                        <span class="p-column-title">Doubles</span>
                        {{round.golfRoundGolferMetric?.doubleBogeys}}
                    </td>
                    <td *ngIf="viewingScores" class="center">
                        <span class="p-column-title">Triples</span>
                        {{round.golfRoundGolferMetric?.tripleBogeys}}
                    </td>
                    <td *ngIf="viewingScores" class="center">
                        <span class="p-column-title">Quads+</span>
                        {{round.golfRoundGolferMetric?.quadrupleBogeysOrWorse}}
                    </td>
                    <td *ngIf="viewingStrokesGained" class="center">
                        <span class="p-column-title">SG: Off the Tee</span>
                        {{getStrokesGainedOffTee(round) | number:'1.3-3'}}
                    </td>
                    <td *ngIf="viewingStrokesGained" class="center">
                        <span class="p-column-title">SG: Approach the Green</span>
                        {{getStrokesGainedApproach(round) | number:'1.3-3'}}
                    </td>
                    <td *ngIf="viewingStrokesGained" class="center">
                        <span class="p-column-title">SG: Around the Green</span>
                        {{getStrokesGainedAroundGreen(round) | number:'1.3-3'}}
                    </td>
                    <td *ngIf="viewingStrokesGained" class="center">
                        <span class="p-column-title">SG: Putting</span>
                        {{getStrokesGainedPutting(round) | number:'1.3-3'}}
                    </td>
                    <td *ngIf="viewingStrokesGained" class="center">
                        <span class="p-column-title">SG: Tee to Green</span>
                        {{getStrokesGainedTeeToGreen(round) | number:'1.3-3'}}
                    </td>
                    <td *ngIf="viewingStrokesGained" class="center">
                        <span class="p-column-title">SG: Total</span>
                        {{getStrokesGainedTotal(round) | number:'1.3-3'}}
                    </td>
                    <td *ngIf="viewingHandicapDetails" class="center">
                        <span class="p-column-title">Adjusted Score</span>
                        {{round.golfRoundGolferHandicapDetail?.formattedAdjustedScore}}
                    </td>
                    <td *ngIf="viewingHandicapDetails" class="center">
                        <span class="p-column-title">Front 9 Rating/Slope</span>
                        {{round.golfRoundGolferHandicapDetail?.courseRatingFront9 | number:'1.1-1'}}<span *ngIf="round.golfRoundGolferHandicapDetail?.courseRatingFront9">/</span>{{round.golfRoundGolferHandicapDetail?.courseSlopeFront9 | number}}
                    </td>
                    <td *ngIf="viewingHandicapDetails" class="center">
                        <span class="p-column-title">Back 9 Rating/Slope</span>
                        {{round.golfRoundGolferHandicapDetail?.courseRatingBack9 | number:'1.1-1'}}<span *ngIf="round.golfRoundGolferHandicapDetail?.courseRatingBack9">/</span>{{round.golfRoundGolferHandicapDetail?.courseSlopeBack9 | number}}
                    </td>
                    <td *ngIf="viewingHandicapDetails" class="center">
                        <span class="p-column-title">Course Handicap</span>
                        {{round.golfRoundGolferHandicapDetail?.courseHandicap | number:'1.0-0'}}
                    </td>
                    <td *ngIf="viewingHandicapDetails" class="center">
                        <span class="p-column-title">Differential</span>
                        {{round.golfRoundGolferHandicapDetail?.scoreDifferential | number:'1.1-1'}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="columnCount">
                        {{getEmptyMessage('No golf rounds.')}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<p-confirmDialog key="round-list-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
