// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreRoundsFairwayMisses = 1 | 2 | 3 | 4;

export const ModelsCoreRoundsFairwayMisses = {
    Left: 1 as ModelsCoreRoundsFairwayMisses,
    Right: 2 as ModelsCoreRoundsFairwayMisses,
    Long: 3 as ModelsCoreRoundsFairwayMisses,
    Short: 4 as ModelsCoreRoundsFairwayMisses
};
