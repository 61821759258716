import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, debounceTime } from 'rxjs/operators';

import { GolfersControllerProxy, PracticeControllerProxy } from '../../../shared/server-proxies';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { MessagesComponent } from '../../../shared/ui/messages/messages.component';
import { CurrentGolferService } from '../../../shared/golfers/current-golfer.service';
import { PracticeTimeChartDialogService } from '../../practice-time-chart-dialog/practice-time-chart-dialog.service';

import {
    ModelsCorePracticePracticePlan
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-practice-plan-list',
    templateUrl: './practice-plan-list.component.html',
    styleUrls: [
        './practice-plan-list.component.scss'
    ]
})
export class PracticePlanListComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersControllerProxy: GolfersControllerProxy,
        private practiceControllerProxy: PracticeControllerProxy,
        private currentGolfer: CurrentGolferService,
        private confirmationService: ConfirmationService,
        private practiceTimeChartDialogService: PracticeTimeChartDialogService) {
        super();
    }

    @ViewChild(MessagesComponent, { static: true }) messages: MessagesComponent;
    @Input() enableAddingToPlan: boolean;
    @Output() planAdded = new EventEmitter<ModelsCorePracticePracticePlan>();
    practicePlans: ModelsCorePracticePracticePlan[];
    private allPracticePlans: ModelsCorePracticePracticePlan[];
    private reorderSubject = new Subject<void>();

    @Input()
    set excludePlanIds(values: number[]) {
        this.myExcludePlanIds = values;
        this.filterPlans();
    }

    get excludePlanIds() {
        return this.myExcludePlanIds;
    }

    private golferId: number;
    private myExcludePlanIds: number[];

    ngOnInit() {
        this.golferId = this.currentGolfer.golferId;

        this.reorderSubject
            .pipe(
                debounceTime(1500),
                this.takeUntilUnsubscribed()
            )
            .subscribe(() => {
                this.saveOrder();
            });

        this.taskStarted();
        this.golfersControllerProxy.getPracticePlans(this.golferId)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed()
            )
            .subscribe(
                response => {
                    this.allPracticePlans = response.body;
                    this.filterPlans();
                });
    }

    showPracticeTimeByCategoryChart(practicePlan: ModelsCorePracticePracticePlan) {
        const header = `${practicePlan.name}`;
        this.practiceTimeChartDialogService.open(header, practicePlan.practiceCategories, practicePlan.practiceTypes);
    }

    getContainerCssClasses() {
        /* eslint-disable @typescript-eslint/naming-convention */
        return {
            'col-12': true,
            'lg:col-8': !this.enableAddingToPlan
        };
        /* eslint-enable @typescript-eslint/naming-convention */
    }

    deletePracticePlan(practicePlan: ModelsCorePracticePracticePlan, index: number) {
        const message = `Are you sure you want to delete ${practicePlan.name}?`;

        this.confirmationService.confirm(
            {
                key: 'practice-plan-list-component',
                header: 'Delete Practice Plan?',
                message: message,
                accept: () => {
                    this.taskStarted();
                    this.practiceControllerProxy.deletePracticePlan(practicePlan.practicePlanId)
                        .pipe(
                            finalize(() => this.taskCompleted()),
                            this.takeUntilUnsubscribed())
                        .subscribe(
                            () => {
                                this.practicePlans.splice(index, 1);
                            },
                            response => this.messages.showApiError(response));
                }
            });
    }

    copyPracticePlan(practicePlan: ModelsCorePracticePracticePlan) {
        this.practiceControllerProxy.copyPracticePlan(practicePlan.practicePlanId)
            .subscribe(
                response => {
                    const newCopy = response.body;
                    const practicePlans = this.practicePlans.slice(0);

                    practicePlans.push(newCopy);
                    practicePlans.sort(
                        (a, b) => {
                            let sort = 0;

                            if(a.name > b.name) {
                                sort = 1;
                            }
                            else if(a.name < b.name) {
                                sort = -1;
                            }

                            return sort;
                        });

                    this.practicePlans = practicePlans;
                });
    }

    addPracticePlanToPlan(practicePlan: ModelsCorePracticePracticePlan) {
        this.planAdded.emit(practicePlan);
    }

    filterPlans() {
        if(this.allPracticePlans) {
            this.practicePlans = this.excludePlanIds && this.excludePlanIds.length > 0
                ? this.allPracticePlans.filter(p => this.excludePlanIds.indexOf(p.practicePlanId) === -1)
                : this.allPracticePlans;
        }
    }

    onRowsReordered() {
        this.reorderSubject.next();
    }

    private saveOrder() {
        const orderedPlanIds = this.practicePlans.map(p => p.practicePlanId);

        this.taskStarted();
        this.golfersControllerProxy.savePracticePlanOrder(this.golferId, orderedPlanIds)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(() => {}, response => this.messages.showApiError(response));
    }
}
