import { NgModule } from '@angular/core';
import { CurrentGolferService } from './current-golfer.service';
import { GolferLookupService } from './golfer-lookup.service';

@NgModule(
    {
        imports: [
        ],
        exports: [
        ],
        declarations: [
        ],
        providers: [
            CurrentGolferService,
            GolferLookupService
        ]
    })
export class SharedGolfersModule {
}
