import { Injectable } from '@angular/core';
import { StorageService } from '../core/storage.service';

import {
    ModelsCoreGolfersGolfer
} from '../../shared/swagger-codegen/models';

@Injectable()
export class SessionService {
    private tokenKey = 'token';
    private golferKey = 'golfer';

    constructor(private storageService: StorageService) {

    }

    getToken(): string {
        return this.storageService.getSessionItem(this.tokenKey);
    }

    getPersistedToken(): string {
        return this.storageService.getItem(this.tokenKey);
    }

    getGolfer(): ModelsCoreGolfersGolfer {
        const json = this.storageService.getSessionItem(this.golferKey);
        return json ? JSON.parse(json) : null;
    }

    initializeSession(golfer: ModelsCoreGolfersGolfer, token: string, rememberMe: boolean) {
        this.removeObsoleteStoredValues();

        if(rememberMe) {
            this.storageService.setItem(this.tokenKey, token);
        }

        this.storageService.setSessionItem(this.tokenKey, token);
        this.setGolfer(golfer);
    }

    disposeSession() {
        this.storageService.deleteSessionItem(this.tokenKey);
        this.storageService.deleteItem(this.tokenKey);

        this.storageService.deleteSessionItem(this.golferKey);
        this.storageService.deleteItem(this.golferKey);
    }

    updateGolfer(golfer: ModelsCoreGolfersGolfer) {
        const current = this.getGolfer();

        if(current && current.golferId === golfer.golferId) {
            current.email = golfer.email;
            current.firstName = golfer.firstName;
            current.lastName = golfer.lastName;
            current.city = golfer.city;
            current.stateCode = golfer.stateCode;
            current.gender = golfer.gender;

            this.setGolfer(current);
        }
    }

    private setGolfer(golfer: ModelsCoreGolfersGolfer) {
        const json = JSON.stringify(golfer);
        this.storageService.setSessionItem(this.golferKey, json);
    }

    private removeObsoleteStoredValues() {
        const obsoleteKeys = [
            'email',
            'golfer',
            'token',
            'user'
        ];

        obsoleteKeys.forEach(obsoleteKey => this.storageService.deleteItem(obsoleteKey, false));
    }
}
