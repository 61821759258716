import { Component, Input } from '@angular/core';
import { TooltipItem } from 'chart.js';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';

@Component({
    selector: 'my-putting-by-starting-distance-line-chart',
    template: '<p-chart type="line" [data]="data" [options]="options" responsive="true"></p-chart>'
})
export class PuttingByStartingDistanceLineChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'line');

        this.options.scales.y.beginAtZero = false;
        this.options.plugins.tooltip.callbacks.title = this.getTooltipTitle.bind(this);
        this.options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);
        this.options.scales.y.ticks.callback = this.getTick.bind(this);
    }

    @Input()
    isPercentage = true;

    @Input()
    puttCountLabel = 'Putts';

    private getTooltipTitle(): string {
        return null;
    }

    private getTooltipLabel(tooltipItem: TooltipItem<'line'>) {
        const dataset = tooltipItem.dataset;
        const data = dataset.data as number[];

        const proximity = tooltipItem.label;
        const golfer = dataset.label;
        const value = data[tooltipItem.dataIndex];

        return this.isPercentage
            ? `${golfer}: ${this.puttCountLabel} ${value.toFixed(2)} % of the Time from ${proximity}`
            : `${golfer}: Averages ${value} Putts from ${proximity}`;
    }

    private getTick(value: any) {
        return this.isPercentage ? `${value}%` : value.toString();
    }
}
