import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { AuthGuard } from '../shared/auth/auth-guard.service';
import { AppLayoutComponent } from '../layout/app.layout.component';
import { CourseDetailTabViewComponent } from './detail/course-detail-tab-view.component';
import { CourseHistoryComponent } from './history/course-history.component';
import { HoleHistoryComponent } from './history/hole-history.component';
import { CourseListComponent } from './list/course-list.component';
import { CourseStrategyComponent } from './strategy/course-strategy.component';
import { NewCourseComponent } from './new/new-course.component';
import { WhichTeeComponent } from './which-tee/which-tee.component';

export const routes: Routes = [
    {
        path: 'courses',
        component: AppLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: CourseListComponent,
                data: {
                    title: 'Course List'
                }
            },
            {
                path: 'new',
                component: NewCourseComponent,
                data: {
                    title: 'New Course'
                }
            },
            {
                path: ':courseId',
                component: CourseDetailTabViewComponent,
                data: {
                    title: 'Course Detail'
                }
            },
            {
                path: ':courseId/holes/:holeId/history',
                component: HoleHistoryComponent,
                data: {
                    title: 'Hole History'
                }
            },
            {
                path: ':courseId/history',
                component: CourseHistoryComponent,
                data: {
                    title: 'Course History'
                }
            },
            {
                path: ':courseId/strategy',
                component: CourseStrategyComponent,
                data: {
                    title: 'Course Strategy'
                }
            },
            {
                path: ':courseId/which-tee',
                component: WhichTeeComponent,
                data: {
                    title: 'Which Tee?'
                }
            }
        ]
    }
];

export const coursesRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
