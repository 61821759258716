import { forwardRef, Type, Directive } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseComponentDirective } from './base-component.directive';

@Directive()
export class BindableDirective<TValue> extends BaseComponentDirective implements ControlValueAccessor  {

    get value(): TValue {
        return this.myValue;
    }

    set value(value: TValue) {
        this.myValue = value;
        this.onChange(value);
        this.onChangeInternal(value);
    }
    private myValue: TValue;

    static providerFor(type: Type<any>, multi = true) {
        return {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => type),
            multi: multi
        };
    }
    // @ts-ignore: Unused code error
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private onChange = (value: TValue) => { };
    // @ts-ignore: Unused code error
    private onTouched = () => {};

    writeValue(value: TValue): void {
        this.value = value;
    }

    registerOnChange(fn: (value: TValue) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    // @ts-ignore: Unused code error
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected onChangeInternal(value: TValue) {

    }

    protected valueIsDefined() {
        return this.value !== undefined && this.value !== null;
    }
}
