import { Injectable } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import { QueryString } from '../util';

import {
    ModelsCoreCoursesCourse,
    ModelsCoreGolfersGolfClub,
    ModelsCoreGolfersGolfer,
    ModelsCorePracticeImprovementPlan,
    ModelsCorePracticePracticeActivity,
    ModelsCorePracticePracticeActivitySummary,
    ModelsCorePracticePracticePlan,
    ModelsCorePracticePracticeSession,
    ModelsCoreRoundsGolfRoundGolfer,
    ModelsCoreSharedStateWithCities,
    ModelsCoreStatisticsGoal,
    ModelsCoursesGetCourseHistoryGetCourseHistoryResponse,
    ModelsGolfersAddGolfClubAddGolfClubResponse,
    ModelsGolfersGetDashboardGetDashboardResponse,
    ModelsGolfersGetFavoriteAchievementsAchievement,
    ModelsGolfersGetFavoriteStatisticsStatistic,
    ModelsGolfersGetFriendRequestsFriendRequest,
    ModelsGolfersGetHandicapsGetHandicapsResponse,
    ModelsGolfersGetHistoricalHandicapsGetHistoricalHandicapsResponse,
    ModelsGolfersGetNotificationPreferencesGetNotificationPreferencesResponse,
    ModelsGolfersGetProjectedHandicapGetProjectedHandicapResponse,
    ModelsGolfersGetSwingThoughtsSwingThought,
    ModelsWebApiGolfersSaveImprovementPlanModel,
    ModelsWebApiGolfersSaveMobileDeviceModel,
    ModelsGolfersSaveSwingThoughtSaveSwingThoughtResponse,
    ModelsRoundsAnalyzeStrokesAnalyzeStrokesResponse,
    ModelsRoundsCountGolfRoundsPerCourseCourseRounds,
    ModelsRoundsGetGolfRoundsGetGolfRoundsResponse,
    ModelsStatisticsGetAchievementHistoryAchievementHistory,
    ModelsStatisticsGetAchievementsGetAchievementsResponse,
    ModelsStatisticsGetChartGetChartResponse,
    ModelsStatisticsGetGoalsGetGoalsResponse,
    ModelsStatisticsGetHeadToHeadStatisticsGolferHeadToHeadStatistics,
    ModelsStatisticsGetStatisticsGetStatisticsResponse,
    ModelsWebApiGolfersChangePasswordModel,
    ModelsWebApiGolfersGetAchievementHistoryArgs,
    ModelsWebApiGolfersGetAchievementsArgs,
    ModelsWebApiGolfersGetCourseHistoryArgs,
    ModelsWebApiGolfersGetDashboardArgs,
    ModelsWebApiGolfersGetGoalsArgs,
    ModelsWebApiGolfersGetGolfClubsArgs,
    ModelsWebApiGolfersGetGolfersArgs,
    ModelsWebApiGolfersGetGolfRoundsArgs,
    ModelsWebApiGolfersGetHeadToHeadStatisticsArgs,
    ModelsWebApiGolfersGetHistoricalHandicapsArgs,
    ModelsWebApiGolfersGetHoleHistoryArgs,
    ModelsWebApiGolfersGetPracticeActivitiesArgs,
    ModelsWebApiGolfersGetPracticeActivitySummaryArgs,
    ModelsWebApiGolfersGetPracticeSessionsArgs,
    ModelsWebApiGolfersGetProjectedHandicapArgs,
    ModelsWebApiGolfersGetStatisticsArgs,
    ModelsWebApiGolfersGetStatisticsChartArgs,
    ModelsWebApiGolfersGetStrokeAnalysisArgs,
    ModelsWebApiGolfersGetYearsPlayedArgs,
    ModelsWebApiGolfersRegisterModel,
    ModelsWebApiGolfersSaveGoalModel,
    ModelsWebApiGolfersSaveGolfClubModel,
    ModelsWebApiGolfersSaveGolferModel,
    ModelsWebApiGolfersSaveNotificationsModel,
    ModelsWebApiGolfersSaveSwingThoughtModel
} from '../../shared/swagger-codegen/models';

@Injectable()
export class GolfersControllerProxy {
    private baseUrl = '/api/golfers';

    constructor(private httpClient: HttpClientWrapperService) {
    }

    register(model: ModelsWebApiGolfersRegisterModel) {
        return this.httpClient.post(this.baseUrl, model);
    }

    getDashboard(golferId: number, args?: ModelsWebApiGolfersGetDashboardArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsGolfersGetDashboardGetDashboardResponse>(`${this.baseUrl}/${golferId}/dashboard${queryString}`);
    }

    getLocations() {
        return this.httpClient.get<ModelsCoreSharedStateWithCities[]>(`${this.baseUrl}/locations`);
    }

    getGolfers(args: ModelsWebApiGolfersGetGolfersArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsCoreGolfersGolfer[]>(`${this.baseUrl}${queryString}`);
    }

    getGolfer(golferId: number) {
        return this.httpClient.get<ModelsCoreGolfersGolfer>(`${this.baseUrl}/${golferId}`);
    }

    saveGolfer(golferId: number, model: ModelsWebApiGolfersSaveGolferModel) {
        return this.httpClient.put<any>(`${this.baseUrl}/${golferId}`, model);
    }

    changePassword(golferId: number, model: ModelsWebApiGolfersChangePasswordModel) {
        return this.httpClient.put<any>(`${this.baseUrl}/${golferId}/password`, model);
    }

    getGolfRounds(golferId: number, args: ModelsWebApiGolfersGetGolfRoundsArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsRoundsGetGolfRoundsGetGolfRoundsResponse>(`${this.baseUrl}/${golferId}/rounds${queryString}`);
    }

    getGolfRoundCountsPerCourse(golferId: number) {
        return this.httpClient.get<ModelsRoundsCountGolfRoundsPerCourseCourseRounds[]>(`${this.baseUrl}/${golferId}/rounds/courses`);
    }

    getFriends(golferId: number) {
        return this.httpClient.get<ModelsCoreGolfersGolfer[]>(`${this.baseUrl}/${golferId}/friends`);
    }

    getFriendRequests(toGolferId: number) {
        return this.httpClient.get<ModelsGolfersGetFriendRequestsFriendRequest[]>(`${this.baseUrl}/${toGolferId}/friends/requests`);
    }

    addFriendRequest(fromGolferId: number, toGolferId: number) {
        return this.httpClient.post<any>(`${this.baseUrl}/${toGolferId}/friends/${fromGolferId}/requests`);
    }

    acceptFriendRequest(fromGolferId: number, toGolferId: number) {
        return this.httpClient.put<any>(`${this.baseUrl}/${toGolferId}/friends/${fromGolferId}/requests`);
    }

    rejectFriendRequest(fromGolferId: number, toGolferId: number) {
        return this.httpClient.delete<any>(`${this.baseUrl}/${toGolferId}/friends/${fromGolferId}/requests`);
    }

    getFavoriteCourses(golferId: number) {
        return this.httpClient.get<ModelsCoreCoursesCourse[]>(`${this.baseUrl}/${golferId}/courses/favorite`);
    }

    addFavoriteCourse(golferId: number, courseId: number) {
        return this.httpClient.post(`${this.baseUrl}/${golferId}/courses/${courseId}/favorite`);
    }

    removeFavoriteCourse(golferId: number, courseId: number) {
        return this.httpClient.delete(`${this.baseUrl}/${golferId}/courses/${courseId}/favorite`);
    }

    getGolfClubs(golferId: number, args?: ModelsWebApiGolfersGetGolfClubsArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsCoreGolfersGolfClub[]>(`${this.baseUrl}/${golferId}/golf-clubs${queryString}`);
    }

    addGolfClub(golferId: number, model: ModelsWebApiGolfersSaveGolfClubModel) {
        return this.httpClient.post<ModelsGolfersAddGolfClubAddGolfClubResponse>(`${this.baseUrl}/${golferId}/golf-clubs`, model);
    }

    saveGolfClub(golferId: number, golfClubId: number, model: ModelsWebApiGolfersSaveGolfClubModel) {
        return this.httpClient.put<any>(`${this.baseUrl}/${golferId}/golf-clubs/${golfClubId}`, model);
    }

    removeGolfClub(golferId: number, golfClubId: number) {
        return this.httpClient.delete<any>(`${this.baseUrl}/${golferId}/golf-clubs/${golfClubId}`);
    }

    getStatistics(golferId: number, args?: ModelsWebApiGolfersGetStatisticsArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsStatisticsGetStatisticsGetStatisticsResponse>(
            `${this.baseUrl}/${golferId}/statistics${queryString}`);
    }

    getChart(golferId: number, args?: ModelsWebApiGolfersGetStatisticsChartArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsStatisticsGetChartGetChartResponse>(`${this.baseUrl}/${golferId}/statistics/chart${queryString}`);
    }

    getAchievements(golferId: number, args?: ModelsWebApiGolfersGetAchievementsArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsStatisticsGetAchievementsGetAchievementsResponse>(
            `${this.baseUrl}/${golferId}/achievements${queryString}`);
    }

    getYearsPlayed(golferId: number, args?: ModelsWebApiGolfersGetYearsPlayedArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<number[]>(`${this.baseUrl}/${golferId}/rounds/years${queryString}`);
    }

    getYearsPracticed(golferId: number) {
        return this.httpClient.get<number[]>(`${this.baseUrl}/${golferId}/practice/years`);
    }

    getCourseHistory(golferId: number, courseId: number, args?: ModelsWebApiGolfersGetCourseHistoryArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsCoursesGetCourseHistoryGetCourseHistoryResponse>(
            `${this.baseUrl}/${golferId}/courses/${courseId}/history${queryString}`);
    }

    getHoleHistory(golferId: number, holeId: number, args: ModelsWebApiGolfersGetHoleHistoryArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsCoreRoundsGolfRoundGolfer[]>(
            `${this.baseUrl}/${golferId}/holes/${holeId}/history${queryString}`);
    }

    getHandicaps(golferId: number) {
        return this.httpClient.get<ModelsGolfersGetHandicapsGetHandicapsResponse>(`${this.baseUrl}/${golferId}/handicaps`);
    }

    getProjectedHandicap(golferId: number, args?: ModelsWebApiGolfersGetProjectedHandicapArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsGolfersGetProjectedHandicapGetProjectedHandicapResponse>(
            `${this.baseUrl}/${golferId}/handicaps/projected${queryString}`);
    }

    getHistoricalHandicaps(golferId: number, args?: ModelsWebApiGolfersGetHistoricalHandicapsArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsGolfersGetHistoricalHandicapsGetHistoricalHandicapsResponse>(
            `${this.baseUrl}/${golferId}/handicaps/historical${queryString}`);
    }

    getFavoriteStatistics(golferId: number) {
        return this.httpClient.get<ModelsGolfersGetFavoriteStatisticsStatistic[]>(
            `${this.baseUrl}/${golferId}/statistics/favorite`);
    }

    addFavoriteStatistic(golferId: number, statisticId: number) {
        return this.httpClient.post(`${this.baseUrl}/${golferId}/statistics/favorite`, statisticId);
    }

    removeFavoriteStatistic(golferId: number, statisticId: number) {
        return this.httpClient.delete(`${this.baseUrl}/${golferId}/statistics/${statisticId}/favorite`);
    }

    getFavoriteAchievements(golferId: number) {
        return this.httpClient.get<ModelsGolfersGetFavoriteAchievementsAchievement[]>(
            `${this.baseUrl}/${golferId}/achievements/favorite`);
    }

    addFavoriteAchievement(golferId: number, achievementId: number) {
        return this.httpClient.post(`${this.baseUrl}/${golferId}/achievements/favorite`, achievementId);
    }

    removeFavoriteAchievement(golferId: number, achievementId: number) {
        return this.httpClient.delete(`${this.baseUrl}/${golferId}/achievements/${achievementId}/favorite`);
    }

    getHeadToHeadStatistics(golferId: number, args?: ModelsWebApiGolfersGetHeadToHeadStatisticsArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsStatisticsGetHeadToHeadStatisticsGolferHeadToHeadStatistics[]>(
            `${this.baseUrl}/${golferId}/head-to-head-statistics${queryString}`);
    }

    getAchievementHistory(golferId: number, achievementId: number, args?: ModelsWebApiGolfersGetAchievementHistoryArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsStatisticsGetAchievementHistoryAchievementHistory[]>(
            `${this.baseUrl}/${golferId}/achievements/${achievementId}/history${queryString}`);
    }

    getGoals(golferId: number, args?: ModelsWebApiGolfersGetGoalsArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsStatisticsGetGoalsGetGoalsResponse>(
            `${this.baseUrl}/${golferId}/goals${queryString}`);
    }

    getGoal(golferId: number, goalId: number) {
        return this.httpClient.get<ModelsCoreStatisticsGoal>(
            `${this.baseUrl}/${golferId}/goals/${goalId}`);
    }

    saveGoal(golferId: number, goalId: number, model: ModelsWebApiGolfersSaveGoalModel) {
        if(goalId > 0) {
            return this.httpClient.put<ModelsCoreStatisticsGoal>(
                `${this.baseUrl}/${golferId}/goals/${goalId}`,
                model);
        }
        else {
            return this.httpClient.post<ModelsCoreStatisticsGoal>(
                `${this.baseUrl}/${golferId}/goals`,
                model);
        }
    }

    deleteGoal(golferId: number, goalId: number) {
        return this.httpClient.delete<any>(
            `${this.baseUrl}/${golferId}/goals/${goalId}`);
    }

    saveDefaultTrackedMetrics(golferId: number, trackedMetricIds: number[]) {
        return this.httpClient.put<any>(
            `${this.baseUrl}/${golferId}/default-tracked-metrics`, trackedMetricIds);
    }

    getSwingThoughts(golferId: number) {
        return this.httpClient.get<ModelsGolfersGetSwingThoughtsSwingThought[]>(`${this.baseUrl}/${golferId}/swing-thoughts`);
    }

    addSwingThought(golferId: number, model: ModelsWebApiGolfersSaveSwingThoughtModel) {
        return this.httpClient.post<ModelsGolfersSaveSwingThoughtSaveSwingThoughtResponse>(
            `${this.baseUrl}/${golferId}/swing-thoughts`, model);
    }

    saveSwingThought(golferId: number, swingThoughtId: number, model: ModelsWebApiGolfersSaveSwingThoughtModel) {
        return this.httpClient.put<any>(`${this.baseUrl}/${golferId}/swing-thoughts/${swingThoughtId}`, model);
    }

    removeSwingThought(golferId: number, swingThoughtId: number) {
        return this.httpClient.delete<any>(`${this.baseUrl}/${golferId}/swing-thoughts/${swingThoughtId}`);
    }

    getPracticePlans(golferId: number) {
        return this.httpClient.get<ModelsCorePracticePracticePlan[]>(`${this.baseUrl}/${golferId}/practice/plans`);
    }

    getPracticeActivities(golferId: number, args?: ModelsWebApiGolfersGetPracticeActivitiesArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsCorePracticePracticeActivity[]>(`${this.baseUrl}/${golferId}/practice/activities${queryString}`);
    }

    getPracticeActivitiesSummary(golferId: number, args?: ModelsWebApiGolfersGetPracticeActivitySummaryArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsCorePracticePracticeActivitySummary[]>(
            `${this.baseUrl}/${golferId}/practice/activities/summary${queryString}`);
    }
    
    getPracticeSessions(golferId: number, args?: ModelsWebApiGolfersGetPracticeSessionsArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsCorePracticePracticeSession[]>(`${this.baseUrl}/${golferId}/practice/sessions${queryString}`);
    }

    getNotificationPreferences(golferId: number) {
        return this.httpClient.get<ModelsGolfersGetNotificationPreferencesGetNotificationPreferencesResponse>(
            `${this.baseUrl}/${golferId}/notifications/preferences`);
    }

    saveNotificationPreferences(golferId: number, model: ModelsWebApiGolfersSaveNotificationsModel) {
        return this.httpClient.put<any>(`${this.baseUrl}/${golferId}/notifications/preferences`, model);
    }

    saveMobileDevice(golferId: number, model: ModelsWebApiGolfersSaveMobileDeviceModel) {
        return this.httpClient.post<any>(`${this.baseUrl}/${golferId}/mobile-devices`, model);
    }

    getImprovementPlans(golferId: number) {
        return this.httpClient.get<ModelsCorePracticeImprovementPlan[]>(`${this.baseUrl}/${golferId}/improvement-plans`);
    }

    saveImprovementPlans(golferId: number, models: ModelsWebApiGolfersSaveImprovementPlanModel[]) {
        return this.httpClient.post<any>(`${this.baseUrl}/${golferId}/improvement-plans`, models);
    }

    savePracticePlanOrder(golferId: number, orderedPracticePlanIds: number[]) {
        return this.httpClient.put<any>(`${this.baseUrl}/${golferId}/practice/plans/ordinals`, orderedPracticePlanIds);
    }

    getStrokeAnalysis(golferId: number, args?: ModelsWebApiGolfersGetStrokeAnalysisArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsRoundsAnalyzeStrokesAnalyzeStrokesResponse>(
            `${this.baseUrl}/${golferId}/rounds/stroke-analysis${queryString}`);
    }
}
