<div class="card">
    <h1>Practice Plan</h1>
    <form *ngIf="practicePlan" #practicePlanForm="ngForm" autocomplete="off" novalidate="novalidate">
        <div class="grid form-group">
            <div class="field col-12 lg:col-4" *ngIf="!isAdding">
                <span class="p-float-label">
                    <input #name="ngModel" style="width: 100%" name="name" maxlength="50" pInputText [(ngModel)]="practicePlan.name" required>
                    <my-validation-message [model]="name" type="required"></my-validation-message>
                    <label>Name</label>
                </span>
            </div>
            <div class="col-12 inline-fields" *ngIf="!isAdding">
                <span class="field">
                    <p-checkbox name="notificationsEnabled" [(ngModel)]="practicePlan.notificationsEnabled" binary="true" label="Enable Notifications"></p-checkbox>
                    <i class="fas fa-info-circle help-icon" pTooltip="This option allows you to decide if your friends are notified when you complete a practice session based on this plan. For example, if this is a 10 minute plan you do at home multiple times per day then you probably would not want to spam your friends with multiple notifications through the day. Your friends will only receive a notification for sessions that include at least one plan that has notifications enabled."></i>
                </span>
                <span class="field">
                    <p-checkbox name="isActive" [(ngModel)]="practicePlan.isActive" binary="true" label="Is Active"></p-checkbox>
                </span>
                <span class="field">
                    <button type="submit" pButton label="Save" (click)="save()" [disabled]="isLoading  || !practicePlanForm.form.valid || practicePlan.practicePlanActivities.length === 0"></button>
                </span>
            </div>
            <div class="col-12" *ngIf="!isAddingChildPracticePlans">
                <p-fieldset legend="Planned Activities">
                    <p-table *ngIf="!isAddingPracticeActivities" [value]="practicePlan.practicePlanActivities" [loading]="isLoading" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md">
                        <ng-template pTemplate="caption">
                            Planned Activities
                            <i class="fas fa-plus-square new-item-icon clickable" (click)="addPracticeActivities()" pTooltip="Add an Activity"></i>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="reorder-column"></th>
                                <th class="width3 left">Category</th>
                                <th class="width3 left">Practice Type</th>
                                <th class="width13 left">Activity</th>
                                <th class="left">Variation</th>
                                <th class="width2">Planned<br />Minutes</th>
                                <th class="width2">Average<br />Time</th>
                                <th class="width5">Goal</th>
                                <th class="width1"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-plannedActivity let-rowIndex="rowIndex">
                            <tr [pReorderableRow]="rowIndex">
                                <td>
                                    <i class="fa fa-bars clickable" pReorderableRowHandle></i>
                                </td>
                                <td class="left">
                                    <span class="p-column-title">Category</span>
                                    {{plannedActivity.practiceActivity.practiceCategory.name}}
                                </td>
                                <td class="left">
                                    <span class="p-column-title">Practice Type</span>
                                    {{plannedActivity.practiceActivity.practiceType.name}}
                                </td>
                                <td class="left">
                                    <span class="p-column-title">Activity</span>
                                    {{plannedActivity.practiceActivity.name}}<br />
                                    <span class="status-icons">
                                        <i class="fas fa-info-circle help-icon" pTooltip="{{plannedActivity.practiceActivity.description}}" [escape]="false"></i>
                                        <i *ngIf="shouldShowScoringHistoryChart(plannedActivity)" class="fas fa-chart-line line-chart clickable" pTooltip="Scoring History" (click)="showScoringHistoryChart(plannedActivity)"></i>
                                    </span>
                                </td>
                                <td class="left">
                                    <span class="p-column-title">Variation</span>
                                    <span *ngIf="plannedActivity.practiceActivityVariationSelectItems">
                                        <p-dropdown #practiceActivityVariation="ngModel" styleClass="width-full" name="practiceActivityVariation-{{rowIndex}}" [options]="plannedActivity.practiceActivityVariationSelectItems" [(ngModel)]="plannedActivity.practiceActivityVariation.practiceActivityVariationId" required (onChange)="onActivityVariationChanged(plannedActivity)"></p-dropdown>
                                        <my-validation-message [model]="practiceActivityVariation" type="required"></my-validation-message>
                                    </span>
                                    <span *ngIf="plannedActivity.practiceActivityVariation"><br />{{plannedActivity.practiceActivityVariation.description}}</span>
                                </td>
                                <td>
                                    <span class="p-column-title">Planned<br />Minutes</span>
                                    <input #plannedMinutes="ngModel" name="plannedMinutes-{{rowIndex}}" class="center" type="number" min="0" pInputText [(ngModel)]="plannedActivity.plannedMinutes">
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Average<br />Time</span>
                                    {{getAverageDuration(plannedActivity)}}
                                </td>
                                <td>
                                    <span class="p-column-title">Goal</span>
                                    <span *ngIf="plannedActivity.practiceActivityVariation">{{plannedActivity.practiceActivityVariation.goal}}</span>
                                </td>
                                <td>
                                    <span class="status-icons">
                                        <i class="fas fa-trash-alt clickable delete-icon" pTooltip="Delete" (click)="deletePracticeActivity(plannedActivity, rowIndex)"></i>
                                    </span>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>{{getTotalPlannedMinutes()}} <i *ngIf="shouldShowPracticeTimeByCategoryChartIcon()" class="fas fa-chart-pie clickable pie-chart-icon" pTooltip="Click to View Practice Time Per Category" (click)="showPracticeTimeByCategoryChart()"></i></th>
                                <th>{{getTotalAverageDuration()}}</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">
                                    {{getEmptyMessage('No planned activities.')}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <my-practice-activity-list [hidden]="!isAddingPracticeActivities" [enableAddingToPlan]="true" (activityAdded)="onActivityAddedToPlan($event)"></my-practice-activity-list>
                    <div class="col-12" *ngIf="isAddingPracticeActivities">
                        <button type="submit" pButton label="Done" (click)="stopAddingActivities()"></button>
                    </div>
                </p-fieldset>
            </div>
            <div class="col-12 lg:col-6" *ngIf="!isAddingPracticeActivities">
                <p-fieldset legend="Child Practice Plans">
                    <p-table *ngIf="!isAddingChildPracticePlans" [value]="practicePlan.childPracticePlans" [loading]="isLoading" styleClass="p-datatable-striped">
                        <ng-template pTemplate="caption">
                            Child Practice Plans
                            <i class="fas fa-plus-square new-item-icon clickable" (click)="addChildPracticePlans()" pTooltip="Add a Child Plan"></i>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="reorder-column"></th>
                                <th class="left">Name</th>
                                <th class="width1"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-plan let-rowIndex="rowIndex">
                            <tr [pReorderableRow]="rowIndex">
                                <td>
                                    <i class="fa fa-bars clickable" pReorderableRowHandle></i>
                                </td>
                                <td class="left">
                                    <span class="p-column-title">Name</span>
                                    {{plan.name}}
                                </td>
                                <td>
                                    <span class="status-icons">
                                        <i class="fas fa-trash-alt clickable delete-icon" pTooltip="Delete" (click)="deleteChildPracticePlan(plan, rowIndex)"></i>
                                    </span>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="3">
                                    {{getEmptyMessage('No child plans.')}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <my-practice-plan-list [hidden]="!isAddingChildPracticePlans" [enableAddingToPlan]="true" [excludePlanIds]="excludedChildPlanIds" (planAdded)="onChildPlanAddedToPlan($event)"></my-practice-plan-list>
                    <div class="col-12" *ngIf="isAddingChildPracticePlans">
                        <button type="submit" pButton label="Done" (click)="stopAddingChildPlans()"></button>
                    </div>
                </p-fieldset>
            </div>
            <div class="col-12" *ngIf="!isAdding">
                <p-message text="Plan changes only affect future practice sessions." severity="info"></p-message>
            </div>
            <div class="col-12" *ngIf="!isAdding">
                <button type="submit" pButton label="Save" (click)="save()" [disabled]="isLoading  || !practicePlanForm.form.valid || practicePlan.practicePlanActivities.length === 0"></button>
            </div>
            <div class="col-12">
                <my-messages></my-messages>
            </div>
        </div>
    </form>
</div>
<p-confirmDialog key="practice-plan-detail-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
