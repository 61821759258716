import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { roundsRoutes } from './rounds.routes';
import { SharedModule } from '../shared/shared.module';
import { ChartsModule } from '../charts/charts.module';
import { CoursesModule } from '../courses/courses.module';
import { StatisticsModule } from '../statistics/statistics.module';

import { NewRoundComponent } from './new/new-round.component';
import { RoundDetailTabViewComponent } from './detail/round-detail-tab-view.component';
import { RoundDetailComponent } from './detail/detail/round-detail.component';
import { RoundListComponent } from './list/round-list.component';
import { ScorecardEntryComponent } from './single-hole-scorecard/scorecard-entry/scorecard-entry.component';
import { ScorecardHoleComponent } from './single-hole-scorecard/scorecard-hole/scorecard-hole.component';
import { ScorecardHoleNotesComponent } from './single-hole-scorecard/scorecard-hole-notes/scorecard-hole-notes.component';
import { ScorecardQuickGlanceComponent } from './single-hole-scorecard/scorecard-quick-glance/scorecard-quick-glance.component';
import { SingleHoleScorecardTabViewComponent } from './single-hole-scorecard/single-hole-scorecard-tab-view.component';
import { StrokeAnalysisComponent } from './stroke-analysis/stroke-analysis.component';
import { StrokeAnalysisGroupTableComponent } from './stroke-analysis/stroke-analysis-group-table.component';

@NgModule({
    imports: [
        roundsRoutes,
        CommonModule,
        FormsModule,
        SharedModule,
        ChartsModule,
        CoursesModule,
        StatisticsModule
    ],
    declarations: [
        NewRoundComponent,
        RoundDetailTabViewComponent,
        RoundDetailComponent,
        RoundListComponent,
        ScorecardHoleComponent,
        ScorecardHoleNotesComponent,
        ScorecardEntryComponent,
        ScorecardQuickGlanceComponent,
        SingleHoleScorecardTabViewComponent,
        StrokeAnalysisComponent,
        StrokeAnalysisGroupTableComponent
    ],
    providers: [
    ]
})
export class RoundsModule {

}
