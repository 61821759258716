import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { Subject, timer } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { PracticeControllerProxy } from '../../../shared/server-proxies';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { PracticeTabViewIndexes } from '../../practice-tab-view-indexes';
import { TimeSpan } from '../../../shared/util';
import { GolferLookupService } from '../../../shared/golfers/golfer-lookup.service';

import {
    ModelsCorePracticePracticeScoreQualifiers,
    ModelsCorePracticePracticeSession,
    ModelsCorePracticePracticeSessionActivity,
    ModelsWebApiPracticeCompletePracticeSessionActivityModel,
    ModelsWebApiPracticeCompletePracticeSessionModel
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-practice-session-entry',
    templateUrl: './practice-session-entry.component.html',
    styleUrls: [
        './practice-session-entry.component.scss'
    ]
})
export class PracticeSessionEntryComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private practiceControllerProxy: PracticeControllerProxy,
        private golferLookups: GolferLookupService,
        private confirmationService: ConfirmationService,
        private datePipe: DatePipe,
        private router: Router,
        private currentRoute: ActivatedRoute) {
        super();
    }

    practiceSession: ModelsCorePracticePracticeSession;
    sessionJustEnded: boolean;
    yesOrNo: SelectItem[] = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
    ];
    timerElapsedTime: string;
    counter = 0;
    private activeActivity: ModelsCorePracticePracticeSessionActivity;
    private practiceSessionId: number;
    private timerUnsubscribeSubject: Subject<void>;

    ngOnInit() {
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    this.practiceSessionId = parseInt(params['practiceSessionId'], 10) || 0;
                    this.loadPracticeSession();
                });
    }

    onDestroy(): void {
        this.stopActivityTimer();
    }

    getActivityHeader(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        let header = '';

        if(sessionActivity.started && sessionActivity.completed && sessionActivity.started === sessionActivity.completed) {
            header = 'SKIPPED - ';
        }
        else if(sessionActivity.completed) {
            header = 'COMPLETED - ';
        }
        else if(sessionActivity.started) {
            header = 'STARTED - ';
        }

        const activity = sessionActivity.practiceActivity;

        header += `${activity.practiceCategory.name}: ${activity.name}`;

        return header;
    }

    showVariation(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return sessionActivity.practiceActivityVariation;
    }

    showPlannedMinutes(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return sessionActivity.plannedMinutes > 0;
    }

    showVariationDescription(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return sessionActivity.practiceActivityVariation
            && sessionActivity.practiceActivityVariation.description
            && sessionActivity.practiceActivityVariation.description.length > 0;
    }

    showGoal(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return sessionActivity.practiceActivityVariation
            && sessionActivity.practiceActivityVariation.goal
            && sessionActivity.practiceActivityVariation.goal.length > 0
            && sessionActivity.started;
    }

    showCounter(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return this.isEditable(sessionActivity);
    }

    showScore(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return sessionActivity.practiceActivity.practiceScoreQualifierId
            !== ModelsCorePracticePracticeScoreQualifiers.None
            && sessionActivity.started;
    }

    showBestScore(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return (sessionActivity.practiceActivityVariation && sessionActivity.practiceActivityVariation.bestScore !== undefined)
            || sessionActivity.practiceActivity.bestScore !== undefined;
    }

    showBestScoreThisYear(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return (sessionActivity.practiceActivityVariation && sessionActivity.practiceActivityVariation.bestScoreThisYear !== undefined)
            || sessionActivity.practiceActivity.bestScoreThisYear !== undefined;
    }

    isEditable(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return sessionActivity.started && !sessionActivity.completed;
    }

    isReadOnly(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return sessionActivity.completed;
    }

    showElapsedMinutes(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return sessionActivity.elapsedMinutes >= 0;
    }

    showAverageDuration(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return (sessionActivity.practiceActivityVariation && sessionActivity.practiceActivityVariation.averageElapsedMinutes > 0)
            || sessionActivity.practiceActivity.averageElapsedMinutes > 0;
    }

    getElapsedTime(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        let elapsedTime;

        if(sessionActivity.started && sessionActivity.completed && sessionActivity.started === sessionActivity.completed) {
            elapsedTime = 'This activity was skipped.';
        }
        else {
            const ts = TimeSpan.fromMinutes(sessionActivity.elapsedMinutes);
            elapsedTime = `Completed in ${ts.toString()}.`;
        }

        return elapsedTime;
    }

    getAverageDuration(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        let duration = '';

        if(sessionActivity.practiceActivityVariation && sessionActivity.practiceActivityVariation.averageElapsedMinutes > 0) {
            duration = sessionActivity.practiceActivityVariation.averageElapsedDuration;
        }
        else if(sessionActivity.practiceActivity.averageElapsedMinutes > 0) {
            duration = sessionActivity.practiceActivity.averageElapsedDuration;
        }

        return duration;
    }

    getBestScore(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        let bestScore;
        let bestScoreDate;

        if(sessionActivity.practiceActivityVariation && sessionActivity.practiceActivityVariation.bestScore !== undefined) {
            bestScore = sessionActivity.practiceActivityVariation.bestScore;
            bestScoreDate = sessionActivity.practiceActivityVariation.mostRecentDateOfBestScore;
        }
        else if(sessionActivity.practiceActivity.bestScore !== undefined) {
            bestScore = sessionActivity.practiceActivity.bestScore;
            bestScoreDate = sessionActivity.practiceActivity.mostRecentDateOfBestScore;
        }

        const formattedDate = this.datePipe.transform(bestScoreDate, 'EEEE, M/d/yyyy');

        return bestScore !== undefined ? `${bestScore} on ${formattedDate}` : '';
    }

    getBestScoreThisYear(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        let bestScore;
        let bestScoreDate;

        if(sessionActivity.practiceActivityVariation && sessionActivity.practiceActivityVariation.bestScoreThisYear !== undefined) {
            bestScore = sessionActivity.practiceActivityVariation.bestScoreThisYear;
            bestScoreDate = sessionActivity.practiceActivityVariation.mostRecentDateOfBestScoreThisYear;
        }
        else if(sessionActivity.practiceActivity.bestScoreThisYear !== undefined) {
            bestScore = sessionActivity.practiceActivity.bestScoreThisYear;
            bestScoreDate = sessionActivity.practiceActivity.mostRecentDateOfBestScoreThisYear;
        }

        const formattedDate = this.datePipe.transform(bestScoreDate, 'EEEE, M/d/yyyy');

        return bestScore !== undefined ? `${bestScore} on ${formattedDate}` : '';
    }

    getGoalAchievedYesOrNo(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return sessionActivity.isGoalAchieved ? 'Yes' : 'No';
    }

    canStartActivity(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return !sessionActivity.started;
    }

    canCompleteActivity(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return sessionActivity.started && !sessionActivity.completed;
    }

    onActivitySelected(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        this.activeActivity = sessionActivity;
    }

    startPracticeActivity(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        this.taskStarted();
        this.counter = 0;

        this.practiceControllerProxy.startPracticeSessionActivity(sessionActivity.practiceSessionActivityId)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(response => {
                sessionActivity.started = response.body.started;
                this.startActivityTimer(sessionActivity);
            });
    }

    skipPracticeActivity(sessionActivity: ModelsCorePracticePracticeSessionActivity, index: number) {
        const message = `Are you sure you want to skip ${sessionActivity.practiceActivity.name} for this session?`;

        this.confirmationService.confirm(
            {
                key: 'practice-session-entry-component',
                header: 'Skip Practice Activity?',
                message: message,
                accept: () => this.completePracticeActivity(sessionActivity, index)
            });
    }

    completePracticeActivity(sessionActivity: ModelsCorePracticePracticeSessionActivity, index: number) {
        this.taskStarted();

        const model: ModelsWebApiPracticeCompletePracticeSessionActivityModel = {
            score: sessionActivity.score,
            isGoalAchieved: sessionActivity.isGoalAchieved
        };

        this.practiceControllerProxy.completePracticeSessionActivity(sessionActivity.practiceSessionActivityId, model)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(response => {
                const result = response.body;
                sessionActivity.started = result.started;
                sessionActivity.completed = result.completed;
                sessionActivity.elapsedMinutes = result.elapsedMinutes;
                this.stopActivityTimer();

                if(result.isSessionComplete) {
                    this.activeActivity = undefined;
                    this.practiceSession.completed = result.completed;
                    this.sessionJustEnded = true;
                }
                else if(index < this.practiceSession.practiceSessionActivities.length - 1) {
                    this.setActiveActivityToNextIncompleteActivity(index);
                }
                else {
                    this.setActiveActivityToNextIncompleteActivity(index);
                }
            });
    }

    completeSession() {
        const model: ModelsWebApiPracticeCompletePracticeSessionModel = {
            notes: this.practiceSession.notes
        };

        this.taskStarted();
        this.practiceControllerProxy.completePracticeSession(this.practiceSession.practiceSessionId, model)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    this.golferLookups.resetYearsPracticed();
                    this.router.navigate(['/practice', { tab: PracticeTabViewIndexes.sessionsTabIndex }]);
                });
    }

    isActiveActivity(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return sessionActivity === this.activeActivity;
    }

    isActivityDisabled(sessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return this.activeActivity
            && this.activeActivity.started
            && !this.activeActivity.completed
            && this.activeActivity !== sessionActivity;
    }

    private loadPracticeSession() {
        this.taskStarted();
        this.practiceControllerProxy.getPracticeSession(this.practiceSessionId)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.practiceSession = response.body;

                    this.practiceSession.practiceSessionActivities.forEach(psa => {
                        if(isNaN(psa.score)
                            && psa.practiceActivity.practiceScoreQualifierId !== ModelsCorePracticePracticeScoreQualifiers.None) {

                            const startingScore = psa.practiceActivityVariation
                                ? psa.practiceActivityVariation.startingScore || psa.practiceActivity.startingScore
                                : psa.practiceActivity.startingScore;
                            psa.score = startingScore || 0;
                        }
                    });

                    this.setActiveActivityToNextIncompleteActivity();
                    this.startActivityTimer(this.activeActivity);
                });
    }

    private setActiveActivityToNextIncompleteActivity(startingIndex = 0) {
        for(let i = startingIndex; i < this.practiceSession.practiceSessionActivities.length; i++) {
            const activity = this.practiceSession.practiceSessionActivities[i];

            if(!activity.completed) {
                this.activeActivity = activity;
                break;
            }
        }
    }

    private startActivityTimer(sessionActivity: ModelsCorePracticePracticeSessionActivity, intervalMilliseconds = 1000) {
        if(sessionActivity && sessionActivity.started && !sessionActivity.completed) {
            this.stopActivityTimer();
            this.timerUnsubscribeSubject = new Subject<void>();

            const ts = new TimeSpan();

            timer(0, intervalMilliseconds)
                .pipe(takeUntil(this.timerUnsubscribeSubject))
                .subscribe(
                    () => {
                        const now = new Date();
                        ts.subtractDates(sessionActivity.started, now);

                        const includeSeconds = intervalMilliseconds < 60000;
                        this.timerElapsedTime = ts.toString(includeSeconds);

                        if(intervalMilliseconds < 60000 && ts.totalSeconds >= 60) {
                            // After 1 minute, switch to a timer that updates every minute instead of every second.
                            this.startActivityTimer(sessionActivity, 60000);
                        }
                    });
        }
    }

    private stopActivityTimer() {
        if(this.timerUnsubscribeSubject) {
            this.timerElapsedTime = undefined;
            this.timerUnsubscribeSubject.next();
            this.timerUnsubscribeSubject.complete();
            this.timerUnsubscribeSubject = undefined;
        }
    }
}
