import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { RoundsControllerProxy } from '../../shared/server-proxies';
import { MessagesComponent } from '../../shared/ui/messages/messages.component';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import {
    ModelsCoreRoundsGolfRound,
    ModelsWebApiRoundsGetRoundArgs
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-round-detail-tab-view',
    templateUrl: './round-detail-tab-view.component.html'
})
export class RoundDetailTabViewComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private roundsProxy: RoundsControllerProxy,
        private router: Router,
        private currentRoute: ActivatedRoute) {
        super();
    }

    @ViewChild(MessagesComponent, { static: true }) messages: MessagesComponent;
    round: ModelsCoreRoundsGolfRound;
    activeTabIndex: number;
    isStartingRound: boolean;
    isCompletingRound: boolean;

    ngOnInit(): void {
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    this.activeTabIndex = parseInt(params['tab'], 10) || 0;
                    this.isStartingRound = params['starting'] === 'true';
                    this.isCompletingRound = params['completing'] === 'true';

                    const golfRoundIdParam = params['golfRoundId'];
                    const golfRoundId = parseInt(golfRoundIdParam, 10);

                    if(golfRoundId) {
                        this.loadGolfRound(golfRoundId);
                    }
                    else {
                        this.messages.showError(`Invalid golf round id: ${golfRoundIdParam}`);
                    }
                });
    }

    onActiveTabChanged(e: any) {
        const params = {
            tab: e.index
        };

        this.router.navigate(['rounds', this.round.golfRoundId, params]);
    }

    getSecondaryTabHeaderStyle() {
        return this.isStartingRound || this.isCompletingRound ? { display: 'none' } : null;
    }

    private loadGolfRound(golfRoundId: number) {
        const args: ModelsWebApiRoundsGetRoundArgs = { };

        if(this.isStartingRound) {
            args.lookupWeather = true;
        }

        return this.roundsProxy.getRound(golfRoundId, args)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.round = response.body;
                },
                error => {
                    if(error.status === 404) {
                        this.messages.showError(`Golf round ${golfRoundId} does not exist.`);
                    }
                    else {
                        this.messages.showError(`An error occurred. (${error.status})`);
                    }
                });
    }
}
