import { Injectable } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';

import {
    ModelsGolfersAuthenticateAuthenticateResponse,
    ModelsWebApiAuthenticationLoginModel,
    ModelsWebApiAuthenticationLoginWithTokenModel
} from '../../shared/swagger-codegen/models';

@Injectable()
export class AuthenticationControllerProxy {
    private baseUrl = '/api/authentication';

    constructor(private httpClient: HttpClientWrapperService) {
    }

    login(model: ModelsWebApiAuthenticationLoginModel) {
        return this.httpClient.post<ModelsGolfersAuthenticateAuthenticateResponse>(this.baseUrl, model);
    }

    loginWithToken(model: ModelsWebApiAuthenticationLoginWithTokenModel) {
        return this.httpClient.post<ModelsGolfersAuthenticateAuthenticateResponse>(`${this.baseUrl}/token`, model);
    }

    logout() {
        return this.httpClient.delete(this.baseUrl);
    }
}
