import { Component, OnInit, Input } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';

import { GolfersControllerProxy } from '../../../shared/server-proxies';
import { MessagesComponent } from '../../../shared/ui/messages/messages.component';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';

import {
    ModelsCoreGolfersGolfer,
    ModelsGolfersGetSwingThoughtsSwingThought,
    ModelsWebApiGolfersSaveSwingThoughtModel
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-swing-thoughts',
    templateUrl: './swing-thoughts.component.html'
})
export class SwingThoughtsComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersControllerProxy: GolfersControllerProxy,
        private confirmationService: ConfirmationService) {
        super();
    }

    @Input() golfer: ModelsCoreGolfersGolfer;
    @Input() messages: MessagesComponent;
    swingThoughts: ModelsGolfersGetSwingThoughtsSwingThought[];
    selectedSwingThought: ModelsGolfersGetSwingThoughtsSwingThought;
    newSwingThought: ModelsGolfersGetSwingThoughtsSwingThought;
    private selectedSwingThoughtOriginalState: ModelsGolfersGetSwingThoughtsSwingThought;

    ngOnInit() {
        this.createNewSwingThoughtPlaceholder();

        this.taskStarted();
        this.golfersControllerProxy.getSwingThoughts(this.golfer.golferId)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.swingThoughts = response.body;
                });
    }

    editSwingThought(swingThought: ModelsGolfersGetSwingThoughtsSwingThought) {
        const copy = {} as ModelsGolfersGetSwingThoughtsSwingThought;

        this.copyGolfGlub(swingThought, copy);

        this.selectedSwingThoughtOriginalState = copy;
        this.selectedSwingThought = swingThought;
    }

    deleteSwingThought(swingThought: ModelsGolfersGetSwingThoughtsSwingThought, index: number) {
        const message = `Are you sure you want to delete this swing thought?`;

        this.confirmationService.confirm(
            {
                key: 'swing-thoughts-component',
                header: 'Delete Swing Thought?',
                message: message,
                accept: () => {
                    this.taskStarted();
                    this.golfersControllerProxy.removeSwingThought(this.golfer.golferId, swingThought.swingThoughtId)
                        .pipe(
                            finalize(() => this.taskCompleted()),
                            this.takeUntilUnsubscribed())
                        .subscribe(
                            () => {
                                this.swingThoughts.splice(index, 1);
                            },
                            response => this.messages.showApiError(response));
                }
            });
    }

    cancelSwingThoughtEdit() {
        if(this.selectedSwingThought && this.selectedSwingThoughtOriginalState) {
            this.copyGolfGlub(this.selectedSwingThoughtOriginalState, this.selectedSwingThought);
        }

        this.selectedSwingThought = undefined;
    }

    saveSwingThought(swingThought: ModelsGolfersGetSwingThoughtsSwingThought) {
        const model = this.createSaveModelFromSwingThought(swingThought);

        this.taskStarted();
        this.golfersControllerProxy.saveSwingThought(this.golfer.golferId, swingThought.swingThoughtId, model)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    this.selectedSwingThought = undefined;
                    this.sortSwingThoughts();
                },
                response => this.messages.showApiError(response)
            );
    }

    addSwingThought(swingThought: ModelsGolfersGetSwingThoughtsSwingThought) {
        const model = this.createSaveModelFromSwingThought(swingThought);

        this.taskStarted();
        this.golfersControllerProxy.addSwingThought(this.golfer.golferId, model)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    swingThought.swingThoughtId = response.body.swingThoughtId;

                    this.swingThoughts.push(swingThought);
                    this.createNewSwingThoughtPlaceholder();
                    this.sortSwingThoughts();
                },
                response => this.messages.showApiError(response));
    }

    private createSaveModelFromSwingThought(swingThought: ModelsGolfersGetSwingThoughtsSwingThought) {
        const model: ModelsWebApiGolfersSaveSwingThoughtModel = {
            description: swingThought.description
        };

        return model;
    }

    private copyGolfGlub(source: ModelsGolfersGetSwingThoughtsSwingThought, destination: ModelsGolfersGetSwingThoughtsSwingThought) {
        destination.swingThoughtId = source.swingThoughtId;
        destination.description = source.description;
    }

    private createNewSwingThoughtPlaceholder() {
        this.newSwingThought = {} as ModelsGolfersGetSwingThoughtsSwingThought;
    }

    private sortSwingThoughts() {
        this.swingThoughts = this.swingThoughts.sort(
            (a, b) => {
                if(a.description < b.description) {
                    return -1;
                }
                else if(a.description > b.description) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
    }
}
