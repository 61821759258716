/// <reference types="google.maps" />
import {
    ModelsCoreCoursesMapMarkers
} from '../../swagger-codegen/models';

import { getTeeMarkerIcon } from './map-marker-icons';
import { MapMarkerWrapper } from './map-marker-wrapper';

import LatLng = google.maps.LatLng;
import Map = google.maps.Map;
import Size = google.maps.Size;

export interface TeeModel {
    teeId: number;
    name: string;
    cssClassName: string;
}

export class TeeMapMarker extends MapMarkerWrapper {
    constructor(map: Map, position: LatLng, tee: TeeModel) {
        super(
            ModelsCoreCoursesMapMarkers.Tee,
            {
                map: map,
                position: position,
                draggable: true,
                icon: {
                    scaledSize: new Size(32, 32),
                    url: getTeeMarkerIcon(tee.cssClassName)
                },
                title: `${tee.name} Tee`
            }
        );
        
        this.myTee = tee;
        this.nativeMarker.set('teeId', tee.teeId);
    }

    get tee() {
        return this.myTee;
    }

    private myTee: TeeModel;

    createUpdateModel() {
        const model = super.createUpdateModel();

        if(this.tee) {
            model.teeId = this.tee.teeId;
        }
        
        return model;
    }
}
