// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreStatisticsMetrics = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 18 | 19 | 21 | 23 | 24;

export const ModelsCoreStatisticsMetrics = {
    Strokes: 0 as ModelsCoreStatisticsMetrics,
    Fairway: 1 as ModelsCoreStatisticsMetrics,
    GIR: 2 as ModelsCoreStatisticsMetrics,
    Putts: 3 as ModelsCoreStatisticsMetrics,
    PenaltyStrokes: 4 as ModelsCoreStatisticsMetrics,
    FairwayResultId: 5 as ModelsCoreStatisticsMetrics,
    TeeShotClubId: 6 as ModelsCoreStatisticsMetrics,
    DrivingDistance: 7 as ModelsCoreStatisticsMetrics,
    ApproachShotResultId: 8 as ModelsCoreStatisticsMetrics,
    ChipResultId: 9 as ModelsCoreStatisticsMetrics,
    PitchResultId: 10 as ModelsCoreStatisticsMetrics,
    SandResultId: 11 as ModelsCoreStatisticsMetrics,
    FirstPuttDistance: 12 as ModelsCoreStatisticsMetrics,
    Mulligans: 13 as ModelsCoreStatisticsMetrics,
    LastPuttDistance: 14 as ModelsCoreStatisticsMetrics,
    ApproachShotClubId: 18 as ModelsCoreStatisticsMetrics,
    ApproachShotDistance: 19 as ModelsCoreStatisticsMetrics,
    GreenHitFromDistance: 21 as ModelsCoreStatisticsMetrics,
    UpAndDowns: 23 as ModelsCoreStatisticsMetrics,
    StrokesGained: 24 as ModelsCoreStatisticsMetrics
};
