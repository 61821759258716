/// <reference types="google.maps" />
import { DeleteMenu } from './delete-menu';
import { Disposable } from './disposable';
import { LabelMapMarker } from './label-map-marker';
import { scrollMarkerIcon } from './map-marker-icons';
import { disposeMapComponents, yardsBetween, yardsToMeters } from './map-utilities';

import LatLng = google.maps.LatLng;
import Map = google.maps.Map;
import Marker = google.maps.Marker;
import MVCObject = google.maps.MVCObject;
import Polyline = google.maps.Polyline;
import Point = google.maps.Point;
import Size = google.maps.Size;
import computeOffset = google.maps.geometry.spherical.computeOffset;
import interpolate = google.maps.geometry.spherical.interpolate;

const strokeColor = '#009900';

export class MeasuringTool implements Disposable {
    constructor(private map: Map, center: LatLng) {
        const halfLength = yardsToMeters(25);
        const fromPosition = computeOffset(center, halfLength, 0);
        const toPosition = computeOffset(center, halfLength, 180);

        this.line = new Polyline({
            map: map,
            path: [
                fromPosition,
                toPosition
            ],
            strokeColor: strokeColor
        });
        this.yardageLabel = new LabelMapMarker(this.map, center);
        this.fromEndpointMarker = this.createEndpointMarker(fromPosition);
        this.toEndpointMarker = this.createEndpointMarker(toPosition);

        this.updateYardageLabel();
    }

    private line: Polyline;
    private yardageLabel: LabelMapMarker;
    private fromEndpointMarker: Marker;
    private toEndpointMarker: Marker;

    dispose() {
        disposeMapComponents(
            this.line,
            this.yardageLabel,
            this.fromEndpointMarker,
            this.toEndpointMarker
        );
    }

    addDeleteListener(deleteMenu: DeleteMenu) {
        this.addDeleteListeners(deleteMenu, this.line, this.fromEndpointMarker, this.toEndpointMarker);
    }

    private addDeleteListeners(deleteMenu: DeleteMenu, ...sources: MVCObject[]) {
        sources.forEach(source => {
            source.addListener('rightclick', (args) => {
                deleteMenu.open(args.latLng, this, 'Delete Measuring Tool');
            });
        });
    }

    private createEndpointMarker(position: LatLng) {
        const marker = new Marker(
            {
                map: this.map,
                position: position,
                draggable: true,
                icon: {
                    anchor: new Point(10, 10),
                    scaledSize: new Size(20, 20),
                    url: scrollMarkerIcon
                }
            });

        marker.addListener(
            'drag',
            () => {
                this.updateYardageLabel();
            }
        );

        return marker;
    }

    private updateYardageLabel() {
        const fromPosition = this.fromEndpointMarker.getPosition();
        const toPosition = this.toEndpointMarker.getPosition();

        const path = this.line.getPath();
        path.setAt(0, fromPosition);
        path.setAt(1, toPosition);

        const midPointPosition = interpolate(fromPosition, toPosition, 0.5);
        const yards = yardsBetween(fromPosition, toPosition);
        this.yardageLabel.text = Math.round(yards).toString();
        this.yardageLabel.setPosition(midPointPosition);
    }
}
