import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { practiceRoutes } from './practice.routes';
import { SharedModule } from '../shared/shared.module';
import { ChartsModule } from '../charts/charts.module';
import { ImprovementPlanComponent } from './improvement-plan/improvement-plan.component';
import { PracticeActivityDetailComponent } from './activities/detail/practice-activity-detail.component';
import { PracticeActivityListComponent } from './activities/list/practice-activity-list.component';
import { PracticeActivitySummaryComponent } from './activities/summary/practice-activity-summary.component';
import { PracticeActivityScoreHistoryChartComponent } from './activities/summary/practice-activity-score-history-chart.component';
import { PracticePlanDetailComponent } from './plans/detail/practice-plan-detail.component';
import { PracticePlanListComponent } from './plans/list/practice-plan-list.component';
import { PracticeChartsComponent } from './charts/practice-charts.component';
import { PracticeSessionDetailComponent } from './sessions/detail/practice-session-detail.component';
import { PracticeSessionEntryComponent } from './sessions/entry/practice-session-entry.component';
import { PracticeSessionListComponent } from './sessions/list/practice-session-list.component';
import { NewPracticeSessionComponent } from './sessions/new/new-practice-session.component';
import { PracticeTabViewComponent } from './practice-tab-view.component';
import { PracticeActivityHistoryChartDialogComponent }
    from './practice-activity-history-chart-dialog/practice-activity-history-chart-dialog.component';
import { PracticeActivityHistoryChartDialogService }
    from './practice-activity-history-chart-dialog/practice-activity-history-chart-dialog.service';
import { PracticeTimeChartDialogComponent } from './practice-time-chart-dialog/practice-time-chart-dialog.component';
import { PracticeTimeChartDialogService } from './practice-time-chart-dialog/practice-time-chart-dialog.service';

@NgModule({
    imports: [
        practiceRoutes,
        CommonModule,
        FormsModule,
        SharedModule,
        ChartsModule
    ],
    declarations: [
        ImprovementPlanComponent,
        PracticeActivityDetailComponent,
        PracticeActivityListComponent,
        PracticeActivitySummaryComponent,
        PracticeActivityScoreHistoryChartComponent,
        PracticePlanDetailComponent,
        PracticePlanListComponent,
        PracticeChartsComponent,
        PracticeSessionDetailComponent,
        PracticeSessionEntryComponent,
        PracticeSessionListComponent,
        NewPracticeSessionComponent,
        PracticeTabViewComponent,
        PracticeActivityHistoryChartDialogComponent,
        PracticeTimeChartDialogComponent
    ],
    providers: [
        PracticeActivityHistoryChartDialogService,
        PracticeTimeChartDialogService
    ]
})
export class PracticeModule {

}
