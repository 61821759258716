<div class="card">
    <h1><my-course-name></my-course-name></h1>
    <div class="grid form-group">
        <div class="field col-12 lg:col-2">
            <span class="p-float-label">
                <p-dropdown [options]="golfers" [(ngModel)]="golferId" styleClass="golfer-selector" (onChange)="onGolferChanged()"></p-dropdown>
                <label>Golfer</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1">
            <span class="p-float-label">
                <p-dropdown [options]="yearsPlayed" [(ngModel)]="args.year" [autoDisplayFirst]="false" styleClass="width2" (onChange)="onYearChanged()"></p-dropdown>
                <label>Year</label>
            </span>
        </div>
        <div class="field col-12 lg:col-8">
            <span class="p-float-label">
                <p-dropdown name="golfRoundTypes" [(ngModel)]="args.golfRoundTypeId" [options]="golfRoundTypes" styleClass="width4" required (onChange)="onGolfRoundTypeChanged()"></p-dropdown>
                <label>Golf Round Type</label>
            </span>
        </div>
        <div>
            <p-tabView>
                <p-tabPanel header="Overall" leftIcon="fas fa-calendar-week">
                    <p-table [value]="courseHistory" [loading]="isLoading" styleClass="p-datatable-striped with-bottom-margin" tableStyleClass="p-datatable-responsive-md">
                        <ng-template pTemplate="header">
                            <tr>
                                <th *ngIf="args.year === 0">Year</th>
                                <th class="left">Tee</th>
                                <th class="width2">Rounds</th>
                                <th>18 Hole Scoring</th>
                                <th>9 Hole Scoring</th>
                                <th class="width2">Front 9<br />Scoring</th>
                                <th class="width2">Back 9<br />Scoring</th>
                                <th class="width2">Par or<br />Better</th>
                                <th class="width2">Bogey or<br />Better</th>
                                <th class="width2">Fairways</th>
                                <th class="width2">GIR</th>
                                <th class="width2">Putts/Hole</th>
                                <th class="width2">Putts/GIR</th>
                                <th class="width2">3 Putts</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-history>
                            <tr>
                                <td *ngIf="args.year === 0">
                                    <span class="p-column-title">Year</span>
                                    {{history.year === 0 ? 'ALL' : history.year}}
                                </td>
                                <td class="left">
                                    <span class="p-column-title">Tee</span>
                                    {{history.tee.teeId === 0 ? 'ALL' : history.tee.displayText}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Times Played</span>
                                    {{history.timesPlayed}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Scoring (18)</span>
                                    <span *ngIf="history && history.historyFor18Holes">
                                        {{history.historyFor18Holes.averageScore | number:'1.2-2'}}<br />
                                        ({{history.historyFor18Holes.bestScore}} - {{history.historyFor18Holes.worstScore}})
                                    </span>
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Scoring (9)</span>
                                    <span *ngIf="history && history.historyFor9Holes">
                                        {{history.historyFor9Holes.averageScore | number:'1.2-2'}}<br />
                                        ({{history.historyFor9Holes.bestScore}} - {{history.historyFor9Holes.worstScore}})
                                    </span>
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Front 9 Scoring</span>
                                    {{history.bestFront9Score}} - {{history.worstFront9Score}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Back 9 Scoring</span>
                                    <span *ngIf="history.bestBack9Score">
                                        {{history.bestBack9Score}} - {{history.worstBack9Score}}
                                    </span>
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Par or Better</span>
                                    {{history.parOrBetterPercent | percent:'1.2-2'}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Bogey or Better</span>
                                    {{history.bogeyOrBetterPercent | percent:'1.2-2'}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Fairways</span>
                                    {{history.fairwayPercent | percent:'1.2-2'}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">GIR</span>
                                    {{history.girPercent | percent:'1.2-2'}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Putts/Hole</span>
                                    {{history.averagePutts | number:'1.3-3'}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Putts/GIR</span>
                                    {{history.puttsPerGIR | number:'1.3-3'}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">3 Putts</span>
                                    {{history.threePuttPercent | percent:'1.2-2'}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [attr.colspan]="args.year === 0 ? 13 : 14">
                                    {{getEmptyMessage('No history at this course.')}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-tabPanel>
                <p-tabPanel header="Hole by Hole" leftIcon="fas fa-list-ol">
                    <div class="col-12">
                        <p-selectButton [options]="viewOptions" [(ngModel)]="selectedViewOption" styleClass="view-options"></p-selectButton>
                    </div>
                    <p-table [value]="holeHistory" [loading]="isLoading" styleClass="p-datatable-striped">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="left">Hole</th>
                                <th class="width4" colspan="2">Scoring</th>
                                <th class="width2">Times<br />Played</th>
                                <th class="width2">Birdie or<br />Better</th>
                                <th class="width2">Par or<br />Better</th>
                                <th class="width2">Bogey or<br />Better</th>
                                <th class="width2">Eagles</th>
                                <th class="width2">Birdies</th>
                                <th class="width2">Pars</th>
                                <th class="width2">Bogeys</th>
                                <th class="width2" *ngIf="viewingStats">Fairways</th>
                                <th class="width2" *ngIf="viewingStats">GIR</th>
                                <th class="width2" *ngIf="viewingStats">Putts/Hole</th>
                                <th class="width2" *ngIf="viewingStats">Putts/GIR</th>
                                <th class="width2" *ngIf="viewingStats">1 Putts</th>
                                <th class="width2" *ngIf="viewingStats">3 Putts</th>
                                <th class="width2" *ngIf="viewingStrokesGained">SG: Off the Tee</th>
                                <th class="width2" *ngIf="viewingStrokesGained">SG: Approach the Green</th>
                                <th class="width2" *ngIf="viewingStrokesGained">SG: Around the Green</th>
                                <th class="width2" *ngIf="viewingStrokesGained">SG: Putting</th>
                                <th class="width2" *ngIf="viewingStrokesGained">SG: Tee to Green</th>
                                <th class="width2" *ngIf="viewingStrokesGained">SG: Total</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-history let-rowIndex="rowIndex">
                            <tr class="rowgroup-header" *ngIf="shouldShowHoleHistoryRowGroupHeader(rowIndex)">
                                <td colspan="17">
                                    <span>{{history.courseNineName}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="left">
                                    <a [routerLink]="['/courses', courseId, 'holes', history.holeId, 'history']">{{history.holeDescription}}</a>
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Scoring</span>
                                    {{history.averageStrokes | number:'1.2-2'}}
                                    ({{history.formattedBestScore}} to {{history.formattedWorstScore}})
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Scoring Achievements</span>
                                    <i class="fa-solid fa-trophy scoring-achievement" [ngClass]="getHoleScoringChallengeCssClass(history, -1)" [pTooltip]="getHoleScoringChallengeTooltip(history, -1)"></i>
                                    <i class="fa-solid fa-medal scoring-achievement" [ngClass]="getHoleScoringChallengeCssClass(history, 0)" [pTooltip]="getHoleScoringChallengeTooltip(history, 0)"></i>
                                    <i class="fa-solid fa-award scoring-achievement" [ngClass]="getHoleScoringChallengeCssClass(history, 1)" [pTooltip]="getHoleScoringChallengeTooltip(history, 1)"></i>
                                </td>
                                <td class="center">
                                    {{history.timesPlayed | number}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Birdie or Better</span>
                                    {{history.birdieOrBetterPercent | percent:'1.2-2'}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Par or Better</span>
                                    {{history.parOrBetterPercent | percent:'1.2-2'}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Bogey or Better</span>
                                    {{history.bogeyOrBetterPercent | percent:'1.2-2'}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Eagles</span>
                                    {{history.eagles | number}} -
                                    {{history.eaglePercent | percent:'1.2-2'}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Birdies</span>
                                    {{history.birdies | number}} -
                                    {{history.birdiePercent | percent:'1.2-2'}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Pars</span>
                                    {{history.pars | number}} -
                                    {{history.parPercent | percent:'1.2-2'}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Bogeys</span>
                                    {{history.bogeys | number}} -
                                    {{history.bogeyPercent | percent:'1.2-2'}}
                                </td>
                                <td *ngIf="viewingStats" class="center">
                                    <span class="p-column-title">Fairways</span>
                                    {{history.fairwayPercent | percent:'1.2-2'}}
                                </td>
                                <td *ngIf="viewingStats" class="center">
                                    <span class="p-column-title">GIR</span>
                                    {{history.girPercent | percent:'1.2-2'}}
                                </td>
                                <td *ngIf="viewingStats" class="center">
                                    <span class="p-column-title">Putts/Hole</span>
                                    {{history.averagePutts | number:'1.3-3'}}
                                </td>
                                <td *ngIf="viewingStats" class="center">
                                    <span class="p-column-title">Putts/GIR</span>
                                    {{history.puttsPerGIR | number:'1.3-3'}}
                                </td>
                                <td *ngIf="viewingStats" class="center">
                                    <span class="p-column-title">One Putts</span>
                                    {{history.onePuttPercent | percent:'1.2-2'}}
                                </td>
                                <td *ngIf="viewingStats" class="center">
                                    <span class="p-column-title">Three Putts</span>
                                    {{history.threePuttPercent | percent:'1.2-2'}}
                                </td>
                                <td *ngIf="viewingStrokesGained" class="center">
                                    <span class="p-column-title">SG: Off the Tee</span>
                                    {{getStrokesGainedOffTee(history) | number:'1.3-3'}}
                                </td>
                                <td *ngIf="viewingStrokesGained" class="center">
                                    <span class="p-column-title">SG: Approach the Green</span>
                                    {{getStrokesGainedApproach(history) | number:'1.3-3'}}
                                </td>
                                <td *ngIf="viewingStrokesGained" class="center">
                                    <span class="p-column-title">SG: Around the Green</span>
                                    {{getStrokesGainedAroundGreen(history) | number:'1.3-3'}}
                                </td>
                                <td *ngIf="viewingStrokesGained" class="center">
                                    <span class="p-column-title">SG: Putting</span>
                                    {{getStrokesGainedPutting(history) | number:'1.3-3'}}
                                </td>
                                <td *ngIf="viewingStrokesGained" class="center">
                                    <span class="p-column-title">SG: Tee to Green</span>
                                    {{getStrokesGainedTeeToGreen(history) | number:'1.3-3'}}
                                </td>
                                <td *ngIf="viewingStrokesGained" class="center">
                                    <span class="p-column-title">SG: Total</span>
                                    {{getStrokesGainedTotal(history) | number:'1.3-3'}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr *ngIf="holeHistory && holeHistory.length > 0">
                                <td>
                                    Combined Lowest Score:
                                </td>
                                <td class="center">
                                    {{getBestPossibleScore()}}
                                </td>
                                <td class="center">
                                    <i class="fa-solid fa-trophy scoring-achievement" [ngClass]="getCourseScoringChallengeCssClass(-1)" [pTooltip]="getCourseScoringChallengeTooltip(-1)"></i>
                                    <i class="fa-solid fa-medal scoring-achievement" [ngClass]="getCourseScoringChallengeCssClass(0)" [pTooltip]="getCourseScoringChallengeTooltip(0)"></i>
                                    <i class="fa-solid fa-award scoring-achievement" [ngClass]="getCourseScoringChallengeCssClass(1)" [pTooltip]="getCourseScoringChallengeTooltip(1)"></i>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="center">{{getTotalEagles() | number}}</td>
                                <td class="center">{{getTotalBirdies() | number}}</td>
                                <td class="center">{{getTotalPars() | number}}</td>
                                <td class="center">{{getTotalBogeys() | number}}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="16">
                                    {{getEmptyMessage('No history at this course.')}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>
