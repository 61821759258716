<div style="width: 100%; overflow: auto">
    <table class="p-datatable p-datatable-striped">
        <thead class="p-datatable-thead">
            <tr>
                <th class="transparent width2"></th>
                <th scope="col" class="width1" *ngFor="let golfer of scorecard.golfers">
                    {{golfer.displayName}}
                </th>
            </tr>
        </thead>
        <tbody class="p-datatable-tbody">
            <tr>
                <th scope="row">Projected Score</th>
                <td *ngFor="let golfer of scorecard.golfers">
                    {{getProjectedScore(golfer)}}
                </td>
            </tr>
            <tr>
                <th scope="row">Birdie Out</th>
                <td *ngFor="let golfer of scorecard.golfers">
                    {{getProjectedBirdieOutScore(golfer)}}
                </td>
            </tr>
            <tr>
                <th scope="row">Par Out</th>
                <td *ngFor="let golfer of scorecard.golfers">
                    {{getProjectedParOutScore(golfer)}}
                </td>
            </tr>
            <tr>
                <th scope="row">Bogey Out</th>
                <td *ngFor="let golfer of scorecard.golfers">
                    {{getProjectedBogeyOutScore(golfer)}}
                </td>
            </tr>
            <tr>
                <th scope="row">Par 3 Scoring Avg</th>
                <td *ngFor="let golfer of scorecard.golfers">
                    {{getPar3ScoringAverage(golfer) | number:'1.2-2'}}
                </td>
            </tr>
            <tr>
                <th scope="row">Par 4 Scoring Avg</th>
                <td *ngFor="let golfer of scorecard.golfers">
                    {{getPar4ScoringAverage(golfer) | number:'1.2-2'}}
                </td>
            </tr>
            <tr>
                <th scope="row">Par 5 Scoring Avg</th>
                <td *ngFor="let golfer of scorecard.golfers">
                    {{getPar5ScoringAverage(golfer) | number:'1.2-2'}}
                </td>
            </tr>
            <tr>
                <th scope="row">Minutes/Hole</th>
                <td *ngFor="let golfer of scorecard.golfers">
                    {{golfer.minutesPerHole}}
                </td>
            </tr>
            <tr>
                <th scope="row">Projected End Time</th>
                <td *ngFor="let golfer of scorecard.golfers">
                    <span *ngIf="golfer.projectedEndTime">
                        {{golfer.projectedEndTime | date:'shortTime'}}
                        ({{golfer.remainingMinutes}} min)
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
