import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';

import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import {
    ModelsRoundsAnalyzeStrokesAnalyzeStrokesResponse,
    ModelsRoundsAnalyzeStrokesStrokeGroup
} from '../../shared/swagger-codegen/models';

export interface ModelsRoundsAnalyzeStrokesStrokeGroupWithPercentages extends ModelsRoundsAnalyzeStrokesStrokeGroup {
    percentOfSubset: number;
    percentOfAll: number;
}

@Component({
    selector: 'my-stroke-analysis-group-table',
    templateUrl: './stroke-analysis-group-table.component.html'
})
export class StrokeAnalysisGroupTableComponent extends BaseComponentDirective implements OnChanges {
    constructor() {
        super();
    }

    @Input()
    nameHeader = 'Name';

    @Input()
    totalShotsHeader = 'Total Shots';

    @Input()
    analysis: ModelsRoundsAnalyzeStrokesAnalyzeStrokesResponse;

    @Input()
    groups: ModelsRoundsAnalyzeStrokesStrokeGroupWithPercentages[];

    @Input()
    isPuttAnalysis = false;

    @Input()
    showPercentOfShots = true;

    @Input()
    showStrokesGained = true;

    get columnCount() {
        return 2 + (this.showPercentOfShots ? 2 : 0) + (this.showStrokesGained ? 4 : 0);
    }

    ngOnChanges(changes: SimpleChanges) {
        if(this.groups && this.analysis && (changes['groups'] || changes['analysis'])) {
            this.setPercentages();
        }
    }

    private setPercentages() {
        const subsetTotal = this.isPuttAnalysis ? this.analysis.totalPutts : this.analysis.totalNonPutts;

        this.groups.filter(group => group.isStrokeBased).forEach(group => {
            group.percentOfSubset = subsetTotal === 0 ? 0 : group.totalShots / subsetTotal;
            group.percentOfAll = this.analysis.totalShots === 0 ? 0 : group.totalShots / this.analysis.totalShots;
        });
    }
}
