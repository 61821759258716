<div class="card p-fluid">
    <div class="grid form-group">
        <div class="col-12 lg:col-2">
            <p-selectButton [options]="viewOptions" [(ngModel)]="selectedView" (onChange)="onViewChanged()"></p-selectButton>
        </div>
        <div class="field col-12 lg:col-1" *ngIf="isCityStateView">
            <span class="p-float-label">
                <p-dropdown [options]="states" [(ngModel)]="stateCode" (onChange)="onStateChanged();"></p-dropdown>
                <label>State</label>
            </span>
        </div>
        <div class="field col-12 lg:col-2" *ngIf="isCityStateView">
            <span class="p-float-label">
                <p-dropdown [options]="cities" [(ngModel)]="city" (onChange)="onCityChanged();"></p-dropdown>
                <label>City</label>
            </span>
        </div>
        <div class="col-12 lg:col-2" *ngIf="isAdmin">
            <p-checkbox [(ngModel)]="includeInactive" binary="true" (onChange)="onIncludeInactiveChange()" label="Include Inactive"></p-checkbox>
        </div>
    </div>
    <div>
        <p-table [value]="golfers" [loading]="isLoading" sortField="lastName,firstName" (sortFunction)="customTableSort($event)" [customSort]="true" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md">
            <ng-template pTemplate="caption">
                Golfers
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="lastName,firstName" class="left">
                        Name
                        <p-sortIcon field="lastName,firstName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="city,stateCode">
                        Location
                        <p-sortIcon field="city,stateCode"></p-sortIcon>
                    </th>
                    <th pSortableColumn="email" class="width10">
                        Email
                        <p-sortIcon field="email"></p-sortIcon>
                    </th>
                    <th pSortableColumn="handicap.handicapIndex" class="width4">
                        Handicap
                        <p-sortIcon field="handicap.handicapIndex"></p-sortIcon>
                    </th>
                    <th pSortableColumn="logins" class="width4">
                        Logins
                        <p-sortIcon field="logins"></p-sortIcon>
                    </th>
                    <th pSortableColumn="lastLoggedIn" class="width4">
                        Last Login
                        <p-sortIcon field="lastLoggedIn"></p-sortIcon>
                    </th>
                    <th pSortableColumn="rounds" class="width4">
                        Rounds
                        <p-sortIcon field="rounds"></p-sortIcon>
                    </th>
                    <th pSortableColumn="lastRound" class="width4">
                        Last Round
                        <p-sortIcon field="lastRound"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-golfer>
                <tr>
                    <td class="left">
                        <span class="p-column-title">Name</span>
                        <span *ngIf="!canEditGolfer(golfer)">{{golfer.firstName}} {{golfer.lastName}}</span>
                        <a *ngIf="canEditGolfer(golfer)" [routerLink]="['/golfers', golfer.golferId]">{{golfer.firstName}} {{golfer.lastName}}</a>
                        <span *ngIf="!golfer.isActive"><em>(Inactive)</em></span>
                        <span class="status-icons right">
                            <i class="far fa-comments favorites-icon" pTooltip="Send Friend Request" *ngIf="canFriend(golfer)" (click)="sendFriendRequest(golfer)"></i>
                            <i class="fas fa-comment-dots favorites-icon" pTooltip="Pending Friend Request" *ngIf="isPendingFriend(golfer)"></i>
                            <i class="fas fa-user-friends favorites-icon" pTooltip="Friend" *ngIf="isFriend(golfer)"></i>
                        </span>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Location</span>
                        {{golfer.city}}, {{golfer.stateCode}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Email</span>
                        <a href="mailto:{{golfer?.email}}">{{golfer?.email}}</a>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Handicap</span>
                        {{golfer?.handicap?.formattedHandicapIndex}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Logins</span>
                        {{golfer.logins | number}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Last Login</span>
                        {{golfer.lastLoggedIn | date:'shortDate'}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Rounds</span>
                        {{golfer.rounds | number}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Last Round</span>
                        {{golfer.lastRound | date:'shortDate'}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">
                        {{getEmptyMessage('No golfers.')}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
