<div class="card">
    <h1>{{ getPageTitle() }}</h1>
    <form *ngIf="golfClub" #clubForm="ngForm" autocomplete="off" novalidate="novalidate">
        <div class="grid form-group">
            <div class="col-12 lg:col-4">
                <p-fieldset legend="Club Details">
                    <div class="grid form-group">
                        <div class="field col-12">
                            <span class="p-float-label">
                                <input #name="ngModel" name="name" [(ngModel)]="golfClub.name" maxlength="50" required
                                       pInputText class="width10" autofocus>
                                <my-validation-message [model]="name" type="required"></my-validation-message>
                                <label>Name</label>
                            </span>
                        </div>
                        <div class="field col-12">
                            <span class="p-float-label">
                                <input #abbreviation="ngModel" name="abbreviation" [(ngModel)]="golfClub.abbreviation"
                                       required pInputText maxlength="20">
                                <my-validation-message [model]="abbreviation" type="required"></my-validation-message>
                                <label>Abbreviation</label>
                            </span>
                        </div>
                        <div class="field col-12">
                            <span class="p-float-label">
                                <p-dropdown #golfClubCategoryId="ngModel" name="golfClubCategoryId" [options]="golfClubCategories"
                                            [(ngModel)]="golfClub.golfClubCategory.golfClubCategoryId"
                                            styleClass="category-selector" [autoDisplayFirst]="false" required></p-dropdown>
                                <my-validation-message [model]="golfClubCategoryId" type="required"></my-validation-message>
                                <label>Category</label>
                            </span>
                        </div>
                        <div class="field col-12">
                            <span class="p-float-label">
                                <p-calendar #purchaseDate="ngModel" name="purchaseDate" [(ngModel)]="golfClub.purchaseDate"
                                            showButtonBar="true" dateFormat="m/d/yy"></p-calendar>
                                <label>Purchase Date</label>
                            </span>
                        </div>
                        <div class="field col-12">
                            <p-checkbox name="isActive" label="Is Active" [(ngModel)]="golfClub.isActive" binary="true"></p-checkbox>
                        </div>
                    </div>
                </p-fieldset>
            </div>
            <div class="col-12 lg:col-4">
                <p-fieldset legend="Head">
                    <div class="grid form-group">
                        <div class="field col-12">
                            <span class="p-float-label">
                                <input #headBrand="ngModel" name="headBrand" [(ngModel)]="golfClub.headBrand" maxlength="30"
                                       pInputText class="width10">
                                <label>Brand</label>
                            </span>
                        </div>
                        <div class="field col-12">
                            <span class="p-float-label">
                                <input #headModel="ngModel" name="headModel" [(ngModel)]="golfClub.headModel" maxlength="30"
                                       pInputText class="width10">
                                <label>Model</label>
                            </span>
                        </div>
                    </div>
                </p-fieldset>
            </div>
            <div class="col-12 lg:col-4">
                <p-fieldset legend="Shaft">
                    <div class="grid form-group">
                        <div class="field col-12">
                            <span class="p-float-label">
                                <input #shaftBrand="ngModel" name="shaftBrand" [(ngModel)]="golfClub.shaftBrand"
                                       maxlength="30" pInputText class="width10">
                                <label>Brand</label>
                            </span>
                        </div>
                        <div class="field col-12">
                            <span class="p-float-label">
                                <input #shaftModel="ngModel" name="shaftModel" [(ngModel)]="golfClub.shaftModel"
                                       maxlength="30" pInputText class="width10">
                                <label>Model</label>
                            </span>
                        </div>
                        <div class="field col-12">
                            <span class="p-float-label">
                                <input #shaftFlex="ngModel" name="shaftFlex" [(ngModel)]="golfClub.shaftFlex" maxlength="20"
                                       pInputText class="width10">
                                <label>Flex</label>
                            </span>
                        </div>
                    </div>
                </p-fieldset>
            </div>
            <div class="col-12 lg:col-4">
                <p-fieldset legend="Yardages">
                    <div *ngIf="shouldShowYardage(golfClub)" class="grid form-group">
                        <div class="grid" *ngIf="hasOnCourseYardages(golfClub)">
                            <div class="col-12">
                                <strong>On Course Percentiles (# of Shots: {{golfClub.onCourseStrokesForYardage}})</strong>
                            </div>
                            <div class="col-3 center">
                                <strong>50th</strong> {{golfClub.yardageOnCoursePercentile50}}
                            </div>
                            <div class="col-3 center">
                                <strong>75th</strong> {{golfClub.yardageOnCoursePercentile75}}
                            </div>
                            <div class="col-3 center">
                                <strong>90th</strong> {{golfClub.yardageOnCoursePercentile90}}
                            </div>
                            <div class="col-3 center">
                                <strong>100th</strong> {{golfClub.yardageOnCoursePercentile100}}
                            </div>
                        </div>
                        <div class="field col-12 xl:col-6">
                            <span class="p-float-label">
                                <input #yardage="ngModel" name="yardage" [(ngModel)]="golfClub.yardage" type="number"
                                       required pInputText>
                                <my-validation-message [model]="yardage" type="required"></my-validation-message>
                                <label>{{golfer.golfClubYardageLabel}} Yardage</label>
                            </span>
                        </div>
                        <div class="field col-12 xl:col-6">
                            <span class="p-float-label">
                                <input name="secondaryYardage1" [(ngModel)]="golfClub.secondaryYardage1" type="number" pInputText>
                                <label>{{golfer.golfClubSecondaryYardage1Label}} Yardage</label>
                            </span>
                        </div>
                        <div class="field col-12 xl:col-6">
                            <span class="p-float-label">
                                <input name="secondaryYardage2" [(ngModel)]="golfClub.secondaryYardage2" type="number" pInputText>
                                <label>{{golfer.golfClubSecondaryYardage2Label}} Yardage</label>
                            </span>
                        </div>
                        <div class="field col-12 xl:col-6">
                            <span class="p-float-label">
                                <input name="secondaryYardage3" [(ngModel)]="golfClub.secondaryYardage3" type="number" pInputText>
                                <label>{{golfer.golfClubSecondaryYardage3Label}} Yardage</label>
                            </span>
                        </div>
                        <div class="field col-12 xl:col-6">
                            <span class="p-float-label">
                                <input #lateralDispersion="ngModel" name="lateralDispersion" [(ngModel)]="golfClub.lateralDispersion" type="number"
                                       pInputText>
                                <label>Lateral Dispersion</label>
                            </span>
                        </div>
                        <div class="field col-12 xl:col-6">
                            <span class="p-float-label">
                                <input #distanceDispersion="ngModel" name="distanceDispersion" [(ngModel)]="golfClub.distanceDispersion" type="number"
                                       pInputText>
                                <label>Distance Dispersion</label>
                            </span>
                        </div>
                    </div>
                    <h2 *ngIf="!shouldShowYardage(golfClub)">N/A</h2>
                </p-fieldset>
            </div>
            <div class="col-12 lg:col-4">
                <p-fieldset legend="Specifications">
                    <div class="grid form-group">
                        <div class="field col-12">
                            <span class="p-float-label">
                                <input #length="ngModel" name="length" [(ngModel)]="golfClub.length" type="number"
                                       pInputText class="width10">
                                <label>Length</label>
                            </span>
                        </div>
                        <div class="field col-12">
                            <span class="p-float-label">
                                <input #lie="ngModel" name="lie" [(ngModel)]="golfClub.lie" type="number" pInputText
                                       class="width10">
                                <my-validation-message [model]="lie"></my-validation-message>
                                <label>Lie</label>
                            </span>
                        </div>
                        <div class="field col-12">
                            <span class="p-float-label">
                                <input #loft="ngModel" name="loft" [(ngModel)]="golfClub.loft" type="number" pInputText
                                       class="width10">
                                <my-validation-message [model]="loft"></my-validation-message>
                                <label>Loft</label>
                            </span>
                        </div>
                        <div class="field col-12">
                            <span class="p-float-label">
                                <input #swingWeight="ngModel" name="swingWeight" [(ngModel)]="golfClub.swingWeight"
                                       maxlength="5" pInputText class="width10">
                                <label>Swing Weight</label>
                            </span>
                        </div>
                        <div class="field col-12">
                            <p-checkbox name="isPured" label="Is Pured" [(ngModel)]="golfClub.isPured" binary="true"></p-checkbox>
                        </div>
                    </div>
                </p-fieldset>
            </div>
            <div class="col-12 lg:col-4">
                <p-fieldset legend="Grip">
                    <div class="grid form-group">
                        <div class="field col-12">
                            <span class="p-float-label">
                                <input #gripBrand="ngModel" name="gripBrand" [(ngModel)]="golfClub.gripBrand" maxlength="30"
                                       pInputText class="width10">
                                <label>Brand</label>
                            </span>
                        </div>
                        <div class="field col-12">
                            <span class="p-float-label">
                                <input #gripModel="ngModel" name="gripModel" [(ngModel)]="golfClub.gripModel" maxlength="30"
                                       pInputText class="width10">
                                <label>Model</label>
                            </span>
                        </div>
                        <div class="field col-12">
                            <span class="p-float-label">
                                <textarea #gripInstructions="ngModel" rows="3" name="gripInstructions" pInputTextarea
                                          [(ngModel)]="golfClub.gripInstructions"></textarea>
                                <label>Instructions</label>
                            </span>
                        </div>
                    </div>
                </p-fieldset>
            </div>
            <div class="col-12">
                <button type="submit" pButton [label]="getButtonLabel()" (click)="save()" [disabled]="isLoading || !clubForm.form.valid"></button>
                <my-messages></my-messages>
            </div>
        </div>
    </form>
</div>
