import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps'

import { CourseNameComponent } from './course-name.component';
import { HoleHistoricalStatsComponent } from './hole-historical-stats/hole-historical-stats.component';
import { CourseMapComponent } from './map/course-map.component';
import { PrimeNgModule } from '../ui/primeng/primeng.module';
import { TeeShotDecisionTreeComponent } from './tee-shot-decision-tree.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PrimeNgModule,
        GoogleMapsModule
    ],
    exports: [
        CourseNameComponent,
        HoleHistoricalStatsComponent,
        CourseMapComponent,
        TeeShotDecisionTreeComponent
    ],
    declarations: [
        CourseNameComponent,
        HoleHistoricalStatsComponent,
        CourseMapComponent,
        TeeShotDecisionTreeComponent
    ],
    providers: [
    ]
})
export class SharedCoursesModule {

}
