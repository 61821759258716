import { Injectable } from '@angular/core';
import { SessionService } from '../auth/session.service';

import {
    ModelsCoreGolfersGolfer
} from '../../shared/swagger-codegen/models';

@Injectable()
export class CurrentGolferService implements  ModelsCoreGolfersGolfer {
    constructor(private session: SessionService) {
    }

    get initials() {
        return this.getGolferOrDefault().initials;
    }

    get isAuthenticated() {
        return this.session.getGolfer() !== undefined;
    }

    get isActive() {
        return this.getGolferOrDefault().isActive;
    }

    get isPublic() {
        return this.getGolferOrDefault().isPublic;
    }

    get city() {
        return this.getGolferOrDefault().city;
    }

    get defaultTrackedMetric() {
        return this.getGolferOrDefault().defaultTrackedMetric;
    }

    get defaultTrackedMetrics() {
        return this.getGolferOrDefault().defaultTrackedMetrics || [];
    }

    get golfClubYardageLabel() {
        return this.getGolferOrDefault().golfClubYardageLabel;
    }

    get golfClubSecondaryYardage1Label() {
        return this.getGolferOrDefault().golfClubSecondaryYardage1Label;
    }

    get golfClubSecondaryYardage2Label() {
        return this.getGolferOrDefault().golfClubSecondaryYardage2Label;
    }

    get golfClubSecondaryYardage3Label() {
        return this.getGolferOrDefault().golfClubSecondaryYardage3Label;
    }

    get email() {
        return this.getGolferOrDefault().email;
    }

    get phone() {
        return this.getGolferOrDefault().phone;
    }

    get defaultCourseYardage() {
        return this.getGolferOrDefault().defaultCourseYardage;
    }

    get defaultCourseYardageType() {
        return this.getGolferOrDefault().defaultCourseYardageType;
    }

    get displayName() {
        return this.getGolferOrDefault().displayName;
    }

    get firstName() {
        return this.getGolferOrDefault().firstName;
    }

    get fullName() {
        return this.getGolferOrDefault().fullName;
    }

    get gender() {
        return this.getGolferOrDefault().gender;
    }

    get handicap() {
        return this.getGolferOrDefault().handicap;
    }

    get golferId() {
        return this.getGolferOrDefault().golferId || 0;
    }

    get lastName() {
        return this.getGolferOrDefault().lastName;
    }

    get logins() {
        return this.getGolferOrDefault().logins || 0;
    }

    get notificationPreferences() {
        return this.getGolferOrDefault().notificationPreferences || [];
    }

    get roles() {
        return this.getGolferOrDefault().roles || [];
    }

    get rounds() {
        return this.getGolferOrDefault().rounds || 0;
    }

    get practiceSessions() {
        return this.getGolferOrDefault().practiceSessions || 0;
    }

    get stateCode() {
        return this.getGolferOrDefault().stateCode;
    }

    get isAdmin() {
        const golfer = this.getGolferOrDefault();
        return golfer.roles && golfer.roles.find(r => r === 'Admin') !== undefined;
    }

    private getGolferOrDefault() {
        return this.session.getGolfer() || {} as ModelsCoreGolfersGolfer;
    }
}
