<div class="grid">
    <div class="col-12">
        <p-tabMenu #golferTabs [model]="golferMenuItems" [activeItem]="currentGolferMenuItem"></p-tabMenu>
    </div>
    <div class="col-12">
        <table *ngIf="currentGolferStats" class="p-datatable p-datatable-striped with-bottom-margin">
            <thead class="p-datatable-thead">
                <tr>
                    <th rowspan="2">Fairways</th>
                    <th rowspan="2">GIRs</th>
                    <th colspan="4">Putts</th>
                    <th rowspan="2">Penalties</th>
                    <th colspan="6">SG vs Scratch</th>
                </tr>
                <tr>
                    <th>Total</th>
                    <th>1 Putts</th>
                    <th>2 Putts</th>
                    <th>3 Putts</th>
                    <th>Tee</th>
                    <th>Approach</th>
                    <th>Around<br />the Green</th>
                    <th>Putting</th>
                    <th>Tee to<br />Green</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody class="p-datatable-tbody">
                <tr>
                    <td class="center">{{currentGolferStats.fairways}}/{{currentGolferStats.fairwayOpportunities}} ({{currentGolferStats.fairwayPercent | percent}})</td>
                    <td class="center">{{currentGolferStats.girs}}/{{currentGolferStats.holesCompleted}} ({{currentGolferStats.girPercent | percent}})</td>
                    <td class="center">{{currentGolferStats.putts}}</td>
                    <td class="center">{{currentGolferStats.onePutts}}</td>
                    <td class="center">{{currentGolferStats.twoPutts}}</td>
                    <td class="center">{{currentGolferStats.threePutts}}</td>
                    <td class="center">{{currentGolferStats.penalties}}</td>
                    <td class="center">{{currentGolferStats.strokesGainedOffTeeVsScratch | number:'1.3-3'}}</td>
                    <td class="center">{{currentGolferStats.strokesGainedApproachVsScratch | number:'1.3-3'}}</td>
                    <td class="center">{{currentGolferStats.strokesGainedAroundGreenVsScratch | number:'1.3-3'}}</td>
                    <td class="center">{{currentGolferStats.strokesGainedPuttingVsScratch | number:'1.3-3'}}</td>
                    <td class="center">{{currentGolferStats.strokesGainedTeeToGreenVsScratch | number:'1.3-3'}}</td>
                    <td class="center">{{currentGolferStats.strokesGainedTotalVsScratch | number:'1.3-3'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <p-tabMenu #holeTabs [model]="holeMenuItems" [activeItem]="currentHoleMenuItem"></p-tabMenu>
    </div>
    <div class="col-6">
        <p-table *ngIf="!isEditingStrokes" [value]="currentGolferHole?.holeStrokes || []" [loading]="isLoading" styleClass="p-datatable-striped with-bottom-margin" tableStyleClass="p-datatable-responsive-md">
            <ng-template pTemplate="caption">
                {{currentGolferHole?.description}}
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="width1">#</th>
                    <th>From</th>
                    <th class="width1">SG vs<br />Pro</th>
                    <th class="width1">SG vs<br />Scratch</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-stroke>
                <tr>
                    <td class="center">
                        <span class="p-column-title">#</span>
                        {{stroke.ordinal}}
                    </td>
                    <td>
                        <span class="p-column-title">From</span>
                        {{stroke.description}}
                    </td>
                    <td class="right">
                        <span class="p-column-title">SG vs Pro</span>
                        {{stroke.strokesGainedVsPro | number:'1.3-3'}}
                    </td>
                    <td class="right">
                        <span class="p-column-title">SG vs Scratch</span>
                        {{stroke.strokesGainedVsScratch | number:'1.3-3'}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="2">
                        No strokes for this hole.
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p-table *ngIf="isEditingStrokes" [value]="currentGolferHole?.holeStrokes" [loading]="isLoading" styleClass="p-datatable-striped with-bottom-margin" tableStyleClass="p-datatable-responsive-md">
            <ng-template pTemplate="caption">
                {{currentGolferHole?.description}}
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="reorder-column"></th>
                    <th>From</th>
                    <th>To</th>
                    <th class="width2">Distance<br />to Hole</th>
                    <th class="width2">Is Approach?</th>
                    <th class="width1">Club</th>
                    <th class="width2">Shot Type</th>
                    <th class="width1"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-stroke let-rowIndex="rowIndex">
                <tr [pReorderableRow]="rowIndex">
                    <td>
                        <i class="fa fa-bars clickable" pReorderableRowHandle></i>
                    </td>
                    <td>
                        <span class="p-column-title">From</span>
                        <p-dropdown name="from-lie-{{stroke.ordinal}}" [(ngModel)]="stroke.fromLieType.lieTypeId" [options]="lieTypeSelectItems" styleClass="width-full" required></p-dropdown>
                    </td>
                    <td>
                        <span class="p-column-title">To</span>
                        <p-dropdown name="to-lie-{{stroke.ordinal}}" [(ngModel)]="stroke.toLieType.lieTypeId" [options]="lieTypeSelectItems" styleClass="width-full" required></p-dropdown>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Distance to Hole</span>
                        <input name="distance-to-hole-{{stroke.ordinal}}" type="number" min="1" pInputText required [(ngModel)]="stroke.distanceToHole" class="center">
                    </td>
                    <td class="center">
                        <span class="p-column-title">Is Approach?</span>
                        <p-radioButton *ngIf="shouldShowIsApproach(stroke)" inputId="is-approach-{{stroke.ordinal}}" name="isApproach" [value]="stroke.golfRoundGolferHoleStrokeId" [(ngModel)]="approachShotId"></p-radioButton>
                    </td>
                    <td>
                        <span class="p-column-title">Golf Club</span>
                        <p-dropdown *ngIf="shouldShowGolfClub(stroke)" name="golf-club-{{stroke.ordinal}}" [(ngModel)]="stroke.golfClub.golfClubId" [options]="golfClubSelectItems" styleClass="width-full" required></p-dropdown>
                    </td>
                    <td>
                        <span class="p-column-title">Shot Type</span>
                        <p-dropdown *ngIf="shouldShowShotType(stroke)" name="shot-type-{{stroke.ordinal}}" [(ngModel)]="stroke.shotType.shotTypeId" [options]="shotTypeSelectItems" styleClass="width-full" required></p-dropdown>
                    </td>
                    <td class="center">
                        <span class="status-icons">
                            <i class="fas fa-trash-alt clickable delete-icon" pTooltip="Delete" (click)="deleteStroke(stroke, rowIndex)"></i>
                            <i *ngIf="!stroke.latitude" class="fas fa-location-arrow clickable add-icon" pTooltip="Add Location" (click)="addLocation(stroke, rowIndex)"></i>
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <td colspan="8">
                        <button pButton type="button" label="Add Stroke" icon="fa fa-plus" class="p-button-info" (click)="addStroke()"></button>
                        <button *ngIf="!holeLocation" pButton type="button" label="Add Hole Location" icon="fa fa-plus" class="p-button-info add-hole-location" (click)="addHoleLocation()"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">
                        No strokes for this hole.
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div *ngIf="currentGolferHole?.completed">
            <button *ngIf="!isEditingStrokes" pButton label="Edit Strokes" (click)="editStrokes()" [disabled]="isLoading"></button>
            <button *ngIf="isEditingStrokes" pButton label="Save Strokes" (click)="saveStrokes()" [disabled]="isLoading"></button>
            <button *ngIf="isEditingStrokes" pButton label="Cancel" (click)="cancelStrokeEditing()" [disabled]="isLoading" style="margin-left: 15px;"></button>
        </div>
    </div>
    <div class="col-6">
        <my-course-map *ngIf="course && currentMapArea"
                       [course]="course"
                       [courseMapArea]="currentMapArea"
                       [holeLocation]="holeLocation"
                       [holeStrokes]="currentGolferHole?.holeStrokes || []"></my-course-map>
        <button *ngIf="!isEditingStrokes && currentGolferHole?.completed" type="submit" pButton label="Save Stroke Locations" (click)="saveStrokes()" [disabled]="isLoading" style="margin-top: 10px;"></button>
    </div>
</div>
<p-confirmDialog key="scorecard-hole-strokes-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
