import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import {
    ModelsWebApiConfigurationModel
} from '../../shared/swagger-codegen/models';

/* eslint-disable no-var */
declare var window: any;

export interface VersionUpdate {
    currentVersion: string;
    newVersion: string;
}

@Injectable()
export class ConfigurationService implements ModelsWebApiConfigurationModel {
    constructor() {
        const config: ModelsWebApiConfigurationModel = window.parProsConfig;

        if(config) {
            Object.keys(config).forEach(key => {
                this[key] = config[key];
            });
        }

        this.myVersionUpdates = new Subject<VersionUpdate>();
    }
    
    version: string;
    sdkVersion: string;
    buildDate: string;
    buildNumber: string;
    sourceBranch: string;
    sourceVersion: string;
    isDebug: boolean;
    environmentName: string;
    traceDsn: string;
    traceSampleRate: number;

    get versionUpdates(): Observable<VersionUpdate> {
        return this.myVersionUpdates;
    }

    private myVersionUpdates: Subject<VersionUpdate>;

    versionUpdated(versionUpdate: VersionUpdate) {
        this.myVersionUpdates.next(versionUpdate);
    }
}
