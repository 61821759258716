import { NgModule } from '@angular/core';
import { AuthenticationControllerProxy } from './authentication-controller-proxy.service';
import { CoursesControllerProxy } from './courses-controller-proxy.service';
import { GolfersControllerProxy } from './golfers-controller-proxy.service';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import { PracticeControllerProxy } from './practice-controller-proxy.service';
import { ReferenceControllerProxy } from './reference-controller-proxy.service';
import { RoundsControllerProxy } from './rounds-controller-proxy.service';
import { StatisticsControllerProxy } from './statistics-controller-proxy.service';

@NgModule(
    {
        providers: [
            AuthenticationControllerProxy,
            CoursesControllerProxy,
            GolfersControllerProxy,
            HttpClientWrapperService,
            PracticeControllerProxy,
            ReferenceControllerProxy,
            RoundsControllerProxy,
            StatisticsControllerProxy
        ]
    })
export class ServerProxiesModule {
}
