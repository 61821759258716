<div class="card">
    <h1 *ngIf="golfer">{{golfer.firstName}} {{golfer.lastName}}</h1>
    <p-tabView *ngIf="golfer" [activeIndex]="activeTabIndex" (onChange)="onActiveTabChanged($event)">
        <p-tabPanel header="Details" leftIcon="far fa-user-circle">
            <ng-template pTemplate="content">
                <my-golfer-detail [golfer]="golfer" [messages]="messages"></my-golfer-detail>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Golf Clubs" leftIcon="fas fa-clipboard-list">
            <ng-template pTemplate="content">
                <my-golf-clubs-list [golfer]="golfer" [messages]="messages"></my-golf-clubs-list>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Default Stats" leftIcon="fas fa-sort-numeric-down">
            <ng-template pTemplate="content">
                <my-golfer-default-stats [golfer]="golfer" [messages]="messages"></my-golfer-default-stats>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Swing Thoughts" leftIcon="far fa-lightbulb">
            <ng-template pTemplate="content">
                <my-swing-thoughts [golfer]="golfer" [messages]="messages"></my-swing-thoughts>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Notifications" leftIcon="far fa-envelope">
            <ng-template pTemplate="content">
                <my-notification-preferences [golfer]="golfer" [messages]="messages"></my-notification-preferences>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
    <div class="grid">
        <div class="col-12">
            <my-messages></my-messages>
        </div>
    </div>
</div>
