<div class="card p-fluid">
    <div class="grid form-group">
        <div class="field col-12 lg:col-4">
            <span class="p-float-label">
                <p-dropdown name="achievement" [(ngModel)]="achievementId" [options]="achievements" required (onChange)="onAchievementChanged()"></p-dropdown>
                <label>Achievement</label>
            </span>
        </div>
        <div class="field col-12 lg:col-2">
            <span class="p-float-label">
                <p-dropdown [options]="golfers" [(ngModel)]="golferId" (onChange)="onGolferChanged()"></p-dropdown>
                <label>Golfer</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1">
            <span class="p-float-label">
                <p-dropdown [options]="yearsPlayed" [(ngModel)]="args.year" styleClass="year-selector" (onChange)="onYearChanged();"></p-dropdown>
                <label>Year</label>
            </span>
        </div>
    </div>

    <div *ngIf="achievementHistory">
        <p-table [value]="achievementHistory" [paginator]="achievementHistory.length > 50" [pageLinks]="10" [rows]="50" [rowsPerPageOptions]="[25,50,75,100]" [loading]="isLoading" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md">
            <ng-template pTemplate="caption">
                Achievement History
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="width10">Tee Time</th>
                    <th class="width12">Course</th>
                    <th class="width2">Score</th>
                    <th class="width2">Times<br />Achieved</th>
                    <th class="left">Detail</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-history let-rowIndex="rowIndex">
                <tr>
                    <td class="left">
                        {{history.teeTime | date:'fullDate'}}
                        {{history.teeTime | date:'shortTime'}}

                        <span *ngIf="history.golfRoundId" class="status-icons right">
                            <i class="fas fa-th clickable scorecard-icon" pTooltip="View Scorecard" (click)="showScorecardDialog(history)"></i>
                        </span>
                    </td>
                    <td class="left">
                        <span class="p-column-title">Course</span>
                        {{history.courseName}}
                        <span *ngIf="history.courseNineName">- {{history.courseNineName}}</span>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Score</span>
                        {{history.formattedScore}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Times Achieved</span>
                        {{history.timesAchieved | number}}
                    </td>
                    <td class="left">
                        <span class="p-column-title">Detail</span>
                        <span [innerHtml]="history.detail | textToHtml"></span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">
                        {{getEmptyMessage('No history for this achievement.')}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
