import { Injectable } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import { QueryString } from '../util';

import {
    ModelsCoreCoursesCourse,
    ModelsCoreCoursesHole,
    ModelsCoreSharedStateWithCities,
    ModelsCoursesGetCourseScorecardCourseScorecard,
    ModelsCoursesSaveCourseMapAreaSaveCourseMapAreaResponse,
    ModelsCoursesWhichTeeTeeSummary,
    ModelsWebApiCoursesCreateCourseModel,
    ModelsWebApiCoursesGetCoursesArgs,
    ModelsWebApiCoursesSaveCourseModel,
    ModelsWebApiCoursesSaveCourseMapAreaModel,
    ModelsWebApiCoursesSaveHoleNotesModel,
    ModelsWebApiCoursesSaveScorecardModel,
    ModelsWebApiCoursesSearchArgs
} from '../../shared/swagger-codegen/models';

@Injectable()
export class CoursesControllerProxy {
    private baseUrl = '/api/courses';

    constructor(private httpClient: HttpClientWrapperService) {
    }

    getCourse(courseId: number) {
        return this.httpClient.get<ModelsCoreCoursesCourse>(`${this.baseUrl}/${courseId}`);
    }

    getCourses(args: ModelsWebApiCoursesGetCoursesArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsCoreCoursesCourse[]>(`${this.baseUrl}${queryString}`);
    }

    createCourse(model: ModelsWebApiCoursesCreateCourseModel) {
        return this.httpClient.post<ModelsCoreCoursesCourse>(this.baseUrl, model);
    }

    getHoles(courseId: number) {
        return this.httpClient.get<ModelsCoreCoursesHole[]>(`${this.baseUrl}/${courseId}/holes`);
    }

    createTee(courseId: number) {
        return this.httpClient.post<any>(`${this.baseUrl}/${courseId}/tees`);
    }

    saveCourse(courseId: number, model: ModelsWebApiCoursesSaveCourseModel) {
        return this.httpClient.put<any>(`${this.baseUrl}/${courseId}`, model);
    }

    getLocations() {
        return this.httpClient.get<ModelsCoreSharedStateWithCities[]>(`${this.baseUrl}/locations`);
    }

    search(search: ModelsWebApiCoursesSearchArgs) {
        const queryString = QueryString.fromObject(search);
        return this.httpClient.get<ModelsCoreCoursesCourse[]>(`${this.baseUrl}/search${queryString}`);
    }

    getScorecard(courseId: number) {
        return this.httpClient.get<ModelsCoursesGetCourseScorecardCourseScorecard>(`${this.baseUrl}/${courseId}/scorecard`);
    }

    saveScorecard(courseId: number, model: ModelsWebApiCoursesSaveScorecardModel) {
        return this.httpClient.put<any>(`${this.baseUrl}/${courseId}/scorecard`, model);
    }

    getTeeSummary(courseId: number) {
        return this.httpClient.get<ModelsCoursesWhichTeeTeeSummary[]>(`${this.baseUrl}/${courseId}/tee-summary`);
    }

    getCourseMap(courseId: number) {
        return this.httpClient.get<ModelsCoreCoursesCourse>(`${this.baseUrl}/${courseId}/map`);
    }

    saveCourseMapArea(courseId: number, courseMapAreaId: number, model: ModelsWebApiCoursesSaveCourseMapAreaModel) {
        return this.httpClient.post<ModelsCoursesSaveCourseMapAreaSaveCourseMapAreaResponse>(
            `${this.baseUrl}/${courseId}/map/areas/${courseMapAreaId}`,
            model
        );
    }

    saveHoleNotes(courseId: number, holeId: number, model: ModelsWebApiCoursesSaveHoleNotesModel) {
        return this.httpClient.post<any>(`${this.baseUrl}/${courseId}/holes/${holeId}/notes`, model);
    }
}
