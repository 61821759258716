import { Observable, NextObserver } from 'rxjs';
import { SignalRHubConnectionFactory } from '../core/signalr-hub-connection-factory.service';
import { SignalRHubConnectionWrapper } from '../core/signalr-hub-connection-wrapper';

import {
    ModelsCoreRoundsScorecardUpdatedMessage,
    ModelsWebHubsScorecardSubscriptionMessage
} from '../../shared/swagger-codegen/models';

interface IServerMethods {
    subscribe: string;
    unsubscribe: string;
    scorecardUpdated: string;
}

export class ScorecardHubProxy {
    constructor(hubConnectionFactory: SignalRHubConnectionFactory) {
        this.connection = hubConnectionFactory.createHubConnection('/api/hubs/scorecard');
    }

    private connection: SignalRHubConnectionWrapper;
    private serverMethodNames: IServerMethods = {
        subscribe: 'Subscribe',
        unsubscribe: 'Unsubscribe',
        scorecardUpdated: 'ScorecardUpdated'
    };

    start() {
        return this.connection.start();
    }

    stop() {
        return this.connection.stop();
    }

    onScorecardUpdated() {
        const observable = new Observable<ModelsCoreRoundsScorecardUpdatedMessage>(
            (observer: NextObserver<ModelsCoreRoundsScorecardUpdatedMessage>) => {
                this.connection.on(
                    this.serverMethodNames.scorecardUpdated,
                    (message: ModelsCoreRoundsScorecardUpdatedMessage) => {
                        observer.next(message);
                    });
            });

        return observable;
    }

    subscribeToScorecardUpdates(golfRoundGolferIds: number[]) {
        const promise = new Promise<void>(
            (resolve: () => void, reject: (e: any) => void) => {
                if(golfRoundGolferIds.length === 0) {
                    resolve();
                }
                else {
                    const message: ModelsWebHubsScorecardSubscriptionMessage = {
                        golfRoundGolferIds: golfRoundGolferIds
                    };

                    this.connection.send(this.serverMethodNames.subscribe, message)
                        .then(
                            () => {
                                resolve();
                            },
                            e => {
                                reject(e);
                            });
                }
            });

        return promise;
    }

    unsubscribeFromScorecardUpdates(golfRoundGolferIds: number[]) {
        const promise = new Promise<void>(
            (resolve: () => void, reject: (e: any) => void) => {
                if(golfRoundGolferIds.length === 0) {
                    resolve();
                }
                else {
                    const message: ModelsWebHubsScorecardSubscriptionMessage = {
                        golfRoundGolferIds: golfRoundGolferIds
                    };

                    this.connection.send(this.serverMethodNames.unsubscribe, message)
                        .then(
                            () => {
                                resolve();
                            },
                            e => {
                                reject(e);
                            });
                }
            });

        return promise;
    }
}
