import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { SelectItem, ConfirmationService, Confirmation } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { RoundsControllerProxy } from '../../../shared/server-proxies';
import { MessagesComponent } from '../../../shared/ui/messages/messages.component';
import { SessionService } from '../../../shared/auth/session.service';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { RoundDetailTabViewIndexes } from '../round-detail-tab-view-indexes';

interface ModelsCoreRoundsGolfRoundGolferWithMetricSelectItems extends ModelsCoreRoundsGolfRoundGolfer {
    metricSelectItems: SelectItem[];
}

import {
    ModelsCoreCoursesTee,
    ModelsCoreGolfersGolfer,
    ModelsCoreRoundsGolfRound,
    ModelsCoreRoundsGolfRoundGolfer,
    ModelsCoreRoundsGolfRoundTypes,
    ModelsCoreStatisticsMetric,
    ModelsCoreStatisticsMetrics,
    ModelsCoreStatisticsStrokesGainedMetricSupportOptions,
    ModelsRoundsGetGolfRoundLookupsGetGolfRoundLookupsResponse,
    ModelsWebApiRoundsSaveGolfRoundGolferModel,
    ModelsWebApiRoundsSaveRoundModel
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-round-detail',
    templateUrl: './round-detail.component.html'
})
export class RoundDetailComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private roundsProxy: RoundsControllerProxy,
        private router: Router,
        private currentRoute: ActivatedRoute,
        private session: SessionService,
        private confirmationService: ConfirmationService) {
        super();
    }

    @Input() messages: MessagesComponent;
    @Input() round: ModelsCoreRoundsGolfRound;
    roundIsStarted: boolean;
    isStartingRound: boolean;
    isCompletingRound: boolean;
    isEndingRound: boolean;
    holesPlanned: SelectItem[] = [
        { value: 9, label: '9 Holes' },
        { value: 18, label: '18 Holes' }
    ];
    metrics: SelectItem[] = [];
    weatherSelectItems: SelectItem[] = [];
    windSelectItems: SelectItem[] = [];
    lookups: ModelsRoundsGetGolfRoundLookupsGetGolfRoundLookupsResponse;
    notes: string;
    imageUploadUrl: string;
    token: string;
    missingGolfersEndingRound: boolean;
    private currentGolfer: ModelsCoreGolfersGolfer;

    ngOnInit(): void {
        this.currentGolfer = this.session.getGolfer();
        this.token = this.session.getToken();
        this.loadLookups();

        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    this.isStartingRound = params['starting'] === 'true';
                    this.isEndingRound = params['ending'] === 'true';
                    this.isCompletingRound = params['completing'] === 'true';
                    this.missingGolfersEndingRound = this.isEndingRound;
                });

        if(this.round) {
            this.imageUploadUrl = `/api/rounds/${this.round.golfRoundId}/images`;
            this.roundIsStarted = this.areStrokesEnteredForAnyGolfer();
            this.loadNotesForCurrentGolfer();
        }
    }

    isDataLoaded() {
        return this.round && this.lookups;
    }

    get isEndingOrCompletingRound() {
        return this.isEndingRound || this.isCompletingRound;
    }

    anyGolferPlansToPlay18Holes() {
        let required = false;

        if(this.round && this.round.golfRoundGolfers) {
            for(let i = 0; i < this.round.golfRoundGolfers.length; i++) {
                if(this.round.golfRoundGolfers[i].holesPlanned === 18) {
                    required = true;
                    break;
                }
            }
        }

        return required;
    }

    saveRound() {
        const model: ModelsWebApiRoundsSaveRoundModel = {
            backCourseNineId: null,
            firstHoleId: this.round.firstHole.holeId,
            frontCourseNineId: this.round.frontCourseNine.courseNineId as any,
            golfRoundGolfers: [],
            greenCondition: this.round.greenCondition.greenConditionId as any,
            greenSpeed: this.round.greenSpeed.greenSpeedId as any,
            notes: this.notes,
            pinDifficulty: this.round.pinDifficulty.pinDifficultyId as any,
            teeTime: this.round.teeTime,
            temperature: this.round.temperature,
            weather: this.round.weather.weatherId as any,
            wind: this.round.wind.windId as any
        };

        if(this.anyGolferPlansToPlay18Holes()) {
            model.backCourseNineId = this.round.backCourseNine.courseNineId;
        }

        for(let i = 0; i < this.round.golfRoundGolfers.length; i++) {
            const golfRoundGolfer = this.round.golfRoundGolfers[i];
            const golferModel: ModelsWebApiRoundsSaveGolfRoundGolferModel = {
                golfRoundGolferId: golfRoundGolfer.golfRoundGolferId,
                holesPlanned: golfRoundGolfer.holesPlanned,
                teeId: golfRoundGolfer.tee.teeId,
                golfRoundType: golfRoundGolfer.golfRoundType.golfRoundTypeId as any,
                trackedMetrics: golfRoundGolfer.trackedMetrics
            };

            if(this.isEndingRound && (golfRoundGolfer as any).endRound) {
                golferModel.endRound = true;
            }

            model.golfRoundGolfers.push(golferModel);
        }

        this.taskStarted();
        this.roundsProxy.updateRound(this.round.golfRoundId, model)
            .pipe(
                finalize(
                    () => {
                        this.taskCompleted();
                        this.messages.clearInMilliseconds(5000);
                }),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    if(this.isStartingRound) {
                        this.router.navigate(['rounds', this.round.golfRoundId, 'scorecard']);
                    }
                    else if(this.isEndingOrCompletingRound) {
                        this.router.navigate(['rounds', this.round.golfRoundId, { tab: RoundDetailTabViewIndexes.scorecardTabIndex }]);
                    }
                    else {
                        this.messages.showSuccess('Changes have been saved.');
                    }
                },
                error => {
                    this.messages.showError(`An error occurred. (${error.status})`);
                });
    }

    getSaveButtonLabel() {
        let label = 'Save Round';

        if(this.isStartingRound) {
            label = 'Start Round';
        }
        else if(this.isEndingRound) {
            label = 'End Round';
        }
        else if(this.isCompletingRound) {
            label = 'Complete Round';
        }

        return label;
    }

    isRoundIncomplete(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        const unplayedHoles = golfRoundGolfer.holesPlanned - golfRoundGolfer.holesPlayed;
        return unplayedHoles > 0 && golfRoundGolfer.holesPlayed > 0;
    }

    describeRoundEndingProcess(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        const holesPlanned = golfRoundGolfer.holesPlanned;
        const holesPlayed = golfRoundGolfer.holesPlayed;
        const unplayedHoles = holesPlanned - holesPlayed;
        const unplayedHolesFor9 = 9 - holesPlayed;
        const unplayedHoleOrHoles = unplayedHoles === 1 ? 'hole' : 'holes';
        const unplayedHoleOrHolesFor9 = unplayedHolesFor9 === 1 ? 'hole' : 'holes';
        const minimumPlayedFor18 = holesPlayed >= 14;
        const minimumPlayedFor9 = holesPlayed >= 7;
        const isPracticeRound = golfRoundGolfer.golfRoundType.golfRoundTypeId === ModelsCoreRoundsGolfRoundTypes.Practice;
        let description = '';

        if(unplayedHoles === 0) {
            description = 'Round is already complete.';
        }
        else if(holesPlayed === 0) {
            description = 'No holes have been played so the round should be deleted.';
        }
        else if(holesPlanned === 18) {
            if(minimumPlayedFor18) {
                description = `The remaining ${unplayedHoles} ${unplayedHoleOrHoles} will be completed with a score of Net Par.`;
            }
            else if(holesPlayed >= 9) {
                description = 'The round will be converted to a 9 hole round.';
            }
            else if(minimumPlayedFor9 || isPracticeRound) {
                description = 'The round will be converted to a 9 hole round and the remaining '
                    + `${unplayedHolesFor9} ${unplayedHoleOrHolesFor9} will be completed with a score of Net Par.`;
            }
            else {
                description = 'The round will be converted to a 9 hole practice round and the remaining '
                    + `${unplayedHolesFor9} ${unplayedHoleOrHolesFor9} will be completed with a score of Net Par.`;
            }
        }
        else {
            if(minimumPlayedFor9 || isPracticeRound) {
                description = `The remaining ${unplayedHoles} ${unplayedHoleOrHoles} will be completed with a score of Net Par.`;
            }
            else {
                description = 'The round will be converted to a practice round and the remaining '
                    + `${unplayedHoles} ${unplayedHoleOrHoles} will be completed with a score of Net Par.`;
            }
        }

        return description;
    }

    onGolferEndingRoundChange() {
        const golfersEndingRound = this.round.golfRoundGolfers.filter(grg => (grg as any).endRound === true);
        this.missingGolfersEndingRound = golfersEndingRound.length === 0;
    }

    getImageUploadHeaders() {
        const headers = new HttpHeaders();
        const token = this.session.getToken();

        if(token) {
            headers.append('Authorization', `Bearer ${token}`);
        }

        return headers;
    }

    onImageUploaded(e: any) {
        this.round.golfRoundImages = e.originalEvent.body;
    }

    onFrontNineChanged() {
        const holes = this.round.frontCourseNine.holes;
        this.round.firstHole.holeId = holes.length > 0 ? holes[0].holeId : undefined;
    }

    onTeeChanged(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer, originalTee: ModelsCoreCoursesTee) {
        const golferName = golfRoundGolfer.golfer.displayName;
        const holesPlayed = golfRoundGolfer.holesPlayed;
        const confirmation: Confirmation = {
            key: 'round-detail-component',
            header: 'Confirm',
            message: 'Are you sure that you want to perform this action?',
            acceptLabel: 'Continue',
            rejectLabel: 'Cancel',
            reject: () => {
                golfRoundGolfer.tee = originalTee;
            }
        };

        if(golfRoundGolfer.isComplete) {
            confirmation.message =
                `${golferName} has completed this round so all hole yardages in this round will be updated `
                + 'and the handicap will be recalculated when saving this tee change.';
            this.confirmationService.confirm(confirmation);
        }
        else if(holesPlayed > 0) {
            confirmation.message =
                `${golferName} has completed ${holesPlayed} ${holesPlayed === 1 ? 'hole' : 'holes'} `
                + `so the yardage for ${holesPlayed === 1 ? 'this hole' : 'these holes'} in this round `
                + `will be updated when saving this tee change.`;
            this.confirmationService.confirm(confirmation);
        }
    }

    onHolesPlannedChanged(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer, originalHolesPlanned: number, $event: any) {
        const firstName = golfRoundGolfer.golfer.firstName;
        const holesPlayed = golfRoundGolfer.holesPlayed;
        const holesPlanned = $event.value;
        const holesThatWillBeLost = holesPlayed - holesPlanned;
        const confirmation: Confirmation = {
            key: 'round-detail-component',
            header: 'Confirm',
            acceptLabel: 'Continue',
            rejectLabel: 'Cancel',
            reject: () => {
                golfRoundGolfer.holesPlanned = originalHolesPlanned;
            }
        };

        if(holesThatWillBeLost > 0) {
            confirmation.header = 'Confirm Delete';
            confirmation.message =
                `${firstName} has completed ${holesPlayed} ${holesPlayed === 1 ? 'hole' : 'holes'} `
                + `so scores and statistics for ${holesThatWillBeLost} ${holesThatWillBeLost === 1 ? 'hole' : 'holes'} `
                + `will be deleted when saving this as a ${ holesPlanned } hole round.`;
            this.confirmationService.confirm(confirmation);
        }
    }

    onTrackedMetricsChanged(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer, $event: any) {
        const firstName = golfRoundGolfer.golfer.firstName;
        const holesPlayed = golfRoundGolfer.holesPlayed;
        const modifiedMetricId = $event.itemValue;
        const trackedMetricIds = $event.value;
        const metricWasRemoved = trackedMetricIds.indexOf(modifiedMetricId) === -1;
        const confirmation: Confirmation = {
            key: 'round-detail-component',
            header: 'Confirm',
            acceptLabel: 'Continue',
            rejectLabel: 'Cancel',
            accept: () => {
                this.enforceMetricSelectionRules(golfRoundGolfer as ModelsCoreRoundsGolfRoundGolferWithMetricSelectItems, modifiedMetricId);
            },
            reject: () => {
                golfRoundGolfer.trackedMetrics.push(modifiedMetricId);
                this.enforceMetricSelectionRules(golfRoundGolfer as ModelsCoreRoundsGolfRoundGolferWithMetricSelectItems, modifiedMetricId);
            }
        };

        if(holesPlayed > 0 && metricWasRemoved) {
            confirmation.header = 'Confirm Delete';
            confirmation.message =
                `${firstName} has completed ${holesPlayed} ${holesPlayed === 1 ? 'hole' : 'holes'} ` +
                `so removing a tracked metric will cause all data saved for this metric to be deleted when saving this round.`;
            this.confirmationService.confirm(confirmation);
        }
        else {
            this.enforceMetricSelectionRules(golfRoundGolfer as ModelsCoreRoundsGolfRoundGolferWithMetricSelectItems, modifiedMetricId);
        }
    }

    getGolferMetricSelectItems(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolferWithMetricSelectItems) {
        if(!golfRoundGolfer.metricSelectItems) {
            golfRoundGolfer.metricSelectItems = this.metrics = this.lookups.metrics.map(
                metric => {
                    return {
                        label: metric.displayName,
                        value: metric.metricId,
                        metric: metric
                    } as SelectItem;
                });

            this.enforceStrokesGainedMetricSelectionRules(golfRoundGolfer);
        }

        return golfRoundGolfer.metricSelectItems;
    }

    private enforceMetricSelectionRules(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolferWithMetricSelectItems, modifiedMetricId: number) {
        switch(modifiedMetricId) {
            case ModelsCoreStatisticsMetrics.Fairway:
                this.enforceMetricMutualExclusion(
                    golfRoundGolfer,
                    ModelsCoreStatisticsMetrics.Fairway,
                    ModelsCoreStatisticsMetrics.FairwayResultId);
                break;
            case ModelsCoreStatisticsMetrics.FairwayResultId:
                this.enforceMetricMutualExclusion(
                    golfRoundGolfer,
                    ModelsCoreStatisticsMetrics.FairwayResultId,
                    ModelsCoreStatisticsMetrics.Fairway);
                break;
            case ModelsCoreStatisticsMetrics.GIR:
                this.enforceMetricMutualExclusion(
                    golfRoundGolfer,
                    ModelsCoreStatisticsMetrics.GIR,
                    ModelsCoreStatisticsMetrics.ApproachShotResultId);
                break;
            case ModelsCoreStatisticsMetrics.ApproachShotResultId:
                this.enforceMetricMutualExclusion(
                    golfRoundGolfer,
                    ModelsCoreStatisticsMetrics.ApproachShotResultId,
                    ModelsCoreStatisticsMetrics.GIR);
                break;
            case ModelsCoreStatisticsMetrics.StrokesGained:
                this.enforceStrokesGainedMetricSelectionRules(golfRoundGolfer);
                break;
        }
    }

    private enforceStrokesGainedMetricSelectionRules(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolferWithMetricSelectItems) {
        const i = golfRoundGolfer.trackedMetrics.indexOf((ModelsCoreStatisticsMetrics.StrokesGained as any));
        const sgSelected = i !== -1;

        golfRoundGolfer.metricSelectItems.forEach(item => {
            const metric: ModelsCoreStatisticsMetric = (item as any).metric;

            if(metric.metricId !== ModelsCoreStatisticsMetrics.StrokesGained) {
                let disabled = false;
                let select = false;
                let unselect = false;

                switch(metric.strokesGainedMetricSupport) {
                case ModelsCoreStatisticsStrokesGainedMetricSupportOptions.Required:
                    select = sgSelected;
                    disabled = sgSelected;
                    break;
                case ModelsCoreStatisticsStrokesGainedMetricSupportOptions.Allowed:
                    disabled = false;
                    break;
                case ModelsCoreStatisticsStrokesGainedMetricSupportOptions.NotAllowed:
                    unselect = sgSelected;
                    disabled = sgSelected;
                    break;
                }

                this.updateMetric(golfRoundGolfer, metric.metricId, disabled, select, unselect);
            }
        });

        if(!sgSelected) {
            this.enforceMetricMutualExclusions(golfRoundGolfer);
        }
    }

    private enforceMetricMutualExclusions(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolferWithMetricSelectItems) {
        this.enforceMetricMutualExclusion(
            golfRoundGolfer,
            ModelsCoreStatisticsMetrics.FairwayResultId,
            ModelsCoreStatisticsMetrics.Fairway);
        this.enforceMetricMutualExclusion(
            golfRoundGolfer,
            ModelsCoreStatisticsMetrics.ApproachShotResultId,
            ModelsCoreStatisticsMetrics.GIR);
    }

    private enforceMetricMutualExclusion(
        golfRoundGolfer: ModelsCoreRoundsGolfRoundGolferWithMetricSelectItems,
        mutuallyExclusiveIdA: ModelsCoreStatisticsMetrics,
        mutuallyExclusiveIdB: ModelsCoreStatisticsMetrics) {

        const mutuallyExclusiveA = golfRoundGolfer.metricSelectItems.find(m => m.value === (mutuallyExclusiveIdA as any));
        const mutuallyExclusiveB = golfRoundGolfer.metricSelectItems.find(m => m.value === (mutuallyExclusiveIdB as any));

        if(mutuallyExclusiveA && mutuallyExclusiveB) {
            const mutuallyExclusiveAIndex = golfRoundGolfer.trackedMetrics.indexOf((mutuallyExclusiveIdA as any));
            const mutuallyExclusiveBIndex = golfRoundGolfer.trackedMetrics.indexOf((mutuallyExclusiveIdB as any));

            if(mutuallyExclusiveAIndex !== -1) {
                if(mutuallyExclusiveBIndex !== -1 && !mutuallyExclusiveB.disabled)
                {
                    golfRoundGolfer.trackedMetrics.splice(mutuallyExclusiveBIndex, 1);
                }
            }
            else if(mutuallyExclusiveBIndex !== -1) {
                if(mutuallyExclusiveAIndex !== -1 && !mutuallyExclusiveA.disabled) {
                    golfRoundGolfer.trackedMetrics.splice(mutuallyExclusiveAIndex, 1);
                }
            }
        }
    }

    private updateMetric(
        golfRoundGolfer: ModelsCoreRoundsGolfRoundGolferWithMetricSelectItems,
        metricId: number,
        disabled: boolean,
        select = false,
        unselect = false) {

        const item = golfRoundGolfer.metricSelectItems.find(m => m.value === (metricId as any));

        if(item) {
            item.disabled = disabled;

            const i = golfRoundGolfer.trackedMetrics.indexOf((metricId as any));

            if(i === -1 && select) {
                golfRoundGolfer.trackedMetrics.push((metricId as any));
            }
            else if(i !== -1 && unselect) {
                golfRoundGolfer.trackedMetrics.splice(i, 1);
            }
        }
    }

    private loadNotesForCurrentGolfer() {
        const currentGolfers = this.round.golfRoundGolfers.filter(g => g.golfer.golferId === this.currentGolfer.golferId);

        if(currentGolfers.length === 1) {
            this.notes = currentGolfers[0].notes;
        }
    }

    private areStrokesEnteredForAnyGolfer() {
        let started = false;
        const round = this.round;

        if(round && round.golfRoundGolfers) {
            for(let i = 0; i < round.golfRoundGolfers.length; i++) {
                const golfRoundGolfer = round.golfRoundGolfers[i];
                started = this.areStrokesEnteredForGolfer(golfRoundGolfer);

                if(started) {
                    break;
                }
            }
        }

        return started;
    }

    private areStrokesEnteredForGolfer(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        let started = false;

        if(golfRoundGolfer.golfRoundGolferHoles) {
            for(let j = 0; j < golfRoundGolfer.golfRoundGolferHoles.length; j++) {
                const golfRoundGolferHole = golfRoundGolfer.golfRoundGolferHoles[j];

                if(golfRoundGolferHole.strokes > 0) {
                    started = true;
                    break;
                }
            }
        }

        return started;
    }

    private loadLookups() {
        this.roundsProxy.getLookups()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.lookups = response.body;
                    this.metrics = this.lookups.metrics.map(
                        metric => {
                            return {
                                label: metric.displayName,
                                value: metric.metricId,
                                metric: metric
                            } as SelectItem;
                        });
                    this.weatherSelectItems = this.lookups.weather.map(
                        weather => {
                            return {
                                label: weather.name,
                                value: weather.weatherId
                            } as SelectItem;
                        });
                    this.windSelectItems = this.lookups.wind.map(
                        wind => {
                            return {
                                label: wind.name,
                                value: wind.windId
                            } as SelectItem;
                        });
                });
    }
}
