import { HubConnection, HubConnectionState, IStreamResult } from '@microsoft/signalr';

export class SignalRHubConnectionWrapper {
    constructor(private connection: HubConnection) {
        this.enableAutoReconnect();

        const oneSecond = 1000;
        const oneMinute = 60 * oneSecond;
        this.connection.serverTimeoutInMilliseconds = 20 * oneMinute;
        this.connection.keepAliveIntervalInMilliseconds = 30 * oneSecond;
    }

    get serverTimeoutInMilliseconds(): number {
        return this.connection.serverTimeoutInMilliseconds;
    }

    set serverTimeoutInMilliseconds(value: number) {
        this.connection.serverTimeoutInMilliseconds = value;
    }

    get keepAliveIntervalInMilliseconds(): number {
        return this.connection.keepAliveIntervalInMilliseconds;
    }

    set keepAliveIntervalInMilliseconds(value: number) {
        this.connection.keepAliveIntervalInMilliseconds = value;
    }

    get state(): HubConnectionState {
        return this.connection.state;
    }

    private maxConnectionRetries = 5;
    private connectionRetries = 0;
    private isStopping = false;

    start(): Promise<void> {
        this.connectionRetries = 0;

        const promise = new Promise<void>(
            (resolve: () => void, reject: (e: any) => void) => {
                this.tryStart(resolve, reject);
            });

        return promise;
    }

    stop(): Promise<void> {
        this.isStopping = true;

        const promise = new Promise<void>(
            (resolve: () => void, reject: (e: any) => void) => {
                this.connection.stop()
                    .then(
                        () => {
                            this.isStopping = false;
                            resolve();
                        })
                    .catch(
                        e => {
                            this.isStopping = false;
                            reject(e);
                        });
            });

        return promise;
    }

    stream<T = any>(methodName: string, ...args: any[]): IStreamResult<T> {
        return this.connection.stream(methodName, ...args);
    }

    send(methodName: string, ...args: any[]): Promise<void> {
        return this.connection.send(methodName, ...args);
    }

    invoke<T = any>(methodName: string, ...args: any[]): Promise<T> {
        return this.connection.invoke(methodName, ...args);
    }

    on(methodName: string, newMethod: (...args: any[]) => void): void {
        this.connection.on(methodName, newMethod);
    }

    off(methodName: string, method: (...args: any[]) => void): void {
        this.connection.off(methodName, method);
    }

    onclose(callback: (error?: Error) => void): void {
        this.connection.onclose(callback);
    }

    private tryStart(resolve: () => void, reject: (e: any) => void) {
        this.connection.start()
            .then(
                () => {
                    this.connectionRetries = 0;
                    resolve();
                })
            .catch(
                e => {
                    this.connectionRetries++;

                    if(this.connectionRetries < this.maxConnectionRetries) {
                        const waitTime = this.getExponentialBackOffWaitTime(this.connectionRetries);

                        setTimeout(
                            () => {
                                this.tryStart(resolve, reject);
                            },
                            waitTime);
                    }
                    else {
                        reject(e);
                    }
                });
    }

    private enableAutoReconnect() {
        this.connection.onclose(() => {
            if(!this.isStopping) {
                this.tryStart(() => { }, () => { });
            }
        });
    }

    private getExponentialBackOffWaitTime(retries: number, baseWaitTime = 100) {
        return Math.pow(2, retries) * baseWaitTime;
    }
}
