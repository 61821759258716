import { Component, OnInit, Input } from '@angular/core';

import { GolfersControllerProxy, ReferenceControllerProxy } from '../../../shared/server-proxies';
import { MessagesComponent } from '../../../shared/ui/messages/messages.component';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';

import {
    ModelsCoreGolfersGolfer,
    ModelsCoreStatisticsMetric,
    ModelsCoreStatisticsMetrics,
    ModelsCoreStatisticsStrokesGainedMetricSupportOptions,
} from '../../../shared/swagger-codegen/models';

interface IMetricWithSelection extends ModelsCoreStatisticsMetric {
    selected: boolean;
    disabled: boolean;
}

@Component({
    selector: 'my-golfer-default-stats',
    templateUrl: './golfer-default-stats.component.html'
})
export class GolferDefaultStatsComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersControllerProxy: GolfersControllerProxy,
        private referenceControllerProxy: ReferenceControllerProxy) {
        super();
    }

    @Input() golfer: ModelsCoreGolfersGolfer;
    @Input() messages: MessagesComponent;
    metrics: IMetricWithSelection[];
    
    ngOnInit() {
        this.referenceControllerProxy.getMetrics()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(response => {
                this.metrics = response.body as IMetricWithSelection[];
                this.setSelectedMetrics();
                this.enforceStrokesGainedMetricSelectionRules();
            });
    }

    onMetricChange(metric: IMetricWithSelection) {
        this.enforceMetricSelectionRules(metric);

        const trackedMetricIds = this.metrics.filter(m => m.selected).map(m => m.metricId);
        this.golfersControllerProxy.saveDefaultTrackedMetrics(this.golfer.golferId, trackedMetricIds)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                },
                response => this.messages.showApiError(response));
    }

    private enforceMetricSelectionRules(metric: IMetricWithSelection) {
        switch(metric.metricId) {
            case ModelsCoreStatisticsMetrics.Fairway:
                this.enforceMetricMutualExclusion(ModelsCoreStatisticsMetrics.Fairway, ModelsCoreStatisticsMetrics.FairwayResultId);
                break;
            case ModelsCoreStatisticsMetrics.FairwayResultId:
                this.enforceMetricMutualExclusion(ModelsCoreStatisticsMetrics.FairwayResultId, ModelsCoreStatisticsMetrics.Fairway);
                break;
            case ModelsCoreStatisticsMetrics.GIR:
                this.enforceMetricMutualExclusion(ModelsCoreStatisticsMetrics.GIR, ModelsCoreStatisticsMetrics.ApproachShotResultId);
                break;
            case ModelsCoreStatisticsMetrics.ApproachShotResultId:
                this.enforceMetricMutualExclusion(ModelsCoreStatisticsMetrics.ApproachShotResultId, ModelsCoreStatisticsMetrics.GIR);
                break;
            case ModelsCoreStatisticsMetrics.StrokesGained:
                this.enforceStrokesGainedMetricSelectionRules();
                break;
        }
    }

    private enforceStrokesGainedMetricSelectionRules() {
        const sg = this.metrics.find(m => m.metricId === ModelsCoreStatisticsMetrics.StrokesGained);
        const sgSelected = sg && sg.selected;

        this.metrics.forEach(metric => {
            if(metric.metricId !== ModelsCoreStatisticsMetrics.StrokesGained) {
                let disabled = false;
                let select = false;
                let unselect = false;

                switch(metric.strokesGainedMetricSupport) {
                case ModelsCoreStatisticsStrokesGainedMetricSupportOptions.Required:
                    select = sgSelected;
                    disabled = sgSelected;
                    break;
                case ModelsCoreStatisticsStrokesGainedMetricSupportOptions.Allowed:
                    disabled = false;
                    break;
                case ModelsCoreStatisticsStrokesGainedMetricSupportOptions.NotAllowed:
                    unselect = sgSelected;
                    disabled = sgSelected;
                    break;
                }

                this.updateMetric(metric.metricId, disabled, select, unselect);
            }
        });

        if(!sgSelected) {
            this.enforceMetricMutualExclusions();
        }
    }

    private enforceMetricMutualExclusions() {
        this.enforceMetricMutualExclusion(ModelsCoreStatisticsMetrics.FairwayResultId, ModelsCoreStatisticsMetrics.Fairway);
        this.enforceMetricMutualExclusion(ModelsCoreStatisticsMetrics.ApproachShotResultId, ModelsCoreStatisticsMetrics.GIR);
    }

    private enforceMetricMutualExclusion(
        mutuallyExclusiveIdA: ModelsCoreStatisticsMetrics,
        mutuallyExclusiveIdB: ModelsCoreStatisticsMetrics) {

        const mutuallyExclusiveA = this.metrics.find(m => m.metricId === mutuallyExclusiveIdA);
        const mutuallyExclusiveB = this.metrics.find(m => m.metricId === mutuallyExclusiveIdB);

        if(mutuallyExclusiveA && mutuallyExclusiveB) {
            if(mutuallyExclusiveA.selected && !mutuallyExclusiveB.disabled) {
                mutuallyExclusiveB.selected = false;
            }
            else if(mutuallyExclusiveB.selected && !mutuallyExclusiveA.disabled) {
                mutuallyExclusiveA.selected = false;
            }
        }
    }

    private updateMetric(
        metricId: number,
        disabled: boolean,
        select = false,
        unselect = false) {

        const item = this.metrics.find(m => m.metricId === metricId);

        if(item) {
            item.disabled = disabled;
            
            if(select) {
                item.selected = true;
            }

            if(unselect) {
                item.selected = false;
            }
        }
    }
    
    private setSelectedMetrics() {
        const selectedIds: { [id: string]: boolean } = {};

        this.golfer.defaultTrackedMetrics.forEach(id => {
            selectedIds[id] = true;
        });

        this.metrics.forEach(metric => metric.selected = selectedIds[metric.metricId]);
    }
}
