<div class="card">
    <h1><my-course-name></my-course-name></h1>
    <form #strategyForm="ngForm" autocomplete="off" novalidate="novalidate">
        <div class="grid" *ngIf="currentMapArea">
            <div class="col-12">
                <p-tabMenu [model]="menuItems" [activeItem]="menuItems[0]"></p-tabMenu>
            </div>
            <div class="col-12">
                <h4>{{currentMapArea?.description}}</h4>
            </div>
        </div>
        <div class="grid">
            <div class="col-12 lg:col-6">
                <div class="col-12 p-fluid" pDroppable="map" (onDrop)="onMapMarkerDropped($event)">
                    <my-course-map *ngIf="course"
                                   [course]="course"
                                   [courseMapArea]="currentMapArea"
                                   [showTeeToGreenLine]="showTeeToGreenLine"
                                   [golfClubShotDispersion]="golfClubShotDispersion"
                                   (shotDispersionToolDeleted)="shotDispersionToolDeleted()"></my-course-map>
                </div>
                <div class="col-12">
                    <button type="submit" pButton label="Save Map" (click)="saveMap()" [disabled]="isSavingMap"></button>
                    <my-messages id="mapMessages"></my-messages>
                </div>
                <div class="col-12" *ngIf="addedShotDispersionTool">
                    <p-selectButton name="golfClub" [options]="golfClubs" [(ngModel)]="selectedGolfClub" optionLabel="abbreviation" (onOptionClick)="onGolfClubSelected($event)"></p-selectButton>
                </div>
                <div class="col-12" *ngIf="currentMapAreaHasHole">
                    <div class="grid">
                        <div class="col-8">
                            <p-fieldset legend="Notes">
                                <textarea name="notes" [(ngModel)]="currentMapArea.notes" rows="6" maxlength="1500" class="with-bottom-margin"></textarea>
                                <button type="submit" pButton label="Save Notes" (click)="saveNotes()" [disabled]="isSavingNotes || !strategyForm.form.valid"></button>
                                <my-messages id="notesMessages"></my-messages>
                            </p-fieldset>
                        </div>
                        <div class="col-4 p-fluid">
                            <p-fieldset legend="Greens Book Images">
                                <div class="grid">
                                    <div class="field col-12">
                                        <p-button *ngIf="!addingHoleImage" icon="pi pi-plus" iconPos="left" label="Add Image" [style]="{ 'width': '150px' }" (click)="addingHoleImage = true"></p-button>
                                        <span *ngIf="addingHoleImage" class="p-float-label">
                                            <p-dropdown name="holeImageType" [(ngModel)]="holeImageTypeId" [options]="holeImageTypes">
                                            </p-dropdown>
                                            <label>Image Type</label>
                                        </span>
                                    </div>
                                    <div class="col-12">
                                        <p-fileUpload *ngIf="holeImageTypeId > 0 && addingHoleImage" name="images" mode="basic" [auto]="true" [multiple]="true" chooseLabel="Choose Image" [url]="imageUploadUrl" accept="image/*" maxFileSize="5000000" [headers]="getImageUploadHeaders()" (onUpload)="onImageUploaded($event);">
                                        </p-fileUpload>
                                    </div>
                                    <div class="col-12" *ngIf="currentMapArea.holeImages.length">
                                        <ul class="no-bullets">
                                            <li *ngFor="let image of currentMapArea.holeImages; let i=index">
                                                <a [routerLink]="" (click)="viewImage(i)" class="clickable">
                                                    {{image.displayText}}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </p-fieldset>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-2">
                <p-tabView>
                    <p-tabPanel header="Course">
                        <small>These are shared by all golfers.</small>
                        <ul *ngIf="currentMapAreaHasHole" class="map-tool-list">
                            <li *ngFor="let tee of courseTees">
                                <span (dblclick)="addTeeMarker(tee)" pTooltip="Double Click to Add to Map">
                                    <img pDraggable="map" (onDragStart)="onTeeMarkerDragStart(tee)" src="{{markerIcons.getTeeMarkerIcon(tee.cssClassName)}}" width="32" height="32" />
                                    {{tee.name}} Tee
                                </span>
                            </li>
                            <li>
                                <span (dblclick)="addGreenMarker()" pTooltip="Double Click to Add to Map">
                                    <img pDraggable="map" (onDragStart)="onGreenMarkerDragStart()" src="{{markerIcons.greenMarkerIcon}}" width="32" height="32" />
                                    Green
                                </span>
                            </li>
                            <li>
                                <span (dblclick)="addYardageMarker()" pTooltip="Double Click to Add to Map">
                                    <img pDraggable="map" (onDragStart)="onYardageMarkerDragStart()" src="{{markerIcons.locationMarkerIcon}}" width="32" height="32" />
                                    Yardage Marker
                                </span>
                            </li>
                        </ul>
                        <ul *ngIf="!currentMapAreaHasHole" class="map-tool-list">
                            <li>
                                <span (dblclick)="addYardageMarker()" pTooltip="Double Click to Add to Map">
                                    <img pDraggable="map" (onDragStart)="onYardageMarkerDragStart()" src="{{markerIcons.greenMarkerIcon}}" width="32" height="32" />
                                    Yardage Marker
                                </span>
                            </li>
                        </ul>
                    </p-tabPanel>
                    <p-tabPanel header="Personal" *ngIf="currentMapAreaHasHole">
                        <small>These are NOT shared with other golfers.</small>
                        <ul class="map-tool-list">
                            <li>
                                <span (dblclick)="addLocationModifierMarker(1)" pTooltip="Double Click to Add to Map">
                                    <img pDraggable="map" (onDragStart)="onLocationModifierMarkerDragStart(1)" src="{{markerIcons.warning1MarkerIcon}}" width="32" height="32" />
                                    Area to Avoid (+1)
                                </span>
                            </li>
                            <li>
                                <span (dblclick)="addLocationModifierMarker(2)" pTooltip="Double Click to Add to Map">
                                    <img pDraggable="map" (onDragStart)="onLocationModifierMarkerDragStart(2)" src="{{markerIcons.warning2MarkerIcon}}" width="32" height="32" />
                                    Area to Avoid (+2)
                                </span>
                            </li>
                            <li>
                                <span (dblclick)="addLocationModifierMarker(3)" pTooltip="Double Click to Add to Map">
                                    <img pDraggable="map" (onDragStart)="onLocationModifierMarkerDragStart(3)" src="{{markerIcons.warning3MarkerIcon}}" width="32" height="32" />
                                    Area to Avoid (+3)
                                </span>
                            </li>
                            <li>
                                <span (dblclick)="addTeeShotTargetMarker()" pTooltip="Double Click to Add to Map">
                                    <img pDraggable="map" (onDragStart)="onTeeShotTargetMarkerDragStart()" src="{{markerIcons.targetMarkerIcon}}" width="32" height="32" />
                                    Tee Shot Target
                                </span>
                            </li>
                            <li>
                                <span (dblclick)="addMeasuringTool()" pTooltip="Double Click to Add to Map">
                                    <img pDraggable="map" (onDragStart)="onMeasuringToolDragStart()" src="{{markerIcons.measuringToolMarkerIcon}}" width="32" height="32" />
                                    Measuring Tool
                                </span>
                            </li>
                            <li>
                                <span (dblclick)="addShotDispersionTool()" pTooltip="Double Click to Add to Map">
                                    <img pDraggable="map" (onDragStart)="onShotDispersionToolDragStart()" src="{{markerIcons.shotDispersionToolMarkerIcon}}" width="32" height="32" />
                                    Shot Dispersion Tool
                                </span>
                            </li>
                        </ul>
                        <div *ngIf="canShowTeeShotDecisionTree">
                            <a *ngIf="!showTeeShotDecisionTree" (click)="showTeeShotDecisionTree=true" class="clickable">Show Tee Shot Decision Tree</a>
                            <a *ngIf="showTeeShotDecisionTree" (click)="showTeeShotDecisionTree=false" class="clickable">Hide Tee Shot Decision Tree</a>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Options" *ngIf="currentMapAreaHasHole">
                        <div class="field col-12">
                            <span class="p-float-label">
                                <p-dropdown name="teeFilter" [(ngModel)]="filteredTeeId" [options]="teeFilters" styleClass="width-full" (onChange)="onTeeFilterChanged()">
                                </p-dropdown>
                                <label>Tee Filter</label>
                            </span>
                        </div>
                        <p-checkbox name="showTeeToGreenLine" [(ngModel)]="showTeeToGreenLine" [binary]="true" label="Show Tee to Green Line"></p-checkbox>
                    </p-tabPanel>
                </p-tabView>
            </div>
            <div class="col-12 lg:col-4" *ngIf="showHistoricalStats">
                <my-hole-historical-stats [allTimeHistory]="currentHoleAllTimeHistory"
                                          [mostRecentYearHistory]="currentHoleMostRecentYearHistory"></my-hole-historical-stats>
            </div>
            <div class="col-12 lg:col-4" *ngIf="showTeeShotDecisionTree">
                <h5>Tee Shot Decision Tree</h5>
                <my-tee-shot-decision-tree></my-tee-shot-decision-tree>
            </div>
        </div>
    </form>
</div>
