    <div class="grid">
        <div class="col-12">
            <p-tabView>
                <p-tabPanel header="Chart" leftIcon="fas fa-chart-line">
                    <my-golf-club-yardage-line-chart [data]="chartData"></my-golf-club-yardage-line-chart>
                </p-tabPanel>
                <p-tabPanel header="Matrix" leftIcon="fas fa-table">
                    <div class="grid">
                        <div class="col-12 lg:col-5">
                            <p-table [value]="matrixClubs" styleClass="p-datatable-striped">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th class="left">Club</th>
                                        <th *ngFor="let header of matrixHeaders">{{header}}</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-club let-rowIndex="rowIndex">
                                    <tr>
                                        <td class="left">
                                            {{club.abbreviation}}
                                        </td>
                                        <td class="center" *ngFor="let yardage of club.yardages">
                                            {{yardage | number}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Gapping" leftIcon="fas fa-arrows-alt-h">
                    <div class="grid">
                        <div class="col-12 lg:col-6">
                            <p-table [value]="gaps" styleClass="p-datatable-striped">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th class="left">Clubs</th>
                                        <th>Distances</th>
                                        <th>Gap</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-gap let-rowIndex="rowIndex">
                                    <tr>
                                        <td class="left">
                                            {{gap.fromDescription}}
                                            <i class="fas fa-arrows-alt-h"></i>
                                            {{gap.toDescription}}
                                        </td>
                                        <td class="center">
                                            {{gap.fromYardage | number}}
                                            <i class="fas fa-arrows-alt-h"></i>
                                            {{gap.toYardage | number}}
                                        </td>
                                        <td class="center">
                                            {{gap.gap | number}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
