/// <reference types="google.maps" />
import { DeleteMenu } from './delete-menu';
import { Disposable } from './disposable';
import { GreenMapMarker } from './green-map-marker';
import { LabelMapMarker } from './label-map-marker';
import { TeeMapMarker } from './tee-map-marker';
import { TeeShotTargetMapMarker } from './tee-shot-target-map-marker';
import { YardageMapMarker } from './yardage-map-marker';
import { scrollMarkerIcon } from './map-marker-icons';
import { disposeMapComponents, metersBetween, radiansToDegrees, yardsBetween, yardsToMeters } from './map-utilities';

import LatLng = google.maps.LatLng;
import Map = google.maps.Map;
import Marker = google.maps.Marker;
import MVCObject = google.maps.MVCObject;
import Polyline = google.maps.Polyline;
import Point = google.maps.Point;
import Size = google.maps.Size;
import computeHeading = google.maps.geometry.spherical.computeHeading;
import computeOffset = google.maps.geometry.spherical.computeOffset;
import interpolate = google.maps.geometry.spherical.interpolate;

const strokeColor = '#009900';

interface ConeMarkerPositions {
    a: LatLng;
    b: LatLng;
    c: LatLng;
}

export interface ShotDispersionToolUpdates {
    teeMarker?: TeeMapMarker;
    teeShotTargetMarker?: TeeShotTargetMapMarker;
    yardageMarkers?: YardageMapMarker[];
    greenMarker?: GreenMapMarker;
    golfClubShotDispersion?: GolfClubShotDispersion;
    reset?: boolean;
}

export interface GolfClubShotDispersion {
    yardage: number;
    lateralDispersion?: number;
    overrideDistanceCap?: boolean;
}

export class ShotDispersionTool implements Disposable {
    constructor(
        private map: Map,
        center: LatLng,
        private holePar: number,
        private teeShotTargetMarker: TeeShotTargetMapMarker,
        private golfClubShotDispersion?: GolfClubShotDispersion) {

        this.dispersionLine = new Polyline({
            map: map,
            path: [
                center,
                center,
                center,
                center
            ],
            strokeColor: strokeColor
        });
        this.nextShotLine = new Polyline({
            map: map,
            path: [
                center,
                center,
                center
            ],
            strokeColor: strokeColor
        });

        this.aVertexMarker = this.createVertexMarker(center);
        this.bVertexMarker = this.createVertexMarker(center);
        this.cVertexMarker = this.createVertexMarker(center);

        this.abYardageLabel = new LabelMapMarker(map, center);
        this.acYardageLabel = new LabelMapMarker(map, center);
        this.bcYardageLabel = new LabelMapMarker(map, center);
        this.bToGreenYardageLabel = new LabelMapMarker(map, center);
        this.cToGreenYardageLabel = new LabelMapMarker(map, center);
    }
    
    private dispersionLine: Polyline;
    private nextShotLine: Polyline;
    private aVertexMarker: Marker;
    private bVertexMarker: Marker;
    private cVertexMarker: Marker;
    private abYardageLabel: LabelMapMarker;
    private acYardageLabel: LabelMapMarker;
    private bcYardageLabel: LabelMapMarker;
    private bToGreenYardageLabel: LabelMapMarker;
    private cToGreenYardageLabel: LabelMapMarker;
    private teeMarker: TeeMapMarker;
    private greenMarker: GreenMapMarker;
    private yardageMarkers: YardageMapMarker[] = [];
    
    dispose() {
        disposeMapComponents(
            this.dispersionLine,
            this.nextShotLine,
            this.aVertexMarker,
            this.bVertexMarker,
            this.cVertexMarker,
            this.abYardageLabel,
            this.acYardageLabel,
            this.bcYardageLabel,
            this.bToGreenYardageLabel,
            this.cToGreenYardageLabel
        );
    }

    update(updates?: ShotDispersionToolUpdates) {
        this.applyUpdates(updates);
        this.updateEdgesFromVertices();
    }

    addDeleteListener(deleteMenu: DeleteMenu) {
        this.addDeleteListeners(
            deleteMenu,
            this.dispersionLine,
            this.nextShotLine,
            this.aVertexMarker,
            this.bVertexMarker,
            this.cVertexMarker
        );
    }

    private addDeleteListeners(deleteMenu: DeleteMenu, ...sources: MVCObject[]) {
        sources.forEach(source => {
            source.addListener('rightclick', (args) => {
                deleteMenu.open(args.latLng, this, 'Delete Shot Dispersion Tool');
            });
        });
    }

    private applyUpdates(updates?: ShotDispersionToolUpdates) {
        if(updates) {
            if(updates.teeMarker) {
                this.teeMarker = updates.teeMarker;
                this.aVertexMarker.setPosition(this.teeMarker.getPosition());
                this.moveMarkerToMaintainIsoscelesTriangle(this.cVertexMarker, this.bVertexMarker);
            }

            if(updates.teeShotTargetMarker) {
                this.teeShotTargetMarker = updates.teeShotTargetMarker;

                const positions = this.getVertexPositionsAfterRotatingHeadingTo(this.teeShotTargetMarker.getPosition());
                this.setVertexPositions(positions);
            }

            if(updates.yardageMarkers) {
                this.yardageMarkers = updates.yardageMarkers;
            }

            if(updates.greenMarker) {
                this.greenMarker = updates.greenMarker;
            }

            if(updates.golfClubShotDispersion) {
                if(updates.golfClubShotDispersion) {
                    this.golfClubShotDispersion = updates.golfClubShotDispersion;
                }
            }

            if(updates.reset) {
                const positions = this.getDefaultVertexPositions();
                this.setVertexPositions(positions);
            }
        }
    }
    
    private createVertexMarker(position: LatLng) {
        const marker = new Marker(
            {
                map: this.map,
                position: position,
                draggable: true,
                icon: {
                    anchor: new Point(10, 10),
                    scaledSize: new Size(20, 20),
                    url: scrollMarkerIcon
                }
            });

        marker.addListener(
            'drag',
            () => {
                this.vertexDragging(marker);
            }
        );

        return marker;
    }
    
    private vertexDragging(marker: Marker) {
        if(marker === this.aVertexMarker || marker === this.bVertexMarker) {
            this.moveMarkerToMaintainIsoscelesTriangle(this.cVertexMarker, this.bVertexMarker);
        }
        else if(marker === this.cVertexMarker) {
            this.moveMarkerToMaintainIsoscelesTriangle(this.bVertexMarker, this.cVertexMarker);
        }
    }

    private moveMarkerToMaintainIsoscelesTriangle(moveableMarker: Marker, fixedMarker: Marker) {
        const a = this.aVertexMarker.getPosition();
        const length = metersBetween(a, fixedMarker.getPosition());
        const heading = computeHeading(a, moveableMarker.getPosition());
        const newPosition = computeOffset(a, length, heading);

        moveableMarker.setPosition(newPosition);
        this.updateEdgesFromVertices();
    }

    private updateEdgesFromVertices() {
        const a = this.aVertexMarker.getPosition();
        const b = this.bVertexMarker.getPosition();
        const c = this.cVertexMarker.getPosition();
        const g = this.greenMarker ? this.greenMarker.getPosition() : this.map.getCenter();

        const dispersionPath = this.dispersionLine.getPath();
        dispersionPath.setAt(0, a);
        dispersionPath.setAt(1, b);
        dispersionPath.setAt(2, c);
        dispersionPath.setAt(3, a);

        let showNextShotLine = false;

        if(this.greenMarker && this.holePar > 3) {
            showNextShotLine = true;
        }

        this.nextShotLine.setVisible(showNextShotLine);
        this.bToGreenYardageLabel.nativeMarker.setVisible(showNextShotLine);
        this.cToGreenYardageLabel.nativeMarker.setVisible(showNextShotLine);

        if(showNextShotLine) {
            const nextShotPath = this.nextShotLine.getPath();
            nextShotPath.setAt(0, b);
            nextShotPath.setAt(1, g);
            nextShotPath.setAt(2, c);
        }

        this.updateYardageLabel(this.abYardageLabel, a, b, 0.80);
        this.updateYardageLabel(this.acYardageLabel, a, c, 0.80);
        this.updateYardageLabel(this.bcYardageLabel, b, c, 0.50);
        this.updateYardageLabel(this.bToGreenYardageLabel, b, g, 0.20);
        this.updateYardageLabel(this.cToGreenYardageLabel, c, g, 0.20);
    }

    private updateYardageLabel(yardageLabel: LabelMapMarker, fromPosition: LatLng, toPosition: LatLng, fraction: number) {
        const midPointPosition = interpolate(fromPosition, toPosition, fraction);
        const yards = yardsBetween(fromPosition, toPosition);

        yardageLabel.text = Math.round(yards).toString();
        yardageLabel.setPosition(midPointPosition);
    }

    private getDefaultVertexPositions() {
        this.ensureValidGolfClubShotDispersion();

        let a: LatLng;
        const target = this.teeShotTargetMarker
            || (this.yardageMarkers.length > 0 ? this.yardageMarkers[0] : undefined)
            || this.greenMarker;
        let targetHeading = 0;
        let maxYardage = this.golfClubShotDispersion.yardage;
        
        if(this.teeMarker && target) {
            a = this.teeMarker.getPosition();
            targetHeading = computeHeading(a, target.getPosition());

            if(this.greenMarker && !this.golfClubShotDispersion.overrideDistanceCap) {
                const yardsToGreen = yardsBetween(this.teeMarker.getPosition(), this.greenMarker.getPosition());
                maxYardage = yardsToGreen;
            }
        }
        else {
            a = computeOffset(this.teeShotTargetMarker.getPosition(), yardsToMeters(maxYardage + 50), -180);
        }

        const lengthInMeters = yardsToMeters(Math.min(this.golfClubShotDispersion.yardage, maxYardage));
        const widthInMeters = yardsToMeters(this.golfClubShotDispersion.lateralDispersion);
        
        return this.computeVertexPositions(a, targetHeading, lengthInMeters, widthInMeters);
    }

    private getVertexPositionsAfterRotatingHeadingTo(target: LatLng) {
        const a = this.aVertexMarker.getPosition();
        const b = this.bVertexMarker.getPosition();
        const c = this.cVertexMarker.getPosition();

        const targetHeading = computeHeading(a, target);
        const lengthInMeters = metersBetween(a, b);
        const widthInMeters = metersBetween(b, c);
        
        return this.computeVertexPositions(a, targetHeading, lengthInMeters, widthInMeters);
    }

    private computeVertexPositions(a: LatLng, targetHeading: number, lengthInMeters: number, widthInMeters: number) {
        const headingOffsetInDegrees = this.getHeadingOffsetInDegrees(lengthInMeters, widthInMeters);

        const positions = { a: a } as ConeMarkerPositions;
        positions.b = computeOffset(a, lengthInMeters, targetHeading - headingOffsetInDegrees);
        positions.c = computeOffset(a, lengthInMeters, targetHeading + headingOffsetInDegrees);

        return positions;
    }
    
    private setVertexPositions(positions: ConeMarkerPositions) {
        this.aVertexMarker.setPosition(positions.a);
        this.bVertexMarker.setPosition(positions.b);
        this.cVertexMarker.setPosition(positions.c);
    }

    private ensureValidGolfClubShotDispersion() {
        const dispersion = this.golfClubShotDispersion || { yardage: 0, lateralDispersion: 0 };

        if(dispersion.yardage < 1) {
            dispersion.yardage = 270;
        }

        if(!dispersion.lateralDispersion || dispersion.lateralDispersion < 1) {
            dispersion.lateralDispersion = 65;
        }

        this.golfClubShotDispersion = dispersion;
    }

    private getHeadingOffsetInDegrees(lengthInMeters: number, widthInMeters: number) {
        const halfWidthInMeters = widthInMeters / 2;

        /*
         * A shot dispersion cone is nothing more than a triangle where we know:
         *    1. The length of the left and right sides of the triangle (the distance we can hit the shot)
         *    2. The width of the base of the triangle (the width of our dispersion pattern at the landing distance)
         *
         * To properly size that cone, we split the triangle in half so we then have 2 right triangles where we know:
         *    1. The length of the longest side (hypotenuse) is the distance we can hit the shot
         *    2. The length of the shortest side (opposite) is HALF the width of our dispersion pattern
         *
         * Using these 2 known lengths and the rules of right triangles, we can calculate the angle left and right of
         * our center line (i.e. the target line) in order to draw the cone with the desired length and width.
         *
         * See SOH of "SOHCAHTOA" at https://www.mathsisfun.com/algebra/trig-finding-angle-right-triangle.html
         */
        
        const asinRadians = Math.asin(halfWidthInMeters / lengthInMeters);
        return radiansToDegrees(asinRadians);
    }
}
