import { Injectable } from '@angular/core';
import { ChartType } from 'chart.js';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';

@Injectable()
export class ChartService {
    constructor() {
        Chart.register(
            ArcElement,
            LineElement,
            BarElement,
            PointElement,
            BarController,
            BubbleController,
            DoughnutController,
            LineController,
            PieController,
            PolarAreaController,
            RadarController,
            ScatterController,
            CategoryScale,
            LinearScale,
            LogarithmicScale,
            RadialLinearScale,
            TimeScale,
            TimeSeriesScale,
            Decimation,
            Filler,
            Legend,
            Title,
            Tooltip,
            annotationPlugin
        );
    }
    
    createDefaultChartOptions(chartType: ChartType) {
        const options: any = {
            elements: {
                line: {
                    cubicInterpolationMode: 'monotone',
                    tension: 0
                }
            },
            scales: {
                x: {
                    ticks: {}
                },
                y: {
                    ticks: {
                    }
                }
            },
            plugins: {
                legend: {},
                title: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                    }
                },
                annotation: {
                    annotations: {}
                }
            }
        };

        if(chartType === 'pie') {
            [options.scales.x, options.scales.y].forEach((scale: any) => {
                scale.grid = {
                    display: false,
                    drawBorder: false,
                    drawOnChartArea: false,
                    drawTicks: false
                };

                scale.ticks.display = false;
            });
        }
        else if(chartType === 'radar') {
            (options as any).scale = {
                ticks: {
                }
            };
        }

        return options;
    }
}
