import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';

import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { ServerProxiesModule } from './server-proxies/server-proxies.module';
import { SharedCoursesModule } from './courses/shared-courses.module';
import { SharedGolfersModule } from './golfers/shared-golfers.module';
import { SharedRoundsModule } from './rounds/shared-rounds.module';
import { UIModule } from './ui/ui.module';

import { httpInterceptorProviders } from './http-interceptors/index';

@NgModule(
    {
        imports: [
        ],
        declarations: [
        ],
        exports: [
            AuthModule,
            CommonModule,
            CoreModule,
            ServerProxiesModule,
            SharedCoursesModule,
            SharedGolfersModule,
            SharedRoundsModule,
            UIModule
        ],
        providers: [
            DatePipe,
            DecimalPipe
        ]
    })
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                httpInterceptorProviders
            ]
        };
    }
}
