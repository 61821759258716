<div class="card login-panel p-fluid">
    <form #passwordForm="ngForm" autocomplete="off" novalidate="novalidate">
        <div class="grid">
            <div class="field col-12">
                <span class="p-float-label">
                    <input #currentPassword="ngModel" class="width10" type="password" name="currentPassword" maxlength="50" pPassword [(ngModel)]="model.currentPassword" required>
                    <my-validation-message [model]="currentPassword" type="required"></my-validation-message>
                    <label>Current Password</label>
                </span>
            </div>
            <div class="field col-12">
                <span class="p-float-label">
                    <input #newPassword="ngModel" class="width10" type="password" name="newPassword" maxlength="50" pPassword [(ngModel)]="model.newPassword" required>
                    <my-validation-message [model]="newPassword" type="required"></my-validation-message>
                    <label>New Password</label>
                </span>
            </div>
            <div class="field col-12">
                <span class="p-float-label">
                    <input #confirmNewPassword="ngModel" class="width10" type="password" name="confirmNewPassword" maxlength="50" pInputText [(ngModel)]="model.confirmNewPassword" required>
                    <my-validation-message [model]="confirmNewPassword" type="required"></my-validation-message>
                    <label>Confirm New Password</label>
                </span>
            </div>
            <div class="col-12">
                <button type="submit" pButton label="Save" (click)="save()" [disabled]="isLoading || !passwordForm.form.valid"></button>
                <my-messages></my-messages>
            </div>
        </div>
    </form>
</div>
