<div class="grid" *ngIf="response">
    <div class="col-12 lg:col-9">
        <table class="p-datatable p-datatable-striped with-bottom-margin">
            <thead class="p-datatable-thead">
            <tr>
                <th class="transparent row-number"></th>
                <th colspan="7">
                    <table class="width-full">
                        <thead>
                        <tr>
                            <th>
                                # of Scores:<br />
                                {{includedScoreCount | number}}
                                of
                                {{recentScoreCount | number}}
                            </th>
                            <th>
                                High Score:<br />
                                {{response.highScore | number}}
                            </th>
                            <th>
                                Low Score:<br />
                                {{response.lowScore | number}}
                            </th>
                            <th>
                                Avg Score:<br />
                                {{response.avgScore | number:'2.1-1'}}
                            </th>
                            <th>
                                <span class="col-6" *ngIf="response.handicap.lowHandicapIndexTeeTime">
                                    Low Handicap Index:<br />
                                    {{response.handicap.lowHandicapIndex | number:'1.1-1'}}
                                    on
                                    {{response.handicap.lowHandicapIndexTeeTime | date:'shortDate'}}
                                </span>
                            </th>
                            <th>
                                Handicap Index:<br />
                                {{response.handicap.formattedHandicapIndex}}
                            </th>
                        </tr>
                        </thead>
                    </table>
                </th>
            </tr>
            <tr>
                <th class="transparent row-number"></th>
                <th class="width2" scope="col">Adjusted<br />Score</th>
                <th class="width3" scope="col">Date</th>
                <th class="width3" scope="col">Rating/Slope</th>
                <th class="width3" scope="col">
                    PCC
                    <i class="fas fa-info-circle help-icon" pTooltip="The playing conditions calculation (PCC) determines whether playing conditions on the day differed from normal conditions to the extent that an adjustment is needed to compensate. It is a daily statistical procedure that compares the scores submitted by players on the day against expected scoring patterns. When significantly fewer players than anticipated attained their expected score at the course then conditions are determined to be harder than normal. When significantly more players than anticipated attained their expected score at the course then conditions are determined to be easier than normal. The PCC adjustment of -1.0, 0.0, +1.0, +2.0 or +3.0 will address both of these conditions."></i>
                </th>
                <th class="width3" scope="col">Adjustment</th>
                <th class="width3" scope="col">Score<br />Differential</th>
                <th class="left" scope="col">Course</th>
            </tr>
            </thead>
            <tbody class="p-datatable-tbody">
            <tr *ngIf="response.golfRoundGolferWaitingToBeCombined" class="awaiting-combine">
                <td class="row-number"></td>
                <td class="center">
                    {{response.golfRoundGolferWaitingToBeCombined.formattedAdjustedScore}}
                </td>
                <td class="center">{{response.golfRoundGolferWaitingToBeCombined.teeTime | date:'shortDate'}}</td>
                <td class="center">
                    {{response.golfRoundGolferWaitingToBeCombined.courseRating | number:'1.1-1'}}/{{response.golfRoundGolferWaitingToBeCombined.courseSlope}}
                </td>
                <td class="center">{{response.golfRoundGolferWaitingToBeCombined.playingConditionsCalculation | number:'1.0-0'}}</td>
                <td class="center">{{response.golfRoundGolferWaitingToBeCombined.adjustment | number}}</td>
                <td class="center">{{response.golfRoundGolferWaitingToBeCombined.scoreDifferential | number:'1.1-1'}}</td>
                <td class="left">
                    <i class="fas fa-th clickable scorecard-icon" pTooltip="View Scorecard" (click)="showScorecardDialog(response.golfRoundGolferWaitingToBeCombined)"></i>
                    {{response.golfRoundGolferWaitingToBeCombined.courseName}}
                    <br />
                    <em>(9 Hole Score Waiting to be Combined)</em>
                </td>
            </tr>
            <tr *ngIf="response.golfRoundGolferWaitingToBeCombined" class="rowgroup-header">
                <td class="row-number"></td>
                <td colspan="7" class="center">Handicap Scores</td>
            </tr>
            <tr *ngFor="let handicapScore of response.handicap.handicapScores; let i = index;" [ngClass]="getRowCss(handicapScore, i + 1)">
                <th scope="row" class="center">{{(i + 1)}}</th>
                <td class="center">
                    <span *ngIf="handicapScore.includedInHandicap">*</span>
                    <span *ngIf="!handicapScore.includedInHandicap">&nbsp;</span>
                    {{handicapScore.formattedAdjustedScore}}
                </td>
                <td class="center">{{handicapScore.teeTime | date:'shortDate'}}</td>
                <td class="center">
                    {{handicapScore.courseRating | number:'1.1-1'}}/{{handicapScore.courseSlope}}
                </td>
                <td class="center">{{handicapScore.playingConditionsCalculation | number:'1.0-0'}}</td>
                <td class="center">{{handicapScore.adjustment | number}}</td>
                <td class="center">{{handicapScore.scoreDifferential | number:'1.1-1'}}</td>
                <td class="left">
                    <i class="fas fa-th clickable scorecard-icon" pTooltip="View Scorecard" (click)="showScorecardDialog(handicapScore.front9GolfRoundGolfer)"></i>
                    <span *ngIf="!handicapScore.back9GolfRoundGolfer">
                        {{handicapScore.front9GolfRoundGolfer.courseName}}
                    </span>
                    <span *ngIf="handicapScore.back9GolfRoundGolfer">
                        {{handicapScore.front9GolfRoundGolfer.description}}
                        ({{handicapScore.front9GolfRoundGolfer.scoreDifferential | number:'1.1-1'}})
                        <br /><br />
                        <i class="fas fa-th clickable scorecard-icon" pTooltip="View Scorecard" (click)="showScorecardDialog(handicapScore.back9GolfRoundGolfer)"></i>
                        {{handicapScore.back9GolfRoundGolfer.description}}
                        ({{handicapScore.back9GolfRoundGolfer.scoreDifferential | number:'1.1-1'}})
                    </span>
                </td>
            </tr>
            </tbody>
        </table>
        <p-messages severity="info" [escape]="false">
            <ng-template pTemplate>
                <span class="p-inline-message-icon pi pi-info-circle"></span>
                <div class="p-inline-message-text">
                    <strong>Missing Rounds? A round can be excluded for any of these reasons:</strong>
                    <ul>
                        <li>It's a practice round. Only competitive rounds are included.</li>
                        <li>It includes 1 or more mulligans. Only rounds adhering to the rules of golf are included.</li>
                        <li>It's an 18 hole round with too few holes played. At least 14 holes must be played.</li>
                        <li>It's a 9 hole round with too few holes played. At least 7 holes must be played.</li>
                    </ul>
                </div>
            </ng-template>
        </p-messages>
    </div>
    <div class="col-12 lg:col-3">
        <table class="p-datatable p-datatable-striped">
            <thead class="p-datatable-thead">
            <tr>
                <th colspan="2">How are Rounds Chosen?</th>
            </tr>
            <tr>
                <th>Total # of<br />Score Differentials</th>
                <th>Handicap Index<br />Calculation</th>
            </tr>
            </thead>
            <tbody class="p-datatable-tbody">
            <tr>
                <td class="center">3 to 5</td>
                <td class="center">Lowest 1</td>
            </tr>
            <tr>
                <td class="center">6 to 8</td>
                <td class="center">Avg of Lowest 2</td>
            </tr>
            <tr>
                <td class="center">9 to 11</td>
                <td class="center">Avg of Lowest 3</td>
            </tr>
            <tr>
                <td class="center">12 to 14</td>
                <td class="center">Avg of Lowest 4</td>
            </tr>
            <tr>
                <td class="center">15 to 16</td>
                <td class="center">Avg of Lowest 5</td>
            </tr>
            <tr>
                <td class="center">17 to 18</td>
                <td class="center">Avg of Lowest 6</td>
            </tr>
            <tr>
                <td class="center">19</td>
                <td class="center">Avg of Lowest 7</td>
            </tr>
            <tr>
                <td class="center">20</td>
                <td class="center">Avg of Lowest 8</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
