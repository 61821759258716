import { Injectable } from '@angular/core';

import {
    ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole
} from '../../../shared/swagger-codegen/models';

interface ScoringAverages {
    par3Average?: number;
    par4Average?: number;
    par5Average?: number;
    relativeScoringAverage?: number;
}

@Injectable()
export class ScorecardCalculatorService {

    getPar3ScoringAverage(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.getScoringAverageByHolePar(holeLists, 3);
    }

    getPar4ScoringAverage(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.getScoringAverageByHolePar(holeLists, 4);
    }

    getPar5ScoringAverage(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.getScoringAverageByHolePar(holeLists, 5);
    }

    getProjectedScore(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.projectScore(holeLists);
    }

    getProjectedBogeyOutScore(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.projectScore(holeLists, 1);
    }

    getProjectedParOutScore(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.projectScore(holeLists, 0);
    }

    getProjectedBirdieOutScore(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.projectScore(holeLists, -1);
    }

    private getScoringAverageByHolePar(holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][], par: number) {
        const holes = this.flattenHoleList(holeLists).filter(h => h.par === par);
        const avg = this.avg(h => h.strokes, holes, strokes => strokes > 0);
        return avg === 0 ? '' : avg;
    }

    private projectScore(holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][], assumedRelativeScore?: number) {
        const holes = this.flattenHoleList(holeLists);
        let scoringAverages: ScoringAverages;

        if(assumedRelativeScore === undefined) {
            scoringAverages = this.getScoringAverages(holes);
        }

        let projectedScore = 0;

        holes.forEach(hole => {
            if(hole.strokes > 0) {
                projectedScore += hole.strokes;
            }
            else if(assumedRelativeScore !== undefined) {
                projectedScore += hole.par + assumedRelativeScore;
            }
            else if(scoringAverages !== undefined) {
                let strokes = 0;

                switch(hole.par) {
                    case 4:
                        strokes = scoringAverages.par4Average || 0;
                        break;
                    case 3:
                        strokes = scoringAverages.par3Average || 0;
                        break;
                    case 5:
                        strokes = scoringAverages.par5Average || 0;
                        break;
                }

                if(strokes === 0) {
                    strokes = hole.par + (scoringAverages.relativeScoringAverage || 0);
                }

                projectedScore += strokes;
            }
        });

        return Math.round(projectedScore);
    }

    private flattenHoleList(holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        const holes: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[] = [];

        holeLists.forEach(list => {
            holes.push(...list);
        });

        return holes;
    }

    private getScoringAverages(golfRoundGolferHoles: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[]) {
        let holes = 0;
        let score = 0;
        let par3Holes = 0;
        let par3Strokes = 0;
        let par4Holes = 0;
        let par4Strokes = 0;
        let par5Holes = 0;
        let par5Strokes = 0;

        golfRoundGolferHoles
            .filter(hole => hole.strokes > 0)
            .forEach(hole => {

            holes++;
            score += hole.strokes - hole.par;

            switch(hole.par) {
                case 4:
                    par4Holes++;
                    par4Strokes += hole.strokes;
                    break;
                case 3:
                    par3Holes++;
                    par3Strokes += hole.strokes;
                    break;
                case 5:
                    par5Holes++;
                    par5Strokes += hole.strokes;
                    break;
            }
        });

        return {
            par3Average: par3Holes > 0 ? par3Strokes / par3Holes : undefined,
            par4Average: par4Holes > 0 ? par4Strokes / par4Holes : undefined,
            par5Average: par5Holes > 0 ? par5Strokes / par5Holes : undefined,
            relativeScoringAverage: holes > 0 ? score / holes : undefined
        } as ScoringAverages;
    }

    private avg(
        getValue: (hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) => number,
        holes: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[],
        filterValue: (value: number) => boolean = () => true) {

        let count = 0;
        let sum = 0;

        for(let j = 0; j < holes.length; j++) {
            const value = getValue(holes[j]);
            const include = filterValue(value);

            if(include) {
                count++;
                sum += value;
            }
        }

        return count > 0 ? (sum / count) : 0.0;
    }
}
