// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreStatisticsStatisticCategories = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 12 | 13 | 14 | 15 | 16 | 18 | 19 | 20 | 21 | 22 | 26 | 28;

export const ModelsCoreStatisticsStatisticCategories = {
    Scoring: 1 as ModelsCoreStatisticsStatisticCategories,
    ScoringVsPar: 2 as ModelsCoreStatisticsStatisticCategories,
    Fairways: 3 as ModelsCoreStatisticsStatisticCategories,
    GIR: 4 as ModelsCoreStatisticsStatisticCategories,
    Putting: 5 as ModelsCoreStatisticsStatisticCategories,
    TroubleAndRecovery: 6 as ModelsCoreStatisticsStatisticCategories,
    PersonalRecords: 7 as ModelsCoreStatisticsStatisticCategories,
    Winning: 8 as ModelsCoreStatisticsStatisticCategories,
    Favorites: 9 as ModelsCoreStatisticsStatisticCategories,
    ScoringDistribution: 12 as ModelsCoreStatisticsStatisticCategories,
    PuttingByDistance: 13 as ModelsCoreStatisticsStatisticCategories,
    ShortGame: 14 as ModelsCoreStatisticsStatisticCategories,
    Basics: 15 as ModelsCoreStatisticsStatisticCategories,
    TeeShots: 16 as ModelsCoreStatisticsStatisticCategories,
    ApproachShots: 18 as ModelsCoreStatisticsStatisticCategories,
    Par3Scoring: 19 as ModelsCoreStatisticsStatisticCategories,
    Par4Scoring: 20 as ModelsCoreStatisticsStatisticCategories,
    Par5Scoring: 21 as ModelsCoreStatisticsStatisticCategories,
    Course: 22 as ModelsCoreStatisticsStatisticCategories,
    MissTendencies: 26 as ModelsCoreStatisticsStatisticCategories,
    ProximityToPin: 28 as ModelsCoreStatisticsStatisticCategories
};
