// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreStatisticsAchievementCategories = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export const ModelsCoreStatisticsAchievementCategories = {
    Favorites: 1 as ModelsCoreStatisticsAchievementCategories,
    MajorAccomplishments: 2 as ModelsCoreStatisticsAchievementCategories,
    ScoringMilestones: 3 as ModelsCoreStatisticsAchievementCategories,
    GolfRoundMilestones: 4 as ModelsCoreStatisticsAchievementCategories,
    Streaks: 5 as ModelsCoreStatisticsAchievementCategories,
    DistanceFeats: 6 as ModelsCoreStatisticsAchievementCategories,
    Winning: 7 as ModelsCoreStatisticsAchievementCategories,
    StatisticStreaks: 8 as ModelsCoreStatisticsAchievementCategories,
    AnnualCourseChallenges: 9 as ModelsCoreStatisticsAchievementCategories,
    CareerCourseChallenges: 10 as ModelsCoreStatisticsAchievementCategories
};
