export class DeleteMenu extends google.maps.OverlayView {
    constructor(private internalMap: google.maps.Map) {
        super();
        this.div = document.createElement('div');
        this.div.className = 'google-maps-delete-menu';
        this.div.innerHTML = 'Delete';
    }

    private divListener: google.maps.MapsEventListener;
    private div: HTMLDivElement;
    private target: any;

    onAdd() {
        this.getPanes().floatPane.appendChild(this.div);

        // mousedown anywhere on the map except on the menu div will close the menu.
        this.divListener = google.maps.event.addDomListener(this.internalMap.getDiv(), 'mousedown', (e: MouseEvent) => {
            if(e.target !== this.div) {
                this.close();
            }
        }, true);
    }

    onRemove() {
        google.maps.event.removeListener(this.divListener);
        this.div.parentNode.removeChild(this.div);
        this.set('position', undefined);
    }

    open(position: google.maps.LatLng, target: any, deleteText?: string) {
        this.target = target;
        this.div.innerHTML = deleteText || 'Delete';
        this.set('position', position);
        this.setMap(this.internalMap);
        this.draw();
    }

    close() {
        this.setMap(null);
    }

    draw() {
        const position = this.get('position');
        const projection = this.getProjection();

        if(!position || !projection) {
            return;
        }

        const point = projection.fromLatLngToDivPixel(position);
        this.div.style.top = point.y + 'px';
        this.div.style.left = point.x + 'px';
    }

    addClickListener(handler: (target: any) => void) {
        this.div.addEventListener('click', () => {
            handler(this.target);
            this.target = undefined;
        });
    }
}
