import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { AuthGuard } from '../shared/auth/auth-guard.service';
import { AppLayoutComponent } from '../layout/app.layout.component';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GolferProfileTabViewComponent } from './profile/golfer-profile-tab-view.component';
import { GolferListComponent } from './list/golfer-list.component';
import { HandicapsTabViewComponent } from './handicaps/handicaps-tab-view.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { RegisterComponent } from './register/register.component';
import { GolfClubDetailsComponent } from './profile/golf-clubs/details/golf-club-details.component';

export const routes: Routes = [
    {
        path: 'golfers',
        component: AppLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent,
                data: {
                    title: 'Dashboard'
                }
            },
            {
                path: ':golferId',
                component: GolferProfileTabViewComponent,
                data: {
                    title: 'Golfer Profile'
                }
            },
            {
                path: ':golferId/handicaps',
                component: HandicapsTabViewComponent,
                data: {
                    title: 'Handicaps'
                }
            },
            {
                path: ':golferId/change-password',
                component: ChangePasswordComponent,
                data: {
                    title: 'Change Password'
                }
            },
            {
                path: ':golferId/golf-club/new',
                component: GolfClubDetailsComponent,
            },
            {
                path: ':golferId/golf-club/:golfClubId',
                component: GolfClubDetailsComponent,
            },
            {
                path: '',
                component: GolferListComponent,
                data: {
                    title: 'Golfer List'
                }
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        data: {
            title: 'Login'
        }
    },
    {
        path: 'logout',
        component: LogoutComponent,
        data: {
            title: 'Logout'
        }
    },
    {
        path: 'register',
        component: RegisterComponent,
        data: {
            title: 'Register'
        }
    }
];

export const golfersRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
