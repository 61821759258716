import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';

import { PracticeActivityHistoryChartDialogComponent } from './practice-activity-history-chart-dialog.component';

export interface IPracticeActivityHistoryChartConfigData {
    practiceActivityId: number;
    practiceActivityVariationId?: number;
}

@Injectable()
export class PracticeActivityHistoryChartDialogService {
    constructor(private dialogService: DialogService) {

    }

    open(header: string, practiceActivityId: number, practiceActivityVariationId?: number) {
        const data: IPracticeActivityHistoryChartConfigData = {
            practiceActivityId: practiceActivityId,
            practiceActivityVariationId: practiceActivityVariationId
        };

        return this.dialogService.open(
            PracticeActivityHistoryChartDialogComponent,
            {
                data: data,
                header: header,
                width: '650px',
                height: '500px',
                contentStyle: {
                    overflow: 'auto',
                    width: '100%',
                    height: '90%'
                }
            });
    }
}
