// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreStatisticsStatisticComparisons = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export const ModelsCoreStatisticsStatisticComparisons = {
    MostRecentYears: 1 as ModelsCoreStatisticsStatisticComparisons,
    MostPlayedYears: 2 as ModelsCoreStatisticsStatisticComparisons,
    Friends: 3 as ModelsCoreStatisticsStatisticComparisons,
    RecentRounds: 4 as ModelsCoreStatisticsStatisticComparisons,
    GolfRound: 5 as ModelsCoreStatisticsStatisticComparisons,
    GolfRoundImpact: 6 as ModelsCoreStatisticsStatisticComparisons,
    Months: 7 as ModelsCoreStatisticsStatisticComparisons,
    Quarters: 8 as ModelsCoreStatisticsStatisticComparisons
};
