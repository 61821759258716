<div class="p-fluid">
    <div class="grid form-group">
        <div class="field col-12 lg:col-2">
            <span class="p-float-label">
                <p-dropdown name="category" [(ngModel)]="args.practiceCategory" [options]="practiceCategories" required (onChange)="onPracticeCategoryChanged()"></p-dropdown>
                <label>Category</label>
            </span>
        </div>
        <div class="field col-12 lg:col-2">
            <span class="p-float-label">
                <p-dropdown name="practiceTypes" [(ngModel)]="args.practiceType" [options]="practiceTypes" required (onChange)="onPracticeTypeChanged()"></p-dropdown>
                <label>Practice Type</label>
            </span>
        </div>
    </div>

    <div *ngIf="practiceActivities">
        <p-table [value]="practiceActivities" [paginator]="practiceActivities.length > 15" [pageLinks]="10" [rows]="15" [loading]="isLoading" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md">
            <ng-template pTemplate="caption">
                Practice Activities
                <i *ngIf="!enableAddingToPlan" class="fas fa-plus-square new-item-icon clickable" [routerLink]="['/practice/activities/new']" pTooltip="Create New Practice Activity"></i>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="width10 left">Name</th>
                    <th class="left">Description</th>
                    <th class="width2"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-activity let-rowIndex="rowIndex">
                <tr>
                    <td class="left">
                        <span class="p-column-title">Name</span>
                        <span *ngIf="enableAddingToPlan">{{activity.name}}</span>
                        <a *ngIf="!enableAddingToPlan" [routerLink]="['/practice/activities', activity.practiceActivityId]">{{activity.name}}</a>
                    </td>
                    <td class="left">
                        <span class="p-column-title">Description</span>
                        <span [innerHtml]="activity.description | textToHtml"></span>
                    </td>
                    <td class="center">
                        <span class="status-icons">
                            <i *ngIf="!enableAddingToPlan" class="fas fa-edit clickable edit-icon" pTooltip="Edit" [routerLink]="['/practice/activities', activity.practiceActivityId]"></i>
                            <i *ngIf="!enableAddingToPlan" class="fas fa-trash-alt clickable delete-icon" pTooltip="Delete" (click)="deletePracticeActivity(activity, rowIndex)"></i>
                            <i *ngIf="enableAddingToPlan" class="far fa-plus-square clickable add-icon" pTooltip="Add to Plan" (click)="addPracticeActivityToPlan(activity)"></i>
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="3">
                        {{getEmptyMessage(noActivitiesMessage)}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p-confirmDialog key="practice-activity-list-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
    </div>
    <div class="grid">
        <div class="col-12">
            <my-messages></my-messages>
        </div>
    </div>
</div>
