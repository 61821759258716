import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { CoursesControllerProxy, GolfersControllerProxy } from '../../shared/server-proxies';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';
import { CurrentGolferService } from '../../shared/golfers/current-golfer.service';

import {
    ModelsCoursesWhichTeeTeeSummary
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-which-tee',
    templateUrl: './which-tee.component.html',
    styleUrls: [
        './which-tee.component.scss'
    ]
})
export class WhichTeeComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private coursesProxy: CoursesControllerProxy,
        private golfersProxy: GolfersControllerProxy,
        private currentRoute: ActivatedRoute,
        private currentGolfer: CurrentGolferService) {
        super();
    }

    tees: ModelsCoursesWhichTeeTeeSummary[] = [];
    drivingDistance: number;

    ngOnInit(): void {
        this.loadDrivingDistance();
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    const courseIdParam = params['courseId'];
                    const courseId = parseInt(courseIdParam, 10);

                    this.taskStarted();
                    this.coursesProxy.getTeeSummary(courseId)
                        .pipe(
                            finalize(() => this.taskCompleted()),
                            this.takeUntilUnsubscribed())
                        .subscribe(
                            response => {
                                this.tees = response.body;
                            });
                });
    }

    shot2Yards(totalYards: number) {
        return totalYards - (this.drivingDistance || 0);
    }

    getCssClassForRecommendedTeeByAvgDrive(from: number, thru: number) {
        const avgDrive = this.drivingDistance;

        from = isNaN(from) ? avgDrive : from;
        thru = isNaN(thru) ? avgDrive : thru;

        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        return avgDrive >= from && avgDrive <= thru ? { 'recommended-tee': true } : undefined;
    }

    private loadDrivingDistance() {
        this.golfersProxy.getGolfClubs(this.currentGolfer.golferId)
            .subscribe(response => {
                if(response.body.length > 0) {
                    this.drivingDistance = response.body[0].yardage;
                }
            });
    }
}
