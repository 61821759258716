<div class="grid">
    <div class="col-12 lg:col-3" *ngIf="metrics">
        <p-table [value]="metrics" [loading]="isLoading" styleClass="p-datatable-striped">
            <ng-template pTemplate="caption">
                Default Stats
            </ng-template>
            <ng-template pTemplate="body" let-metric>
                <tr>
                    <td class="left">
                        <p-checkbox [(ngModel)]="metric.selected" [disabled]="metric.disabled" binary="true" (onChange)="onMetricChange(metric)" [label]="metric.displayName"></p-checkbox>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
