<div class="grid">
    <div class="col-12 lg:col-4" *ngIf="swingThoughts">
        <p-table [value]="swingThoughts" [loading]="isLoading" styleClass="p-datatable-striped" selectionMode="single">
            <ng-template pTemplate="header">
                <tr>
                    <th>Swing Thought</th>
                    <th class="width2"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-swingThought let-rowIndex="rowIndex">
                <tr>
                    <td class="left">
                        <span class="p-column-title">Description</span>
                        <textarea *ngIf="swingThought === selectedSwingThought" [(ngModel)]="swingThought.description" [required] rows="15" maxlength="4000"></textarea>
                        <span *ngIf="swingThought !== selectedSwingThought" [innerHtml]="swingThought.description | textToHtml"></span>
                    </td>
                    <td class="center">
                        <span class="status-icons">
                            <i *ngIf="swingThought !== selectedSwingThought" class="fas fa-edit clickable edit-icon" pTooltip="Edit" (click)="editSwingThought(swingThought)"></i>
                            <i *ngIf="swingThought !== selectedSwingThought" class="fas fa-trash-alt clickable delete-icon" pTooltip="Delete" (click)="deleteSwingThought(swingThought, rowIndex)"></i>
                            <i *ngIf="swingThought === selectedSwingThought" class="fas fa-save clickable save-icon" pTooltip="Save" (click)="saveSwingThought(swingThought)"></i>
                            <i *ngIf="swingThought === selectedSwingThought" class="fas fa-ban clickable cancel-icon" pTooltip="Cancel" (click)="cancelSwingThoughtEdit()"></i>
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <td>
                        <span class="p-column-title">Swing Thought</span>
                        <textarea [(ngModel)]="newSwingThought.description" [required] rows="15" maxlength="4000"></textarea>
                    </td>
                    <td class="center">
                        <span class="status-icons">
                            <i class="fas fa-plus-circle clickable save" pTooltip="Add New Swing Thought" (click)="addSwingThought(newSwingThought)"></i>
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="2">
                        {{getEmptyMessage('No swing thoughts.')}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p-confirmDialog key="swing-thoughts-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
    </div>
</div>
