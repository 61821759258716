import { Injectable } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';

@Injectable()
export class StatisticsControllerProxy {
    private baseUrl = '/api/statistics';

    constructor(private httpClient: HttpClientWrapperService) {
    }

    getStatistics() {
        return this.httpClient.get<any>(`${this.baseUrl}`);
    }
}
