import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstrumentationService } from './instrumentation.service';
import { StorageService } from './storage.service';
import { ConfigurationService } from './configuration.service';
import { MessagePubSubService } from './message-pub-sub.service';
import { SignalRHubConnectionFactory } from './signalr-hub-connection-factory.service';

@NgModule(
    {
        declarations: [
        ],
        providers: [
            InstrumentationService,
            StorageService,
            ConfigurationService,
            MessagePubSubService,
            SignalRHubConnectionFactory
        ],
        imports: [
            CommonModule
        ],
        exports: [
        ]
    })
export class CoreModule {
}
