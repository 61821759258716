/// <reference types="google.maps" />
import {
    ModelsCoreCoursesMapMarkers
} from '../../swagger-codegen/models';

import { locationMarkerIcon } from './map-marker-icons';
import { MapMarkerWrapper } from './map-marker-wrapper';

import LatLng = google.maps.LatLng;
import Map = google.maps.Map;
import Size = google.maps.Size;

export class YardageMapMarker extends MapMarkerWrapper {
    constructor(map: Map, position: LatLng, ordinal: number, title?: string, iconUrl: string = locationMarkerIcon) {
        super(
            ModelsCoreCoursesMapMarkers.Yardage,
            {
                map: map,
                position: position,
                draggable: true,
                icon: {
                    scaledSize: new Size(32, 32),
                    url: iconUrl
                },
                title: title || `Yardage Marker ${ordinal}`
            }
        );

        this.ordinal = ordinal;
    }

    get ordinal() {
        return this.myOrdinal;
    }

    set ordinal(ordinal: number) {
        this.myOrdinal = ordinal;

        this.nativeMarker.setLabel({
            text: (ordinal).toString(),
            color: '#FFFFFF'
        });
    }

    private myOrdinal: number;

    createUpdateModel() {
        const model = super.createUpdateModel();
        model.ordinal = this.ordinal;
        return model;
    }
}
