const markerIconBaseUrl = '/assets/images/map-markers';

export const greenMarkerIcon = `${markerIconBaseUrl}/golf-flag-with-pole.svg`;
export const locationMarkerIcon = `${markerIconBaseUrl}/location.svg`;
export const locationRedMarkerIcon = `${markerIconBaseUrl}/location-red.svg`;
export const measuringToolMarkerIcon = `${markerIconBaseUrl}/strategy/tape.svg`;
export const shotDispersionToolMarkerIcon = `${markerIconBaseUrl}/strategy/geometry.svg`;
export const targetMarkerIcon = `${markerIconBaseUrl}/strategy/target.svg`;
export const warning1MarkerIcon = `${markerIconBaseUrl}/strategy/warning-1.svg`;
export const warning2MarkerIcon = `${markerIconBaseUrl}/strategy/warning-2.svg`;
export const warning3MarkerIcon = `${markerIconBaseUrl}/strategy/danger.svg`;
export const scrollMarkerIcon = `${markerIconBaseUrl}/scroll.svg`;
export const defaultTeeMarkerIcon = getTeeMarkerIcon('blue-tee');

export function getTeeMarkerIcon(teeCssClassName: string) {
    return `${markerIconBaseUrl}/tees/${teeCssClassName.toLowerCase()}.svg`;
}
