// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCorePracticePracticeTypes = 1 | 2 | 3 | 4;

export const ModelsCorePracticePracticeTypes = {
    BlockPractice: 1 as ModelsCorePracticePracticeTypes,
    RandomPractice: 2 as ModelsCorePracticePracticeTypes,
    CompetitivePractice: 3 as ModelsCorePracticePracticeTypes,
    Instruction: 4 as ModelsCorePracticePracticeTypes
};
