<div class="p-fluid">
    <div class="grid form-group">
        <div class="field col-12 lg:col-2" *ngIf="shouldShowGolferSelector">
            <span class="p-float-label">
                <p-dropdown [options]="golfers" [(ngModel)]="args.golferId" (onChange)="onGolferChanged()"></p-dropdown>
                <label>Golfer</label>
            </span>
        </div>
        <div class="field col-12 lg:col-3">
            <span class="p-float-label">
                <p-dropdown name="charts" [(ngModel)]="args.chart" [options]="charts" required (onChange)="onChartChanged()"></p-dropdown>
                <label>Chart</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1" *ngIf="args.year">
            <span class="p-float-label">
                <p-dropdown [options]="yearsPracticed" [(ngModel)]="args.year" (onChange)="onYearChanged();"></p-dropdown>
                <label>Year</label>
            </span>
        </div>
    </div>
    <div *ngIf="chartData">
        <my-practice-time-by-month-stacked-bar-chart [data]="chartData" *ngIf="showPracticeTimeByMonthChart"></my-practice-time-by-month-stacked-bar-chart>
        <my-practice-time-by-category-stacked-bar-chart [data]="chartData" *ngIf="showPracticeTimeByCategoryChart"></my-practice-time-by-category-stacked-bar-chart>
        <my-improvement-plan-bar-chart [data]="chartData" *ngIf="showImprovementPlanChart"></my-improvement-plan-bar-chart>
    </div>
</div>
