import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { AuthGuard } from '../shared/auth/auth-guard.service';
import { AppLayoutComponent } from '../layout/app.layout.component';
import { AchievementHistoryComponent } from './achievements/history/achievement-history.component';
import { GoalDetailComponent } from './goals/detail/goal-detail.component';
import { StatisticsTabViewComponent } from './statistics-tab-view.component';
import { HeadToHeadStatisticsComponent } from './head-to-head/head-to-head-statistics.component';

export const routes: Routes = [
    {
        path: 'statistics',
        component: AppLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: StatisticsTabViewComponent,
                data: {
                    title: 'Statistics'
                }
            },
            {
                path: 'head-to-head',
                component: HeadToHeadStatisticsComponent,
                data: {
                    title: 'Head to Head Statistics'
                }
            },
            {
                path: 'goals/new',
                component: GoalDetailComponent,
                data: {
                    title: 'New Goal'
                }
            },
            {
                path: 'goals/:goalId',
                component: GoalDetailComponent,
                data: {
                    title: 'Goal'
                }
            },
            {
                path: 'achievements/:achievementId/history',
                component: AchievementHistoryComponent,
                data: {
                    title: 'Achievement History'
                }
            }
        ]
    }
];

export const statisticsRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
