<div class="grid">
    <div class="col-12 lg:col-6" *ngIf="practicePlans">
        <p-table [value]="practicePlans" [paginator]="practicePlans.length > 25" [pageLinks]="10" [rows]="25" [loading]="isLoading" styleClass="p-datatable-striped" tableStyleClass="table">
            <ng-template pTemplate="caption">
                Select Practice Plans for this Session
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="width1"></th>
                    <th>Practice Plan</th>
                    <th class="width4">Planned<br />Time</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-plan let-rowIndex="rowIndex">
                <tr>
                    <td>
                        <p-checkbox name="practicePlanIds" [value]="plan.practicePlanId" [(ngModel)]="practicePlanIds"></p-checkbox>
                    </td>
                    <td class="left">
                        <span class="p-column-title">Name</span>
                        <a [routerLink]="['/practice/plans', plan.practicePlanId]">{{plan.name}}</a>
                    </td>
                    <td class="center">
                        <span class="p-column-title">Planned Time</span>
                        {{plan.plannedDuration}}
                        <i *ngIf="plan.plannedMinutes > 0" class="fas fa-chart-pie clickable pie-chart-icon" pTooltip="Click to View Practice Time Per Category" (click)="showPracticeTimeByCategoryChart(plan)"></i>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">
                        {{getEmptyMessage('No practice plans.')}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p>
            <button pButton type="button" icon="fas fa-play" iconPos="right" label="Create Session" [disabled]="practicePlanIds.length === 0" (click)="createSession()"></button>
        </p>
    </div>
    <div class="grid">
        <div class="col-12">
            <my-messages></my-messages>
        </div>
    </div>
</div>
<p-confirmDialog key="practice-plan-list-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
