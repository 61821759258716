// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreStatisticsGoalStatus = 0 | 1 | 2 | 3 | 4;

export const ModelsCoreStatisticsGoalStatus = {
    InProgressAccomplishing: 0 as ModelsCoreStatisticsGoalStatus,
    InProgressNotAccomplishing: 1 as ModelsCoreStatisticsGoalStatus,
    CompleteAccomplished: 2 as ModelsCoreStatisticsGoalStatus,
    CompleteNotAccomplished: 3 as ModelsCoreStatisticsGoalStatus,
    NotStarted: 4 as ModelsCoreStatisticsGoalStatus
};
