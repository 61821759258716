import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { coursesRoutes } from './courses.routes';
import { LightboxModule } from 'ngx-lightbox';
import { SharedModule } from '../shared/shared.module';

import { CourseDetailComponent } from './detail/detail/course-detail.component';
import { CourseDetailTabViewComponent } from './detail/course-detail-tab-view.component';
import { CourseHistoryComponent } from './history/course-history.component';
import { CourseListComponent } from './list/course-list.component';
import { CourseStrategyComponent } from './strategy/course-strategy.component';
import { CourseScorecardComponent } from './detail/scorecard/course-scorecard.component';
import { HoleHistoryComponent } from './history/hole-history.component';
import { NewCourseComponent } from './new/new-course.component';
import { WhichTeeComponent } from './which-tee/which-tee.component';

@NgModule({
    imports: [
        coursesRoutes,
        CommonModule,
        FormsModule,
        LightboxModule,
        SharedModule
    ],
    exports: [
    ],
    declarations: [
        CourseDetailComponent,
        CourseDetailTabViewComponent,
        CourseHistoryComponent,
        CourseListComponent,
        CourseStrategyComponent,
        CourseScorecardComponent,
        HoleHistoryComponent,
        NewCourseComponent,
        WhichTeeComponent
    ],
    providers: [
    ]
})
export class CoursesModule {

}
