<table class="p-datatable p-datatable-striped">
    <thead class="p-datatable-thead">
        <tr>
            <th class="transparent"></th>
            <th scope="col">ALL TIME</th>
            <th scope="col">{{mostRecentYearHistory.year}}</th>
        </tr>
    </thead>
    <tbody class="p-datatable-tbody center-cells">
        <tr>
            <th scope="row" class="left">Times Played</th>
            <td>{{allTimeHistory.timesPlayed | number}}</td>
            <td>{{mostRecentYearHistory.timesPlayed | number}}</td>
        </tr>
        <tr>
            <th scope="row" class="left">First Played</th>
            <td>{{allTimeHistory.firstPlayed | date:'shortDate'}}</td>
            <td>{{mostRecentYearHistory.firstPlayed | date:'shortDate'}}</td>
        </tr>
        <tr>
            <th scope="row" class="left">Last Played</th>
            <td>{{allTimeHistory.lastPlayed | date:'shortDate'}}</td>
            <td>{{mostRecentYearHistory.lastPlayed | date:'shortDate'}}</td>
        </tr>
        <tr>
            <th scope="row" class="left">Scoring</th>
            <td>
                {{allTimeHistory.averageScore | number:'1.2-2'}}
                ({{allTimeHistory.formattedBestScore}} to {{allTimeHistory.formattedWorstScore}})
            </td>
            <td>
                {{mostRecentYearHistory.averageScore | number:'1.2-2'}}
                ({{mostRecentYearHistory.formattedBestScore}} to {{mostRecentYearHistory.formattedWorstScore}})
            </td>
        </tr>
        <tr>
            <th scope="row" class="left">Eagles</th>
            <td>
                {{allTimeHistory.eagles | number:'1.0-0'}} -
                {{allTimeHistory.eaglePercent | percent:'1.2-2'}}
            </td>
            <td>
                {{mostRecentYearHistory.eagles | number:'1.0-0'}} -
                {{mostRecentYearHistory.eaglePercent | percent:'1.2-2'}}
            </td>
        </tr>
        <tr>
            <th scope="row" class="left">Birdies</th>
            <td>
                {{allTimeHistory.birdies | number:'1.0-0'}} -
                {{allTimeHistory.birdiePercent | percent:'1.2-2'}}
            </td>
            <td>
                {{mostRecentYearHistory.birdies | number:'1.0-0'}} -
                {{mostRecentYearHistory.birdiePercent | percent:'1.2-2'}}
            </td>
        </tr>
        <tr>
            <th scope="row" class="left">Pars</th>
            <td>
                {{allTimeHistory.pars | number:'1.0-0'}} -
                {{allTimeHistory.parPercent | percent:'1.2-2'}}
            </td>
            <td>
                {{mostRecentYearHistory.pars | number:'1.0-0'}} -
                {{mostRecentYearHistory.parPercent | percent:'1.2-2'}}
            </td>
        </tr>
        <tr>
            <th scope="row" class="left">Bogeys</th>
            <td>
                {{allTimeHistory.bogeys | number:'1.0-0'}} -
                {{allTimeHistory.bogeyPercent | percent:'1.2-2'}}
            </td>
            <td>
                {{mostRecentYearHistory.bogeys | number:'1.0-0'}} -
                {{mostRecentYearHistory.bogeyPercent | percent:'1.2-2'}}
            </td>
        </tr>
        <tr>
            <th scope="row" class="left">Double Bogeys</th>
            <td>
                {{allTimeHistory.doubleBogeys | number:'1.0-0'}} -
                {{allTimeHistory.doubleBogeyPercent | percent:'1.2-2'}}
            </td>
            <td>
                {{mostRecentYearHistory.doubleBogeys | number:'1.0-0'}} -
                {{mostRecentYearHistory.doubleBogeyPercent | percent:'1.2-2'}}
            </td>
        </tr>
        <tr>
            <th scope="row" class="left">Triple Bogeys</th>
            <td>
                {{allTimeHistory.tripleBogeys | number:'1.0-0'}} -
                {{allTimeHistory.tripleBogeyPercent | percent:'1.2-2'}}
            </td>
            <td>
                {{mostRecentYearHistory.tripleBogeys | number:'1.0-0'}} -
                {{mostRecentYearHistory.tripleBogeyPercent | percent:'1.2-2'}}
            </td>
        </tr>
        <tr>
            <th scope="row" class="left">Quadruple Bogeys or Worse</th>
            <td>
                {{allTimeHistory.quadrupleBogeysOrWorse | number:'1.0-0'}} -
                {{allTimeHistory.quadrupleBogeyOrWorsePercent | percent:'1.2-2'}}
            </td>
            <td>
                {{mostRecentYearHistory.quadrupleBogeysOrWorse | number:'1.0-0'}} -
                {{mostRecentYearHistory.quadrupleBogeyOrWorsePercent | percent:'1.2-2'}}
            </td>
        </tr>
        <tr>
            <th scope="row" class="left">Birdie or Better</th>
            <td>
                {{allTimeHistory.birdieOrBetterPercent | percent:'1.2-2'}}
            </td>
            <td>
                {{mostRecentYearHistory.birdieOrBetterPercent | percent:'1.2-2'}}
            </td>
        </tr>
        <tr>
            <th scope="row" class="left">Par or Better</th>
            <td>
                {{allTimeHistory.parOrBetterPercent | percent:'1.2-2'}}
            </td>
            <td>
                {{mostRecentYearHistory.parOrBetterPercent | percent:'1.2-2'}}
            </td>
        </tr>
        <tr>
            <th scope="row" class="left">Bogey or Better</th>
            <td>
                {{allTimeHistory.bogeyOrBetterPercent | percent:'1.2-2'}}
            </td>
            <td>
                {{mostRecentYearHistory.bogeyOrBetterPercent | percent:'1.2-2'}}
            </td>
        </tr>
        <tr>
            <th scope="row" class="left">Double Bogey<br />or Better</th>
            <td>
                {{allTimeHistory.doubleBogeyOrBetterPercent | percent:'1.2-2'}}
            </td>
            <td>
                {{mostRecentYearHistory.doubleBogeyOrBetterPercent | percent:'1.2-2'}}
            </td>
        </tr>
        <tr>
            <th scope="row" class="left">Fairway %</th>
            <td>{{allTimeHistory.fairwayPercent | percent}}</td>
            <td>{{mostRecentYearHistory.fairwayPercent | percent}}</td>
        </tr>
        <tr>
            <th scope="row" class="left">GIR %</th>
            <td>{{allTimeHistory.girPercent | percent}}</td>
            <td>{{mostRecentYearHistory.girPercent | percent}}</td>
        </tr>
        <tr>
            <th scope="row" class="left">Putting/Hole</th>
            <td>{{allTimeHistory.averagePutts | number:'1.3-3'}}</td>
            <td>{{mostRecentYearHistory.averagePutts | number:'1.3-3'}}</td>
        </tr>
        <tr>
            <th scope="row" class="left">Putts/GIR</th>
            <td>{{allTimeHistory.puttsPerGIR | number:'1.3-3'}}</td>
            <td>{{mostRecentYearHistory.puttsPerGIR | number:'1.3-3'}}</td>
        </tr>
        <tr>
            <th scope="row" class="left">1 Putt %</th>
            <td>{{allTimeHistory.onePuttPercent | percent}}</td>
            <td>{{mostRecentYearHistory.onePuttPercent | percent}}</td>
        </tr>
        <tr>
            <th scope="row" class="left">2 Putt %</th>
            <td>{{allTimeHistory.twoPuttPercent | percent}}</td>
            <td>{{mostRecentYearHistory.twoPuttPercent | percent}}</td>
        </tr>
        <tr>
            <th scope="row" class="left">3 Putt %</th>
            <td>{{allTimeHistory.threePuttPercent | percent}}</td>
            <td>{{mostRecentYearHistory.threePuttPercent | percent}}</td>
        </tr>
        <tr>
            <th scope="row" class="left">Tee Shot Penalties</th>
            <td>
                {{allTimeHistory.teeShotPenaltyStrokes | number:'1.0-0'}}
                <span *ngIf="allTimeHistory.teeShotPenaltyStrokePercent !== undefined">-</span>
                {{allTimeHistory.teeShotPenaltyStrokePercent | percent:'1.2-2'}}
            </td>
            <td>
                {{mostRecentYearHistory.teeShotPenaltyStrokes | number:'1.0-0'}}
                <span *ngIf="mostRecentYearHistory.teeShotPenaltyStrokePercent !== undefined">-</span>
                {{mostRecentYearHistory.teeShotPenaltyStrokePercent | percent:'1.2-2'}}
            </td>
        </tr>
        <tr>
            <th scope="row" class="left">Approach Shot Penalties</th>
            <td>
                {{allTimeHistory.approachShotPenaltyStrokes | number:'1.0-0'}}
                <span *ngIf="allTimeHistory.approachShotPenaltyStrokePercent !== undefined">-</span>
                {{allTimeHistory.approachShotPenaltyStrokePercent | percent:'1.2-2'}}
            </td>
            <td>
                {{mostRecentYearHistory.approachShotPenaltyStrokes | number:'1.0-0'}}
                <span *ngIf="mostRecentYearHistory.approachShotPenaltyStrokePercent !== undefined">-</span>
                {{mostRecentYearHistory.approachShotPenaltyStrokePercent | percent:'1.2-2'}}
            </td>
        </tr>
        <tr>
            <th scope="row" class="left">Penalties</th>
            <td>
                {{allTimeHistory.penaltyStrokes | number:'1.0-0'}}
                <span *ngIf="allTimeHistory.penaltyStrokePercent !== undefined">-</span>
                {{allTimeHistory.penaltyStrokePercent | percent:'1.2-2'}}
            </td>
            <td>
                {{mostRecentYearHistory.penaltyStrokes | number:'1.0-0'}}
                <span *ngIf="mostRecentYearHistory.penaltyStrokePercent !== undefined">-</span>
                {{mostRecentYearHistory.penaltyStrokePercent | percent:'1.2-2'}}
            </td>
        </tr>
    </tbody>
</table>
