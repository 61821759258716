<div class="card">
    <h1 *ngIf="course">Course Details</h1>
    <p-tabView *ngIf="course" [activeIndex]="activeTabIndex" (onChange)="onActiveTabChanged($event)">
        <p-tabPanel header="Details" leftIcon="fas fa-shapes">
            <my-course-detail [course]="course" [messages]="messages"></my-course-detail>
        </p-tabPanel>
        <p-tabPanel header="Scorecard" leftIcon="fas fa-th" cache="false">
            <ng-template pTemplate="content">
                <my-course-scorecard [course]="course" [messages]="messages"></my-course-scorecard>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
    <div class="grid">
        <div class="col-12">
            <my-messages></my-messages>
        </div>
    </div>
</div>
