import { Component } from '@angular/core';
import { TooltipItem } from 'chart.js';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';
import { formatHandicap } from '../shared/ui';

@Component({
    selector: 'my-strokes-gained-handicap-bar-chart',
    template: '<p-chart type="bar" [data]="data" [options]="options" responsive="true"></p-chart>'
})
export class StrokesGainedHandicapBarChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'bar');
        this.options.scales.y.ticks.callback = this.getTick.bind(this);
        this.options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);
    }

    private getTick(value: any) {
        return formatHandicap(value);
    }

    private getTooltipLabel(tooltipItem: TooltipItem<'bar'>) {
        const dataSet = tooltipItem.dataset;
        const data = dataSet.data as number[];

        return formatHandicap(data[tooltipItem.dataIndex]);
    }
}
