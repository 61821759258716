// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreRoundsApproachShotMisses = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export const ModelsCoreRoundsApproachShotMisses = {
    Left: 1 as ModelsCoreRoundsApproachShotMisses,
    LongAndLeft: 2 as ModelsCoreRoundsApproachShotMisses,
    Long: 3 as ModelsCoreRoundsApproachShotMisses,
    LongAndRight: 4 as ModelsCoreRoundsApproachShotMisses,
    Right: 5 as ModelsCoreRoundsApproachShotMisses,
    ShortAndRight: 6 as ModelsCoreRoundsApproachShotMisses,
    Short: 7 as ModelsCoreRoundsApproachShotMisses,
    ShortAndLeft: 8 as ModelsCoreRoundsApproachShotMisses
};
