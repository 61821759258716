<div class="card">
    <h1>Golf Round</h1>
    <p-tabView *ngIf="round" [activeIndex]="activeTabIndex" (onChange)="onActiveTabChanged($event)">
        <p-tabPanel header="Details" leftIcon="fas fa-shapes">
            <my-round-detail [round]="round" [messages]="messages"></my-round-detail>
        </p-tabPanel>
        <p-tabPanel [headerStyle]="getSecondaryTabHeaderStyle()" header="Scorecard" leftIcon="fas fa-th" cache="false">
            <ng-template pTemplate="content">
                <my-scorecard [round]="round" [messages]="messages"></my-scorecard>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel [headerStyle]="getSecondaryTabHeaderStyle()" header="Statistics" leftIcon="fas fa-list-ol" cache="false">
            <ng-template pTemplate="content">
                <my-statistics-list [golfRoundId]="round.golfRoundId"></my-statistics-list>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel [headerStyle]="getSecondaryTabHeaderStyle()" header="Achievements" leftIcon="fas fa-trophy" cache="false">
            <ng-template pTemplate="content">
                <my-achievements-list [golfRoundId]="round.golfRoundId"></my-achievements-list>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel [headerStyle]="getSecondaryTabHeaderStyle()" header="Charts" leftIcon="fas fa-chart-bar" cache="false">
            <ng-template pTemplate="content">
                <my-statistic-charts [golfRoundId]="round.golfRoundId"></my-statistic-charts>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
    <div class="grid">
        <div class="col-12">
            <my-messages></my-messages>
        </div>
    </div>
</div>
