// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCorePracticePracticeCategories = 1 | 2 | 3 | 4;

export const ModelsCorePracticePracticeCategories = {
    Putting: 1 as ModelsCorePracticePracticeCategories,
    FinesseWedges: 2 as ModelsCorePracticePracticeCategories,
    FullSwing: 3 as ModelsCorePracticePracticeCategories,
    ApproachWedges: 4 as ModelsCorePracticePracticeCategories
};
