<div class="card">
    <h1><my-course-name></my-course-name></h1>
    <div class="grid form-group">
        <div class="field col-12 lg:col-2">
            <span class="p-float-label">
                <p-dropdown [options]="golfers" [(ngModel)]="golferId" styleClass="golfer-selector" (onChange)="onGolferChanged()"></p-dropdown>
                <label>Golfer</label>
            </span>
        </div>
        <div class="field col-12 lg:col-1" *ngIf="year > 0">
            <span class="p-float-label">
                <p-dropdown [options]="yearsPlayed" [(ngModel)]="year" styleClass="width2" (onChange)="onYearChanged()"></p-dropdown>
                <label>Year</label>
            </span>
        </div>
        <div class="field col-12 lg:col-2">
            <span class="p-float-label">
                <p-dropdown [options]="holes" [(ngModel)]="holeId" styleClass="width8" (onChange)="onHoleChanged()"></p-dropdown>
                <label>Hole</label>
            </span>
        </div>
        <div>
            <p-table [value]="holeHistory" [loading]="isLoading" styleClass="scorecard p-datatable-striped with-bottom-margin" tableStyleClass="p-datatable-responsive-md">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="left">Date</th>
                        <th class="left">Tee</th>
                        <th>Score</th>
                        <th>Fairway</th>
                        <th>Fairway<br />Miss</th>
                        <th>Club Used<br />on Tee</th>
                        <th>Approach<br />Shot</th>
                        <th>Approach<br />Shot Miss</th>
                        <th>Club Used<br />on Approach</th>
                        <th>Approach<br />Shot Distance</th>
                        <th>Green Hit<br />from Distance</th>
                        <th>Putts</th>
                        <th>First Putt<br />Distance</th>
                        <th>Last Putt<br />Distance</th>
                        <th>Penalties</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-golfRoundGolfer let-golfRound="$implicit.golfRound" let-golfRoundGolferHole="$implicit.golfRoundGolferHoles[0]" let-golfRoundGolferHoleMetric="$implicit.golfRoundGolferHoles[0].golfRoundGolferHoleMetric">
                    <tr>
                        <td class="left">
                            <span class="p-column-title">Date</span>
                            {{golfRound.teeTime | date:'shortDate'}}
                        </td>
                        <td class="left">
                            <span class="p-column-title">Tee</span>
                            {{golfRoundGolfer.tee?.name}} -
                            {{golfRoundGolferHole.yards}}
                        </td>
                        <td class="center" [ngClass]="getScoreCssClass(golfRoundGolferHole)">
                            <span class="p-column-title">Score</span>
                            {{golfRoundGolferHole.formattedScore}}
                        </td>
                        <td class="center" [ngClass]="getMetricCssClass(golfRoundGolferHoleMetric.fairwayResult?.fairwayResultId, 'fairwayResultId')">
                            <span class="p-column-title">Fairway</span>
                            {{golfRoundGolferHoleMetric.fairwayResult?.name}}
                        </td>
                        <td class="center">
                            <span class="p-column-title">Fairway Miss</span>
                            {{golfRoundGolferHoleMetric.fairwayMiss?.name}}
                        </td>
                        <td class="center">
                            <span class="p-column-title">Club Used on Tee</span>
                            {{golfRoundGolferHoleMetric.teeShotClub?.abbreviation}}
                        </td>
                        <td class="center" [ngClass]="getMetricCssClass(golfRoundGolferHoleMetric.approachShotResult?.approachShotResultId, 'approachShotResultId')">
                            <span class="p-column-title">Approach Shot</span>
                            {{golfRoundGolferHoleMetric.approachShotResult?.name}}
                        </td>
                        <td class="center">
                            <span class="p-column-title">Approach Shot Miss</span>
                            {{golfRoundGolferHoleMetric.approachShotMiss?.name}}
                        </td>
                        <td class="center">
                            <span class="p-column-title">Club Used on Approach</span>
                            {{golfRoundGolferHoleMetric.approachShotClub?.abbreviation}}
                        </td>
                        <td class="center">
                            <span class="p-column-title">Approach Shot Distance</span>
                            {{golfRoundGolferHoleMetric.approachShotDistance}}
                        </td>
                        <td class="center">
                            <span class="p-column-title">Green Hit from Distance</span>
                            {{golfRoundGolferHoleMetric.greenHitFromDistance}}
                        </td>
                        <td class="center" [ngClass]="getMetricCssClass(golfRoundGolferHoleMetric.putts, 'putts')">
                            <span class="p-column-title">Putts</span>
                            {{golfRoundGolferHoleMetric.putts}}
                        </td>
                        <td class="center">
                            <span class="p-column-title">First Putt Distance</span>
                            {{golfRoundGolferHoleMetric.firstPuttDistance}}
                        </td>
                        <td class="center">
                            <span class="p-column-title">Last Putt Distance</span>
                            {{golfRoundGolferHoleMetric.lastPuttDistance}}
                        </td>
                        <td class="center" [ngClass]="getMetricCssClass(golfRoundGolferHoleMetric.penaltyStrokes, 'penaltyStrokes')">
                            <span class="p-column-title">Penalties</span>
                            {{golfRoundGolferHoleMetric.penaltyStrokes}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="15">
                            {{getEmptyMessage('No history playing this hole.')}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
