import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { PracticeControllerProxy } from '../../../shared/server-proxies';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { MessagesComponent } from '../../../shared/ui/messages/messages.component';
import { CurrentGolferService } from '../../../shared/golfers/current-golfer.service';
import { GolferLookupService } from '../../../shared/golfers/golfer-lookup.service';
import { PracticeTabViewIndexes } from '../../practice-tab-view-indexes';

import {
    ModelsCorePracticePracticeActivityVariation,
    ModelsCorePracticePracticeScoreQualifiers,
    ModelsCorePracticePracticeSession,
    ModelsCorePracticePracticeSessionActivity,
    ModelsWebApiPracticeSavePracticeSessionActivityModel,
    ModelsWebApiPracticeSavePracticeSessionModel
} from '../../../shared/swagger-codegen/models';

export interface ISelectItemWithPracticeActivityVariation extends SelectItem {
    variation: ModelsCorePracticePracticeActivityVariation;
}

export interface IPracticeSessionActivityWithActivityVariationSelectItems extends ModelsCorePracticePracticeSessionActivity {
    practiceActivityVariationSelectItems: ISelectItemWithPracticeActivityVariation[];
}

@Component({
    selector: 'my-practice-session-detail',
    templateUrl: './practice-session-detail.component.html'
})
export class PracticeSessionDetailComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private practiceControllerProxy: PracticeControllerProxy,
        private confirmationService: ConfirmationService,
        private currentGolfer: CurrentGolferService,
        private golferLookups: GolferLookupService,
        private datePipe: DatePipe,
        private router: Router,
        private currentRoute: ActivatedRoute) {
        super();
    }

    @ViewChild(MessagesComponent) messages: MessagesComponent;
    isStarting: boolean;
    practiceSession: ModelsCorePracticePracticeSession;
    title = 'Practice Session';
    private golferId: number;

    ngOnInit() {
        this.golferId = this.currentGolfer.golferId;

        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    const practiceSessionId = parseInt(params['practiceSessionId'], 10) || 0;
                    this.isStarting = params['starting'] === 'true';

                    if(this.isStarting) {
                        this.title = 'Configure New Practice Session';
                    }

                    if(this.shouldLoadPracticeSession(practiceSessionId)) {
                        this.loadPracticeSession(practiceSessionId);
                    }
                });
    }

    deleteSessionActivity(practiceSessionActivity: ModelsCorePracticePracticeSessionActivity, index: number) {
        const message = `Are you sure you want to remove ${practiceSessionActivity.practiceActivity.name} from this session?`;

        this.confirmationService.confirm(
            {
                key: 'practice-session-detail-component',
                header: 'Remove Practice Activity from Session?',
                message: message,
                accept: () => {
                    if(practiceSessionActivity.practiceSessionActivityId > 0) {
                        this.taskStarted();
                        this.practiceControllerProxy.deletePracticeSessionActivity(practiceSessionActivity.practiceSessionActivityId)
                            .pipe(
                                finalize(() => this.taskCompleted()),
                                this.takeUntilUnsubscribed())
                            .subscribe(
                                () => {
                                    this.practiceSession.practiceSessionActivities.splice(index, 1);
                                },
                                response => this.messages.showApiError(response));
                    }
                    else {
                        this.practiceSession.practiceSessionActivities.splice(index, 1);
                    }
                }
            });
    }

    save() {
        this.savePracticeSession()
            .subscribe(
                () => {
                    this.router.navigate(['/practice', { tab: PracticeTabViewIndexes.sessionsTabIndex }]);
                },
                response => this.messages.showApiError(response));
    }

    startSession() {
        this.savePracticeSession()
            .subscribe(
                () => {
                    this.golferLookups.resetYearsPracticed();
                    this.router.navigate(['/practice/sessions', this.practiceSession.practiceSessionId, 'enter']);
                },
                response => this.messages.showApiError(response));
    }

    shouldShowScore(practiceSessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return practiceSessionActivity.practiceActivity.practiceScoreQualifierId
            !== ModelsCorePracticePracticeScoreQualifiers.None;
    }

    sessionActivityIsInProgress(practiceSessionActivity: ModelsCorePracticePracticeSessionActivity) {
        return practiceSessionActivity.started && !practiceSessionActivity.completed;
    }

    getSessionActivityInProgressTooltip(practiceSessionActivity: ModelsCorePracticePracticeSessionActivity) {
        const dateString = this.datePipe.transform(practiceSessionActivity.started, 'shortDate');
        const timeString = this.datePipe.transform(practiceSessionActivity.started, 'shortTime');
        return `Activity started ${dateString} at ${timeString}`;
    }

    getAverageDuration(practiceSessionActivity: ModelsCorePracticePracticeSessionActivity) {
        let duration = '';

        if(practiceSessionActivity.practiceActivityVariation
            && practiceSessionActivity.practiceActivityVariation.averageElapsedMinutes > 0) {
            duration = practiceSessionActivity.practiceActivityVariation.averageElapsedDuration;
        }
        else if(practiceSessionActivity.practiceActivity.averageElapsedMinutes > 0) {
            duration = practiceSessionActivity.practiceActivity.averageElapsedDuration;
        }

        return duration;
    }

    onActivityVariationChanged(sessionActivity: IPracticeSessionActivityWithActivityVariationSelectItems) {
        const variationId = sessionActivity.practiceActivityVariation.practiceActivityVariationId;
        sessionActivity.practiceActivityVariation = sessionActivity.practiceActivityVariationSelectItems
            .find(i => i.value === variationId).variation;
    }

    private shouldLoadPracticeSession(practiceSessionId: number) {
        return practiceSessionId > 0
            && (!this.practiceSession || this.practiceSession.practiceSessionId !== practiceSessionId);
    }

    private loadPracticeSession(practiceSessionId: number) {
        this.taskStarted();
        this.practiceControllerProxy.getPracticeSession(practiceSessionId)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.mapActivityVariationsForSession(response.body);
                    this.practiceSession = response.body;
                });
    }

    private mapActivityVariationsForSession(practiceSession: ModelsCorePracticePracticeSession) {
        practiceSession.practiceSessionActivities.forEach(
            a => this.mapActivityVariations(a as IPracticeSessionActivityWithActivityVariationSelectItems)
        );
    }

    private mapActivityVariations(sessionActivity: IPracticeSessionActivityWithActivityVariationSelectItems) {
        const variation = sessionActivity.practiceActivity.practiceActivityVariations;

        if(variation && variation.length > 0) {
            sessionActivity.practiceActivityVariationSelectItems = variation.map(
                v => {
                    return {
                        value: v.practiceActivityVariationId,
                        label: v.name,
                        variation: v
                    };
                });

            if(!sessionActivity.practiceActivityVariation) {
                sessionActivity.practiceActivityVariation = {} as ModelsCorePracticePracticeActivityVariation;
            }
        }
    }

    private savePracticeSession() {
        this.taskStarted();

        const updateModel = this.convertPracticePlanToUpdateModel();

        return this.practiceControllerProxy.savePracticeSession(this.practiceSession.practiceSessionId, updateModel)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed());
    }

    private convertPracticePlanToUpdateModel() {
        const sessionModel = {
            golferId: this.golferId,
            name: this.practiceSession.name,
            notes: this.practiceSession.notes,
            practiceSessionActivities: []
        } as ModelsWebApiPracticeSavePracticeSessionModel;

        this.practiceSession.practiceSessionActivities.forEach((activity: IPracticeSessionActivityWithActivityVariationSelectItems) => {
            const activityModel = {
                practiceSessionActivityId: activity.practiceSessionActivityId,
                practiceActivityId: activity.practiceActivity.practiceActivityId,
                practiceActivityVariationId: undefined,
                started: activity.started,
                completed: activity.completed,
                elapsedMinutes: activity.elapsedMinutes,
                score: activity.score,
                plannedMinutes: activity.plannedMinutes,
                isGoalAchieved: activity.isGoalAchieved
            } as ModelsWebApiPracticeSavePracticeSessionActivityModel;

            if(activity.practiceActivityVariation) {
                activityModel.practiceActivityVariationId = activity.practiceActivityVariation.practiceActivityVariationId;
            }

            sessionModel.practiceSessionActivities.push(activityModel);
        });

        return sessionModel;
    }
}
