import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { MessagesComponent, ScrollService } from '../../../shared/ui';
import { ConfigurationService } from '../../../shared/core/configuration.service';
import { CurrentGolferService } from '../../../shared/golfers/current-golfer.service';
import { GolfersControllerProxy, RoundsControllerProxy } from '../../../shared/server-proxies';
import { ScorecardBaseDirective } from '../../../shared/rounds/scorecard-base.directive';
import { StorageService } from '../../../shared/core/storage.service';
import { SignalRHubConnectionFactory } from '../../../shared/core/signalr-hub-connection-factory.service';
import { tap, finalize } from 'rxjs/operators';

import {
    ModelsCoreRoundsApproachShotMisses,
    ModelsCoreRoundsApproachShotResults,
    ModelsCoreRoundsFairwayMisses,
    ModelsCoreRoundsFairwayResults,
    ModelsCoreRoundsShortGameResults,
    ModelsCoreRoundsUpAndDownResults,
    ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
    ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole
} from '../../../shared/swagger-codegen/models';

interface IScorecardOptions {
    golfRoundId: number;
    trackedScorecards: { [golfRoundGolferId: number]: boolean };
}

@Component({
    selector: 'my-scorecard-hole',
    templateUrl: './scorecard-hole.component.html',
    styleUrls: [
        './scorecard-hole.component.scss'
    ]
})
export class ScorecardHoleComponent extends ScorecardBaseDirective implements OnInit {
    constructor(
        roundsProxy: RoundsControllerProxy,
        golfersProxy: GolfersControllerProxy,
        currentGolfer: CurrentGolferService,
        hubConnectionFactory: SignalRHubConnectionFactory,
        private configuration: ConfigurationService,
        private scrollService: ScrollService,
        private router: Router,
        private storage: StorageService) {

        super(roundsProxy, golfersProxy, currentGolfer, hubConnectionFactory);
        this.includeGolferHoleInfo = true;
    }

    @ViewChild('scorecardMessages') scorecardMessages: MessagesComponent;
    @Output() holeInitialized = new EventEmitter<ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole>();
    holes: SelectItem[] = [];
    selectedGolfRoundGolferId: number;
    selectedGolfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer;
    selectedHoleKey: string;
    selectedHole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole;
    strokeItems: SelectItem[] = [];
    secondaryStrokeItems: SelectItem[];
    basicFairwayItems: SelectItem[] = [
        { value: ModelsCoreRoundsFairwayResults.Hit, label: 'Yes' },
        { value: ModelsCoreRoundsFairwayResults.OkMiss, label: 'No' }
    ];
    basicGIRItems: SelectItem[] = [
        { value: ModelsCoreRoundsApproachShotResults.GIR, label: 'Yes' },
        { value: ModelsCoreRoundsApproachShotResults.OkMiss, label: 'No' }
    ];
    puttItems: SelectItem[];
    secondaryPuttItems: SelectItem[];
    penaltyItems: SelectItem[];
    mulliganItems: SelectItem[];
    firstPuttDistanceItems: SelectItem[];
    lastPuttDistanceItems: SelectItem[];
    secondaryPuttingDistanceItems: SelectItem[];
    strokeRangeItems: SelectItem[];
    secondaryStrokeRangeItems: SelectItem[];
    showConfigOptions = false;
    private selectedGolferCssClasses: { [key: string]: string };
    private navigationIndex = 0;
    private scorecardOptionsKey = 'scorecard-options';

    ngOnInit() {
        this.loadLookupSelectItemLists();
    }

    get dataIsLoaded() {
        return this.navigationSequence.length > 0 && this.selectedHole !== undefined;
    }

    get hasMultipleGolfers() {
        return this.golfers.length > 1;
    }

    get isPhone() {
        return window.innerWidth <= 640;
    }

    loadSingleHoleScorecard(golfRoundId: number) {
        return this.loadScorecard(golfRoundId)
            .pipe(tap(() => {
                this.loadScorecardOptions();
            }));
    }

    saveScorecard() {
        const models = this.getModifiedScorecardData();

        if(models.length > 0) {
            this.saveScorecardModels(models)
                .pipe(
                    finalize(
                        () => {
                            this.scorecardMessages.clearInMilliseconds(5000);
                    }),
                    this.takeUntilUnsubscribed())
                .subscribe(
                    response => {
                        response.body.processedGolfers.forEach(
                            processedGolfer => {
                                const entry = this.golferDictionary[processedGolfer.golfRoundGolferId];

                                if(entry) {
                                    entry.golfer.minutesPerHole = processedGolfer.minutesPerHole;
                                    entry.golfer.remainingMinutes = processedGolfer.remainingMinutes;

                                    if(processedGolfer.projectedEndTime) {
                                        entry.golfer.projectedEndTime = new Date(processedGolfer.projectedEndTime);
                                    }
                                }
                            });

                        this.scrollService.scrollToTop();
                        this.navigateToNextIndex();
                    },
                    error => {
                        this.scorecardMessages.showError(`An error occurred. (${error.status})`);
                    });
        }
        else {
            this.navigateToNextIndex();
        }
    }

    onGolferChanged() {
        const previousGolferHoleIndex = this.holes.findIndex(h => h.value === this.selectedHoleKey) || 0;
        const newGolferHoles = this.getHolesByGolfRoundGolferId(this.selectedGolfRoundGolferId);
        let holeKey: string;

        if(previousGolferHoleIndex >= newGolferHoles.length) {
            holeKey = newGolferHoles[this.holes.length - 1].value;
        }
        else {
            holeKey = newGolferHoles[previousGolferHoleIndex].value;
        }

        this.navigateToHoleKey(holeKey);
    }

    onHoleChanged() {
        this.navigateToHoleKey(this.selectedHoleKey);
    }

    getHeaderRowCssClass() {
        return {
            clickable: this.configuration.isDebug
        };
    }

    getBasicFairwayCssClass(item: SelectItem) {
        let css: string;

        switch((item.value as any)) {
            case ModelsCoreRoundsFairwayResults.Hit:
                css = 'far fa-check-circle positive-adornment';
                break;
            case ModelsCoreRoundsFairwayResults.OkMiss:
                css = 'far fa-times-circle negative-adornment';
                break;
        }

        return css;
    }

    getFairwayResultCssClass(item: SelectItem) {
        let css: string;

        switch((item.value as any)) {
            case ModelsCoreRoundsFairwayResults.Hit:
                css = 'far fa-check-circle positive-adornment';
                break;
            case ModelsCoreRoundsFairwayResults.OkMiss:
                css = 'far fa-times-circle';
                break;
            case ModelsCoreRoundsFairwayResults.BadMiss:
                css = 'far fa-times-circle negative-adornment';
                break;
            case ModelsCoreRoundsFairwayResults.Penalty:
                css = 'far fa-exclamation-triangle negative-adornment';
                break;
        }

        return css;
    }

    getFairwayMissCssClass(item: SelectItem) {
        let css: string;

        switch((item.value as any)) {
            case ModelsCoreRoundsFairwayMisses.Left:
                css = 'fas fa-arrow-alt-circle-left';
                break;
            case ModelsCoreRoundsFairwayMisses.Right:
                css = 'fas fa-arrow-alt-circle-right';
                break;
            case ModelsCoreRoundsFairwayMisses.Long:
                css = 'fas fa-arrow-alt-circle-up';
                break;
            case ModelsCoreRoundsFairwayMisses.Short:
                css = 'fas fa-arrow-alt-circle-down';
                break;
        }

        return css;
    }

    getBasicGIRCssClass(item: SelectItem) {
        let css: string;

        switch((item.value as any)) {
            case ModelsCoreRoundsApproachShotResults.Hit:
                css = 'far fa-check-circle positive-adornment';
                break;
            case ModelsCoreRoundsApproachShotResults.OkMiss:
                css = 'far fa-times-circle negative-adornment';
                break;
        }

        return css;
    }

    getApproachShotResultCssClass(item: SelectItem) {
        let css: string;

        switch((item.value as any)) {
            case ModelsCoreRoundsApproachShotResults.GIR:
                css = 'far fa-check-circle positive-adornment';
                break;
            case ModelsCoreRoundsApproachShotResults.Hit:
                css = 'far fa-check-circle';
                break;
            case ModelsCoreRoundsApproachShotResults.OkMiss:
                css = 'far fa-times-circle';
                break;
            case ModelsCoreRoundsApproachShotResults.BadMiss:
                css = 'far fa-times-circle negative-adornment';
                break;
            case ModelsCoreRoundsApproachShotResults.Penalty:
                css = 'far fa-exclamation-triangle negative-adornment';
                break;
            case ModelsCoreRoundsApproachShotResults.NoAttempt:
                css = 'fas fa-ban';
                break;
        }

        return css;
    }

    getApproachShotMissCssClass(item: SelectItem) {
        let css: string;

        switch((item.value as any)) {
            case ModelsCoreRoundsApproachShotMisses.Left:
                css = 'fas fa-arrow-alt-circle-left';
                break;
            case ModelsCoreRoundsApproachShotMisses.LongAndLeft:
                css = 'fas fa-arrow-alt-circle-left fa-rotate-45';
                break;
            case ModelsCoreRoundsApproachShotMisses.Long:
                css = 'fas fa-arrow-alt-circle-up';
                break;
            case ModelsCoreRoundsApproachShotMisses.LongAndRight:
                css = 'fas fa-arrow-alt-circle-up fa-rotate-45';
                break;
            case ModelsCoreRoundsApproachShotMisses.Right:
                css = 'fas fa-arrow-alt-circle-right';
                break;
            case ModelsCoreRoundsApproachShotMisses.ShortAndRight:
                css = 'fas fa-arrow-alt-circle-right fa-rotate-45';
                break;
            case ModelsCoreRoundsApproachShotMisses.Short:
                css = 'fas fa-arrow-alt-circle-down';
                break;
            case ModelsCoreRoundsApproachShotMisses.ShortAndLeft:
                css = 'fas fa-arrow-alt-circle-down fa-rotate-45';
                break;
        }

        return css;
    }

    getShortGameResultCssClass(item: SelectItem) {
        let css: string;

        switch((item.value as any)) {
            case ModelsCoreRoundsShortGameResults.FinishedOnGreen:
                css = 'far fa-check-circle positive-adornment';
                break;
            case ModelsCoreRoundsShortGameResults.MissedGreen:
                css = 'far fa-times-circle negative-adornment';
                break;
        }

        return css;
    }

    isUpAndDownResultNoAttempt(item: SelectItem) {
        return (item.value as any) === ModelsCoreRoundsUpAndDownResults.NoAttempt;
    }

    getUpAndDownResultCssClass(item: SelectItem) {
        let css: string;

        switch((item.value as any)) {
            case ModelsCoreRoundsUpAndDownResults.Yes:
                css = 'far fa-check-circle positive-adornment';
                break;
            case ModelsCoreRoundsUpAndDownResults.No:
                css = 'far fa-times-circle negative-adornment';
                break;
        }

        return css;
    }

    isShortGameResultNoAttempt(item: SelectItem) {
        return (item.value as any) === ModelsCoreRoundsShortGameResults.NoAttempt;
    }

    fillWithMockData(
        golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
        hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole,
        saveHole = false) {

        if(this.configuration.isDebug) {
            // We avoid under par scores to eliminate excessive alerts during testing.
            hole.strokes = this.getRandomInt(hole.par, hole.par + 2);
            hole.putts = this.getRandomInt(1, 3);
            hole.drivingDistance = hole.par > 3 ? this.getRandomInt(270, 340) : undefined;
            hole.firstPuttDistance = this.getRandomInt(11, 40);
            hole.lastPuttDistance = this.getRandomInt(1, 10);
            hole.penaltyStrokes = this.getRandomInt(0, 2);
            hole.mulligans = this.getRandomInt(0, 1);
            hole.fairwayResultId = this.getRandomValue([
                ModelsCoreRoundsFairwayResults.Hit,
                ModelsCoreRoundsFairwayResults.OkMiss,
                ModelsCoreRoundsFairwayResults.BadMiss,
                ModelsCoreRoundsFairwayResults.Penalty
            ]);
            hole.fairwayMissId = hole.fairwayResultId !== ModelsCoreRoundsFairwayResults.Hit
                ? this.getRandomValue([
                    ModelsCoreRoundsFairwayMisses.Left,
                    ModelsCoreRoundsFairwayMisses.Right,
                    ModelsCoreRoundsFairwayMisses.Long,
                    ModelsCoreRoundsFairwayMisses.Short
                ])
                : undefined;
            hole.teeShotClubId = this.getRandomValue(golfer.golfClubs).golfClubId;
            hole.approachShotResultId = this.getRandomValue([
                ModelsCoreRoundsApproachShotResults.GIR,
                ModelsCoreRoundsApproachShotResults.Hit,
                ModelsCoreRoundsApproachShotResults.OkMiss,
                ModelsCoreRoundsApproachShotResults.BadMiss,
                ModelsCoreRoundsApproachShotResults.Penalty,
                ModelsCoreRoundsApproachShotResults.NoAttempt
            ]);
            hole.approachShotMissId = hole.approachShotResultId !== ModelsCoreRoundsApproachShotResults.Hit
                ? this.getRandomValue([
                    ModelsCoreRoundsApproachShotMisses.Left,
                    ModelsCoreRoundsApproachShotMisses.LongAndLeft,
                    ModelsCoreRoundsApproachShotMisses.Long,
                    ModelsCoreRoundsApproachShotMisses.LongAndRight,
                    ModelsCoreRoundsApproachShotMisses.Right,
                    ModelsCoreRoundsApproachShotMisses.ShortAndRight,
                    ModelsCoreRoundsApproachShotMisses.Short,
                    ModelsCoreRoundsApproachShotMisses.ShortAndLeft
                ])
                : undefined;
            hole.chipResultId = this.getRandomValue([
                ModelsCoreRoundsShortGameResults.FinishedOnGreen,
                ModelsCoreRoundsShortGameResults.MissedGreen,
                ModelsCoreRoundsShortGameResults.NoAttempt
            ]);
            hole.pitchResultId = this.getRandomValue([
                ModelsCoreRoundsShortGameResults.FinishedOnGreen,
                ModelsCoreRoundsShortGameResults.MissedGreen,
                ModelsCoreRoundsShortGameResults.NoAttempt
            ]);
            hole.sandResultId = this.getRandomValue([
                ModelsCoreRoundsShortGameResults.FinishedOnGreen,
                ModelsCoreRoundsShortGameResults.MissedGreen,
                ModelsCoreRoundsShortGameResults.NoAttempt
            ]);
            hole.upAndDownResultFrom1To10Id = this.getRandomValue([
                ModelsCoreRoundsUpAndDownResults.Yes,
                ModelsCoreRoundsUpAndDownResults.No,
                ModelsCoreRoundsUpAndDownResults.NoAttempt
            ]);
            hole.upAndDownResultFrom11To30Id = this.getRandomValue([
                ModelsCoreRoundsUpAndDownResults.Yes,
                ModelsCoreRoundsUpAndDownResults.No,
                ModelsCoreRoundsUpAndDownResults.NoAttempt
            ]);
            hole.upAndDownResultFrom31To50Id = this.getRandomValue([
                ModelsCoreRoundsUpAndDownResults.Yes,
                ModelsCoreRoundsUpAndDownResults.No,
                ModelsCoreRoundsUpAndDownResults.NoAttempt
            ]);
            hole.approachShotClubId = this.getRandomValue(golfer.golfClubs).golfClubId;
            hole.approachShotDistance = this.getRandomInt(50, 175);
            hole.greenHitFromDistance = this.getRandomInt(1, hole.approachShotDistance);

            if(saveHole) {
                this.saveScorecard();
            }
        }
    }

    showBasicOrAdvancedFairways(
        golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
        hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        return this.showBasicFairways(golfer, hole) || this.showAdvancedFairways(golfer, hole);
    }

    showBasicOrAdvancedGIRs(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return this.trackingBasicGIRs(golfer) || this.trackingAdvancedGIRs(golfer);
    }

    getRowCss(name: string) {
        return this.selectedGolferCssClasses[name];
    }

    isCurrentGolfer(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.golferId === this.currentGolfer.golferId;
    }

    configureRound() {
        this.showConfigOptions = true;
    }

    saveRoundConfiguration() {
        this.saveScorecardOptions();
        this.showConfigOptions = false;
        this.resetListeningForScorecardUpdates();
    }

    private getRandomValue(values: any[]) {
        const index = this.getRandomInt(0, values.length - 1);
        return values[index];
    }

    private getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    private loadStrokeItemsForCurrentHole() {
        const birdie = this.selectedHole.par - 1;
        const maxStrokes = birdie + 6;
        const items = this.createNumericSelectItemList(birdie, maxStrokes);

        for(let i = 0; i < items.length; i++) {
            this.strokeItems[i] = items[i];
        }
    }

    private initializeHole() {
        const trackedMetrics = this.selectedGolfer.trackedMetrics;
        const hole = this.selectedHole;

        if(trackedMetrics.trackChips && hole.chipResultId == null) {
            hole.chipResultId = ModelsCoreRoundsShortGameResults.NoAttempt;
        }

        if(trackedMetrics.trackPitches && hole.pitchResultId == null) {
            hole.pitchResultId = ModelsCoreRoundsShortGameResults.NoAttempt;
        }

        if(trackedMetrics.trackSandShots && hole.sandResultId == null) {
            hole.sandResultId = ModelsCoreRoundsShortGameResults.NoAttempt;
        }

        if(trackedMetrics.trackUpAndDowns) {
            if(hole.upAndDownResultFrom1To10Id == null) {
                hole.upAndDownResultFrom1To10Id = ModelsCoreRoundsUpAndDownResults.NoAttempt;
            }

            if(hole.upAndDownResultFrom11To30Id == null) {
                hole.upAndDownResultFrom11To30Id = ModelsCoreRoundsUpAndDownResults.NoAttempt;
            }

            if(hole.upAndDownResultFrom31To50Id == null) {
                hole.upAndDownResultFrom31To50Id = ModelsCoreRoundsUpAndDownResults.NoAttempt;
            }
        }

        if(trackedMetrics.trackPenaltyStrokes && hole.penaltyStrokes == null) {
            hole.penaltyStrokes = 0;
        }

        if(trackedMetrics.trackMulligans && hole.mulligans == null) {
            hole.mulligans = 0;
        }

        this.holeInitialized.emit(hole);
    }

    private createGolferCssClasses() {
        const evaluations: any[] = [];
        const cssClasses: { [key: string]: string } = {};
        const cssClassNames = ['p-datatable-odd', 'p-datatable-even'];

        evaluations.push({ name: 'strokes', show: true });
        evaluations.push({ name: 'fairway', show: this.showBasicOrAdvancedFairways(this.selectedGolfer, this.selectedHole) });
        evaluations.push({ name: 'teeShotClub', show: this.trackingTeeShotClub(this.selectedGolfer) });
        evaluations.push({ name: 'drivingDistance', show: this.trackingDrivingDistance(this.selectedGolfer) });
        evaluations.push({ name: 'gir', show: this.showBasicOrAdvancedGIRs(this.selectedGolfer) });
        evaluations.push({ name: 'chip', show: this.trackingChips(this.selectedGolfer) });
        evaluations.push({ name: 'pitch', show: this.trackingPitches(this.selectedGolfer) });
        evaluations.push({ name: 'sand', show: this.trackingSandShots(this.selectedGolfer) });
        evaluations.push({ name: 'putts', show: this.trackingPutts(this.selectedGolfer) });
        evaluations.push({ name: 'firstPuttDistance', show: this.trackingFirstPuttDistance(this.selectedGolfer) });
        evaluations.push({ name: 'lastPuttDistance', show: this.trackingLastPuttDistance(this.selectedGolfer) });
        evaluations.push({ name: 'penaltyStrokes', show: this.trackingPenaltyStrokes(this.selectedGolfer) });
        evaluations.push({ name: 'mulligans', show: this.trackingMulligans(this.selectedGolfer) });
        evaluations.push({ name: 'approachShotClub', show: this.trackingApproachShotClub(this.selectedGolfer) });
        evaluations.push({ name: 'approachShotDistance', show: this.trackingApproachShotDistance(this.selectedGolfer) });
        evaluations.push({ name: 'greenHitFromDistance', show: this.trackingGreenHitFromDistance(this.selectedGolfer) });
        evaluations.push({ name: 'upAndDownFrom1To10', show: this.trackingUpAndDowns(this.selectedGolfer) });
        evaluations.push({ name: 'upAndDownFrom11To30', show: this.trackingUpAndDowns(this.selectedGolfer) });
        evaluations.push({ name: 'upAndDownFrom31To50', show: this.trackingUpAndDowns(this.selectedGolfer) });

        let classNameIndex = 0;

        for(let i = 0; i < evaluations.length; i++) {
            const css = evaluations[i];

            if(css.show) {
                cssClasses[css.name] = cssClassNames[classNameIndex];
                classNameIndex = (classNameIndex + 1) % cssClassNames.length;
            }
        }

        return cssClasses;
    }

    setNavigationIndex(index: number) {
        if(index < 0 || index >= this.navigationSequence.length) {
            index = 0;
        }

        this.navigationIndex = index;

        if(this.navigationIndex < this.navigationSequence.length) {
            const navItem = this.navigationSequence[this.navigationIndex];

            this.selectedGolfRoundGolferId = navItem.golfer.golfRoundGolferId;
            this.selectedGolfer = navItem.golfer;
            this.holes = this.getHolesByGolfRoundGolferId(this.selectedGolfRoundGolferId);
            this.selectedHoleKey = this.createHoleKey(navItem.golfer, navItem.hole);
            this.selectedHole = navItem.hole;
            this.selectedGolferCssClasses = this.createGolferCssClasses();
            this.loadStrokeItemsForCurrentHole();
            this.initializeHole();
        }
    }

    private navigateToNextIndex() {
        const nextIndex = this.getNextIndexOfGolferWithKeptScorecard(this.navigationIndex + 1);

        if(this.navigationSequence.length > nextIndex) {
            this.navigateToIndex(nextIndex);
        }
        else {
            this.navigateToComplete();
        }
    }

    private getNextIndexOfGolferWithKeptScorecard(startIndex: number) {
        let nextIndex = startIndex;

        while(nextIndex < this.navigationSequence.length && !this.navigationSequence[nextIndex].golfer.keepScorecard) {
            nextIndex++;
        }

        return nextIndex;
    }

    navigateToFirstUnscoredHole() {
        let i: number;

        for(i = 0; i < this.navigationSequence.length; i++) {
            const navItem = this.navigationSequence[i];
            const scored = navItem.hole.strokes > 0;

            if(!scored && navItem.golfer.keepScorecard) {
                this.navigateToIndex(i);
                break;
            }
        }

        if(i === this.navigationSequence.length) {
            this.navigateToComplete();
        }
    }

    private navigateToHoleKey(key: string) {
        const index = this.getNavigationIndexByHoleKey(key);
        this.navigateToIndex(index);
    }

    private navigateToIndex(index: number) {
        if(index >= 0) {
            this.router.navigate(['rounds', this.golfRoundId, 'scorecard', { index: index }]);
        }
    }

    private navigateToComplete() {
        this.router.navigate(['rounds', this.golfRoundId, { completing: true }]);
    }

    private getHolesByGolfRoundGolferId(golfRoundGolferId: number) {
        const golferWithHoles = this.golferDictionary[golfRoundGolferId];
        const holes: SelectItem[] = golferWithHoles ? golferWithHoles.holes : [];

        return holes;
    }

    private getNavigationIndexByHoleKey(key: string) {
        const item = this.navigationDictionary[key];
        const index: number = item ? item.navigationIndex : null;

        return index;
    }

    private loadLookupSelectItemLists() {
        this.loadScorecardLookups()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe();
        this.createSelectListItems();
    }

    private createSelectListItems() {
        this.secondaryStrokeItems = this.createNumericSelectItemList(1, 20);
        this.puttItems = this.createNumericSelectItemList(1, 3);
        this.secondaryPuttItems = this.createNumericSelectItemList(0, 7);
        this.penaltyItems = this.createNumericSelectItemList(0, 7);
        this.mulliganItems = this.createNumericSelectItemList(0, 7);
        this.firstPuttDistanceItems = this.createNumericSelectItemList(1, 15);
        this.lastPuttDistanceItems = this.createNumericSelectItemList(1, 11);
        this.secondaryPuttingDistanceItems = this.createNumericSelectItemList(0, 99);
        this.strokeRangeItems = this.createNumericSelectItemList(1, 7);
        this.secondaryStrokeRangeItems = this.createNumericSelectItemList(1, 12);
    }

    private createNumericSelectItemList(from: number, to: number) {
        const items: SelectItem[] = [];

        for(let i = from; i <= to; i++) {
            items.push(
                {
                    value: i,
                    label: i.toString()
                });
        }

        return items;
    }

    protected getGolfRoundGolferIdsToTrackForScorecardUpdates(): number[] {
        return this.getUntrackedGolfRoundGolferIds();
    }

    private resetListeningForScorecardUpdates() {
        const untrackedGolfRoundGolferIds = this.getUntrackedGolfRoundGolferIds();
        this.subscribeToScorecardUpdates(untrackedGolfRoundGolferIds);

        const trackedGolfRoundGolferIds = this.getTrackedGolfRoundGolferIds();
        this.unsubscribeFromScorecardUpdates(trackedGolfRoundGolferIds);
    }

    private getTrackedGolfRoundGolferIds() {
        return this.scorecard.golfers
            .filter(golfer => golfer.keepScorecard)
            .map(golfer => golfer.golfRoundGolferId);
    }

    private getUntrackedGolfRoundGolferIds() {
        return this.scorecard.golfers
            .filter(golfer => !golfer.keepScorecard)
            .map(golfer => golfer.golfRoundGolferId);
    }

    private saveScorecardOptions() {
        const options: IScorecardOptions = {
            golfRoundId: this.golfRoundId,
            trackedScorecards: {}
        };

        this.scorecard.golfers.forEach(golfer => {
            options.trackedScorecards[golfer.golfRoundGolferId] = golfer.keepScorecard;
        });

        this.storage.setSessionItem(this.scorecardOptionsKey, options);
    }

    private loadScorecardOptions() {
        const options = this.storage.getSessionItem(this.scorecardOptionsKey) as IScorecardOptions;

        if(options) {
            if(options.golfRoundId !== this.golfRoundId) {
                this.storage.deleteSessionItem(this.scorecardOptionsKey);
                return;
            }

            if(options.trackedScorecards) {
                this.scorecard.golfers.forEach(golfer => {
                    golfer.keepScorecard = options.trackedScorecards[golfer.golfRoundGolferId];
                });
            }
        }
    }
}
