import { Component, OnInit, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Observable, of as observableOf, forkJoin } from 'rxjs';
import { map, tap, finalize } from 'rxjs/operators';

import { GolfersControllerProxy, RoundsControllerProxy } from '../../../shared/server-proxies';
import { CurrentGolferService, GolferLookupService, IGolferSelectItem, IGolferLookupSelectItemLists } from '../../../shared/golfers';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';

import {
    ModelsCoreStatisticsAchievementCategories,
    ModelsCoreStatisticsAchievementComparisons,
    ModelsStatisticsGetAchievementsAchievement,
    ModelsStatisticsGetAchievementsAchievementValue,
    ModelsStatisticsGetAchievementsGetAchievementsResponse,
    ModelsWebApiGolfersGetAchievementsArgs
} from '../../../shared/swagger-codegen/models';

/* eslint-disable no-shadow */
export enum PseudoAchievementCategories {
    achieved = -1,
    notAchieved = -2
}

@Component({
    selector: 'my-achievements-list',
    templateUrl: './achievements-list.component.html',
    styleUrls: [
        './achievements-list.component.scss'
    ]
})
export class AchievementsListComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private roundsProxy: RoundsControllerProxy,
        private golferLookups: GolferLookupService,
        private currentGolfer: CurrentGolferService) {
        super();
    }

    golfers: IGolferSelectItem[];
    response: ModelsStatisticsGetAchievementsGetAchievementsResponse;
    golferId: number;
    args: ModelsWebApiGolfersGetAchievementsArgs = {};
    categories: SelectItem[] = [];
    selectedCategoryId: number;
    yearsPlayed: SelectItem[];
    achievements: ModelsStatisticsGetAchievementsAchievement[] = [];
    comparisons: SelectItem[] = [
        { label: 'Years', value: ModelsCoreStatisticsAchievementComparisons.Years },
        { label: 'Friends', value: ModelsCoreStatisticsAchievementComparisons.Friends }
    ];
    private favoriteAchievements: { [achievementId: number]: boolean } = {};

    get golfRoundId() {
        return this.args.golfRoundId;
    }

    @Input()
    set golfRoundId(value: number) {
        this.args.golfRoundId = value;
    }

    get isViewingGolfRoundAchievements() {
        return this.golfRoundId > 0;
    }

    get shouldShowGolfers() {
        return this.args.comparison === ModelsCoreStatisticsAchievementComparisons.Years;
    }

    get shouldShowYears() {
        return this.args.comparison === ModelsCoreStatisticsAchievementComparisons.Friends;
    }

    get shouldShowComparisons() {
        return this.args.comparison !== ModelsCoreStatisticsAchievementComparisons.GolfRound;
    }

    ngOnInit(): void {
        const favoritesObservable = this.loadFavoriteAchievements();
        let selectItemListObservable: Observable<IGolferLookupSelectItemLists>;

        if(this.isViewingGolfRoundAchievements) {
            selectItemListObservable = this.initializeGolfRoundAchievements();
        }
        else {
            selectItemListObservable = this.initializeAchievements();
        }

        forkJoin([favoritesObservable, selectItemListObservable])
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                results => {
                    this.golfers = results[1].friends;

                    if(!this.isViewingGolfRoundAchievements) {
                        this.args.year = this.golferLookups.defaultYearPlayed;
                    }

                    this.yearsPlayed = results[1].yearsPlayed;

                    this.golferId = this.golfers.length > 0 ? this.golfers[0].value : -1;
                    
                    this.loadAchievements();
                });
    }

    onCategoryChanged() {
        this.filterAchievementsByCategory();
    }

    onComparisonChanged() {
        this.loadAchievements();
    }

    onGolferChanged() {
        this.loadAchievements();
    }

    onYearChanged() {
        this.loadAchievements();
    }

    getHistoryRoute(achievement: ModelsStatisticsGetAchievementsAchievement, columnIndex: number) {
        const value = achievement.columnValues[columnIndex];
        const route: any[] = ['/statistics/achievements', achievement.achievementId, 'history'];
        const args: any = {};

        switch(this.args.comparison) {
            case ModelsCoreStatisticsAchievementComparisons.Friends:
                args.year = this.args.year;
                args.golferId = value.valueId;
                break;
            case ModelsCoreStatisticsAchievementComparisons.Years:
                args.year = value.valueId;
                args.golferId = this.golferId;
                break;
        }

        route.push(args);

        return route;
    }

    isFavoriteAchievement(achievement: ModelsStatisticsGetAchievementsAchievement) {
        return this.favoriteAchievements[achievement.achievementId] !== undefined;
    }

    addFavoriteAchievement(achievement: ModelsStatisticsGetAchievementsAchievement) {
        const golferId = this.currentGolfer.golferId;
        this.golfersProxy.addFavoriteAchievement(golferId, achievement.achievementId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(() => {
                this.favoriteAchievements[achievement.achievementId] = true;
            });
    }

    removeFavoriteAchievement(achievement: ModelsStatisticsGetAchievementsAchievement) {
        const golferId = this.currentGolfer.golferId;
        this.golfersProxy.removeFavoriteAchievement(golferId, achievement.achievementId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(() => {
                delete this.favoriteAchievements[achievement.achievementId];

                if(this.selectedCategoryId === ModelsCoreStatisticsAchievementCategories.Favorites) {
                    this.filterAchievementsByCategory();
                }
            });
    }

    shouldShowAchievementRowGroupHeader(rowIndex: number) {
        let show = rowIndex === 0;

        if(!show) {
            const previousRowIndex = rowIndex - 1;
            const previousCategory = previousRowIndex < this.achievements.length
                ? this.achievements[previousRowIndex].category.name
                : undefined;
            const currentCategory = rowIndex < this.achievements.length
                ? this.achievements[rowIndex].category.name
                : undefined;

            show = previousCategory !== currentCategory;
        }

        return show;
    }

    getRankingClass(achievement: ModelsStatisticsGetAchievementsAchievementValue) {
        let cssClass = '';

        switch(achievement.rank) {
            case 1:
                cssClass = 'first-place';
                break;
            case 2:
                cssClass = 'second-place';
                break;
            case 3:
                cssClass = 'third-place';
                break;
        }

        return cssClass;
    }

    shouldShowRanking(achievement: ModelsStatisticsGetAchievementsAchievementValue) {
        return achievement.rank >= 1 && achievement.rank <= 3;
    }

    private initializeGolfRoundAchievements() {
        this.args.comparison = ModelsCoreStatisticsAchievementComparisons.GolfRound;

        const golfersObservable = this.roundsProxy.getGolfRoundGolfers(this.golfRoundId)
            .pipe(
                map(
                    response => {
                        return response.body.map(
                            golfer => {
                                return {
                                    value: golfer.golferId,
                                    label: `${golfer.firstName} ${golfer.lastName}`,
                                    golfer: golfer
                                } as IGolferSelectItem;
                            });
                    }));
        const yearsObservable = observableOf([] as SelectItem[]);

        return forkJoin([golfersObservable, yearsObservable])
            .pipe(
                map(
                    results => {
                        return {
                            friends: results[0],
                            yearsPlayed: results[1]
                        } as IGolferLookupSelectItemLists;
                    }));
    }

    private initializeAchievements() {
        this.args.comparison = ModelsCoreStatisticsAchievementComparisons.Friends;
        return this.golferLookups.getFriendAndYearsPlayedSelectItems();
    }

    private loadFavoriteAchievements() {
        const golferId = this.currentGolfer.golferId;
        return this.golfersProxy.getFavoriteAchievements(golferId)
            .pipe(
                tap(
                    response => {
                        response.body.forEach(
                            achievement => {
                                this.favoriteAchievements[achievement.achievementId] = true;
                            });
                    }));
    }

    private loadAchievements() {
        this.taskStarted();
        this.golfersProxy.getAchievements(this.golferId, this.args)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.response = response.body;
                    this.createCategorySelectItems();

                    if(this.response.columnHeaders.length > 0) {
                        this.filterAchievementsByCategory();
                    }
                });
    }

    private createCategorySelectItems() {
        if(this.categories.length === 0) {
            this.categories = this.response.categories.map(
                (category) => {
                    return { value: category.achievementCategoryId, label: category.name };
                });

            this.categories.unshift(
                { value: PseudoAchievementCategories.achieved, label: 'Achieved' },
                { value: PseudoAchievementCategories.notAchieved, label: 'Not Achieved' }
            );
        }

        if(this.selectedCategoryId === undefined) {
            this.selectedCategoryId = ModelsCoreStatisticsAchievementCategories.Favorites;
        }
    }

    private filterAchievementsByCategory() {
        const selectedCategoryId = this.selectedCategoryId || ModelsCoreStatisticsAchievementCategories.Favorites;

        if(selectedCategoryId === ModelsCoreStatisticsAchievementCategories.Favorites) {
            this.achievements = this.response.achievements.filter(
                (achievement) => {
                    return this.isFavoriteAchievement(achievement);
                });
        }
        else if(selectedCategoryId === PseudoAchievementCategories.achieved
            || selectedCategoryId === PseudoAchievementCategories.notAchieved) {

            const hasAnyAchieved = selectedCategoryId === PseudoAchievementCategories.achieved;

            this.achievements = this.response.achievements.filter(
                (achievement) => {
                    return achievement.hasAnyAchieved === hasAnyAchieved;
                });
        }
        else {
            this.achievements = this.response.achievements.filter(
                (achievement) => {
                    return achievement.category.achievementCategoryId === selectedCategoryId;
                });
        }
    }
}
