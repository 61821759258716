<div class="grid dashboard">
    <div class="col-12 md:col-6 lg:col-3">
        <div class="overview-box card flex flex-column">
            <div class="flex align-items-center text-gray-700">
                <i class="far fa-calendar-alt"></i>
                <h6 class="m-0 text-color pl-2">Last Round</h6>
                <div class="ml-auto text-color-secondary">
                    <span *ngIf="daysSinceLastPlayed > 1">
                        {{daysSinceLastPlayed | number}} {{getDaysSinceLastPlayedLabel()}}
                    </span>
                </div>
            </div>
            <div class="flex justify-content-between mt-3 flex-wrap">
                <div class="flex flex-column">
                    <span class="mb-1 text-4xl">
                        <span *ngIf="lastRound">{{lastRound.formattedScore}}</span>
                        <span *ngIf="!lastRound">N/A</span>
                    </span>
                    <span *ngIf="lastRound" class="p-1 text-sm text-color-secondary">
                        {{lastRound.courseName}}<br />
                        <span *ngIf="daysSinceLastPlayed > 1">{{lastPlayed | date:'fullDate'}}</span>
                        <span *ngIf="daysSinceLastPlayed === 1">Yesterday</span>
                        <span *ngIf="daysSinceLastPlayed === 0">Today</span>
                        <span *ngIf="daysSinceLastPlayed === null || daysSinceLastPlayed === undefined">N/A</span>
                    </span>
                </div>
                <div class="flex align-items-end">
                    <button pButton type="button" label="New Round" icon="fa fa-plus" class="p-button-info" (click)="enterNewRound()"></button>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-6 lg:col-3">
        <div class="overview-box card flex flex-column">
            <div class="flex align-items-center text-gray-700">
                <i class="fas fa-trophy"></i>
                <h6 class="m-0 text-color pl-2">{{yearLastPlayed}} Scoring Average</h6>
                <div class="ml-auto">

                </div>
            </div>
            <div class="flex justify-content-between mt-3 flex-wrap">
                <div class="flex flex-column">
                    <span class="mb-1 text-2xl">
                        <table *ngIf="hasAnnual9HoleRounds || hasAnnual18HoleRounds" class="centered-table">
                            <tr style="vertical-align: bottom;">
                                <td>
                                    {{annualStatisticsFor9HoleRounds?.scoringAverage | number:'1.2-2'}}
                                </td>
                                <td *ngIf="hasAnnual18HoleRounds">
                                    <span>&bull;</span>
                                </td>
                                <td *ngIf="hasAnnual18HoleRounds">
                                    {{annualStatisticsFor18HoleRounds?.scoringAverage | number:'1.2-2'}}
                                </td>
                            </tr>
                            <tr style="vertical-align: top;">
                                <td class="overview-status font-medium border-round-xs text-white p-1 bg-teal-500 text-sm">
                                    {{annualStatisticsFor9HoleRounds?.bestScore}}&minus;{{annualStatisticsFor9HoleRounds?.worstScore}}
                                </td>
                                <td *ngIf="hasAnnual18HoleRounds"></td>
                                <td class="overview-status font-medium border-round-xs text-white p-1 bg-teal-500 text-sm" *ngIf="hasAnnual18HoleRounds">
                                    {{annualStatisticsFor18HoleRounds?.bestScore}}&minus;{{annualStatisticsFor18HoleRounds?.worstScore}}
                                </td>
                            </tr>
                        </table>
                        <span *ngIf="!hasAnnual18HoleRounds && !hasAnnual9HoleRounds">N/A</span>
                    </span>
                </div>
                <div class="flex align-items-end">
                    <p-chart *ngIf="scoringAverageTrendChartData" type="line" [data]="scoringAverageTrendChartData" [options]="scoringAverageTrendChartOptions" responsive="true" height="60px" width="160px"></p-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-6 lg:col-3">
        <div class="overview-box card flex flex-column">
            <div class="flex align-items-center text-gray-700">
                <i class="fas fa-award"></i>
                <h6 class="m-0 text-color pl-2">Handicap</h6>
                <div class="ml-auto">
                    <a [routerLink]="['/golfers', currentGolferId, 'handicaps']" class="handicap-link" pTooltip="View Handicap Details">
                        <i class="fas fa-long-arrow-alt-right"></i>
                    </a>
                </div>
            </div>
            <div class="flex justify-content-between mt-3 flex-wrap">
                <div class="flex flex-column">
                    <span class="mb-1 text-4xl">
                        <span *ngIf="handicap">{{handicap?.formattedHandicapIndex}}</span>
                        <span *ngIf="!handicap">N/A</span>
                    </span>
                    <span *ngIf="handicap">
                        <span class="overview-status font-medium border-round-xs text-white p-1 bg-teal-500 text-sm" pTooltip="Lowest Handicap in {{yearLastPlayed}}">
                            {{lowestHandicapOfYear?.formattedHandicapIndex}} on {{lowestHandicapOfYearTeeTime | date:'longDate'}}
                        </span>
                    </span>
                </div>
                <div class="flex align-items-end">
                    <p-chart *ngIf="handicapTrendChartData" type="line" [data]="handicapTrendChartData" [options]="handicapTrendChartOptions" responsive="true" height="60px" width="160px"></p-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-6 lg:col-3">
        <div class="overview-box card flex flex-column">
            <div class="flex align-items-center text-gray-700">
                <i class="fas fa-medal"></i>
                <h6 class="m-0 text-color pl-2">Best of {{yearLastPlayed}}</h6>
                <div class="ml-auto">

                </div>
            </div>
            <div class="flex justify-content-between mt-3 flex-wrap">
                <div class="grid centered-grid">
                    <div class="col-6 md:col-3 center">
                        <span class="overview-status font-medium border-round-xs text-white p-1 bg-teal-500 text-sm">Score</span><br />
                        <span class="mb-1 text-2xl">
                            <my-dual-statistic [statistic9Holes]="annualStatisticsFor9HoleRounds?.bestScore"
                                               [statistic18Holes]="annualStatisticsFor18HoleRounds?.bestScore"></my-dual-statistic>
                        </span>
                    </div>
                    <div class="col-6 md:col-3 center">
                        <span class="overview-status font-medium border-round-xs text-white p-1 bg-teal-500 text-sm">Fairways</span><br />
                        <span class="mb-1 text-2xl">
                            <my-dual-statistic [statistic9Holes]="annualStatisticsFor9HoleRounds?.mostFairways"
                                               [statistic18Holes]="annualStatisticsFor18HoleRounds?.mostFairways"></my-dual-statistic>
                        </span>
                    </div>
                    <div class="col-6 md:col-3 center">
                        <span class="overview-status font-medium border-round-xs text-white p-1 bg-teal-500 text-sm">GIRs</span><br />
                        <span class="mb-1 text-2xl">
                            <my-dual-statistic [statistic9Holes]="annualStatisticsFor9HoleRounds?.mostGIR"
                                               [statistic18Holes]="annualStatisticsFor18HoleRounds?.mostGIR"></my-dual-statistic>
                        </span>
                    </div>
                    <div class="col-6 md:col-3 center">
                        <span class="overview-status font-medium border-round-xs text-white p-1 bg-teal-500 text-sm">Putts</span><br />
                        <span class="mb-1 text-2xl">
                            <my-dual-statistic [statistic9Holes]="annualStatisticsFor9HoleRounds?.fewestPutts"
                                               [statistic18Holes]="annualStatisticsFor18HoleRounds?.fewestPutts"></my-dual-statistic>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 lg:col-8">
        <div class="card">
            <div class="card-header">
                <h5>{{yearLastPlayed}} At a Glance</h5>
            </div>
            <p-tabView *ngIf="charts">
                <p-tabPanel header="Recent Rounds">
                    <my-recent-rounds-bar-chart [data]="charts.recentRounds"></my-recent-rounds-bar-chart>
                </p-tabPanel>
                <p-tabPanel header="Scoring">
                    <my-scoring-pie-chart [data]="charts.scoring"></my-scoring-pie-chart>
                </p-tabPanel>
                <p-tabPanel header="Par 3 Scoring">
                    <my-scoring-pie-chart [data]="charts.par3Scoring"></my-scoring-pie-chart>
                </p-tabPanel>
                <p-tabPanel header="Par 4 Scoring">
                    <my-scoring-pie-chart [data]="charts.par4Scoring"></my-scoring-pie-chart>
                </p-tabPanel>
                <p-tabPanel header="Par 5 Scoring">
                    <my-scoring-pie-chart [data]="charts.par5Scoring"></my-scoring-pie-chart>
                </p-tabPanel>
                <p-tabPanel header="Putting">
                    <my-putting-pie-chart [data]="charts.putting"></my-putting-pie-chart>
                </p-tabPanel>
                <p-tabPanel header="Courses Played">
                    <my-courses-played-pie-chart [data]="charts.coursesPlayed"></my-courses-played-pie-chart>
                </p-tabPanel>
                <p-tabPanel header="SG Per Round" *ngIf="charts.averageStrokesGained">
                    <my-strokes-gained-bar-chart [data]="charts.averageStrokesGained"></my-strokes-gained-bar-chart>
                </p-tabPanel>
                <p-tabPanel header="SG Handicap" *ngIf="charts.strokesGainedHandicaps">
                    <my-strokes-gained-handicap-bar-chart [data]="charts.strokesGainedHandicaps"></my-strokes-gained-handicap-bar-chart>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>

    <div class="col-12 lg:col-4">
        <div class="card">
            <p-tabView *ngIf="charts">
                <p-tabPanel header="Recent Activity">
                    <p-table [value]="recentActivity" [loading]="isLoading" [paginator]="recentActivity && recentActivity.length > 12" [pageLinks]="10" [rows]="12" styleClass="p-datatable-striped">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width5 left">Date &amp; Time</th>
                                <th class="left">Summary</th>
                                <th class="width3">Detail</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-activity>
                            <tr [ngClass]="getRecentActivityRowCssClass(activity)">
                                <td class="left">
                                    <span class="p-column-title">Date &amp; Time</span>
                                    <a *ngIf="isGolfRound(activity)" routerLink="/rounds/{{activity.golfRoundGolfer.golfRound.golfRoundId}}">
                                        {{activity.dateTime | date:'EEE, M/d/yy'}}
                                        {{activity.dateTime | date:'shortTime'}}
                                    </a>
                                    <span *ngIf="!isGolfRound(activity)">
                                        {{activity.dateTime | date:'EEE, M/d/yy'}}
                                        {{activity.dateTime | date:'shortTime'}}
                                    </span>
                                </td>
                                <td class="left">
                                    <span class="p-column-title">Summary</span>
                                    <i *ngIf="isMyActivity(activity)" class="fas fa-user my-activity" pTooltip="Your Activity"></i>
                                    <i *ngIf="!isMyActivity(activity)" class="fas fa-user friend-activity" pTooltip="Your Friend's Activity"></i>
                                    {{activity.summary}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Detail</span>
                                    <span>{{activity.detail}}</span>
                                    <span class="status-icons">
                                        <i *ngIf="shouldShowExceptionalScoreIcon(activity.golfRoundGolfer)" class="fas fa-fire exceptional-score" [pTooltip]="activity.golfRoundGolfer.exceptionalScoreDescription"></i>
                                        <i *ngIf="shouldShowAboveAverageScoreIcon(activity.golfRoundGolfer)" class="fa-solid fa-circle-check positive-adornment" [pTooltip]="getAboveAverageScoreTooltip(activity.golfRoundGolfer)"></i>
                                        <i *ngIf="shouldShowBelowAverageScoreIcon(activity.golfRoundGolfer)" class="fa-solid fa-toilet negative-adornment" [pTooltip]="getBelowAverageScoreTooltip(activity.golfRoundGolfer)"></i>
                                        <i *ngIf="isStartedRound(activity)" class="fas fa-th clickable scorecard-icon" pTooltip="View Scorecard" (click)="showScorecardDialog(activity)"></i>
                                        <i *ngIf="isUnstartedRound(activity)" class="fas fa-calendar-check unstarted-round" pTooltip="This Round has not Started Yet"></i>
                                        <i *ngIf="isHandicapDecrease(activity)" class="fas fa-thumbs-up handicap-decrease" pTooltip="Handicap Decreased"></i>
                                        <i *ngIf="isHandicapIncrease(activity)" class="fas fa-thumbs-down handicap-increase" pTooltip="Handicap Increased"></i>
                                        <i *ngIf="isPracticeSession(activity)" class="fas fa-crosshairs practice-session" pTooltip="Practice Session"></i>
                                    </span>
                                    <br *ngIf="isMyIncompleteRound(activity)" />
                                    <button *ngIf="isMyIncompleteRound(activity)" pButton type="button" icon="fas fa-play" iconPos="right" label="{{getContinueButtonText(activity)}}" class="p-button-info" (click)="continueRound(activity)"></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="3">
                                    {{getEmptyMessage('No recent activity.')}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-tabPanel>
                <p-tabPanel header="Key Trends" *ngIf="maxRoundCountForKeyStatisticTrends > 0">
                    <p-table [value]="keyStatisticTrendMappers" styleClass="p-datatable-striped">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="left">Key Statistic</th>
                                <th class="width2" *ngIf="maxRoundCountForKeyStatisticTrends >= 3">Last<br />3 Rounds</th>
                                <th class="width2" *ngIf="maxRoundCountForKeyStatisticTrends >= 5">Last<br />5 Rounds</th>
                                <th class="width2" *ngIf="maxRoundCountForKeyStatisticTrends >= 8">Last<br />8 Rounds</th>
                                <th class="width2" *ngIf="maxRoundCountForKeyStatisticTrends >= 10">Last<br />10 Rounds</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-mapper>
                            <tr>
                                <td>
                                    <span class="p-column-title">Key Statistic</span>
                                    {{mapper.name}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Last 3 Rounds</span>
                                    <i [ngClass]="getKeyStatisticTrendCssClass(mapper, 3)" class="trend-icon" [pTooltip]="getKeyStatisticTrendTooltip(mapper, 3)"></i>
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Last 5 Rounds</span>
                                    <i [ngClass]="getKeyStatisticTrendCssClass(mapper, 5)" class="trend-icon"></i>
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Last 8 Rounds</span>
                                    <i [ngClass]="getKeyStatisticTrendCssClass(mapper, 8)" class="trend-icon"></i>
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Last 10 Rounds</span>
                                    <i [ngClass]="getKeyStatisticTrendCssClass(mapper, 10)" class="trend-icon"></i>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>
