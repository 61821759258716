// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreSharedNotificationsNotifications = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const ModelsCoreSharedNotificationsNotifications = {
    None: 0 as ModelsCoreSharedNotificationsNotifications,
    FriendStartedRound: 1 as ModelsCoreSharedNotificationsNotifications,
    FriendCompletedFront9: 2 as ModelsCoreSharedNotificationsNotifications,
    FriendCompletedRound: 3 as ModelsCoreSharedNotificationsNotifications,
    FriendCompletedPracticeSession: 4 as ModelsCoreSharedNotificationsNotifications,
    FriendGolfRoundAccomplishments: 5 as ModelsCoreSharedNotificationsNotifications,
    Reminders: 6 as ModelsCoreSharedNotificationsNotifications
};
