<div class="grid p-fluid">
    <div class="col-12">
        <div class="field col-12 lg:col-1">
            <span class="p-float-label" *ngIf="args">
                <p-dropdown [options]="yearsPlayed" [(ngModel)]="args.year" styleClass="year-selector" (onChange)="onYearChanged();"></p-dropdown>
                <label>Year</label>
            </span>
        </div>
    </div>
    <div class="col-12">
        <my-handicap-history-line-chart [data]="chartData"></my-handicap-history-line-chart>
    </div>
</div>
