import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { AuthGuard } from '../shared/auth/auth-guard.service';
import { AppLayoutComponent } from '../layout/app.layout.component';
import { PracticeTabViewComponent } from './practice-tab-view.component';
import { PracticeActivityDetailComponent } from './activities/detail/practice-activity-detail.component';
import { ImprovementPlanComponent } from './improvement-plan/improvement-plan.component';
import { PracticePlanDetailComponent } from './plans/detail/practice-plan-detail.component';
import { PracticeSessionDetailComponent } from './sessions/detail/practice-session-detail.component';
import { PracticeSessionEntryComponent } from './sessions/entry/practice-session-entry.component';
import { NewPracticeSessionComponent } from './sessions/new/new-practice-session.component';

export const routes: Routes = [
    {
        path: 'practice',
        component: AppLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'plans/new',
                component: PracticePlanDetailComponent,
                data: {
                    title: 'New Practice Plan'
                }
            },
            {
                path: 'plans/:practicePlanId',
                component: PracticePlanDetailComponent,
                data: {
                    title: 'Practice Plan'
                }
            },
            {
                path: 'sessions/new',
                component: NewPracticeSessionComponent,
                data: {
                    title: 'Start Practice Session'
                }
            },
            {
                path: 'sessions/:practiceSessionId',
                component: PracticeSessionDetailComponent,
                data: {
                    title: 'Practice Session Detail'
                }
            },
            {
                path: 'sessions/:practiceSessionId/enter',
                component: PracticeSessionEntryComponent,
                data: {
                    title: 'Practice Session'
                }
            },
            {
                path: 'activities/new',
                component: PracticeActivityDetailComponent,
                data: {
                    title: 'New Practice Activity'
                }
            },
            {
                path: 'activities/:practiceActivityId',
                component: PracticeActivityDetailComponent,
                data: {
                    title: 'Practice Activity'
                }
            },
            {
                path: 'improvement-plan',
                component: ImprovementPlanComponent,
                data: {
                    title: 'Improvement Plan'
                }
            },
            {
                path: '',
                component: PracticeTabViewComponent,
                data: {
                    title: 'Practice Plans'
                }
            }
        ]
    }
];

export const practiceRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
