import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ChartData, ChartOptions, TooltipItem } from 'chart.js';

import { AppComponent } from '../../app.component';
import { GolfersControllerProxy } from '../../shared/server-proxies';
import { CurrentGolferService } from '../../shared/golfers/current-golfer.service';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';
import { ScorecardDialogService } from '../../shared/rounds/scorecard-dialog/scorecard-dialog.service';

import {
    ModelsCoreGolfersHandicap,
    ModelsCoreGolfersGolferActivity,
    ModelsCoreRoundsGolfRoundGolfer,
    ModelsCoreRoundsScoreCategories,
    ModelsCoreStatisticsStatisticTrend,
    ModelsGolfersGetDashboardBasicStatisticsPerRound,
    ModelsGolfersGetDashboardDashboardCharts,
    ModelsGolfersGetDashboardRecentRound,
    ModelsGolfersGetDashboardStatisticSnapshot,
    ModelsWebApiGolfersGetDashboardArgs
} from '../../shared/swagger-codegen/models';

interface IKeyStatisticTrendNameValueMapper {
    name: string;
    propertyName: string;
}

@Component({
    selector: 'my-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: [
        './dashboard.component.scss'
    ]
})
export class DashboardComponent extends BaseComponentDirective implements OnInit {
    constructor(
        public app: AppComponent,
        private golferProxy: GolfersControllerProxy,
        private currentGolfer: CurrentGolferService,
        private scorecardDialog: ScorecardDialogService,
        private router: Router,
        private datePipe: DatePipe) {
        super();
    }

    lastPlayed: Date;
    lastRound: ModelsGolfersGetDashboardRecentRound;
    yearLastPlayed: number;
    daysSinceLastPlayed: number;
    recentActivity: ModelsCoreGolfersGolferActivity[];
    
    annualStatisticsFor9HoleRounds: ModelsGolfersGetDashboardBasicStatisticsPerRound;
    annualStatisticsFor18HoleRounds: ModelsGolfersGetDashboardBasicStatisticsPerRound;
    handicap: ModelsCoreGolfersHandicap;
    lowestHandicapOfYear?: ModelsCoreGolfersHandicap;
    lowestHandicapOfYearTeeTime?: Date;

    hasAnnual9HoleRounds: boolean;
    hasAnnual18HoleRounds: boolean;
    hasAnnual9And18HoleRounds: boolean;
    hasNoAnnualRounds = true;

    charts: ModelsGolfersGetDashboardDashboardCharts;
    scoringAverageTrendChartData: ChartData;
    scoringAverageTrendChartOptions: ChartOptions;
    handicapTrendChartData: ChartData;
    handicapTrendChartOptions: ChartOptions;
    keyStatisticTrendMappers: IKeyStatisticTrendNameValueMapper[] = [];
    maxRoundCountForKeyStatisticTrends = 0;
    
    args: ModelsWebApiGolfersGetDashboardArgs = {
        recentRoundCount: 15,
        includeScoringTrend: true,
        includeHandicapTrend: true,
        includeKeyStatisticTrends: true
    };

    get currentGolferId() {
        return this.currentGolfer.golferId;
    }

    private keyStatisticTrends: ModelsCoreStatisticsStatisticTrend[];

    ngOnInit() {
        this.taskStarted();
        this.golferProxy.getDashboard(this.currentGolfer.golferId, this.args)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    const result = response.body;
                    this.lastRound = result.lastRound;
                    this.lastPlayed = result.lastRound ? new Date(result.lastRound.teeTime) : undefined;
                    this.yearLastPlayed = this.lastPlayed ? this.lastPlayed.getFullYear() : null;
                    this.daysSinceLastPlayed = result.daysSinceLastPlayed;
                    this.recentActivity = result.recentActivity;
                    this.charts = result.charts;
                    this.handicap = result.handicap;
                    this.lowestHandicapOfYear = result.lowestHandicapOfYear;
                    this.lowestHandicapOfYearTeeTime = result.lowestHandicapOfYearTeeTime;
                    this.annualStatisticsFor9HoleRounds = result.annualStatisticsFor9HoleRounds;
                    this.annualStatisticsFor18HoleRounds = result.annualStatisticsFor18HoleRounds;

                    this.hasAnnual9HoleRounds = this.hasRounds(this.annualStatisticsFor9HoleRounds);
                    this.hasAnnual18HoleRounds = this.hasRounds(this.annualStatisticsFor18HoleRounds);
                    this.hasAnnual9And18HoleRounds = this.hasAnnual9HoleRounds && this.hasAnnual18HoleRounds;
                    this.hasNoAnnualRounds = !(this.hasAnnual9HoleRounds || this.hasAnnual18HoleRounds);

                    this.loadScoringAverageTrendChart(response.body.scoringTrendFor18Holes, response.body.scoringTrendFor9Holes);
                    this.loadHandicapTrendChart(response.body.handicapTrend);
                    this.loadKeyStatisticTrends(response.body.keyStatisticTrends);
                });
    }

    getDaysSinceLastPlayedLabel() {
        return this.daysSinceLastPlayed === 1 ? 'Day Ago' : 'Days Ago';
    }

    enterNewRound() {
        this.router.navigate(['rounds', 'new']);
    }

    getRecentActivityRowCssClass(activity: ModelsCoreGolfersGolferActivity) {
        const isMyActivity = this.isMyActivity(activity);

        /* eslint-disable @typescript-eslint/naming-convention */
        return {
            'my-activity': isMyActivity,
            'friend-activity': !isMyActivity
        };
        /* eslint-enable @typescript-eslint/naming-convention */
    }

    isStartedRound(activity: ModelsCoreGolfersGolferActivity) {
        return this.isGolfRound(activity) && activity.golfRoundGolfer.holesPlayed > 0;
    }

    isUnstartedRound(activity: ModelsCoreGolfersGolferActivity) {
        return this.isGolfRound(activity) && activity.golfRoundGolfer.holesPlayed === 0;
    }

    isCompletedRound(activity: ModelsCoreGolfersGolferActivity) {
        return this.isGolfRound(activity) && activity.golfRoundGolfer.isComplete;
    }

    isMyIncompleteRound(activity: ModelsCoreGolfersGolferActivity) {
        return this.isGolfRound(activity)
            && !this.isCompletedRound(activity)
            && this.isMyActivity(activity);
    }

    isExceptionalRound(activity: ModelsCoreGolfersGolferActivity) {
        return this.isGolfRound(activity)
            && activity.golfRoundGolfer.scoreCategory === ModelsCoreRoundsScoreCategories.Exceptional;
    }

    isInProgressRound(activity: ModelsCoreGolfersGolferActivity) {
        return this.isStartedRound(activity) && !this.isCompletedRound(activity);
    }

    isHandicapDecrease(activity: ModelsCoreGolfersGolferActivity) {
        return this.isHandicapChange(activity) && activity.handicapChange.difference < 0;
    }

    isHandicapIncrease(activity: ModelsCoreGolfersGolferActivity) {
        return this.isHandicapChange(activity) && activity.handicapChange.difference > 0;
    }

    isMyActivity(activity: ModelsCoreGolfersGolferActivity) {
        return activity.golfer.golferId === this.currentGolfer.golferId;
    }

    isGolfRound(activity: ModelsCoreGolfersGolferActivity) {
        return activity.golfRoundGolfer !== undefined;
    }

    isPracticeSession(activity: ModelsCoreGolfersGolferActivity) {
        return activity.practiceSession !== undefined;
    }

    isHandicapChange(activity: ModelsCoreGolfersGolferActivity) {
        return activity.handicapChange !== undefined;
    }
    
    getContinueButtonText(activity: ModelsCoreGolfersGolferActivity) {
        return this.isInProgressRound(activity) ? 'Continue' : 'Start';
    }

    shouldShowExceptionalScoreIcon(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer?.scoreCategory === ModelsCoreRoundsScoreCategories.Exceptional;
    }

    shouldShowAboveAverageScoreIcon(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer?.scoreCategory === ModelsCoreRoundsScoreCategories.AboveAverage;
    }

    shouldShowBelowAverageScoreIcon(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer?.scoreCategory === ModelsCoreRoundsScoreCategories.BelowAverage;
    }

    getAboveAverageScoreTooltip(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer?.scoreCategory === ModelsCoreRoundsScoreCategories.AboveAverage
            ? `Net Score of ${golfRoundGolfer.formattedNetScore} is an Above Average Round`
            : undefined;
    }

    getBelowAverageScoreTooltip(golfRoundGolfer: ModelsCoreRoundsGolfRoundGolfer) {
        return golfRoundGolfer?.scoreCategory === ModelsCoreRoundsScoreCategories.BelowAverage
            ? `Net Score of ${golfRoundGolfer.formattedNetScore} is a Below Average Round`
            : undefined;
    }

    showScorecardDialog(activity: ModelsCoreGolfersGolferActivity) {
        const golfRound = activity.golfRoundGolfer.golfRound;
        this.scorecardDialog.open(golfRound.golfRoundId, golfRound.teeTime, golfRound.course.name);
    }
    
    continueRound(activity: ModelsCoreGolfersGolferActivity) {
        const golfRoundId = activity.golfRoundGolfer.golfRound.golfRoundId;

        if(this.isStartedRound(activity)) {
            this.router.navigate(['rounds', golfRoundId, 'scorecard', { continue: true }]);
        }
        else {
            this.router.navigate(['rounds', golfRoundId, { starting: true }]);
        }
    }

    getKeyStatisticTrendCssClass(mapper: IKeyStatisticTrendNameValueMapper, roundCount: number) {
        const value = this.getKeyStatisticTrendValue(mapper, roundCount);

        /* eslint-disable @typescript-eslint/naming-convention */
        return {
            fas: value !== undefined,
            'fa-smile-beam positive-adornment': value > 0,
            'fa-meh': value === 0,
            'fa-sad-tear negative-adornment': value < 0
        };
        /* eslint-enable @typescript-eslint/naming-convention */
    }

    getKeyStatisticTrendTooltip(mapper: IKeyStatisticTrendNameValueMapper, roundCount: number) {
        const value = this.getKeyStatisticTrendValue(mapper, roundCount);
        let tooltip: string;

        if(value > 0) {
            tooltip = 'Getting Better';
        }
        else if(value < 0) {
            tooltip = 'Getting Worse';
        }
        else if(value === 0) {
            tooltip = 'Not Changing';
        }

        return tooltip;
    }

    getKeyStatisticTrendValue(mapper: IKeyStatisticTrendNameValueMapper, roundCount: number) {
        const trends = this.keyStatisticTrends.filter(t => t.roundCount === roundCount);
        return trends.length > 0 ? trends[0][mapper.propertyName] : undefined;
    }

    private hasRounds(statistics: ModelsGolfersGetDashboardBasicStatisticsPerRound) {
        return statistics && statistics.roundsPlayed > 0;
    }

    private loadScoringAverageTrendChart(
        scoringTrendFor18Holes: ModelsGolfersGetDashboardStatisticSnapshot[],
        scoringTrendFor9Holes: ModelsGolfersGetDashboardStatisticSnapshot[]) {

        this.scoringAverageTrendChartOptions = this.createOverviewChartOptions();
        this.scoringAverageTrendChartData = {
            datasets: [
                {
                    label: '18 Hole Scoring Avg',
                    borderColor: '#009688',
                    backgroundColor: '#009688',
                    borderWidth: 2,
                    fill: false,
                    data: []
                },
                {
                    label: '9 Hole Scoring Avg',
                    borderColor: '#E91E63',
                    backgroundColor: '#E91E63',
                    borderWidth: 2,
                    fill: false,
                    data: []
                }
            ]
        };

        this.scoringAverageTrendChartData.datasets[0].data = scoringTrendFor18Holes.map(s => {
            return {
                x: new Date(s.asOfTeeTime).valueOf(),
                y: s.value,
                formattedValue: s.formattedValue
            } as any;
        });
              
        this.scoringAverageTrendChartData.datasets[1].data = scoringTrendFor9Holes.map(s => {
            return {
                x: new Date(s.asOfTeeTime).valueOf(),
                y: s.value,
                formattedValue: s.formattedValue
            } as any;
        });
    }

    private loadHandicapTrendChart(handicapTrend: ModelsGolfersGetDashboardStatisticSnapshot[]) {
        this.handicapTrendChartOptions = this.createOverviewChartOptions();
        this.handicapTrendChartData = {
            datasets: [
                {
                    label: 'Handicap Index',
                    borderColor: '#E91E63',
                    backgroundColor: '#F48FB1',
                    borderWidth: 2,
                    fill: true,
                    data: []
                }
            ]
        };
        
        this.handicapTrendChartData.datasets[0].data = handicapTrend.map(s => {
            return {
                x: new Date(s.asOfTeeTime).valueOf(),
                y: s.value,
                formattedValue: s.formattedValue
            } as any;
        });
    }

    private createOverviewChartOptions() {
        const options: any = {
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: true,
                    callbacks: {
                        title: () => null,
                        label: (tooltipItem: TooltipItem<'line'>) => {
                            const data = tooltipItem.dataset.data as any[];
                            const item = data[tooltipItem.dataIndex];
                            const formattedDate = this.datePipe.transform(new Date(item.x), 'shortDate');

                            return `${item.formattedValue} on ${formattedDate}`;
                        }
                    }
                }
            },
            responsive: true,
            scales: {
                y: {
                    display: false
                },
                x: {
                    type: 'timeseries',
                    display: false
                }
            },
            elements: {
                point: {
                    radius: 0,
                    hitRadius: 5
                }
            },
        };
        
        return options;
    }

    private loadKeyStatisticTrends(trends: ModelsCoreStatisticsStatisticTrend[]) {
        const mappers: IKeyStatisticTrendNameValueMapper[] = [
            { name: 'Scoring Avg', propertyName: 'scoringAvgTrend' },
            { name: 'Par 3 Scoring Avg', propertyName: 'scoringAvgPar3Trend' },
            { name: 'Par 4 Scoring Avg', propertyName: 'scoringAvgPar4Trend' },
            { name: 'Par 5 Scoring Avg', propertyName: 'scoringAvgPar5Trend' },
            { name: 'Double Bogey or Better', propertyName: 'doubleBogeyOrBetterPercentTrend' },
            { name: 'Bogey or Better', propertyName: 'bogeyOrBetterPercentTrend' },
            { name: 'Par or Better', propertyName: 'parOrBetterPercentTrend' },
            { name: 'Fairway %', propertyName: 'fairwayPercentTrend' },
            { name: 'GIR %', propertyName: 'girPercentTrend' },
            { name: 'Putts/Hole', propertyName: 'puttsPerHoleTrend' },
            { name: 'SG: Driving', propertyName: 'strokesGainedOffTeePerRoundVsScratchTrend' },
            { name: 'SG: Approach', propertyName: 'strokesGainedApproachPerRoundVsScratchTrend' },
            { name: 'SG: Around the Green', propertyName: 'strokesGainedAroundGreenPerRoundVsScratchTrend' },
            { name: 'SG: Putting', propertyName: 'strokesGainedPuttingPerRoundVsScratchTrend' }
        ];
        const valueTrackingByMapperIndex: { [index: number]: boolean } = {};

        trends.forEach(trend => {
            if(trend.roundCount > this.maxRoundCountForKeyStatisticTrends) {
                this.maxRoundCountForKeyStatisticTrends = trend.roundCount;
            }

            mappers.forEach((mapper, i) => {
                if(trend[mapper.propertyName] !== undefined) {
                    valueTrackingByMapperIndex[i] = true;
                }
            });
        });

        mappers.forEach((mapper, i) => {
            if(valueTrackingByMapperIndex[i]) {
                this.keyStatisticTrendMappers.push(mapper);
            }
        });

        this.keyStatisticTrends = trends;
    }
}
