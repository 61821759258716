export * from './base-component.directive';
export * from './bindable.directive';
export * from './scroll.service';
export * from './directives/select-all.directive';
export * from './ui.module';
export * from './validation-message.component';
export * from './messages/messages.component';


export function formatHandicap(handicap: number | string) {
    const s = typeof handicap == 'string' ? handicap : parseFloat(handicap as any).toFixed(1).toString();
    return s.replace('-', '+');
}
