import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';

import { chartColors } from '../../charts';
import { PracticeTimeChartDialogComponent } from './practice-time-chart-dialog.component';

import {
    ModelsCorePracticePracticeCategory,
    ModelsCorePracticePracticeType
} from '../../shared/swagger-codegen/models';

export interface PracticeTypeChartData {
    practiceCategoryData: any;
    practiceTypeData: any;
}

@Injectable()
export class PracticeTimeChartDialogService {
    constructor(private dialogService: DialogService) {

    }

    open(header: string, practiceCategories: ModelsCorePracticePracticeCategory[], practiceTypes: ModelsCorePracticePracticeType[]) {
        const chartData: PracticeTypeChartData = {
            practiceCategoryData: this.initializeChartData(),
            practiceTypeData: this.initializeChartData()
        };

        practiceCategories.forEach(category => {
            chartData.practiceCategoryData.datasets[0].data.push(category.minutes);
            chartData.practiceCategoryData.labels.push(category.name);
        });

        practiceTypes.forEach(type => {
            chartData.practiceTypeData.datasets[0].data.push(type.minutes);
            chartData.practiceTypeData.labels.push(type.name);
        });

        return this.dialogService.open(
            PracticeTimeChartDialogComponent,
            {
                data: chartData,
                header: header,
                width: '650px',
                height: '500px',
                contentStyle: {
                    overflow: 'auto',
                    width: '100%',
                    height: '90%'
                }
            });
    }

    private initializeChartData() {
        return {
            datasets: [
                    {
                        backgroundColor: chartColors,
                        data: [],
                        fill: true
                    }
                ],
                labels: []
        };
    }
}
