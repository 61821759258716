import { Component } from '@angular/core';
import { TooltipItem, ChartData, ChartDataset } from 'chart.js';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';
import { TimeSpan } from '../shared/util';

@Component({
    selector: 'my-practice-time-by-category-stacked-bar-chart',
    template: '<p-chart type="bar" [data]="data" [options]="options" responsive="true"></p-chart>'
})
export class PracticeTimeByCategoryStackedBarChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'bar');
        this.options.scales.y.stacked = true;
        this.options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);
        this.options.scales.y.ticks.callback = this.getTick.bind(this);
    }

    private getTooltipLabel(tooltipItem: TooltipItem<'bar'>) {
        const dataSet = tooltipItem.dataset;
        const data = dataSet.data as number[];

        const category = tooltipItem.label;
        const practiceType = dataSet.label;

        const totalMinutes = this.sumAllDataSets(tooltipItem.chart.data);
        const totalMinutesTimeSpan = TimeSpan.fromMinutes(totalMinutes);
        const categoryMinutes = this.sumAllDataSetsAtDataIndex(tooltipItem.chart.data, tooltipItem.dataIndex);
        const categoryMinutesTimeSpan = TimeSpan.fromMinutes(categoryMinutes);
        const minutesForThisTypeInCategory = data[tooltipItem.dataIndex];
        const minutesForThisTypeInCategoryTimeSpan = TimeSpan.fromMinutes(minutesForThisTypeInCategory);

        const percentOfCategory = (minutesForThisTypeInCategory / categoryMinutes) * 100;
        const percentOfTotal = (minutesForThisTypeInCategory / totalMinutes) * 100;

        return [
            ` ${minutesForThisTypeInCategoryTimeSpan.toString()} Spent on ${practiceType}`,
            ` ${percentOfCategory.toFixed(0)}% of ${categoryMinutesTimeSpan.toString()} ${category} Practice Time`,
            ` ${percentOfTotal.toFixed(0)}% of ${totalMinutesTimeSpan.toString()} Total Practice Time.`
        ];
    }

    private getTick(value: any) {
        const ts = TimeSpan.fromMinutes(value);
        return `${ts.toString()}`;
    }

    private sumAllDataSets(chartData: ChartData) {
        let total = 0;

        for(let i = 0; i < chartData.datasets.length; i++) {
            const dataSet = chartData.datasets[i];
            total += this.sumDataSet(dataSet);
        }

        return total;
    }

    private sumDataSet(dataSet: ChartDataset) {
        const data = dataSet.data as number[];
        return data.reduce((runningTotal, currentValue) => runningTotal + currentValue, 0);
    }

    private sumAllDataSetsAtDataIndex(chartData: ChartData, index: number) {
        let total = 0;

        for(let i = 0; i < chartData.datasets.length; i++) {
            const dataSet = chartData.datasets[i];
            const data = dataSet.data as number[];

            if(data.length > index) {
                const value = data[index];

                if(value) {
                    total += value;
                }
            }
        }

        return total;
    }
}
