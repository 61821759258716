import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimeNgModule } from '../primeng/primeng.module';
import { MessagesComponent } from './messages.component';

@NgModule(
    {
        imports: [
            CommonModule,
            PrimeNgModule
        ],
        exports: [
            MessagesComponent
        ],
        declarations: [
            MessagesComponent
        ]
    })
export class MessagesModule {
}
