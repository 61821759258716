import { Input, OnDestroy, Directive } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { GolfersControllerProxy, RoundsControllerProxy } from '../../shared/server-proxies';
import { CurrentGolferService } from '../../shared/golfers/current-golfer.service';
import { BaseComponentDirective } from '../ui/base-component.directive';
import { ScorecardHubProxy } from './scorecard-hub-proxy';
import { SignalRHubConnectionFactory } from '../core/signalr-hub-connection-factory.service';
import { finalize, map, tap } from 'rxjs/operators';

import {
    ModelsCoreRoundsApproachShotMisses,
    ModelsCoreRoundsApproachShotResults,
    ModelsCoreRoundsFairwayMisses,
    ModelsCoreRoundsFairwayResults,
    ModelsCoreRoundsScorecardUpdatedMessage,
    ModelsCoreRoundsShortGameResults,
    ModelsCoreRoundsUpAndDownResults,
    ModelsRoundsGetGolfRoundScorecardGolfRoundScorecard,
    ModelsRoundsGetGolfRoundScorecardLookupsGetGolfRoundScorecardLookupsResponse,
    ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
    ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole,
    ModelsRoundsGetGolfRoundScorecardScorecardHole,
    ModelsRoundsGetGolfRoundScorecardScorecardTeeCourseNine,
    ModelsWebApiRoundsGetScorecardArgs,
    ModelsWebApiRoundsSaveScorecardModel
} from '../../shared/swagger-codegen/models';

interface INavigationItem {
    navigationIndex: number;
    golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer;
    hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole;
}

interface IGolferWithHoles {
    golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer;
    holes: SelectItem[];
}

export interface IScorecardLookupInitializationOptions {
    includeEmptyDefaultSelectItems: boolean;
}

export interface IScorecardInitializationOptions {
    includeEmptyDefaultGolfClubSelectItems: boolean;
}

@Directive()
export abstract class ScorecardBaseDirective extends BaseComponentDirective implements OnDestroy {
    constructor(
        protected roundsProxy: RoundsControllerProxy,
        protected golfersProxy: GolfersControllerProxy,
        protected currentGolfer: CurrentGolferService,
        hubConnectionFactory: SignalRHubConnectionFactory) {
        super();

        this.scorecardHubProxy = new ScorecardHubProxy(hubConnectionFactory);
    }

    @Input() scorecard: ModelsRoundsGetGolfRoundScorecardGolfRoundScorecard;
    scorecardLookups: ModelsRoundsGetGolfRoundScorecardLookupsGetGolfRoundScorecardLookupsResponse;
    fairwayResults: SelectItem[] = [];
    fairwayMisses: SelectItem[] = [];
    approachShotResults: SelectItem[] = [];
    approachShotMisses: SelectItem[] = [];
    shortGameResults: SelectItem[] = [];
    upAndDownResults: SelectItem[] = [];
    golfers: SelectItem[] = [];
    realTimeHoleUpdates: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[] = [];
    protected successfulSaveMessage: string = null;
    protected saveNotNecessaryMessage: string = null;
    protected golfRoundId: number;
    protected includeGolferHoleInfo: boolean;
    protected navigationDictionary: { [key: string]: INavigationItem } = {};
    protected navigationSequence: INavigationItem[] = [];
    protected golferDictionary: { [key: number]: IGolferWithHoles } = {};
    protected scorecardHubProxy: ScorecardHubProxy;
    private originalGolfers: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer[];
    private debugShowAll = false;
    private golfClubSelectItems: { [golfRoundGolferId: number]: SelectItem[] } = {};

    ngOnDestroy() {
        this.stopScorecardHubProxy();
    }

    getGolfClubSelectItems(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return this.golfClubSelectItems[golfer.golfRoundGolferId];
    }

    showBasicFairways(
        golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
        hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {

        return this.hasFairway(hole) && this.trackingBasicFairways(golfer);
    }

    showAdvancedFairways(
        golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
        hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {

        return this.hasFairway(hole) && this.trackingAdvancedFairways(golfer);
    }

    hasFairway(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        return hole.par > 3;
    }

    trackingAnyFairways(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return this.trackingBasicFairways(golfer) || this.trackingAdvancedFairways(golfer);
    }

    trackingBasicFairways(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return (golfer.trackedMetrics.trackBasicFairways && !this.trackingAdvancedFairways(golfer)) || this.debugShowAll;
    }

    trackingAdvancedFairways(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.trackedMetrics.trackAdvancedFairways || this.debugShowAll;
    }

    trackingTeeShotClub(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.trackedMetrics.trackTeeShotClub || this.debugShowAll;
    }

    trackingDrivingDistance(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.trackedMetrics.trackDrivingDistance || this.debugShowAll;
    }

    trackingAnyGIRs(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return this.trackingBasicGIRs(golfer) || this.trackingAdvancedGIRs(golfer);
    }

    trackingBasicGIRs(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return (golfer.trackedMetrics.trackBasicGIRs && !this.trackingAdvancedGIRs(golfer)) || this.debugShowAll;
    }

    trackingAdvancedGIRs(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.trackedMetrics.trackAdvancedGIRs || this.debugShowAll;
    }

    trackingChips(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.trackedMetrics.trackChips || this.debugShowAll;
    }

    trackingPitches(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.trackedMetrics.trackPitches || this.debugShowAll;
    }

    trackingSandShots(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.trackedMetrics.trackSandShots || this.debugShowAll;
    }

    trackingUpAndDowns(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.trackedMetrics.trackUpAndDowns || this.debugShowAll;
    }

    trackingPutts(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.trackedMetrics.trackPutts || this.debugShowAll;
    }

    trackingFirstPuttDistance(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.trackedMetrics.trackFirstPuttDistance || this.debugShowAll;
    }

    trackingLastPuttDistance(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.trackedMetrics.trackLastPuttDistance || this.debugShowAll;
    }

    trackingPenaltyStrokes(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.trackedMetrics.trackPenaltyStrokes || this.debugShowAll;
    }

    trackingMulligans(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.trackedMetrics.trackMulligans || this.debugShowAll;
    }

    trackingApproachShotDistance(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.trackedMetrics.trackApproachShotDistance || this.debugShowAll;
    }

    trackingApproachShotClub(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.trackedMetrics.trackApproachShotClub || this.debugShowAll;
    }

    trackingGreenHitFromDistance(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.trackedMetrics.trackGreenHitFromDistance || this.debugShowAll;
    }

    trackingStrokesGained(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return golfer.trackedMetrics.trackStrokesGained || this.debugShowAll;
    }

    sumStrokes(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.strokes, holeLists);
    }

    sumNetStrokes(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.netStrokes, holeLists);
    }

    sumAdjustedStrokes(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.adjustedStrokes, holeLists);
    }

    sumStrokesGainedTotalVsPro(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.strokesGainedTotalVsPro, holeLists);
    }

    sumStrokesGainedTotalVsScratch(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.strokesGainedTotalVsScratch, holeLists);
    }

    sumScore(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        const strokes = this.sum(c => c.strokes, holeLists);
        const par = this.sum(c => c.par, holeLists, c => c.strokes > 0);
        const score = strokes - par;
        let formattedScore: string;

        if(score === 0) {
            formattedScore = 'EVEN';
        }
        else if(score > 0) {
            formattedScore = '+' + score;
        }
        else {
            formattedScore = score.toString();
        }

        return strokes === 0 ? 0 : `${strokes} (${formattedScore})`;
    }

    calculateFairwayPercent(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        const successes = this.sumFairways(...holeLists);
        const opportunities = this.sum(c => c.strokes > 0 && c.par > 3 ? 1 : 0, holeLists);
        return opportunities > 0 ? successes / opportunities : undefined;
    }

    sumFairways(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.fairwayResultId === ModelsCoreRoundsFairwayResults.Hit ? 1 : 0, holeLists);
    }

    avgDrivingDistance(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.avg(c => c.drivingDistance, holeLists, v => v > 0).toFixed(1);
    }

    calculateGIRPercent(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        const successes = this.sumGIRs(...holeLists);
        const opportunities = this.sum(c => c.strokes > 0 ? 1 : 0, holeLists);
        return opportunities > 0 ? successes / opportunities : undefined;
    }

    sumGIRs(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.approachShotResultId === ModelsCoreRoundsApproachShotResults.GIR ? 1 : 0, holeLists);
    }

    avgApproachShotDistance(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.avg(c => c.approachShotDistance, holeLists, v => v >= 0).toFixed(1);
    }

    avgGreenHitFromDistance(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.avg(c => c.greenHitFromDistance, holeLists, v => v >= 0).toFixed(1);
    }

    sumGreenHitFromDistance(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.greenHitFromDistance, holeLists);
    }
    
    calculateChipPercent(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        const successes = this.sumChips(...holeLists);
        const opportunities = this.sum(
            c => c.strokes > 0 && c.chipResultId !== ModelsCoreRoundsShortGameResults.NoAttempt ? 1 : 0,
            holeLists
        );
        return opportunities > 0 ? successes / opportunities : undefined;
    }

    sumChips(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.chipResultId === ModelsCoreRoundsShortGameResults.FinishedOnGreen ? 1 : 0, holeLists);
    }

    calculatePitchPercent(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        const successes = this.sumPitches(...holeLists);
        const opportunities = this.sum(
            c => c.strokes > 0 && c.pitchResultId !== ModelsCoreRoundsShortGameResults.NoAttempt ? 1 : 0,
            holeLists
        );
        return opportunities > 0 ? successes / opportunities : undefined;
    }

    sumPitches(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.pitchResultId === ModelsCoreRoundsShortGameResults.FinishedOnGreen ? 1 : 0, holeLists);
    }

    calculateSandShotPercent(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        const successes = this.sumSandShots(...holeLists);
        const opportunities = this.sum(
            c => c.strokes > 0 && c.sandResultId !== ModelsCoreRoundsShortGameResults.NoAttempt ? 1 : 0,
            holeLists
        );
        return opportunities > 0 ? successes / opportunities : undefined;
    }

    sumSandShots(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.sandResultId === ModelsCoreRoundsShortGameResults.FinishedOnGreen ? 1 : 0, holeLists);
    }

    calculateUpAndDownFrom1To10Percent(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        const successes = this.sumUpAndDownsFrom1To10(...holeLists);
        const opportunities = this.sum(
            c => c.strokes > 0 && c.upAndDownResultFrom1To10Id !== ModelsCoreRoundsUpAndDownResults.NoAttempt ? 1 : 0,
            holeLists
        );

        return successes / opportunities;
    }

    sumUpAndDownsFrom1To10(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.upAndDownResultFrom1To10Id === ModelsCoreRoundsUpAndDownResults.Yes ? 1 : 0, holeLists);
    }

    calculateUpAndDownFrom11To30Percent(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        const successes = this.sumUpAndDownsFrom11To30(...holeLists);
        const opportunities = this.sum(
            c => c.strokes > 0 && c.upAndDownResultFrom11To30Id !== ModelsCoreRoundsUpAndDownResults.NoAttempt ? 1 : 0,
            holeLists
        );

        return successes / opportunities;
    }

    sumUpAndDownsFrom11To30(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.upAndDownResultFrom11To30Id === ModelsCoreRoundsUpAndDownResults.Yes ? 1 : 0, holeLists);
    }

    calculateUpAndDownFrom31To50Percent(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        const successes = this.sumUpAndDownsFrom31To50(...holeLists);
        const opportunities = this.sum(
            c => c.strokes > 0 && c.upAndDownResultFrom31To50Id !== ModelsCoreRoundsUpAndDownResults.NoAttempt ? 1 : 0,
            holeLists
        );

        return successes / opportunities;
    }

    sumUpAndDownsFrom31To50(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.upAndDownResultFrom31To50Id === ModelsCoreRoundsUpAndDownResults.Yes ? 1 : 0, holeLists);
    }

    sumPutts(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.putts, holeLists);
    }

    avgFirstPuttDistance(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.avg(c => c.firstPuttDistance, holeLists, v => v >= 0).toFixed(1);
    }

    avgLastPuttDistance(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.avg(c => c.lastPuttDistance, holeLists, v => v >= 0).toFixed(1);
    }

    sumLastPuttDistance(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.lastPuttDistance, holeLists);
    }

    sumPenaltyStrokes(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.penaltyStrokes, holeLists);
    }

    sumMulligans(...holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {
        return this.sum(c => c.mulligans, holeLists);
    }

    ensureDefaultStrokes(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        if(hole.strokes === undefined) {
            hole.strokes = hole.par;
        }
    }

    ensureDefaultPutts(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        if(hole.putts === undefined) {
            hole.putts = 2;
        }
    }

    ensureDefaultPenaltyStrokes(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        if(hole.penaltyStrokes === undefined) {
            hole.penaltyStrokes = 0;
        }
    }

    ensureDefaultMulligans(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        if(hole.mulligans === undefined) {
            hole.mulligans = 0;
        }
    }

    onFairwayCheckChanged(
        golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
        hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole,
        checked: boolean) {

        hole.fairwayResultId = checked ? ModelsCoreRoundsFairwayResults.Hit : ModelsCoreRoundsFairwayResults.OkMiss;
        this.onFairwayResultChanged(golfer, hole);
    }

    onGIRCheckChanged(
        golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
        hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole,
        checked: boolean) {

        hole.approachShotResultId = checked ? ModelsCoreRoundsApproachShotResults.GIR : ModelsCoreRoundsApproachShotResults.OkMiss;
        this.onApproachShotResultChanged(golfer, hole);
    }

    onFairwayResultChanged(
        golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
        hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {

        if(!this.isMissedFairway(hole)) {
            hole.fairwayMissId = undefined;
        }

        (hole as any).fairway = hole.fairwayResultId === ModelsCoreRoundsFairwayResults.Hit;

        this.ensureMinimumPenaltyStrokes(golfer, hole);
    }

    onTeeShotClubChanged(
        golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
        hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {

        if(hole.par === 3 && this.trackingApproachShotClub(golfer)) {
            hole.approachShotClubId = hole.teeShotClubId;
        }
    }

    onApproachShotResultChanged(
        golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
        hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {

        if(!this.isMissedApproachShot(hole)) {
            hole.approachShotMissId = undefined;
        }

        const gir = hole.approachShotResultId === ModelsCoreRoundsApproachShotResults.GIR;
        (hole as any).gir = gir;

        if(gir) {
            if(this.trackingChips(golfer) && hole.chipResultId === undefined) {
                hole.chipResultId = ModelsCoreRoundsShortGameResults.NoAttempt;
            }

            if(this.trackingPitches(golfer) && hole.pitchResultId === undefined) {
                hole.pitchResultId = ModelsCoreRoundsShortGameResults.NoAttempt;
            }

            if(this.trackingSandShots(golfer) && hole.sandResultId === undefined) {
                hole.sandResultId = ModelsCoreRoundsShortGameResults.NoAttempt;
            }
        }
        
        this.ensureMinimumPenaltyStrokes(golfer, hole);
    }

    onApproachShotDistanceChanged(
        golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
        hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {

        if(this.isHitGreen(hole) && this.trackingGreenHitFromDistance(golfer)) {
            hole.greenHitFromDistance = hole.approachShotDistance;
        }
    }

    onPuttsChanged(
        golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
        hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {

        if(hole.putts === 0) {
            if(this.trackingFirstPuttDistance(golfer)) {
                hole.firstPuttDistance = 0;
            }

            if(this.trackingLastPuttDistance(golfer)) {
                hole.lastPuttDistance = 0;
            }
        }
    }

    onFirstPuttDistanceChanged(
        golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
        hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {

        if(hole.putts === 1 && this.trackingLastPuttDistance(golfer)) {
            hole.lastPuttDistance = hole.firstPuttDistance;
        }
    }

    onLastPuttDistanceChanged(
        golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
        hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {

        if(hole.putts === 1 && this.trackingFirstPuttDistance(golfer)) {
            hole.firstPuttDistance = hole.lastPuttDistance;
        }
    }

    isMissedFairway(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        return hole.fairwayResultId !== undefined && hole.fairwayResultId !== ModelsCoreRoundsFairwayResults.Hit;
    }

    isMissedApproachShot(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        return hole.approachShotResultId !== undefined
            && hole.approachShotResultId !== ModelsCoreRoundsApproachShotResults.NoAttempt
            && hole.approachShotResultId !== ModelsCoreRoundsApproachShotResults.GIR
            && hole.approachShotResultId !== ModelsCoreRoundsApproachShotResults.Hit;
    }

    isHitGreen(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        return hole.approachShotResultId === ModelsCoreRoundsApproachShotResults.GIR
            || hole.approachShotResultId === ModelsCoreRoundsApproachShotResults.Hit;
    }

    getMinimumPenaltyStrokes(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        let minimum = 0;

        if(hole.fairwayResultId === ModelsCoreRoundsFairwayResults.Penalty) {
            minimum++;
        }

        if(hole.approachShotResultId === ModelsCoreRoundsApproachShotResults.Penalty) {
            minimum++;
        }

        return minimum;
    }

    approachShotWasAttempted(hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {
        return hole.approachShotResultId !== ModelsCoreRoundsApproachShotResults.NoAttempt;
    }

    protected ensureMinimumPenaltyStrokes(
        golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
        hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {

        if(this.trackingPenaltyStrokes(golfer)) {
            const minimum = this.getMinimumPenaltyStrokes(hole);
            const penaltyStrokes = hole.penaltyStrokes || 0;

            if(penaltyStrokes < minimum) {
                hole.penaltyStrokes = minimum;
            }
        }
    }

    protected loadScorecardLookups(options: IScorecardLookupInitializationOptions = { includeEmptyDefaultSelectItems: false }) {
        return this.roundsProxy.getScorecardLookups()
            .pipe(
                map(
                    response => {
                        const lookups = response.body;

                        this.fairwayResults = lookups.fairwayResults.map(
                            r => {
                                return {
                                    value: r.fairwayResultId,
                                    label: r.name,
                                    model: r
                                };
                            });
                        this.fairwayMisses = lookups.fairwayMisses.map(
                            r => {
                                return {
                                    value: r.fairwayMissId,
                                    label: r.name,
                                    model: r
                                };
                            });
                        this.approachShotResults = lookups.approachShotResults.map(
                            r => {
                                return {
                                    value: r.approachShotResultId,
                                    label: r.name,
                                    model: r
                                };
                            });
                        this.approachShotMisses = lookups.approachShotMisses.map(
                            r => {
                                return {
                                    value: r.approachShotMissId,
                                    label: r.name,
                                    model: r
                                };
                            });
                        this.shortGameResults = lookups.shortGameResults.map(
                            r => {
                                return {
                                    value: r.shortGameResultId,
                                    label: r.code,
                                    model: r
                                };
                            });
                        this.upAndDownResults = lookups.upAndDownResults.map(
                            r => {
                                return {
                                    value: r.upAndDownResultId,
                                    label: r.name,
                                    model: r
                                };
                            });
                        this.scorecardLookups = lookups;

                        if(options.includeEmptyDefaultSelectItems) {
                            this.fairwayResults.unshift({ value: '', label: '' });
                            this.fairwayMisses.unshift({ value: '', label: '' });
                            this.approachShotResults.unshift({ value: '', label: '' });
                            this.approachShotMisses.unshift({ value: '', label: '' });
                            this.shortGameResults.unshift({ value: '', label: '' });
                            this.upAndDownResults.unshift({ value: '', label: '' });
                        }

                        return response;
                    }));
    }

    protected loadScorecard(
        golfRoundId: number,
        options: IScorecardInitializationOptions = { includeEmptyDefaultGolfClubSelectItems: false }) {

        this.golfRoundId = golfRoundId;

        const args: ModelsWebApiRoundsGetScorecardArgs = {
            includeGolferHoleInfo: this.includeGolferHoleInfo,
            includePutters: true
        };

        return this.roundsProxy.getScorecard(this.golfRoundId, args)
            .pipe(
                map(
                    response => {
                        this.scorecard = response.body;               
                        this.initializeScorecard(options);
                        return response;
                    }));
    }

    protected initializeScorecard(options: IScorecardInitializationOptions = { includeEmptyDefaultGolfClubSelectItems: true }) {
        if(!this.scorecard.course.back9) {
            this.scorecard.course.back9 = {
                holes: []
            } as any;

            this.scorecard.course.tees.forEach(tee => {
                tee.back9 = {
                    holes: []
                } as ModelsRoundsGetGolfRoundScorecardScorecardTeeCourseNine;
            });
        }

        this.captureCurrentGolferHoles();
        this.convertGolferClubsToSelectItems(options.includeEmptyDefaultGolfClubSelectItems);
        this.startScorecardHubProxy();
        this.initializeNavigationStructures();
    }

    private initializeNavigationStructures() {
        const course = this.scorecard.course;
        const holes = course.front9.holes.concat(course.back9.holes);
        const orderedGolferHoleMatrix: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][] = [];

        this.scorecard.golfers.forEach(golfer => {
            const golferHoles = golfer.front9.concat(golfer.back9);
            orderedGolferHoleMatrix.push(golferHoles);

            this.addGolferSelectItem(golfer);

            const holeSelectItems = this.getHoleSelectItems(golfer, golferHoles, holes);
            this.addToGolferDictionary(golfer, holeSelectItems);
        });

        this.createNavigationItems(this.scorecard.golfers, orderedGolferHoleMatrix);
    }

    private getHoleSelectItems(
        golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
        golferHoles: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[],
        holes: ModelsRoundsGetGolfRoundScorecardScorecardHole[]) {

        const selectItems: SelectItem[] = [];

        golferHoles.forEach(
            (golferHole, index) => {
                const holeKey = this.createHoleKey(golfer, golferHole);
                const selectItem = {
                    value: holeKey,
                    label: holes[index].displayText
                };

                selectItems.push(selectItem);
            });

        return selectItems;
    }

    private addGolferSelectItem(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        const item: SelectItem = {
            value: golfer.golfRoundGolferId,
            label: golfer.displayName
        };

        this.golfers.push(item);
    }

    private addToGolferDictionary(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer, holeSelectItems: SelectItem[]) {
        const golferWithHoles: IGolferWithHoles = {
            golfer: golfer,
            holes: holeSelectItems
        };

        this.golferDictionary[golfer.golfRoundGolferId] = golferWithHoles;
    }

    private createNavigationItems(
        golferRows: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer[],
        golferHoleMatrix: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][]) {

        let done = false;

        for(let holeIndex = 0; !done; holeIndex++) {
            done = true;

            for(let golferIndex = 0; golferIndex < golferHoleMatrix.length; golferIndex++) {
                const golfer = golferRows[golferIndex];
                const golferHoles = golferHoleMatrix[golferIndex].sort((a, b) => {
                    return a.ordinal < b.ordinal ? -1 : 1;
                });

                if(holeIndex < golferHoles.length) {
                    const golferHole = golferHoles[holeIndex];
                    const holeKey = this.createHoleKey(golfer, golferHole);

                    const navItem: INavigationItem = {
                        navigationIndex: this.navigationSequence.length,
                        golfer: golfer,
                        hole: golferHole
                    };

                    this.navigationSequence.push(navItem);
                    this.navigationDictionary[holeKey] = navItem;
                    done = false;
                }
            }
        }
    }

    protected createHoleKey(
        golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
        hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {

        return this.createScorecardUpdateHoleKey(golfer.golfRoundGolferId, hole.holeId, hole.ordinal);
    }

    private createScorecardUpdateHoleKey(golfRoundGolferId: number, holeId: number, ordinal: number) {
        return `${golfRoundGolferId}:${holeId}:${ordinal}`;
    }

    protected saveScorecardModels(models: ModelsWebApiRoundsSaveScorecardModel[]) {
        this.taskStarted();

        return this.roundsProxy.updateScorecard(this.golfRoundId, models)
            .pipe(
                finalize(
                    () => {
                        this.taskCompleted();
                    }),
                tap(
                    () => {
                        this.captureCurrentGolferHoles();
                    }));
    }

    protected getModifiedScorecardData() {
        const models: ModelsWebApiRoundsSaveScorecardModel[] = [];

        this.scorecard.golfers.forEach(
            (golfer, index) => {
                const original = this.originalGolfers[index];
                this.convertGolferHolesToUpdateModel(
                    golfer,
                    golfer.front9,
                    original.front9,
                    models);

                this.convertGolferHolesToUpdateModel(
                    golfer,
                    golfer.back9,
                    original.back9,
                    models);
            });

        return models;
    }

    private captureCurrentGolferHoles() {
        const golfers: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer[] = [];

        this.scorecard.golfers.forEach(
            golfer => {
                const copy: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer = {
                    front9: [],
                    back9: []
                } as ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer;

                this.copyGolferHoles(golfer.front9, copy.front9);
                this.copyGolferHoles(golfer.back9, copy.back9);

                golfers.push(copy);
            });

        this.originalGolfers = golfers;
    }

    private convertGolferClubsToSelectItems(includeEmptyDefaultGolfClubSelectItems: boolean) {
        this.scorecard.golfers.forEach(
            golfer => {
                const golfClubSelectItems: SelectItem[] = golfer.golfClubs
                    .filter(club => club.golfClubCategory.golfClubCategoryId !== 8)
                    .map(club => {
                        return {
                            value: club.golfClubId,
                            label: club.abbreviation,
                            model: club
                        };
                    });

                if(includeEmptyDefaultGolfClubSelectItems) {
                    golfClubSelectItems.unshift({ value: '', label: '' });
                }

                this.golfClubSelectItems[golfer.golfRoundGolferId] = golfClubSelectItems;
            });
    }

    private copyGolferHoles(
        holes: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[],
        copies: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[]) {

        holes.forEach(
            hole => {
                const copy = {
                    adjustedStrokes: hole.adjustedStrokes,
                    approachShotMissId: hole.approachShotMissId,
                    approachShotResultId: hole.approachShotResultId,
                    approachShotDistance: hole.approachShotDistance,
                    approachShotClubId: hole.approachShotClubId,
                    greenHitFromDistance: hole.greenHitFromDistance,
                    chipResultId: hole.chipResultId,
                    description: hole.description,
                    drivingDistance: hole.drivingDistance,
                    fairway: hole.fairway,
                    fairwayMissId: hole.fairwayMissId,
                    fairwayResultId: hole.fairwayResultId,
                    firstPuttDistance: hole.firstPuttDistance,
                    gir: hole.gir,
                    golferHoleInfo: hole.golferHoleInfo,
                    handicap: hole.handicap,
                    holeId: hole.holeId,
                    lastPuttDistance: hole.lastPuttDistance,
                    mulligans: hole.mulligans,
                    netStrokes: hole.netStrokes,
                    ordinal: hole.ordinal,
                    par: hole.par,
                    penaltyStrokes: hole.penaltyStrokes,
                    pitchResultId: hole.pitchResultId,
                    putts: hole.putts,
                    sandResultId: hole.sandResultId,
                    strokes: hole.strokes,
                    teeShotClubId: hole.teeShotClubId,
                    upAndDownResultFrom1To10Id: hole.upAndDownResultFrom1To10Id,
                    upAndDownResultFrom11To30Id: hole.upAndDownResultFrom11To30Id,
                    upAndDownResultFrom31To50Id: hole.upAndDownResultFrom31To50Id,
                    yards: hole.yards
                } as ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole;

                copies.push(copy);
            });
    }

    private sum(
        getValue: (hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) => number,
        holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][],
        filterHole: (hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) => boolean = () => true) {

        let sum = 0;

        for(let i = 0; i < holeLists.length; i++) {
            const holes = holeLists[i];

            for(let j = 0; j < holes.length; j++) {
                const value = getValue(holes[j]);

                if(filterHole(holes[j])) {
                    if(value != null) {
                        sum += value;
                    }
                }
            }
        }

        return sum;
    }

    private avg(
        getValue: (hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) => number,
        holeLists: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[][],
        filterValue: (value: number) => boolean = () => true) {

        let count = 0;
        let sum = 0;

        for(let i = 0; i < holeLists.length; i++) {
            const holes = holeLists[i];

            for(let j = 0; j < holes.length; j++) {
                const value = getValue(holes[j]);
                const include = filterValue(value);

                if(include) {
                    count++;
                    sum += value;
                }
            }
        }

        return count > 0 ? (sum / count) : 0.0;
    }

    private convertGolferHolesToUpdateModel(
        golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer,
        holes: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[],
        originalHoles: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[],
        models: ModelsWebApiRoundsSaveScorecardModel[]) {
        holes.forEach(
            (hole, index) => {
                const originalHole = originalHoles[index];

                if(this.didHoleDataChange(hole, originalHole)) {
                    const model: ModelsWebApiRoundsSaveScorecardModel = {
                        fairwayResult: hole.fairwayResultId as ModelsCoreRoundsFairwayResults,
                        fairwayMiss: hole.fairwayMissId as ModelsCoreRoundsFairwayMisses,
                        approachShotResult: hole.approachShotResultId as ModelsCoreRoundsApproachShotResults,
                        approachShotMiss: hole.approachShotMissId as ModelsCoreRoundsApproachShotMisses,
                        approachShotDistance: hole.approachShotDistance,
                        approachShotClubId: hole.approachShotClubId,
                        greenHitFromDistance: hole.greenHitFromDistance,
                        chipResult: hole.chipResultId as ModelsCoreRoundsShortGameResults,
                        drivingDistance: hole.drivingDistance,
                        firstPuttDistance: hole.firstPuttDistance,
                        lastPuttDistance: hole.lastPuttDistance,
                        golfRoundGolferId: golfer.golfRoundGolferId,
                        holeId: hole.holeId,
                        mulligans: hole.mulligans,
                        ordinal: hole.ordinal,
                        penaltyStrokes: hole.penaltyStrokes,
                        pitchResult: hole.pitchResultId as ModelsCoreRoundsShortGameResults,
                        putts: hole.putts,
                        sandResult: hole.sandResultId as ModelsCoreRoundsShortGameResults,
                        strokes: hole.strokes,
                        teeShotClubId: hole.teeShotClubId,
                        upAndDownResultFrom1To10: hole.upAndDownResultFrom1To10Id as ModelsCoreRoundsUpAndDownResults,
                        upAndDownResultFrom11To30: hole.upAndDownResultFrom11To30Id as ModelsCoreRoundsUpAndDownResults,
                        upAndDownResultFrom31To50: hole.upAndDownResultFrom31To50Id as ModelsCoreRoundsUpAndDownResults
                    };

                    if(!this.approachShotWasAttempted(hole)) {
                        model.approachShotClubId = undefined;
                        model.approachShotDistance = undefined;
                    }

                    if(model.fairwayResult === undefined && this.trackingAnyFairways(golfer)) {
                        model.fairwayResult = hole.fairway ? ModelsCoreRoundsFairwayResults.Hit : ModelsCoreRoundsFairwayResults.OkMiss;
                    }

                    if(model.approachShotResult === undefined && this.trackingAnyGIRs(golfer)) {
                        model.approachShotResult = hole.gir
                            ? ModelsCoreRoundsApproachShotResults.GIR
                            : ModelsCoreRoundsApproachShotResults.OkMiss;
                    }

                    models.push(model);
                }
            });
    }

    private didHoleDataChange(
        hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole,
        originalHole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole) {

        return Object.keys(originalHole)
            .some(key => {
                return (hole[key] !== originalHole[key]);
            });
    }

    private startScorecardHubProxy() {
        this.scorecardHubProxy.onScorecardUpdated()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(updates => this.applyUntrackedScorecardUpdates(updates));

        this.scorecardHubProxy.start()
            .then(() => {
                const golfRoundGolferIds = this.getGolfRoundGolferIdsToTrackForScorecardUpdates();
                this.subscribeToScorecardUpdates(golfRoundGolferIds);
            });
    }

    protected getGolfRoundGolferIdsToTrackForScorecardUpdates(): number[] {
        return this.scorecard.golfers
            .map(golfer => golfer.golfRoundGolferId);
    }

    private stopScorecardHubProxy() {
        const golfRoundGolferIds: number[] = this.scorecard.golfers
            .map(golfer => golfer.golfRoundGolferId);

        this.scorecardHubProxy.unsubscribeFromScorecardUpdates(golfRoundGolferIds)
            .then(() => {
                this.scorecardHubProxy.stop();
            });
    }

    protected subscribeToScorecardUpdates(golfRoundGolferIds: number[]) {
        this.scorecardHubProxy.subscribeToScorecardUpdates(golfRoundGolferIds);
    }

    protected unsubscribeFromScorecardUpdates(golfRoundGolferIds: number[]) {
        this.scorecardHubProxy.unsubscribeFromScorecardUpdates(golfRoundGolferIds);
    }

    private applyUntrackedScorecardUpdates(message: ModelsCoreRoundsScorecardUpdatedMessage) {
        const sourceToDestinationKeyMap: { [key: string]: string } =
        {
            fairwayResult: 'fairwayResultId',
            fairwayMiss: 'fairwayMissId',
            approachShotResult: 'approachShotResultId',
            approachShotMiss: 'approachShotMissId',
            chipResult: 'chipResultId',
            pitchResult: 'pitchResultId',
            sandResult: 'sandResultId',
            upAndDownResultFrom1To10: 'upAndDownResultFrom1To10Id',
            upAndDownResultFrom11To30: 'upAndDownResultFrom11To30Id',
            upAndDownResultFrom31To50: 'upAndDownResultFrom31To50Id'
        };

        const updatedHoles: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole[] = [];

        message.updates.forEach(update => {
            const holeKey = this.createScorecardUpdateHoleKey(update.golfRoundGolferId, update.holeId, update.ordinal);
            const hole = this.getHoleByKey(holeKey);

            if(hole) {
                if(update.strokes > 0) {
                    Object.keys(update)
                        .filter(sourceKey => sourceKey !== 'holeStrokeUpdates')
                        .forEach(sourceKey => {
                        const destinationKey = sourceToDestinationKeyMap[sourceKey] || sourceKey;
                        hole[destinationKey] = update[sourceKey];
                    });
                }
                
                if(update.holeStrokeUpdates) {
                    const holeStrokeUpdateMap: { [ordinal: number]: any } = {};
                    let strokesAdded = false;

                    update.holeStrokeUpdates.forEach(holeStrokeUpdate => {
                        holeStrokeUpdateMap[holeStrokeUpdate.ordinal] = holeStrokeUpdate;

                        if(!hole.holeStrokes.find(hs => hs.ordinal === holeStrokeUpdate.ordinal)) {
                            hole.holeStrokes.push({ ordinal: holeStrokeUpdate.ordinal } as any);
                            strokesAdded = true;
                        }
                    });

                    if(strokesAdded) {
                        hole.holeStrokes.sort((h1, h2) => h1.ordinal < h2.ordinal ? -1 : 1);
                    }
                    
                    hole.holeStrokes.forEach(holeStroke => {
                        const holeStrokeUpdate = holeStrokeUpdateMap[holeStroke.ordinal];

                        if(holeStrokeUpdate) {
                            Object.keys(holeStrokeUpdate).forEach(key => {
                                holeStroke[key] = holeStrokeUpdate[key];
                            });
                        }
                    });
                }

                updatedHoles.push(hole);
            }
        });

        if(updatedHoles.length > 0) {
            this.realTimeHoleUpdates = updatedHoles;
        }
    }

    private getHoleByKey(key: string) {
        const item = this.navigationDictionary[key];
        const hole: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolferHole = item ? item.hole : null;

        return hole;
    }
}
