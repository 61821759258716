/// <reference types="google.maps" />
import {
    ModelsCoreCoursesMapMarkers
} from '../../swagger-codegen/models';

import { warning1MarkerIcon, warning2MarkerIcon, warning3MarkerIcon } from './map-marker-icons';
import { MapMarkerWrapper } from './map-marker-wrapper';

import Icon = google.maps.Icon;
import LatLng = google.maps.LatLng;
import Map = google.maps.Map;
import Point = google.maps.Point;
import Size = google.maps.Size;

interface ModifierSpecificMarkerOptions {
    icon: Icon;
    title: string;
}

export class LocationModifierMapMarker extends MapMarkerWrapper {
    constructor(map: Map, position: LatLng, modifier: number) {
        const options = LocationModifierMapMarker.getModifierSpecificMarkerOptions(modifier);

        super(
            ModelsCoreCoursesMapMarkers.LocationModifier,
            {
                map: map,
                position: position,
                draggable: true,
                icon: options.icon,
                title: options.title
            }
        );

        this.myModifier = modifier;
    }

    get modifier() {
        return this.myModifier;
    }
    
    private myModifier: number;

    createUpdateModel() {
        const model = super.createUpdateModel();
        model.modifier = this.modifier;
        return model;
    }

    private static getModifierSpecificMarkerOptions(modifier: number) {
        const options: ModifierSpecificMarkerOptions = {
            icon: {
                anchor: new Point(16, 16),
                scaledSize: new Size(32, 32),
                url: ''
            },
            title: '???'
        };

        switch(modifier) {
            case 1:
                options.title = 'Avoid: Bad (+1)';
                options.icon.url = warning1MarkerIcon;
                break;
            case 2:
                options.title = 'Avoid: Very Bad (+2)';
                options.icon.url = warning2MarkerIcon;
                break;
            case 3:
                options.title = 'Avoid: Extremely Bad (+3)';
                options.icon.url = warning3MarkerIcon;
                break;
        }

        return options;
    }
}
